<template>
  <div class="card relative grid grid-cols-3 divide-x-2 divide-grey w-full py-8">
    <VinLoadingBlock v-if="isFetching" />
    <div class="col-span-1 pl-10">
      <VinWidgetHeader
        :title="$t('Best Performing Vehicle')"
        :timestamp="generatedAt"
        @bust-cache="fetchData(true)"
      />

      <div class="flex items-center justify-between mt-6 pb-2 w-2/3">
        <VinGravatar
          :email="email"
          :name="name === '--' ? '?' : name"
          :circle="true"
          :size="52"
          class="text-xl"
          style="min-width: 50px;"
        />
        <VinTooltip
          id="best-vehicle-tip"
          placement="top"
          trigger="hover"
          content-padding="p-0"
          header-padding="p-0"
          class="leading-none text-grey-dk transition-2s"
        >
          <template
            #content
          >
            <div
              style="width:200px;"
              class="p-4 leading-tight text-center cursor-default"
            >
              <div class="w-full mx-auto px-2">
                <span class="text-xs text-grey-dk font-semithin">
                  {{ $t('click_to_view_vehicle') }}
                </span>
              </div>
            </div>
          </template>
          <div
            class="hover:cursor-pointer hover:underline"
            @click="navToVehicle"
          >
            <div class="flex items-center">
              <div>
                <VinTitle
                  el="h3"
                  :text="name"
                  class="font-bold text-copy text-lg mb-0 leading-none my-2"
                />
                <VinTitle
                  el="h3"
                  :text="$t('Fleet Driver')"
                  class="font-normal text-grey-dk text-lg mb-2 leading-none my-2"
                />
              </div>
            </div>
          </div>
        </VinTooltip>
      </div>
    </div>

    <div class="col-span-2 grid grid-cols-2 pt-8">
      <div class="col-span-1 flex flex-col items-center pt-4">
        <VinTitle
          el="h3"
          :text="vehicle"
          class="font-bold text-copy text-lg mb-0"
        />
        <VinStatChange
          v-if="delta"
          :label="$t('more_efficient')"
          :is-percent="true"
          :change="delta"
          change-class="positive"
        />
      </div>
      <VinCar
        :image="icon"
        class="col-span-1 pr-24"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'

import { composeWidget } from '../compose-widget'
export default {
  name: 'WidgetBestVehicle',
  displayName: 'Best Performing Vehicle',
  icon: 'Best',
  setup () {
    const widget = {
      slug: 'report-fleet-cost-league-table',
    }
    const report = {}

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    rows () {
      if (this.results) {
        return this.results.rows || []
      }
      return []
    },
    highestFuelEconRow () {
      if (this.rows.length > 0) {
        return this.rows.reduce((p, c) => {
          return (p.fuelEconomy > c.fuelEconomy) ? p : c
        })
      }
      return null
    },
    name () {
      // handle empty string names
      const name = get(this.highestFuelEconRow, 'driver', '')
      return name ? name : '--'
    },
    email () {
      return get(this.highestFuelEconRow, 'driver.email', '--')
    },
    vehicle () {
      // handle empty string make & model
      const vehicleString = `${get(this.highestFuelEconRow, 'make', '')} ${get(this.highestFuelEconRow, 'model', '')}`
      return vehicleString === ' ' ? '--' : vehicleString
    },
    icon () {
      const v = this.$store.getters.getVehicleById(get(this.highestFuelEconRow, 'vehicleId', null))
      return get(v, 'icon', null)
    },
    delta () {
      const fuelEcon = get(this.highestFuelEconRow, 'fuelEconomy', 0)

      if (fuelEcon > 0) {
        const total = get(this.results, 'totals.totalFuelEconomy', 0)
        if (total > 0) {
          const localTotal = formatNumber(total, {
            type: 'fuel',
            unit: 'econ',
          }).value()
          const localBest = formatNumber(fuelEcon, {
            type: 'fuel',
            unit: 'econ',
          }).value()

          let diff = 0
          this.$store.state.localizationModule.fuelEcoUnit === 'L/100km'
            ? diff = localTotal - localBest
            : diff = localBest - localTotal
          const delta = (diff / localTotal * 100).toFixed(2)
          return delta < 0 ? delta * -1 : delta
        }
      }
      return '--'
    },
  },
  methods: {
    navToVehicle () {
      if (this.highestFuelEconRow) {

        this.$router.push({
          name: 'VehicleDetails',
          params: {
            orgId: get(this.$store.state.authModule, 'activeOrg.id'),
            vehicleId: this.highestFuelEconRow.vehicleId,
          },
        })
      }
    },
  },
}
</script>
