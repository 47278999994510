<template>
  <div class="flex items-center justify-center cursor-pointer text-copy hover:text-primary-dk -ml-4">
    <div class="w-1/4">
      <VinGravatar
        :circle="true"
        :email="email"
        :name="name"
        class="mr-4 text-xl"
      />
    </div>

    <div class="w-3/4">
      <div class="flex text-xl font-normal leading-normal tracking-normal">
        {{ name }}
      </div>
      <span class="text-sm font-normal leading-none tracking-normal">
        {{ phone }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpotlightResultDriver',
  props: {
    driver: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    name () {
      const first = this.driver?.firstName ?? ''
      const last = this.driver?.lastName ?? ''
      return first === '' && last === '' ? this.$t('unknown_driver') : `${first} ${last}`
    },
    phone () {
      return this.driver?.phone ?? ''
    },
    email () {
      return this.driver?.email ?? ''
    },
  },
}
</script>
