<template>
  <main>
    <AppHeaderDesktop v-if="currentBreakpoint === 'desktop'" />
    <AppHeaderMobile v-if="currentBreakpoint !== 'desktop'" />
  </main>
</template>

<script>
import AppHeaderDesktop from './AppHeaderDesktop'
import AppHeaderMobile from './AppHeaderMobile'
export default {
  name: 'AppHeaderDefault',
  components: {
    AppHeaderDesktop,
    AppHeaderMobile,
  },
  computed: {
    currentBreakpoint () {
      return this.$store.state.baseModule.breakpoint
    },
  },
}
</script>
