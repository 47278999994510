<template>
  <div>
    <div class="container px-container relative">
      <div class="flex absolute z-10 top-0 right-0 pr-container ">
        <VinButton
          vid="add-poi-btn"
          :color="!showCreatePlace ? 'cta-lt' : 'grey'"
          :text="$t('poi_add')"
          :class="!showCreatePlace ? '' : 'hover:cursor-not-allowed'"
          class="mt-4 px-16 text-base lg:font-normal xl:font-bold tracking-normal rounded-lg"
          @click="!showCreatePlace ?
            handleAdd() :
            $emit('disabled')"
        />
      </div>
    </div>
    <div class="map-wrapper relative">
      <div class="relative">
        <div class="flex absolute z-10 top-0 right-0 bottom-0 h-full">
          <VehicleLocationsCreate
            :show="showCreatePlace"
            :errors="newPlace.errors"
            @handleName="setName"
            @handleMode="setMode"
            @handleCancel="closePlace"
            @handleSave="savePlace"
          />
        </div>
      </div>
      <GmapMap
        id="locationsMap"
        ref="locationsMap"
        :center="{ lat: 45, lng: -20 }"
        :zoom="3.5"
        :options="mapStyle"
        class="vehicle-locations-map"
        style="width: 100%; height: 60vh;"
      >
        <GmapMarker
          v-if="placeMapSearchSelection"
          :position="{
            lat: placeMapSearchSelection.lat,
            lng: placeMapSearchSelection.lng
          }"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue'

import colors from '@/assets/themes/colors'
import mapstyle from '@/common/utils/map-style'

import VehicleLocationsCreate from './LocationsMapPopover/VehicleLocationsCreate'
export default {
  name: 'VehicleLocationsMap',
  components: {
    VehicleLocationsCreate,
  },
  data () {
    return {
      // VehicleLocationsCreate data store
      showCreatePlace: false,
      orgPlaces: [],
      targetPlace: null,
      newPlace: {
        name: '',
        description: '',
        isValid: false,
        errors: {},
      },
      // POST places payload
      place: {
        shape: {
          type: '',
          coordinates: [],
        },
        radius: null,
      },
      // gmap
      map: null,
      drawingManager: null,
      mode: 'polygon',
      overlays: [],
      fences: [],
      mapTypeId: 'roadmap',
      fenceStyles: {
        strokeColor: colors['primary-dk'],
        strokeWeight: '6',
        fillColor: colors['primary-dk'],
        fillOpacity: '0.2',
      },
    }
  },
  computed: {
    google: getGoogleMapsAPI,
    placeMapSearchSelection () {
      return this.$store.state.placesModule.placeMapSearchSelection
    },
    computedOrgPlaces () {
      return this.$store.state.placesModule.orgPlaces
    },
    computedTargetPlace () {
      return this.$store.state.placesModule.targetPlace
    },
    mapStyle () {
      // only style the default map
      const styles = this.mapTypeId === 'roadmap' ? mapstyle : { styles: [] }
      if (this.google) {
        return {
          ...styles,
          zoomControlOptions: {
            position: this.showCreatePlace ? google.maps.ControlPosition.LEFT_BOTTOM : google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          streetViewControlOptions: {
            position: this.showCreatePlace ? google.maps.ControlPosition.LEFT_BOTTOM : google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          fullscreenControlOptions: {
            position: this.showCreatePlace ? google.maps.ControlPosition.LEFT_TOP : google.maps.ControlPosition.RIGHT_TOP,
          },
        }
      }
      return styles
    },
  },
  watch: {
    placeMapSearchSelection () {
      if (this.placeMapSearchSelection) {
        this.panMap()
      }
    },
    // watch places, destroy dangling fence on delete
    computedOrgPlaces () {
      this.orgPlaces = this.computedOrgPlaces
      if (this.orgPlaces.length > 0) {
        this.destroyFences()
        this.renderFences()
      } else {
        this.fences.forEach(idx => {
          idx.fence.setMap(null)
        })
        this.fences = []
      }
    },
    computedTargetPlace () {
      this.targetPlace = this.computedTargetPlace
      if (this.targetPlace) {
        this.panToFence()
      } else {
        this.destroyFences()
        this.renderFences()
      }
    },
  },
  created () {
    this.orgPlaces = this.computedOrgPlaces
    this.targetPlace = this.computedTargetPlace
  },
  mounted () {
    this.$refs.locationsMap.$mapPromise.then(map => {
      const _self = this
      // set the map type with listener
      map.addListener('maptypeid_changed', function () {
        const newMapType = map.getMapTypeId()
        _self.mapTypeId = newMapType
      })

      this.$nextTick().then(() => {
        if (this.orgPlaces.length > 0) {
          this.renderFences()
          this.map = map
        } else {
          this.map = map
        }
      })
    }).catch(err => {
      throw err
    })
  },
  methods: {
    // gmap DrawingManager methods start
    mountDrawingManager () {
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        polygonOptions: {
          editable: false,
          ...this.fenceStyles,
        },
        circleOptions: {
          editable: false,
          ...this.fenceStyles,
        },
      })
    },
    destroyDrawingManager () {
      for (var i = 0; i < this.overlays.length; i++) {
        this.overlays[i].overlay.setMap(null)
      }
      this.overlays = []
      this.drawingManager.setMap(null)
      this.drawingManager = null
      this.place = {
        shape: {
          type: '',
          coordinates: [],
        },
        radius: null,
      }
      this.newPlace = {
        name: '',
        description: '',
        isValid: false,
        errors: {},
      }
      this.$store.commit('SET_MAP_PLACE_SELECTION', null)
    },
    attachOverlayListener () {
      const _self = this
      this.google.maps.event.addListener(this.drawingManager, 'overlaycomplete', function (e) {
        _self.overlays.push(e)
        if (_self.mode === 'polygon') {
          _self.completePolygon(e)
        }
        if (_self.mode === 'circle') {
          _self.completeCircle(e)
        }
      })
    },
    setMode (mode) {
      for (var i = 0; i < this.overlays.length; i++) {
        this.overlays[i].overlay.setMap(null)
      }
      this.overlays = []
      this.place = {
        shape: {
          type: '',
          coordinates: [],
        },
        radius: null,
      }
      this.mode = mode
      this.drawingManager.setDrawingMode(mode)
    },
    // gmap Autocomplete center map method
    panMap () {
      let bounds = new google.maps.LatLngBounds()
      let loc = new google.maps.LatLng(this.placeMapSearchSelection.lat, this.placeMapSearchSelection.lng)
      bounds.extend(loc)
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.025, bounds.getNorthEast().lng() + 0.025)
        var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.025, bounds.getNorthEast().lng() - 0.025)
        bounds.extend(extendPoint1)
        bounds.extend(extendPoint2)
      }
      this.$refs.locationsMap.fitBounds(bounds)
    },
    // location render & create methods start
    renderFences () {
      let bounds = new google.maps.LatLngBounds()
      this.orgPlaces.forEach(p => {
        let type = p.shape.type
        if (type === 'Polygon') {
          this.drawPolygon(p)
          let coords = p.shape.coordinates[0]
          coords.forEach(c => {
            bounds.extend({
              lat: c[1],
              lng: c[0],
            })
          })
        }
        if (type === 'Point') {
          this.drawCircle(p)
          bounds.extend({
            lat: p.shape.coordinates[1],
            lng: p.shape.coordinates[0],
          })
          // TODO: extract bounds from gmap.circle()
          const offset = this.getCircleBounds(p)
          bounds.extend({
            lat: offset.Lat0,
            lng: offset.Lng0,
          })
          bounds.extend({
            lat: offset.Lat1,
            lng: offset.Lng1,
          })
        }
      })
      this.$nextTick().then(() => {
        this.$refs.locationsMap.fitBounds(bounds)
      })
      this.$refs.locationsMap.panToBounds(bounds)
    },
    destroyFences () {
      let filtered = this.fences.filter(o1 => this.orgPlaces.some(o2 => o1.id !== o2.id))
      filtered.forEach(idx => {
        idx.fence.setMap(null)
      })
    },
    panToFence () {
      let bounds = new google.maps.LatLngBounds()
      const type = this.targetPlace.shape.type
      const p = this.targetPlace
      if (type === 'Polygon') {
        let coords = p.shape.coordinates[0]
        coords.forEach(c => {
          bounds.extend({
            lat: c[1],
            lng: c[0],
          })
        })
      }
      if (type === 'Point') {
        bounds.extend({
          lat: p.shape.coordinates[1],
          lng: p.shape.coordinates[0],
        })
        // TODO: extract bounds from gmap.circle()
        const offset = this.getCircleBounds(p)
        bounds.extend({
          lat: offset.Lat0,
          lng: offset.Lng0,
        })
        bounds.extend({
          lat: offset.Lat1,
          lng: offset.Lng1,
        })
      }
      this.$nextTick().then(() => {
        this.$refs.locationsMap.fitBounds(bounds)
      })
      this.$refs.locationsMap.panToBounds(bounds)
    },
    drawPolygon (place) {
      let coords = place.shape.coordinates[0]
      let polyCoords = []
      coords.forEach(c => {
        polyCoords.push({ lat: c[1], lng: c[0] })
      })
      let fence = new this.google.maps.Polygon({
        paths: polyCoords,
        ...this.fenceStyles,
      })
      this.$refs.locationsMap.$mapPromise.then((map) => {
        fence.setMap(map)
        this.fences.push({
          fence: fence,
          placeId: place.id,
        })
      })
    },
    completePolygon (event) {
      this.place.shape.type = 'Polygon'
      let vertices = event.overlay.getPath()
      const polyCoords = []
      for (var i = 0; i < vertices.getLength(); i++) {
        var xy = vertices.getAt(i)
        polyCoords.push([xy.lng(), xy.lat()])
      }
      var first = vertices.getAt(0)
      polyCoords.push([first.lng(), first.lat()])
      this.place.shape.coordinates.push(polyCoords)
      this.drawingManager.setDrawingMode(null)
    },
    drawCircle (place) {
      let circle = new this.google.maps.Circle({
        center: {
          lat: place.shape.coordinates[1],
          lng: place.shape.coordinates[0],
        },
        radius: place.radius,
        ...this.fenceStyles,
      })
      this.$refs.locationsMap.$mapPromise.then((map) => {
        circle.setMap(map)
        this.fences.push({
          fence: circle,
          placeId: place.id,
        })
      })
    },
    getCircleBounds (p) {
      const dLat = p.radius / 6378137
      const dLng = p.radius / (6378137 * Math.cos(Math.PI * p.shape.coordinates[1] / 180))
      const Lat0 = p.shape.coordinates[1] + dLat * 180 / Math.PI
      const Lng0 = p.shape.coordinates[0] + dLng * 180 / Math.PI
      const Lat1 = p.shape.coordinates[1] - dLat * 180 / Math.PI
      const Lng1 = p.shape.coordinates[0] - dLng * 180 / Math.PI
      return { Lat0, Lng0, Lat1, Lng1 }
    },
    completeCircle (event) {
      this.place.shape.type = 'Point'
      let center = event.overlay.getCenter()
      let lat = center.lat()
      let lng = center.lng()
      this.place.shape.coordinates = [lng, lat]
      this.place.radius = event.overlay.getRadius()
      this.drawingManager.setDrawingMode(null)
    },
    // VehicleLocationsCreate methods start
    clearError (field) {
      this.$set(this.newPlace.errors, field, null)
    },
    setName (name) {
      this.clearError('locationName')
      this.newPlace.name = name
    },
    handleAdd () {
      this.showCreatePlace = true
      this.mode = 'polygon'
      this.mountDrawingManager()
      this.$refs.locationsMap.$mapPromise.then((map) => {
        this.fences.forEach(idx => {
          idx.fence.setMap(null)
        })
        this.map = map
        this.drawingManager.setMap(map)
        this.drawingManager.setDrawingMode('polygon')
        this.attachOverlayListener()
      })
    },
    validate () {
      let valid = true
      if (this.newPlace.name === '') {
        valid = false
        this.$set(this.newPlace.errors, 'locationName', this.$t('name_is_required'))
      }
      if (this.newPlace.name.length > 40) {
        valid = false
        this.$set(this.newPlace.errors, 'locationName', this.$t('name_min_length', { length: 40 }))
      }
      if (this.place.shape.coordinates.length === 0) {
        valid = false
        this.$set(this.newPlace.errors, 'coordinates', this.$t('place_shape_required'))
      }
      if (this.place.shape.type === 'Point' && !this.place.radius) {
        valid = false
        this.$set(this.newPlace.errors, 'radius', this.$t('place_radius_required'))
      }
      if (valid) {
        this.newPlace.errors = {}
      }
      this.newPlace.isValid = valid
    },
    savePlace () {
      this.validate()
      if (this.newPlace.isValid) {
        let payload = this.place
        payload.name = this.newPlace.name
        payload.orgId = this.$store.state.authModule.activeOrg.id
        this.$store.dispatch('CREATE_PLACE_PROMISE', payload)
          .then(() => {
            this.$store.dispatch('SERVE_TOAST', {
              type: 'success',
              message: 'New location created!',
            })
            this.closePlace()
          })
          .catch(err => {
            this.$store.dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'error_msg_place_creation',
            })
            this.closePlace()
            throw err
          })
      }
    },
    closePlace () {
      this.showCreatePlace = false
      this.destroyDrawingManager()
      if (this.orgPlaces.length > 0) {
        this.renderFences()
      }
    },
  },
}
</script>

<style>
.vehicle-locations-map {
  position: relative;
  z-index: 0;
}
.vehicle-locations-map > .map-wrapper {
  position: relative;
  z-index: 5;
}
.vehicle-locations-map:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 10;
  pointer-events: none;
  -webkit-box-shadow: inset 0 4px 24px 0 rgba(37, 38, 94, 0.15);
  -moz-box-shadow: inset 0 4px 24px 0 rgba(37, 38, 94, 0.15);
  box-shadow: inset 0 4px 24px 0 rgba(37, 38, 94, 0.15);
}
</style>
