<template>
  <div :id="vid">
    <span
      v-if="shouldShow"
      class="tag-styles text-center outline-none inline-block leading-normal rounded-xl pl-4 pr-3 py-1"
    >
      <div class="flex items-center">
        <span class="font-semibold tracking-relaxed">
          <slot /> </span>
        <x-icon
          v-if="closable"
          class="text-primary-dk ml-2 h-5 hover:cursor-pointer"
          @click="closeAction"
        />
      </div>
    </span>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
export default {
  name: 'VinTag',
  components: {
    XIcon,
  },
  props: {
    name: {
      type: [String, Number],
      default: null,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: null,
    },
    vid: {
      type: String,
      required: true,
      default: null,
    },
  },
  data () {
    return {
      shouldShow: true,
    }
  },
  methods: {
    closeAction (evt) {
      if (typeof this.name === 'undefined') {
        this.$emit('on-close', evt)
        this.shouldShow = false
      } else {
        this.$emit('on-close', this.name)
        this.shouldShow = false
      }
    },
  },
}
</script>
<style scoped>
.tag-styles {
  background-color: rgba(0, 141, 255, 0.2);
}
</style>
