<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="calculate-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="gradientStart"
        />
        <stop
          offset="100%"
          :stop-color="gradientStop"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="48"
        height="48"
        fill="url(#calculate-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        transform="translate(16 12)"
      >
        <path
          stroke-width="1.778"
          d="M15.092 23.656H1.482A1.484 1.484 0 0 1 0 22.174V1.483C0 .666.665 0 1.483 0h13.609c.818 0 1.483.666 1.483 1.483v20.69c0 .818-.665 1.483-1.483 1.483z"
        />
        <ellipse
          cx="4.177"
          cy="11.802"
          fill="#FFF"
          stroke-width=".5"
          rx="1.019"
          ry="1.007"
        />
        <ellipse
          cx="8.338"
          cy="11.802"
          fill="#FFF"
          stroke-width=".5"
          rx="1.019"
          ry="1.007"
        />
        <ellipse
          cx="12.498"
          cy="11.802"
          fill="#FFF"
          stroke-width=".5"
          rx="1.019"
          ry="1.007"
        />
        <ellipse
          cx="4.177"
          cy="15.911"
          fill="#FFF"
          stroke-width=".5"
          rx="1.019"
          ry="1.007"
        />
        <ellipse
          cx="8.338"
          cy="15.911"
          fill="#FFF"
          stroke-width=".5"
          rx="1.019"
          ry="1.007"
        />
        <ellipse
          cx="12.498"
          cy="15.911"
          fill="#FFF"
          stroke-width=".5"
          rx="1.019"
          ry="1.007"
        />
        <ellipse
          cx="4.177"
          cy="20.02"
          fill="#FFF"
          stroke-width=".5"
          rx="1.019"
          ry="1.007"
        />
        <ellipse
          cx="8.338"
          cy="20.02"
          fill="#FFF"
          stroke-width=".5"
          rx="1.019"
          ry="1.007"
        />
        <ellipse
          cx="12.498"
          cy="20.02"
          fill="#FFF"
          stroke-width=".5"
          rx="1.019"
          ry="1.007"
        />
        <rect
          width="9.54"
          height="3.556"
          x="3.6"
          y="4.444"
          stroke-width="1.778"
          rx=".889"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import iconMix from '../icon-mixin'
export default {
  name: 'VinCalculateIcon',
  mixins: [iconMix],
}
</script>
