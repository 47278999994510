import get from 'lodash.get'
import Vue from 'vue'

import axios from '../middleware/axios-store'
// import validateSession from '../middleware/validate-session'

const CONSTANTS_KEY = 'constants'
const SERVICE_LOCATIONS_KEY = 'service-locations'
const MAINTENANCE_STATUS_KEY = 'maintenance-status'
const SERVICE_SCHEDULE_KEY = 'service-schedule-status'
const MAINTENANCE_DELETING_KEY = 'maintenance-deleting'

const module = {
  state: {
    isFetchingMaintenance: {},
    enabledForOrgPack: false,
    maintenanceCTAMethod: '',
    maintenanceCTAString: '',
    serviceLocations: [],
    targetServiceLocation: {},
    serviceLocationPagination: {
      limit: 20,
      offset: 0,
      total: 0,
    },
    serviceLocationUploadErrors: [],
    vehicleMakes: [],
    orgSettings: [],
    serviceTypes: [],
    orgServiceSettings: {},
    vehicleMaintenanceRecords: {},
    vehicleServiceScheduleRecords: {},
    serviceScheduleQueryParams: {},
  },
  getters: {
    isMaintenanceEnabled: state => {
      return state.enabledForOrgPack
    },
    maintenanceStatusForVehicleId: state => vehicleId => {
      return get(state.vehicleMaintenanceRecords, vehicleId, null)
    },
    serviceScheduleForVehicleId: state => vehicleId => {
      return get(state.vehicleServiceScheduleRecords, vehicleId, null)
    },
    isFetchingMaintenance: state => slug => {
      // grab for slug
      if (slug) {
        return state.isFetchingMaintenance[slug]
      }
      // grab for any in flight slug
      return Object.values(state.isFetchingMaintenance).some((v) => v === true)
    },
    isLoading: state => (keys) => {
      return keys.some(key => state.isFetchingMaintenance[key])
    },
  },
  mutations: {
    CLEAR_SERVICE_MAINTENANCE_MODULE: (state) => {
      state.isFetchingMaintenance = {}
      state.enabledForOrgPack = false
      state.maintenanceCTAMethod = ''
      state.maintenanceCTAString = ''
      state.serviceLocations = []
      state.targetServiceLocation = {}
      state.serviceLocationPagination = {
        limit: 20,
        offset: 0,
        total: 0,
      }
      state.serviceLocationUploadErrors = []
      state.vehicleMakes = []
      state.orgSettings = []
      state.serviceTypes = []
      state.orgServiceSettings = {}
      state.vehicleMaintenanceRecords = {}
    },
    TOGGLE_MAINTENANCE_LOADING: (state, payload) => {
      Vue.set(state.isFetchingMaintenance, payload.key, payload.flag)
    },
    SET_SERVICE_MAINTENANCE_PACK_SETTINGS: (state, pack) => {
      state.enabledForOrgPack = pack.maintenanceEnabled
      state.maintenanceCTAMethod = pack.maintenanceCTAMethod
      state.maintenanceCTAString = pack.maintenanceCTAString
    },
    SET_SERVICE_LOCATIONS: (state, locations) => {
      state.serviceLocations = locations
    },
    SET_SERVICE_LOCATION_PAGINATION: (state, pagination) => {
      state.serviceLocationPagination.offset = pagination.offset
      state.serviceLocationPagination.limit = pagination.limit
    },
    SET_SERVICE_LOCATION_TOTAL: (state, total) => {
      state.serviceLocationPagination.total = total
    },
    SET_TARGET_SERVICE_LOCATION: (state, location) => {
      state.targetServiceLocation = location
    },
    SET_SERVICE_SCHEDULE_PARAMS: (state, payload) => {
      state.serviceScheduleQueryParams = payload
    },
    SET_SERVICE_TYPES: (state, types) => {
      state.serviceTypes = types
    },
    SET_VEHICLE_MAKES: (state, makes) => {
      state.vehicleMakes = makes
    },
    SET_ORG_SETTINGS: (state, orgSettings) => {
      state.orgSettings = orgSettings
    },
    SET_ORG_SERVICE_SETTINGS: (state, orgServiceSettings) => {
      state.orgServiceSettings = orgServiceSettings
    },
    ADD_MAINTENANCE_STATUS_ENTRY: (state, { resp, vehicleId }) => {
      Vue.set(state.vehicleMaintenanceRecords, vehicleId, resp)
    },
    ADD_SERVICE_SCHEDULE_ENTRY: (state, { resp, vehicleId }) => {
      if(!state.vehicleServiceScheduleRecords[vehicleId]){
        Vue.set(state.vehicleServiceScheduleRecords, vehicleId, resp)
      } else {
        Vue.set(state.vehicleMaintenanceRecords[vehicleId], 'serviceInfo',resp)
      }
    },
    SET_SERVICE_SCHEDULE_ENTRY: (state, { resp, vehicleId }) => {
      Vue.set(state.vehicleServiceScheduleRecords, vehicleId, resp)
    },
    COMPLETE_SERVICE_ENTRY: (state, { vehicleId, entry }) => {
      const vehicleEntry = { ...state.vehicleServiceScheduleRecords[vehicleId] }
      const currentInfo = vehicleEntry?.serviceInfo ?? []
      const updatedInfo = currentInfo.map(r => {
        if (r.serviceSchedule.id === entry.id) {
          r.serviceSchedule = entry
          // check for the typo :(
          if (r.mainenanceWindow) {
            r.mainenanceWindow.status = { serviced: true}
          }
          if (r.maintenanceWindow) {
            r.maintenanceWindow.status = { serviced: true}
          }
        }
        return r
      })
      vehicleEntry.serviceInfo = updatedInfo
      Vue.set(state.vehicleServiceScheduleRecords, vehicleId, vehicleEntry)
    },
  },
  actions: {
    async HYDRATE_SERVICE_MAINTENANCE ({ commit, dispatch }, pack) {
      commit('CLEAR_SERVICE_MAINTENANCE_MODULE')

      if (!pack) {
        return
      }

      commit('SET_SERVICE_MAINTENANCE_PACK_SETTINGS', pack)

      if (pack.maintenanceEnabled) {
        dispatch('FETCH_ORG_MAINTENANCE_CONSTANTS')
      }
    },
    FETCH_ORG_MAINTENANCE_CONSTANTS ({ commit, getters, dispatch, rootState }) {
      const orgId = get(rootState, 'authModule.activeOrg.id')

      if (getters.isFetchingMaintenance(CONSTANTS_KEY) || !orgId) {
        return
      }

      commit('TOGGLE_MAINTENANCE_LOADING', { key: CONSTANTS_KEY, flag: true })

      axios.get(`/api/v1/orgs/${orgId}/constants`)
        .then(r => {
          commit('SET_VEHICLE_MAKES', get(r, 'data.vehicleMakes', []))
          commit('SET_SERVICE_TYPES', get(r, 'data.serviceTypes', []))
          commit('SET_ORG_SETTINGS', get(r, 'data.orgSettings[0]', {}))
          commit('SET_ORG_SERVICE_SETTINGS', get(r, 'data.orgServiceSettings[0]', {}))
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'org_maintenance_constants_fetch_error',
          })
          throw e
        })

      commit('TOGGLE_MAINTENANCE_LOADING', { key: CONSTANTS_KEY, flag: false })
    },
    FETCH_ORG_SERVICE_LOCATIONS ({ state, commit, getters, dispatch, rootState }) {
      const orgId = get(rootState, 'authModule.activeOrg.id')

      if (getters.isFetchingMaintenance(SERVICE_LOCATIONS_KEY) || !orgId) {
        return
      }

      commit('TOGGLE_MAINTENANCE_LOADING', { key: SERVICE_LOCATIONS_KEY, flag: true })

      axios.get(`/api/v1/orgs/${orgId}/servicelocations`, {
        params: {
          limit: state.serviceLocationPagination.limit,
          offset: state.serviceLocationPagination.offset,
        },
      })
        .then(r => {
          commit('SET_SERVICE_LOCATIONS', get(r, 'data.serviceLocations', []))
          commit('SET_SERVICE_LOCATION_TOTAL', get(r, 'data.meta.pagination.total', 0))
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'service_locations_fetch_error',
          })
          throw e
        })

      commit('TOGGLE_MAINTENANCE_LOADING', { key: SERVICE_LOCATIONS_KEY, flag: false })
    },
    UPDATE_SERVICE_LOCATION_PROMISE ({ dispatch, rootState }, payload) {
      const orgId = get(rootState, 'authModule.activeOrg.id')
      const serviceLocationID = get(payload, 'id')

      if (!orgId || !serviceLocationID) {
        return
      }

      const makesArrayPayload = payload.vehicleMakes.map(m => {
        return {
          name: m,
        }
      })

      const newServiceLocationPayload = {
        serviceLocation: {
          active: true,
          name: payload.name,
          lat: payload.lat,
          lon: payload.lon,
          address: payload.address,
          phone: payload.phone,
        },
        vehicleMakes: makesArrayPayload,
      }

      axios.put(`/api/v1/orgs/${orgId}/servicelocations/${serviceLocationID}`, newServiceLocationPayload)
        .then(() => {
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'service_locations_update_success',
          })
          dispatch('FETCH_ORG_SERVICE_LOCATIONS')
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'service_locations_update_error',
          })
          throw e
        })
    },
    CREATE_SERVICE_LOCATION_PROMISE ({ dispatch, rootState }, payload) {
      const orgId = get(rootState, 'authModule.activeOrg.id')

      if (!orgId) {
        return
      }

      const makesArrayPayload = payload.vehicleMakes.map(m => {
        return {
          name: m,
        }
      })

      const newServiceLocationPayload = {
        serviceLocations: [{
          serviceLocation: {
            active: true,
            name: payload.name,
            lat: Number(payload.lat),
            lon: Number(payload.lon),
            address: payload.address,
            phone: payload.phone,
          },
          vehicleMakes: makesArrayPayload,
        }],
      }

      axios.post(`/api/v1/orgs/${orgId}/servicelocations`, newServiceLocationPayload)
        .then(() => {
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'service_locations_create_success',
          })
          dispatch('FETCH_ORG_SERVICE_LOCATIONS')
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'service_locations_create_error',
          })
          throw e
        })
    },
    BULK_CREATE_SERVICE_LOCATION_PROMISE ({ dispatch, rootState }, payload) {
      const orgId = get(rootState, 'authModule.activeOrg.id')

      if (!orgId) {
        return
      }

      const bulkPayload = {
        serviceLocations: [],
      }

      payload.forEach(sl => {
        const makesArrayPayload = sl.vehicleMakes.map(m => {
          return {
            name: m,
          }
        })
        const slPayload = {
          active: true,
          name: sl.name,
          lat: Number(sl.lat),
          lon: Number(sl.lon),
          address: sl.address,
          phone: sl.phone,
        }
        bulkPayload.serviceLocations.push({
          serviceLocation: slPayload,
          vehicleMakes: makesArrayPayload,
        })
      })

      axios.post(`/api/v1/orgs/${orgId}/servicelocations`, bulkPayload)
        .then(() => {
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'service_locations_create_success',
          })
          dispatch('FETCH_ORG_SERVICE_LOCATIONS')
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'service_locations_create_error',
          })
          throw e
        })
    },
    DELETE_SERVICE_LOCATION_PROMISE ({ dispatch, rootState }, locationId) {
      const orgId = get(rootState, 'authModule.activeOrg.id')

      if (!orgId) {
        return
      }

      axios.delete(`/api/v1/orgs/${orgId}/servicelocations/${locationId}`)
        .then(() => {
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'service_locations_delete_success',
          })
          dispatch('FETCH_ORG_SERVICE_LOCATIONS')
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'service_locations_delete_error',
          })
          throw e
        })
    },
    FETCH_SERVICE_STATUS_FOR_VEHICLE ({ commit, getters, dispatch, rootState }, vehicle) {
      let orgId = get(rootState, 'authModule.activeOrg.id')

      if (!vehicle.id) {
        return
      }

      // require authModule orgId first
      if (getters.isFetchingMaintenance(MAINTENANCE_STATUS_KEY + vehicle.id) || !orgId) {
        return
      }

      // if the vehicle has a different orgId from platform service, use it.
      const vOrgId = get(vehicle, 'orgId', null)
      if (vOrgId !== null && vOrgId !== orgId) {
        orgId = vOrgId
      }

      commit('TOGGLE_MAINTENANCE_LOADING', { key: MAINTENANCE_STATUS_KEY + vehicle.id, flag: true })

      axios.get(`/api/v1/orgs/${orgId}/vehicles/${vehicle.id}/maintenance-status`)
        .then(r => {
          commit('ADD_MAINTENANCE_STATUS_ENTRY', {
            resp: r.data,
            vehicleId: vehicle.id,
          })
        })
        .catch(e => {
          const errStatus = e.response.status
          if (errStatus === 404) {
            // no maintenance records for this vehicle, likely rollup err
            // stash in state without toast err
            commit('ADD_MAINTENANCE_STATUS_ENTRY', {
              resp: errStatus,
              vehicleId: vehicle.id,
            })

            if (vehicle.shouldWarn) {
              dispatch('SERVE_TOAST', {
                type: 'warning',
                message: 'service_status_no_records',
              })
            }
          }
          else {
            // fatal
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'service_status_fetch_error',
            })
            throw e
          }
        })

      commit('TOGGLE_MAINTENANCE_LOADING', { key: MAINTENANCE_STATUS_KEY + vehicle.id, flag: false })
    },
    FETCH_SERVICE_STATUS_FOR_FLEET ({ commit, getters, dispatch, rootState }) {
      const orgId = get(rootState, 'authModule.activeOrg.id')

      // require authModule orgId first
      if (getters.isFetchingMaintenance(MAINTENANCE_STATUS_KEY) || !orgId) {
        return
      }

      // setup fetch function for recursion
      const fetch = (url, offset = 0) => {
        // toggle fetch flag
        commit('TOGGLE_MAINTENANCE_LOADING', { key: MAINTENANCE_STATUS_KEY, flag: true })
        // make api call
        axios.get(url, {
          params: {
            offset: offset,
          },
        }).then(resp => {
          const vehiclesResp = get(resp.data, 'vehicles', [])
          vehiclesResp?.forEach(v => {
            const vehicleId = v.vehicle.id
            commit('ADD_MAINTENANCE_STATUS_ENTRY', {
              resp: v,
              vehicleId: vehicleId,
            })
          })

          // parse pagination
          const total = get(resp, 'data.meta.pagination.total')
          const limit = get(resp, 'data.meta.pagination.limit')
          const offset = get(resp, 'data.meta.pagination.offset')
          if (total > limit + offset) {
            const next = get(resp, 'data.meta.pagination.links.next')
            // if we don't have all the vehicles yet, fetch next page
            if (next) {
              fetch(url, offset + limit)
            }
          } else {
            commit('TOGGLE_MAINTENANCE_LOADING', { key: MAINTENANCE_STATUS_KEY, flag: false })
          }
        }).catch(e => {
          commit('TOGGLE_MAINTENANCE_LOADING', { key: MAINTENANCE_STATUS_KEY, flag: false })
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'service_status_fetch_error',
          })
          throw e
        })
      }

      // kick off the initial request
      const startUrl = `/api/v1/orgs/${orgId}/maintenance-status?limit=100`

      fetch(startUrl)
    },
    BULK_UPDATE_SERVICE_STATUS_FOR_VEHICLE ({ dispatch, rootState }, payload) {
      const orgId = get(rootState, 'authModule.activeOrg.id')
      if (!orgId) {
        return
      }

      payload.orgId = orgId

      axios.post(`/api/v1/orgs/${orgId}/maintenance-status`, payload)
        .then(() => {
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'update_successful',
          })
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'service_status_update_error',
          })
          throw e
        })
    },
    async COMPLETE_SERVICE_FOR_VEHICLE ({ dispatch, commit }, payload) {
      const { maintenanceId, serviceScheduleId, vehicleId, orgId } = payload
      if (!maintenanceId || !serviceScheduleId) {
        return
      }

      try {
        const { data } = await axios.put(`/api/v1/orgs/${orgId}/maintenance/${maintenanceId}/schedule/${serviceScheduleId}`, payload)
        commit('COMPLETE_SERVICE_ENTRY', { vehicleId, entry: data?.serviceSchedule})
        dispatch('SERVE_TOAST', {
          type: 'success',
          message: 'update_successful',
        })
        dispatch('FETCH_SERVICE_STATUS_FOR_VEHICLE', { id: vehicleId, orgId })
      } catch (error) {
        console.error(error)
        dispatch('SERVE_TOAST', {
          type: 'danger',
          message: 'service_status_update_error',
        })
      }
    },
    SERVICE_SCHEDULE_CREATE ({ dispatch }, payload) {
      const { orgId, vehicleId } = payload

      axios.post(`/api/v1/orgs/${orgId}/maintenance/${payload.maintenanceId}/schedule`, {
        serviceSchedule: payload.serviceScheduleCreate,
      })
        .then(() => {
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'schedule_create_successful',
          })
          dispatch('FETCH_SERVICE_STATUS_FOR_VEHICLE', { id: vehicleId, orgId })
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'service_status_create_error',
          })
          throw e
        })
    },
    SERVICE_SCHEDULE_DELETE ({ dispatch, commit, state }, payload) {
      if (!payload.maintenanceOrgId) {
        return
      }
      if (!state.isFetchingMaintenance[MAINTENANCE_DELETING_KEY]) {
        commit('TOGGLE_MAINTENANCE_LOADING', { key: MAINTENANCE_DELETING_KEY, flag: true })
        axios.delete(`/api/v1/orgs/${payload.maintenanceOrgId}/maintenance/${payload.maintenanceId}/schedule/${payload.serviceScheduleId}`)
          .then(() => {
            dispatch('SERVE_TOAST', {
              type: 'success',
              message: 'schedule_delete_successful',
            })
            dispatch('FETCH_SERVICE_STATUS_FOR_VEHICLE', { id: payload.vehicleId, orgId: payload.maintenanceOrgId })
          })
          .catch(e => {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'service_status_delete_error',
            })
            throw e
          }).finally(() => {
            commit('TOGGLE_MAINTENANCE_LOADING', { key: MAINTENANCE_DELETING_KEY, flag: false })
          })
      }
    },
    SAVE_ORG_MAINTENANCE_SETTINGS ({ state, commit, dispatch, rootState }, settings) {
      settings.orgId = get(rootState, 'authModule.activeOrg.id')
      if (!settings.orgId) {
        return
      }

      settings.id = get(state, 'orgSettings.id') // hack for broken api
      if (!settings.id) {
        return
      }

      // merge with existing settings
      const current = state.orgSettings ?? {}
      const updated = Object.assign(current, settings)

      const orgSettings = {
        'orgSettings': updated,
      }

      axios.put(`/api/v1/orgs/${settings.orgId}/settings/${settings.id}`,
        orgSettings,
      )
        .then(r => {
          commit('SET_ORG_SETTINGS', get(r, 'data.orgSettings', {}))
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'org_settings_updated',
          })
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'org_settings_save_error',
          })
          throw e
        })
    },
    SAVE_FIXED_INTERVALS ({ state, commit, dispatch, rootState }, settings) {
      const orgId = get(rootState, 'authModule.activeOrg.id')
      const orgServiceSettingsId = get(state, 'orgServiceSettings.id')

      if (!orgId || !orgServiceSettingsId) {
        return
      }

      settings.id = orgServiceSettingsId

      settings.serviceTypeId = get(state, 'orgServiceSettings.serviceTypeId')
      if (!settings.serviceTypeId) {
        return
      }

      settings.active = true

      const payload = {
        'orgServiceSetting': settings,
      }

      axios.put(`/api/v1/orgs/${orgId}/service-settings/${orgServiceSettingsId}`,
        payload,
      )
        .then(r => {
          const ss = r.data.orgServiceSetting
          commit('SET_ORG_SERVICE_SETTINGS', ss)
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'fixed_service_setings_saved',
          })
        })
        .catch(e => {
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'fixed_service_setings_error',
          })
          throw e
        })
    },
    FETCH_VEHICLE_SERVICE_SCHEDULE ({ commit, dispatch, rootState }, payload, replaceResults = false) {
      const orgId = get(rootState, 'authModule.activeOrg.id')

      if (!orgId) {
        return
      }

      commit('TOGGLE_MAINTENANCE_LOADING', { key: SERVICE_SCHEDULE_KEY, flag: true })

      commit('SET_SERVICE_SCHEDULE_PARAMS', payload)

      const params = {
        limit: 1000,
        since: payload.since,
        until: payload.until,
      }

      const comm = replaceResults ? 'SET_SERVICE_SCHEDULE_ENTRY' : 'ADD_SERVICE_SCHEDULE_ENTRY'
      axios.get(`/api/v1/orgs/${orgId}/vehicles/${payload.vehicleId}/service-info-paginated`, {
        params: params,
      })
        .then(r => {
          commit(comm, {
            resp: r.data,
            vehicleId: payload.vehicleId,
          })
        })
        .catch(e => {
          const errStatus = e.response.status
          if (errStatus === 404) {
            // no maintenance records for this vehicle, likely rollup err
            // stash in state without toast err
            commit(comm, {
              resp: errStatus,
              vehicleId: payload.vehicleId,
            })
          }
          else {
            // fatal
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'service_status_fetch_error',
            })
            throw e
          }
        }).finally(() => {
          commit('TOGGLE_MAINTENANCE_LOADING', { key: SERVICE_SCHEDULE_KEY, flag: false })
        })

    },
    CLEAR_SERVICE_SCHEDULE_FOR_VEHICLE ({state}, vehicleId) {
      return Vue.delete(state.vehicleServiceScheduleRecords, vehicleId)
    },
    CLEAR_SERVICE_SCHEDULE_ENTRY: ({state}, {vehicleId, serviceScheduleId}) => {
      let filtered = state.vehicleServiceScheduleRecords[vehicleId]['serviceInfo'].filter((ss) => {
        return ss.serviceSchedule.id != serviceScheduleId
      })

      state.vehicleServiceScheduleRecords[vehicleId]['serviceInfo'] = filtered
    },
  },
}

export default module
