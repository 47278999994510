<template>
  <ServiceLocationsPopover :show="show">
    <template #content>
      <div class="flex flex-col justify-between h-full">
        <div class="flex flex-col">
          <ServiceLocationsSearch
            v-if="showAddressSearch"
            @select="handleAddress"
          />
          <hr class="w-full border-grey">
          <ServiceLocationsName
            :input-name="inputName"
            @name-input="handleName"
          />
          <ServiceLocationsPhone
            :input-phone="inputPhone"
            @phone-input="handlePhone"
          />
          <ServiceLocationsVehicleMakes
            :input-vehicle-makes="inputVehicleMakes"
            @vehicle-makes-input="handleVehicleMakes"
          />
          <p
            v-for="(val, key) in errors"
            :key="key"
            class="mx-8 text-danger-lt text-sm"
          >
            {{ val }}
          </p>
        </div>
        <div>
          <div class="px-8 flex items-center justify-between mb-6">
            <div class="flex justify-between w-full">
              <VinButton
                :vid="'cancel-new-sl-btn'"
                :text="$t('Cancel')"
                class="w-1/2 mr-4 text-base lg:font-normal xl:font-bold border-grey text-cta-lt"
                @click="handleCancel"
              />
              <VinButton
                :vid="'save-new-sl-btn'"
                :text="$t('Save')"
                color="cta-lt"
                class="w-1/2 text-base lg:font-normal xl:font-bold"
                @click="handleSave"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </ServiceLocationsPopover>
</template>

<script>
import ServiceLocationsName from './ServiceLocationsName'
import ServiceLocationsPhone from './ServiceLocationsPhone'
import ServiceLocationsPopover from './ServiceLocationsPopover'
import ServiceLocationsSearch from './ServiceLocationsSearch'
import ServiceLocationsVehicleMakes from './ServiceLocationsVehicleMakes'
export default {
  name: 'ServiceLocationsCreate',
  components: {
    ServiceLocationsPopover,
    ServiceLocationsSearch,
    ServiceLocationsVehicleMakes,
    ServiceLocationsName,
    ServiceLocationsPhone,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => { },
    },
    inputPhone: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: '',
    },
    inputVehicleMakes: {
      type: Array,
      default: () => { return [] },
    },
    showAddressSearch: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleSave () {
      this.$emit('handle-save')
    },
    handleCancel () {
      this.$emit('handle-cancel')
    },
    handlePhone (payload) {
      this.$emit('handle-phone', payload)
    },
    handleName (payload) {
      this.$emit('handle-name', payload)
    },
    handleAddress (payload) {
      this.$emit('handle-address', payload)
    },
    handleVehicleMakes (payload) {
      this.$emit('handle-vehicle-makes', payload)
    },
  },
}
</script>
