<template>
  <VinSmallWidgetContainer
    :show-loading-block="fetching"
  >
    <VinWidgetHeader
      :title="title"
      :timestamp="timestamp"
      :icon="icon"
      @bust-cache="$emit('bust-cache')"
    />
    <VinWidgetStat
      v-if="hasStat"
      :fetching="fetching"
      :stat="stat"
      class="mb-32"
    />
    <div
      v-if="hasDriver"
      style="paddingTop:6px;"
    >
      <VinSmallWidgetDriverEvents
        :text="$t('Best Driver')"
        :driver="best"
        class="sm:pt-8 xl:pt-2 max:pt-4"
      />
      <VinSmallWidgetDriverEvents
        :text="$t('Highest Risk')"
        :driver="worst"
        class="sm:pt-6 xl:pt-2 max:pt-6"
      />
    </div>
    <VinSmallWidgetFooter
      v-if="packHasReport"
      :cta-title="ctaTitle"
      :cta-action="ctaAction"
      class="absolute bottom-0 w-full pr-20"
      style="height:72px"
    />
  </VinSmallWidgetContainer>
</template>

<script>
export default {
  name: 'VinSmallWidgetTemplate',
  props: {
    title: {
      type: String,
      default: '',
    },
    timestamp: {
      type: [String, Number],
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    stat: {
      type: Object,
      default: () => {
        return {
          numeral: '--',
          unit: '',
        }
      },
    },
    packHasReport: {
      type: Boolean,
      default: false,
    },
    ctaTitle: {
      type: String,
      default: '',
    },
    ctaAction: {
      type: Function,
      default: () => {},
    },
    best: {
      type: Object,
      default: () => {},
    },
    worst: {
      type: Object,
      default: () => {},
    },
    hasStat: {
      type: Boolean,
      default: true,
    },
    hasDriver: {
      type: Boolean,
      default: false,
    },
    hasFuel: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
