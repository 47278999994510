const optionsList = [
  'maxDailyDrivingHours',
  'maxLongDrivingHours',
  'highRiskHours',
  'daysStanding',
  'maxTripDistance',
  'maxDailyDistance',
]

const module = {
  state: {
    windowWidth: 1440,
    breakpoint: 'desktop',
    settingOptions: optionsList,
    mobileDrawerOpen: false,
    previousRoute: null,
    pageCount: -1,
  },
  mutations: {
    SET_REPORT_PARAMS: (state, payload) => {
      state.settingOptions = payload
    },
    RESET_REPORT_PARAMS: (state) => {
      state.settingOptions = optionsList
    },
    CLEAR_BASE_MODULE: (state) => {
      state.mobileDrawerOpen = false
      state.previousRoute = null
    },
    SET_WINDOW_WIDTH (state, payload) {
      state.windowWidth = payload
      if (state.windowWidth >= 1200) {
        state.breakpoint = 'desktop'
      }
      if (state.windowWidth < 1200 && state.windowWidth >= 768) {
        state.breakpoint = 'tablet'
      }
      if (state.windowWidth < 768 && state.windowWidth >= 320) {
        state.breakpoint = 'mobile'
      }
    },
    // drawer
    TOGGLE_DRAWER: (state, flag) => {
      state.mobileDrawerOpen = flag
    },
    // route
    SET_PREVIOUS_ROUTE: (state, route) => {
      state.pageCount++
      state.previousRoute = route
    },
  },
}

export default module
