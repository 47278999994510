<template>
  <div id="view-default">
    <AppHeaderDefault />
    <AppNav />

    <div v-if="!$store.getters.packIsSimple">
      <VehicleLocationsMap v-if="currentBreakpoint === 'desktop'" />
      <div
        :class="currentBreakpoint === 'desktop' ? '' : 'pt-8'"
        class="w-full bg-white"
      >
        <div
          id="fleet-dashboard-container"
          class="container"
        >
          <router-view name="defaultHeader" />
        </div>
      </div>
      <div
        id="fleet-dashboard-container"
        :class="bottomPadding"
        class="container"
      >
        <router-view name="default" />
      </div>
    </div>
    <div
      v-else
      id="fleet-dashboard-container"
      :class="bottomPadding"
      class="container pt-12"
    >
      {{ $t('POIs are not enabled for your organization. Contact your ALD Account Manager for more information.') }}
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/App/AppFooter'
import AppHeaderDefault from '@/components/App/AppHeader/AppHeaderDefault'
import AppNav from '@/components/App/AppNav/AppNav'

import VehicleLocationsMap from '../components/Vehicles/Locations/VehicleLocationsMap'
export default {
  name: 'ViewLocations',
  components: {
    AppHeaderDefault,
    AppNav,
    VehicleLocationsMap,
    AppFooter,
  },
  computed: {
    hasMap () {
      return this.$store.state.dashboardModule.map && this.$store.getters.isAuthenticated
    },
    currentBreakpoint () {
      return this.$store.state.baseModule.breakpoint
    },
    bottomPadding () {
      return this.$route.meta.bottomPadding || 'pb-24'
    },
  },
}
</script>
<style>
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-to {
  opacity: 0;
}
</style>
