<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="report-journey-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="colors['icon-gradient-0']"
        />
        <stop
          offset="100%"
          :stop-color="colors['icon-gradient-1']"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="48"
        height="48"
        fill="url(#report-journey-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path
          stroke-width="2"
          d="M21.61 12.526L16.656 35.54h16.106l-4.954-23.014z"
        />
        <path
          stroke-width="1.943"
          d="M24.709 12.526v3.162M24.709 20.905v4.186M24.709 30.331v5.209"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import colors from '@/assets/themes/colors'
export default {
  name: 'IconJourneySummary',
  computed: {
    colors () {
      return colors
    },
  },
}
</script>
