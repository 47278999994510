import getTheme from './get-theme'

const setUrl = url => {
  delete window.location
  window.location = {
    hostname: url,
  }
}

describe('get-theme.js', () => {

  it('should choose default theme', () => {
    expect(getTheme()).toBe('profleet')
  })

  it('should choose lvs theme', () => {
    setUrl('lvs.myprofleet.net')
    expect(getTheme()).toBe('lvs')

    setUrl('lvs-fleet.ald.vinli.eu')
    expect(getTheme()).toBe('lvs')
  })

  it('should choose profleet theme', () => {
    setUrl('ald.myprofleet.net')
    expect(getTheme()).toBe('profleet')

    setUrl('fleet.ald.vinli.eu')
    expect(getTheme()).toBe('profleet')
  })

  it('should choose generic theme', () => {
    setUrl('fleet-demo.d01.vin.li')
    expect(getTheme()).toBe('generic')
  })
})
