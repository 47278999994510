import Vue from 'vue'

const determineAbbrv = (val, abbrvAt) => {
  const isOutside = (x, max) => {
    x = Math.abs(x)
    return x >= max
  }

  const thresh = Math.ceil(Math.log10(Math.abs(val + 1)))
  let shouldAbbrv = false

  switch (abbrvAt) {
    // '1.[5]K'
    case 4:
      shouldAbbrv = isOutside(val, 1000)
      break

    // '10.[5]K'
    case 5:
      shouldAbbrv = isOutside(val, 10000)
      break

    // '100.[25]K',
    case 6:
      shouldAbbrv = isOutside(val, 100000)
      break

    // '1.[25]M',
    case 7:
      shouldAbbrv = isOutside(val, 1000000)
      break

    // '1.[25]B',
    case 8:
      shouldAbbrv = isOutside(val, 1000000000)
      break

    // '1.[25]T',
    default:
      shouldAbbrv = true
  }

  return {
    thresh,
    shouldAbbrv,
  }
}

const abbreviate = (val, numeral, thresh) => {
  switch (thresh) {
    // '1.[5]K' '10.[5]K' '100.[5]K'
    case 4:
    case 5:
    case 6:
      // floor nearest hundredth
      val = Math.floor(val / 100) * 100
      return numeral(val).format('0.[0]a')

    // '1.[25]M' '10.[25]M' '100.[25]M'
    case 7:
    case 8:
    case 9:
      // floor nearest ten thousandth
      val = Math.floor(val / 10000) * 10000
      return numeral(val).format('0.[00]a')

    // '1.[25]B' '10.[25]B' '100.[25]B'
    case 10:
    case 11:
    case 12:
      // floor nearest 10 million
      val = Math.floor(val / 10000000) * 10000000
      return numeral(val).format('0.[00]a')

    // '1.[25]T' '10.[25]T' '100.[25]T'
    default:
      // floor to nearest 10 billion
      val = Math.floor(val / 10000000000) * 10000000000
      return numeral(val).format('0.[00]a')
  }
}

// integer() formatter - truncate decimals
export const integer = (val, numeral, abbrvAt = 6) => {

  if (typeof abbrvAt === 'number') {
    const {
      thresh,
      shouldAbbrv,
    } = determineAbbrv(val, abbrvAt, numeral)

    return shouldAbbrv
      ? abbreviate(val, numeral, thresh)
      : numeral(val).format('0,0')
  }

  return numeral(val).format('0,0')
}

// float() formatter - two optional decimal places
export const float = (val, numeral, abbrvAt = 6) => {
  // '-0.[99]' ... '0.[99]'
  if (-1 < val && val < 1) {
    return numeral(val).format('0.[00]')
  }

  if (typeof abbrvAt === 'number') {
    const {
      thresh,
      shouldAbbrv,
    } = determineAbbrv(val, abbrvAt, numeral)

    return shouldAbbrv
      ? abbreviate(val, numeral, thresh)
      : numeral(val).format('0,0.[00]')
  }

  return numeral(val).format('0,0.[00]')
}

// currency() formatter - two required decimal places
export const currency = (val, numeral, abbrvAt = 5) => {
  // -0.99 ... 0.99
  if (-1 < val && val < 1) {
    return numeral(val).format('0.00')
  }

  if (typeof abbrvAt === 'number') {
    const {
      thresh,
      shouldAbbrv,
    } = determineAbbrv(val, abbrvAt, numeral)

    return shouldAbbrv
      ? abbreviate(val, numeral, thresh)
      : numeral(val).format('0,0.00')
  }

  return numeral(val).format('0,0.00')
}

// duration() formatter - '2days 3hours 10min 20sec'
export const duration = (milli, units) => {
  if (!milli) {
    return {
      fn: '--',
      pairs: [],
    }
  }

  const secs = {
    val: Math.floor(milli / 1000),
    unit: 's',
    thresh: 60,
  }
  const mins = {
    val: Math.floor(milli / 1000 / 60),
    unit: 'min',
    thresh: 60,
  }
  const hrs = {
    val: Math.floor(milli / 1000 / 60 / 60),
    unit: 'hr',
    thresh: 24,
  }
  const dayVal = Math.floor(milli / 1000 / 60 / 60 / 24)
  const days = {
    val: dayVal,
    unit: dayVal > 1 ? 'days' : 'day',
    thresh: Number.POSITIVE_INFINITY,
  }

  const calcArr = [days, hrs, mins, secs]
  const pairs = []
  let fmtStr = ''

  calcArr.forEach((e) => {
    if (e.val >= e.thresh) {
      e.val = Math.floor(e.val % e.thresh)
    }
    if (e.val !== 0) {
      // remove plural for compare
      const compareStr = e.unit === 'days' ? 'day' : e.unit

      if (units.includes(compareStr)) {
        const val = e.val
        const unit = Vue.i18n.translate(e.unit)
        pairs.push({
          val: val,
          unit: unit,
        })
        fmtStr += `${val}${unit} `
      }
    }
  })

  return {
    fn: fmtStr.trim(),
    pairs: pairs,
  }
}
