<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-high-mileage-icon"
        x1="91.704%"
        x2="17.237%"
        y1="1.917%"
        y2="60.839%"
      >
        <stop
          offset="0%"
          stop-color="#979AA7"
        />
        <stop
          offset="100%"
          stop-color="#7F8394"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-high-mileage-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M41.263 41.263H14.737a4.421 4.421 0 0 1-4.421-4.42v-5.896A2.947 2.947 0 0 1 13.263 28h2.948a2.947 2.947 0 0 1 2.947 2.947v2.948a1.474 1.474 0 1 1-2.947 0v-2.948h-2.948v5.895c0 .814.66 1.474 1.474 1.474h26.526c.814 0 1.474-.66 1.474-1.474V17.684h-2.948v16.21a1.474 1.474 0 1 1-2.947 0v-16.21a2.947 2.947 0 0 1 2.947-2.947h2.948a2.947 2.947 0 0 1 2.947 2.947v19.158a4.421 4.421 0 0 1-4.42 4.421zm-8.842-7.368v-7.369a2.947 2.947 0 0 0-2.947-2.947h-2.948a2.947 2.947 0 0 0-2.947 2.947v7.369a1.474 1.474 0 1 0 2.947 0v-7.369h2.948v7.369a1.474 1.474 0 1 0 2.947 0z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconHighMileage',
}
</script>
