var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container px-container relative"},[_c('div',{staticClass:"flex absolute z-10 top-0 right-0 pr-container"},[_c('VinButton',{staticClass:"mt-4 px-16 text-base lg:font-normal xl:font-bold tracking-normal rounded-lg",class:!_vm.showCreateLocation ? '' : 'hover:cursor-not-allowed',attrs:{"vid":"add-sl-btn","color":!_vm.showCreateLocation ? 'cta-lt' : 'grey',"text":_vm.$t('sl_add')},on:{"click":function($event){!_vm.showCreateLocation ?
          _vm.handleAdd() :
          _vm.$emit('disabled')}}})],1)]),_c('div',{staticClass:"map-wrapper relative"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex absolute z-10 top-0 right-0 bottom-0 h-full"},[_c('ServiceLocationsCreate',{attrs:{"show":_vm.showCreateLocation,"show-address-search":_vm.showAddressSearch,"errors":_vm.newSL.errors,"input-phone":_vm.newSL.phone,"input-name":_vm.newSL.name,"input-vehicle-makes":_vm.newSL.vehicleMakes},on:{"handle-name":_vm.setName,"handle-phone":_vm.setPhone,"handle-address":_vm.setLocation,"handle-vehicle-makes":_vm.setVehicleMakes,"handle-cancel":_vm.closeCreateServiceLocation,"handle-save":_vm.saveServiceLocation}})],1)]),_c('GmapMap',{ref:"serviceLocationsMap",staticClass:"vehicle-locations-map",staticStyle:{"width":"100%","height":"60vh"},attrs:{"id":"serviceLocationsMap","center":{ lat: 45, lng: -20 },"zoom":3.5,"options":_vm.mapStyle}},[(_vm.computedTargetServiceLocation && _vm.computedTargetServiceLocation.lat && _vm.computedTargetServiceLocation.lon)?_c('GmapMarker',{attrs:{"position":{
          lat: _vm.computedTargetServiceLocation.lat,
          lng: _vm.computedTargetServiceLocation.lon
        }}}):_c('div',_vm._l((_vm.computedOrgServiceLocations),function(sl,idx){return _c('GmapMarker',{key:idx,attrs:{"position":{
            lat: sl.lat,
            lng: sl.lon
          }}})}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }