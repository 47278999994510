<template>
  <div
    :id="idPrefix + 'table-container'"
    class="overflow-x-auto card pt-4"
  >
    <v-table
      :id="idPrefix"
      :data="data"
      :class="tableFixed ? 'table-fixed' : ''"
      class="w-full"
    >
      <thead slot="head">
        <th
          v-if="selectable"
          :class="compact ? 'p-2' : 'pl-4 pt-2 pb-5'"
        >
          <VinCheckbox
            v-model="selectAll"
            :vid="`${idPrefix}-table-header-checkbox-${i}`"
          />
        </th>
        <v-th
          v-for="(header, i) in sortableHeaders"
          :key="i + 'header'"
          ref="headers"
          :sort-key="header.key"
          class="th-svg text-left text-copy select-none hover:cursor-pointer"
          :class="compact ? 'pb-2' : 'px-2 pt-3 pb-5'"
          @click.native="logClick(header.text, header.key)"
        >
          <div class="ml-4 pl-2">
            {{ $t(header.text) }}
          </div>
        </v-th>
        <th
          v-for="(header, i) in staticHeaders"
          :key="i + 'header'"
          class="th-svg text-left text-copy select-none hover:cursor-pointer"
          :class="compact ? 'pb-2' : 'px-2 pt-3 pb-5'"
        >
          <div class="ml-4 pl-2">
            {{ $t(header.text) }}
          </div>
        </th>
      </thead>
      <tbody
        slot="body"
        slot-scope="{displayData}"
        class="border-grey-lt border-t"
      >
        <tr
          v-for="(row, i) in displayData"
          :key="i + '-row'"
          :class="classForRow(i)"
          class="text-grey-dk"
        >
          <td
            v-if="selectable"
            :class="compact ? 'p-2' : 'pl-4 py-6'"
          >
            <VinCheckbox
              :vid="`${idPrefix}-table-body-checkbox-${i}`"
              :value="selectAll"
              @input="$event ? $emit('row-selected', row) : $emit('row-deselected', row)"
            />
          </td>
          <td
            v-for="cell in cells"
            :key="cell"
            class="text-sm pointer-events-none"
            :class="compact ? 'p-2' : 'pl-4 py-6'"
          >
            {{ row[cell] }}
          </td>
          <td v-if="tableBtn">
            <router-link
              v-if="ctaEnabled(row)"
              :to="row.meta.ctaRoute || '/'"
              @click="handleCta(row)"
            >
              <div class="vin-tbl-cta-rect flex items-center justify-center border-2 border-grey rounded-md hover:cursor-pointer hover:border-border text-cta-lt hover:text-cta-dk">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 7 14"
                  class="stroke-current"
                >
                  <path
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 13l6-6-6-6"
                  />
                </svg>
              </div>
            </router-link>
          </td>
        </tr>
      </tbody>
    </v-table>
    <smart-pagination v-if="pagination" />
  </div>
</template>

<script>
// styled wrapper of https://tochoromero.github.io/vuejs-smart-table/
import get from 'lodash.get'

export default {
  name: 'VinTable',
  props: {
    // data is the table data
    data: {
      type: Array,
    },
    headers: {
      type: Array,
      // [{ text: 'Vehicle', key: 'vehicle}]
    },
    tableBtn: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    striped: {
      type: Boolean,
    },
    // cuts down on row padding to fit more in smaller area
    compact: {
      type: Boolean,
    },
    tableFixed: {
      type: Boolean,
    },
    selectable: {
      type: Boolean,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    idPrefix: {
      type: String,
      default: null,
      required: true,
    },
    defaultSort: {
      type: Object,
      default: () => {
        return {
          column: '',
          direction: '',
        }
      },
    },
  },
  data () {
    return {
      selectAll: false,
      sortSync: {
        column: '',
        direction: '',
      },
    }
  },
  computed: {
    sortableHeaders () {
      return this.sortable ? this.headers : []
    },
    staticHeaders () {
      return this.sortable ? [] : this.headers
    },
    cells () {
      // rowId is a whitelisted key, that will not apper in cell data
      // use this to handle CTA events for a row
      const meta = 'meta'
      let keys = []
      const example = get(this.data, '[0]', {})
      for (let key in example) {
        if (key !== meta) {
          keys.push(key)
        }
      }
      return keys
    },
    currentSort () {
      const slugResults = this.$store.getters.resultsForSlug(this.idPrefix)
      return slugResults?.sortKey ?? null
    },
  },
  watch: {
    selectAll () {
      this.$emit('select-all', this.selectAll)
    },
  },
  mounted () {
    this.sortSync = this.currentSort
      ? this.currentSort
      : this.defaultSort

    if (this.sortSync.column) {
      this.triggerSort()
    }

  },
  methods: {
    triggerSort () {
      this.$refs.headers.forEach((el, idx) => {
        if (el?.$options?.propsData?.sortKey === this.sortSync.column) {
          this.$refs.headers[idx].sort()
          if (this.sortSync.direction && this.sortSync.direction === 'ascending') {
            this.$refs.headers[idx].sort()
          }
        }
      })
    },
    handleCta (row) {
      const payload = get(row, 'meta.rowId', row)
      this.$emit('cta-clicked', payload)
    },
    ctaEnabled (row) {
      return !get(row, 'meta.ctaDisabled', false)
    },
    checkNested (obj, level, ...rest) {
      if (obj === undefined) return false
      if (rest.length === 0 && obj.hasOwnProperty(level)) return true
      return this.checkNested(obj[level], ...rest)
    },
    classForRow (i) {
      let classString = ''
      const odd = i % 2 !== 0
      classString = odd ? 'border-t border-b border-grey-lt' : ''
      classString += odd && this.striped ? ' bg-grey-ltr' : ''
      return classString
    },
    logClick (val, sortKey) {
      if (this.sortSync.column && this.sortSync.column === sortKey) {
        if (this.sortSync.direction && this.sortSync.direction === 'ascending') {
          this.sortSync = this.defaultSort
          this.triggerSort()
        } else {
          this.sortSync = {
            column: sortKey,
            direction: 'ascending',
          }
        }
      } else {
        this.sortSync = {
          column: sortKey,
          direction: 'descending',
        }
      }

      this.$emit('sortCol', {
        slug: this.idPrefix,
        sortKey: this.sortSync,
      })

    },
  },
}
</script>
<style>
.vin-tbl-cta-rect {
  height: 52px;
  width: 52px;
}
.th-svg svg {
  margin-bottom: -25px;
  margin-left: 4px;
  height: 18px;
  width: 18px;
}
</style>
