<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-overrev-comparison-icon"
        x1="91.704%"
        x2="11.892%"
        y1="1.917%"
        y2="65.069%"
      >
        <stop
          offset="0%"
          stop-color="#876ED5"
        />
        <stop
          offset="100%"
          stop-color="#A08BE3"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-overrev-comparison-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M15.783 41.69a1.474 1.474 0 0 1-1.046-2.52l8.238-8.237h-8.238a1.474 1.474 0 0 1 0-2.948h11.79a1.474 1.474 0 0 1 1.046 2.52L16.829 41.248c-.275.282-.652.44-1.046.443zM28 39.79v-4.422a1.474 1.474 0 1 0-2.947 0v4.421a1.474 1.474 0 0 0 2.947 0zm15.061-14.737c0-.814-.66-1.474-1.474-1.474H33.35l7.9-7.899a1.474 1.474 0 0 0-2.078-2.078l-10.42 10.42a1.474 1.474 0 0 0 1.047 2.52h11.79a1.474 1.474 0 0 0 1.473-1.49zm-11.79-7.369v-4.42a1.474 1.474 0 1 0-2.947 0v4.42a1.474 1.474 0 0 0 2.948 0z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconOverrevComparison',
}
</script>
