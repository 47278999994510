<template>
  <div class="toaster sticky">
    <div
      :style="showForMimic || showForOrgTree ? 'marginTop:40px' : ''"
      :class="currentBreakpoint === 'mobile' ? 'mt-4 mr-6' : 'right-0'"
      class="fixed"
    >
      <VinToast
        v-for="(toast, k) in toasts"
        :key="k"
        :toast="toast"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
export default {
  name: 'VinToaster',
  computed: {
    currentBreakpoint () {
      return this.$store.state.baseModule.breakpoint
    },
    toasts () {
      return this.$store.state.overlayModule.toasts
    },
    showForMimic () {
      return this.$store.state.authModule.mimic.isActive
    },
    showForOrgTree () {
      return this.rootOrgId !== this.activeOrgId
    },
    rootOrgId () {
      return get(this.$store.state.authModule, 'session.orgs[0].id', null)
    },
    activeOrgId () {
      return get(this.$store.state.authModule.activeOrg, 'id', null)
    },
  },
}
</script>

<style scoped>
.toaster {
  z-index: 500;
}
</style>
