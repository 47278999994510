<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="best-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="gradientStart"
        />
        <stop
          offset="100%"
          :stop-color="gradientStop"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g>
        <g transform="translate(-304 -30) translate(304 30)">
          <rect
            width="48"
            height="48"
            fill="url(#best-icon)"
            fill-rule="nonzero"
            rx="10"
          />
          <g
            stroke="#FFF"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          >
            <g>
              <g transform="translate(10 12) translate(0 12) matrix(-1 0 0 1 27.2 0)">
                <path d="M8.96 10.181L18.43 10.181M2.934 9.926h-.771c-.462 0-.873-.293-1.025-.729L.307 6.803c-.19-.546-.07-1.151.312-1.584L4.752.54C5.056.197 5.492 0 5.952 0h8.66c.468 0 .913.205 1.217.561l2.415 2.828c.244.286.582.477.953.539l6.095 1.018c.844.14 1.427.92 1.326 1.768l-.258 2.154c-.073.603-.585 1.058-1.193 1.058h-1.065" />
                <ellipse
                  cx="6.08"
                  cy="10.075"
                  rx="2.88"
                  ry="2.925"
                />
                <ellipse
                  cx="21.44"
                  cy="10.075"
                  rx="2.88"
                  ry="2.925"
                />
                <path d="M15.086 4.216L11.874 4.216 10.667 2.552" />
              </g>
            </g>
            <g>
              <path
                d="M14 0L7.955 6.333 4.773 3 0 8"
                transform="translate(10 12) translate(8)"
              />
              <path
                d="M10.182 0L14 0 14 4"
                transform="translate(10 12) translate(8)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMix from '../icon-mixin'
export default {
  name: 'VinBestIcon',
  mixins: [iconMix],
}
</script>
