var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center",attrs:{"id":_vm.vid}},[(_vm.unCheckedText || _vm.checkedText)?_c('span',{class:[
      `switch-text text-${_vm.textColor} block leading-tight text-lg mr-4`,
      _vm.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
    ]},[(_vm.checkStatus)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm._f("translate")(_vm.checkedText)))]}):_vm._e(),(!_vm.checkStatus)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm._f("translate")(_vm.unCheckedText)))]}):_vm._e()],2):_vm._e(),_c('span',{staticClass:"switch select-none relative inline-block align-middle h-5 border border-grey-dk rounded-xl bg-grey-dk",class:[
      _vm.size ? `switch-${_vm.size}` : '',
      _vm.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
      {
        'switch-checked border-success-lt bg-success-lt': _vm.checkStatus,
      },
    ],on:{"click":_vm.toggleSwitch}})])
}
var staticRenderFns = []

export { render, staticRenderFns }