<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-speed-icon"
        x1="91.704%"
        x2="11.892%"
        y1="1.917%"
        y2="65.069%"
      >
        <stop
          offset="0%"
          stop-color="#FFA801"
        />
        <stop
          offset="100%"
          stop-color="#F3A205"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-speed-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M19.895 29.35H13.14a1.35 1.35 0 1 1 0-2.7h6.755a1.35 1.35 0 1 1 0 2.7zM44.21 28a1.35 1.35 0 0 0-1.351-1.35h-6.755a1.35 1.35 0 1 0 0 2.7h6.755A1.35 1.35 0 0 0 44.21 28zM29.35 42.86v-6.755a1.35 1.35 0 1 0-2.702 0v6.755a1.35 1.35 0 1 0 2.702 0zm0-22.965V13.14a1.35 1.35 0 1 0-2.702 0v6.755a1.35 1.35 0 1 0 2.702 0zm11.577 3.93a1.35 1.35 0 0 0 .702-1.782 14.86 14.86 0 0 0-7.673-7.673 1.35 1.35 0 0 0-1.08 2.472 12.158 12.158 0 0 1 6.281 6.281 1.35 1.35 0 0 0 1.783.703h-.013zm-24.073-.688a12.158 12.158 0 0 1 6.282-6.282 1.35 1.35 0 1 0-1.08-2.472 14.86 14.86 0 0 0-7.674 7.673 1.35 1.35 0 0 0 1.243 1.891 1.35 1.35 0 0 0 1.23-.81zm6.97 17.79a1.35 1.35 0 0 0-.702-1.782 12.158 12.158 0 0 1-6.281-6.282 1.35 1.35 0 0 0-2.472 1.08 14.86 14.86 0 0 0 7.673 7.674c.17.073.354.11.54.108a1.35 1.35 0 0 0 1.243-.797zm10.119.703a14.86 14.86 0 0 0 7.673-7.673 1.35 1.35 0 0 0-2.472-1.08 12.158 12.158 0 0 1-6.282 6.281 1.35 1.35 0 0 0 .54 2.594c.184.01.368-.018.54-.081v-.04z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSpeed',
}
</script>
