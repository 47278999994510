<template>
  <VinModal :show="show">
    <template #content>
      <div>
        <div class="w-full flex justify-center my-5">
          <VinTitle
            class="text-40 leading-none font-semithin mb-4"
            :text="$t(title)"
          />
        </div>
        <VinCSVParser
          :csv-string="csv"
          :headers="headers"
          :parser-options="parserOptions"
          :errors="errors"
          :extra-cols="extraCols"
          @cancel-table-data="handleCancelTableData()"
          @save-table-data="handleSaveTableData($event)"
        />
      </div>
    </template>
  </VinModal>
</template>

<script>
import VinTitle from '../VinTitle/VinTitle.vue'
import VinCSVParser from './VinCSVParser.vue'
export default {
  name: 'VinCSVModal',
  components: {
    VinCSVParser,
    VinTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    csv: {
      type: String,
      required: true,
      default: '',
    },
    extraCols: {
      type: Array,
      required: false,
      default: () => [],
    },
    errors: {
      type: Array,
      required: true,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    parserOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data () {
    return {
      error: null,
    }
  },
  computed: {
    show () {
      return this.csv !== ''
    },

  },
  methods: {
    handleSaveTableData (csvToSave) {
      this.$emit('save-table-data', csvToSave)
    },
    handleCancelTableData () {
      this.$emit('cancel-table-data')
    },
  },
}
</script>
