<template>
  <VehicleLocationsPopover :show="show">
    <template #content>
      <div class="flex flex-col justify-between h-full">
        <div class="flex flex-col">
          <VehicleLocationsSearch />
          <hr class="w-full border-grey">
          <VehicleLocationsDraw @drawingMode="handleMode" />
          <hr class="w-full border-grey">
          <VehicleLocationsName
            @nameInput="handleName"
          />
          <p
            v-for="(val, key) in errors"
            :key="key"
            class="mx-8 text-danger-lt text-sm"
          >
            {{ val }}
          </p>
        </div>
        <div>
          <div class="px-8 flex items-center justify-between mb-6">
            <div class="flex justify-between w-full">
              <VinButton
                :vid="'cancel-new-poi-btn'"
                :text="$t('Cancel')"
                class="w-1/2 mr-4 text-base lg:font-normal xl:font-bold border-grey text-cta-lt"
                @click="handleCancel"
              />
              <VinButton
                :vid="'save-new-poi-btn'"
                :text="$t('Save')"
                color="cta-lt"
                class="w-1/2 text-base lg:font-normal xl:font-bold"
                @click="handleSave"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </VehicleLocationsPopover>
</template>

<script>
import VehicleLocationsDraw from './VehicleLocationsDraw'
import VehicleLocationsName from './VehicleLocationsName'
import VehicleLocationsPopover from './VehicleLocationsPopover'
import VehicleLocationsSearch from './VehicleLocationsSearch'
export default {
  name: 'VehicleLocationsCreate',
  components: {
    VehicleLocationsPopover,
    VehicleLocationsSearch,
    VehicleLocationsDraw,
    VehicleLocationsName,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    handleSave () {
      this.$emit('handleSave')
    },
    handleCancel () {
      this.$emit('handleCancel')
    },
    handleMode (payload) {
      this.$emit('handleMode', payload)
    },
    handleName (payload) {
      this.$emit('handleName', payload)
    },
  },
}
</script>
