module.exports = err => {
  // check if our error matches reload cases
  const reloadCases = ['Loading chunk', 'Loading CSS chunk']
  const reload = reloadCases.some(c => err.message.includes(c))
  if (!reload) {
    return
  }
  // grab version so we can check local storage
  const version = process.env.VUE_APP_BUILD_VERSION || 'profleet'
  const key = `chunk-reload-${version}`
  const alreadyReloaded = localStorage.getItem(key)
  // don't reload if we've performed a reload for this version already
  if (alreadyReloaded) {
    return
  }
  // if so, store a flag locally then reload
  if (reload) {
    localStorage.setItem(key, 'true')
    console.info('Encountered chunk load error, attempting to reload page.')
    window.location.reload()
  }
}
