import {
  addDays,
  endOfToday,
  format,
  getMonth,
  isThisYear,
  parseISO,
  startOfDay,
  subMonths,
} from 'date-fns'
import get from 'lodash.get'
import Vue from 'vue'

import { makeDefaultDates, makeSinceUntil } from '../../common/utils/default-dates'
import { EventBus } from '../../event-bus'
import router from '../../router/router'
import axios from '../middleware/axios-store'

const dftDates = makeDefaultDates()

const module = {
  state: {
    // Cache version
    version: '',
    // duration picker
    since: dftDates.since,
    until: dftDates.until,
    duration: dftDates.duration,
    durationOptions: [7, 14, 30, 'custom'],
    customSince: dftDates.since, // used for weekly timesheet or other cases where since/until don't line up with global since/until
    customUntil: dftDates.until, // used for weekly timesheet or other cases where since/until don't line up with global since/until
    rawCustomUntil: dftDates.until, // store raw val since timesheet offsets customUntil to start of week

    // report params
    startHour: 0,
    endHour: 4,
    maxDailyDrivingHours: 2,
    maxLongDrivingHours: 2,
    daysStanding: 4,
    maxDailyDistance: 160934.4, // 100 mi in meters
    maxTripDistance: 160934.4, // 100 mi in meters

    // overview reports month picker
    firstMonth: subMonths(new Date(), 3),
    lastMonth: endOfToday(),
    month: getMonth(new Date()),

    // sorting
    sortBy: 'newest',
    sortByOptions: ['newest', 'oldest'],
    sorts: {
      vehicles: {
        options: [
          'last_check_in',
          'driver_last_name',
          'driver_first_name',
          'Registration',

        ],
        sortBy: 'last_check_in',
        asc: false,
      },
      vehiclesService: {
        options: [
          'status_due',
          'status_overdue',
          'status_not_due',
        ],
        sortBy: 'status_overdue',
        asc: false,
      },
      vehicleJourneys: {
        options: ['Date', 'Events', 'Duration', 'Mileage', 'Score'],
        sortBy: 'Date',
        asc: false,
      },
      journeyEvents: {
        options: ['Type', 'Time'],
        sortBy: 'Time',
        asc: true,
      },
      weeklyTimesheet: {
        options: ['Driver', 'Registration'],
        sortBy: 'Driver',
        asc: true,
      },
      users: {
        options: ['Last Name', 'First Name', 'Email'],
        sortBy: 'Last Name',
        asc: true,
      },
      locations: {
        options: ['Name', 'Created On'],
        sortBy: 'Name',
        asc: true,
      },
      serviceLocations: {
        options: ['Name', 'Supported Makes'],
        sortBy: 'Name',
        asc: true,
      },
      fuelPrices: {
        options: ['Date', 'price_petrol', 'price_diesel', 'created_date'],
        sortBy: 'Date',
        asc: false,
      },
    },
    targetVehicle: null,
  },
  getters: {
    textForDuration: (state, getters, rootState) => (duration) => {
      if (!duration) {
        duration = state.duration
      }
      switch (duration) {
        case 14:
          return 'Last 14 Days'
        case 30:
          return 'Last 30 Days'
        case 'custom':
          // include the year if our custom
          // range is not in the current year
          const thisYear = isThisYear(state.since) && isThisYear(state.until)
          const dayFirstFmt = thisYear ? 'do MMM' : 'do MMM yyyy'
          const mFirstFmt = thisYear ? 'MMM do' : 'MMM do, yyyy'
          const fmt = rootState.localizationModule.dayFirst
            ? dayFirstFmt
            : mFirstFmt
          return (
            format(state.since, fmt, {
              locale: getters.localeSettings.dateLocale,
            }) +
            ' - ' +
            format(state.until, fmt, {
              locale: getters.localeSettings.dateLocale,
            })
          )
        default:
          return 'Last 7 Days'
      }
    },
    textForMonth: (state, getters) => (month) => {
      // eslint-disable-line no-unused-vars
      return format(new Date(1988, month, 1), `MMM`, {
        locale: getters.localeSettings.dateLocale,
      })
    },
    sinceUntil: (state) => {
      return {
        since: state.since.toISOString(),
        until: state.until.toISOString(),
      }
    },
  },
  mutations: {
    INITIALIZE_STORE (state) {
      const version = process.env.VUE_APP_BUILD_VERSION || new Date().getTime()

      // Check if the ID exists
      if (localStorage.getItem('pfParamsModule')) {
        let pfParamsModule = JSON.parse(localStorage.getItem('pfParamsModule'))

        // Check the version stored against current. If different, don't
        // load the cached version
        if (pfParamsModule.version === version) {
          let ignored = []

          // let url date params win if they are there and valid
          if (dftDates.fromUrl) {
            ignored = [
              'since',
              'until',
              'customSince',
              'customUntil',
              'duration',
            ]
          }

          let dateKeys = [
            'since',
            'until',
            'customSince',
            'customUntil',
            'rawCustomUntil',
            'firstMonth',
            'lastMonth',
          ]
          for (let key in pfParamsModule) {
            if (!ignored.includes[key]) {
              let val = pfParamsModule[key]
              if (dateKeys.indexOf(key) !== -1) {
                val = parseISO(val)
              }
              state[key] = val
            }
          }
          // now reset duration so that underlying dates roll to current timeframe
          if (state.duration !== 'custom') {
            const { since, until } = makeSinceUntil(state.duration )
            state.since = since
            state.until = until
          }
        } else {
          state.version = version
        }
      }
    },
    CLEAR_PARAM_MODULE (state) {
      state.starHour = 0
      state.endHour = 4
      state.maxDailyDrivingHours = 2
      state.maxLongDrivingHours = 2
      state.daysStanding = 4
      state.customSince = startOfDay(addDays(new Date(), -7))
      state.customUntil = startOfDay(new Date())
      state.rawCustomUntil = startOfDay(new Date())
    },
    SET_DURATION (state, duration) {
      if (state.durationOptions.indexOf(duration) === -1) {
        throw new Error(`Duration must be one of ${state.durationOptions}`)
      }
      state.duration = duration
      if (duration !== 'custom') {
        const { since, until } = makeSinceUntil(duration)
        state.since = since
        state.until = until
        EventBus.$emit('duration-change')
      }
    },
    SET_REPORT_SETTINGS (state, payload) {
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
    SET_CUSTOM_DURATION (state, payload) {
      state.since = payload.since
      state.until = payload.until
      EventBus.$emit('duration-change')
    },
    SET_MONTH_DURATION (state, payload) {
      state.month = payload.month
      state.firstMonth = payload.firstMonth
      state.lastMonth = payload.lastMonth
      EventBus.$emit('duration-change')
    },
    SET_TARGET_VEHICLE (state, vehicleId) {
      state.targetVehicle = vehicleId
    },
    SET_SORT_FOR_SLUG (state, payload) {
      let val = state.sorts[payload.slug]
      val.sortBy = payload.sortBy
      val.asc = payload.asc
      Vue.set(state.sorts, payload.slug, val)
    },
    SET_CUSTOM_SINCE_UNTIL (state, payload) {
      state.customSince = payload.since
      state.customUntil = payload.until
      state.rawCustomUntil = payload.raw
    },
  },
  actions: {
    UPDATE_REPORT_SETTINGS_PROMISE ({ commit }, payload) {
      const settings = {
        settings: payload,
      }
      axios
        .put('/api/v1/users/settings', settings)
        .then((resp) => {
          commit('SET_USER_SETTINGS', resp.data.settings)
          commit('SET_REPORT_SETTINGS', resp.data.settings)
        })
        .catch((err) => {
          throw err
        })
    },
    SET_DURATION_AND_CLEAR_DATA ({ commit, state }, payload) {
      if (typeof payload === 'number') {
        commit('SET_DURATION', payload)
      } else {
        commit('SET_DURATION', 'custom')
        commit('SET_CUSTOM_DURATION', payload)
      }
      // we don't want to re-push on first load
      if (!payload.noPush) {
        router.push({
          ...router.currentRoute,
          query: {
            since: state.since.toISOString(),
            until: state.until.toISOString(),
          },
        })
      }

    },
    SET_MONTH_AND_CLEAR_OVERVIEW_DATA ({ commit, rootState }, payload) {
      const orgId = get(rootState, 'authModule.activeOrg.id')
      commit('SET_MONTH_DURATION', payload)
      commit('CLEAR_RESULTS_FOR_SLUG', {
        orgId,
        slug: 'report-fleet-cost-overview',
      })
      commit('CLEAR_RESULTS_FOR_SLUG', {
        orgId,
        slug: 'report-fleet-risk-overview',
      })
      commit('CLEAR_RESULTS_FOR_SLUG', {
        orgId,
        slug: 'report-fleet-footprint-overview',
      })
    },
  },
}

export default module
