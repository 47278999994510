<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-standing-idle-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#F41745"
        />
        <stop
          offset="100%"
          stop-color="#D6123C"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-standing-idle-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        transform="matrix(-1 0 0 1 43.4 25.667)"
      >
        <path d="M10.453 11.878h11.049M3.423 11.58h-.9a1.265 1.265 0 0 1-1.195-.85L.307 7.79a1.6 1.6 0 0 1 .312-1.585L5.624.541A1.6 1.6 0 0 1 6.823 0h10.348a1.6 1.6 0 0 1 1.216.561l2.96 3.466a1.6 1.6 0 0 0 .954.54l7.458 1.245a1.6 1.6 0 0 1 1.325 1.768l-.331 2.766a1.4 1.4 0 0 1-1.39 1.234" />
        <ellipse
          cx="7.093"
          cy="11.754"
          rx="3.36"
          ry="3.413"
        />
        <ellipse
          cx="25.013"
          cy="11.754"
          rx="3.36"
          ry="3.413"
        />
        <path d="M17.6 4.918h-3.747l-1.409-1.94" />
      </g>
      <g
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      >
        <path d="M42.754 25.865c1.624.135 3.152-.786 3.71-2.321l.048-.13c.674-1.853-.362-3.949-2.305-4.656l-.898-.327.096-.263c.69-1.898-.361-4.023-2.348-4.746-1.988-.723-4.16.228-4.85 2.126l-.138.378" />
        <path d="M37.282 17.427c-.478-1.016-1.034-1.563-2.16-1.972l-.16-.053c-1.872-.543-3.861.393-4.507 2.168l-.047.13c-.548 1.506-.003 3.159 1.276 4.11" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconStandingIdle',
}
</script>
