<template>
  <input
    :id="vid"
    v-model="syncValue"
    class="input block outline-none leading-normal p-input md:text-sm bg-white border rounded"
    :class="[
      color ? `hover:border-${color} ` : '',
      color ? `focus:border-${color}` : '',
      textColor ? `text-${textColor}` : 'text-grey-dkr',
      borderColor ? `border-${borderColor}` : 'border-grey',
    ]"
    :style="borderStyles"
    :type="type"
    :min="min"
    :max="max"
    :maxlength="maxLength"
    :placeholder="placeholder"
    @input="emitValue"
    @keyup.enter="$emit('enter')"
  >
</template>

<script>
export default {
  name: 'VinInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    vid: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: null,
    },
    borderColor: {
      type: String,
      default: null,
    },
    borderStyles: {
      type: String,
      default: 'border-width: 1px;',
    },
    min: {
      type: [Number, String],
      default: null,
    },
    max: {
      type: [Number, String],
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      syncValue: this.value,
    }
  },
  watch: {
    value () {
      this.syncValue = this.value
    },
  },
  methods: {
    emitValue () {
      this.$emit('input', this.syncValue)
    },
  },
}
</script>
<style>
.input {
  transition: border 0.2s;
}
.input:hover {
  transition: border 0.2s;
}
.input:focus {
  transition: border 0.2s;
}
.input::placeholder {
  @apply text-grey-lt font-semithin;
  color: theme("colors.grey-dk");
  opacity: 1;
}
.input::placeholder:hover {
  color: theme("colors.copy");
  opacity: 1;
}
.input::placeholder:focus {
  color: theme("colors.copy");
  opacity: 1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
