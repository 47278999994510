module.exports = {
  transparent: 'transparent',

  black: '#000000',
  white: '#ffffff',

  // greys
  'grey-ltr': '#fafbfc',
  'grey-lt': '#ededed',
  grey: '#d6dde3',
  'grey-dk': '#65747b',
  'grey-dkr': '#1d262b',
  'grey-dkst': '#39393a',

  // theme colors
  'primary-lt': '#0060df',
  'primary-dk': '#00095b',
  'primary-dkr': '#0061af',
  'cta-lt': '#00095b',
  'cta-dk': '#1700f4',

  // interaction colors
  'success-lt': '#4cd29a',
  'success-dk': '#3fac7f',
  'warning-lt': '#f7d999',
  'warning-dk': '#f6d183',
  'danger-lt': '#ff747a',
  'danger-dk': '#F41745',
  'pending-dk': '#bf6702',

  // utility colors
  'border': '#aeb5bc',
  'svg-grey': '#8c91a2',
  'sidebar-grey': '#979aa7',
  'copy': '#002c5d',
  'positive': '#22cd91',
  'negative': '#d0021b',
  'score-a': '#00d28b',
  'score-b': '#0b8eff',
  'score-c': '#ffd255',
  'score-d': '#f98658',
  'score-f': '#f41745',
  // non tailwind colors below
  // auth gradient
  'auth-gradient-0': '#1700f4',
  'auth-gradient-1': '#00095b',
  'auth-gradient-2': '#00095b',
  // icon gradient
  'icon-gradient-0': '#1700f4',
  'icon-gradient-1': '#00095b',
  // map
  'static-polyline': '0x0b8effff',
  // chart
  'bar-0': 'rgb(134, 201, 255)',
  'bar-1': 'rgb(0, 141, 255)',
}
