<template>
  <div class="flex items-center justify-center cursor-pointer text-copy hover:text-primary-dk -ml-4">
    <div class="w-2/5">
      <VinCar
        :image="icon"
        class="w-full"
      />
    </div>

    <div class="w-3/5 pl-4 pt-2">
      <div class="flex text-xl font-normal leading-none tracking-normal">
        {{ prettyVehicle }}
      </div>
      <span class="text-sm font-normal leading-normal tracking-normal">
        {{ vehicleDisplayCode }}
      </span>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
export default {
  name: 'SpotlightResultVehicle',
  props: {
    vehicle: {
      type: Object,
    },
  },
  computed: {
    vin () {
      return get(this.vehicle, 'vin')
    },
    reg () {
      return get(this.vehicle, 'registration')
    },
    vehicleDisplayCode () {
      if (this.reg) {
        return this.reg
      }
      if (this.vin) {
        return this.vin
      }
      return get(this.vehicle, 'id', '--')
    },
    icon () {
      return get(this.vehicle, 'icon')
    },
    prettyVehicle () {
      return this.$store.getters.prettyVehicle(this.vehicle)
    },
  },
}
</script>
