<template>
  <div class="py-4 px-12">
    <div
      v-for="s in switches"
      :key="s.title"
      class="flex justify-between"
    >
      <span class="font-bold text-copy text-lg">
        {{ $t(s.title) }}
      </span>
      <VinSwitch
        :vid="genId(s.title)"
        :value="s.state"
        size="lg"
        class="ml-4"
        @change="s.mutation"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardMapSettingsSwitchPanel',
  computed: {
    switches () {
      const list = [
        {
          title: 'show_pois',
          state: this.$store.state.mapSettingsModule.geofence,
          mutation: () => { this.$store.commit('TOGGLE_MAP_SETTING_GEOFENCE', !this.$store.state.mapSettingsModule.geofence) },
        },
        {
          title: 'show_clusters',
          state: this.$store.state.mapSettingsModule.cluster,
          mutation: () => { this.$store.commit('TOGGLE_MAP_SETTING_CLUSTER', !this.$store.state.mapSettingsModule.cluster) },
        },
        {
          title: 'show_traffic',
          state: this.$store.state.mapSettingsModule.traffic,
          mutation: () => { this.$store.commit('TOGGLE_MAP_SETTING_TRAFFIC', !this.$store.state.mapSettingsModule.traffic) },
        },
      ]
      if (this.$store.getters.hasMixin('lt')) {
        list.push({
          title: 'live_update',
          state: this.$store.state.mapSettingsModule.liveStream,
          mutation: () => { this.$store.dispatch('TOGGLE_TRACKING_STREAM', !this.$store.state.mapSettingsModule.liveStream) },
        })
      }
      return list
    },
  },
  methods: {
    genId (val) {
      const kebab = val.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase()
      return `dash-map-${kebab}-switch`
    },
  },
}
</script>
