import get from 'lodash.get'
import Vue from 'vue'

import axios from '../middleware/axios-store'
const module = {
  state: {
    // App scoped group
    activeGroup: {}, // group to use in app banner & query params

    // selected group for src/Vehicles/Groups/VehicleGroupsPanel/ components
    selectedGroup: {}, // group being modified for all dispatches
    groupVehicles: {}, // a map of groupIds with array of vehicles
    groupUsers: {}, // a map of groupIds with array of users

    // watcher payloads in src/components/Vehicles/Groups/VehicleGroupsTreeLevel
    newSubGroup: {}, // POST_SUB_GROUP_PROMISE payload to push into tree
    modifiedSubGroup: {}, // PUT_SUB_GROUP_PROMISE payload to modify in tree
    deletedSubGroup: '', // DELETE_SUB_GROUP payload to remove from tree
    loadingVehicles: false,

    // edit user
    selectedGroupUser: {},
  },
  mutations: {
    CLEAR_GROUPS_MODULE: (state) => {
      state.activeGroup = {}
      state.selectedGroup = {}
      state.groupVehicles = {}
      state.groupUsers = {}
      state.newSubGroup = {}
      state.modifiedSubGroup = {}
      state.deletedSubGroup = ''
      state.loadingVehicles = false
      state.selectedGroupUser = {}
    },
    SET_NEW_SUB_GROUP: (state, group) => {
      state.newSubGroup = group
      setTimeout(() => {
        // reset to default after watcher runs
        state.newSubGroup = {}
      }, 1000)
    },
    SET_MODIFIED_SUB_GROUP: (state, group) => {
      // updated selectedGroup with new name
      state.selectedGroup = group

      // let watcher update tree node
      state.modifiedSubGroup = group
      setTimeout(() => {
        // reset to default after watcher runs
        state.modifiedSubGroup = {}
      }, 1000)
    },
    SET_DELETED_SUB_GROUP: (state, groupId) => {
      state.deletedSubGroup = groupId
      setTimeout(() => {
        // reset to default after watcher runs
        state.deletedSubGroup = ''
      }, 1000)
    },
    SELECT_H_GROUP: (state, group) => {
      state.selectedGroup = group
    },
    SET_VEHICLES_FOR_GROUP: (state, payload) => {
      // grab vehicles for group using groupId as the key
      if (state.groupVehicles[payload.id]) {
        let vehicles = state.groupVehicles[payload.id]
        const existingIds = vehicles.map(v => v.id)
        payload.vehicles.forEach(v => {
          if (!existingIds.includes(v.id)) {
            state.groupVehicles[payload.id].push(v)
          }
        })
      } else {
        Vue.set(state.groupVehicles, payload.id, payload.vehicles)
      }
    },
    SET_USERS_FOR_GROUP: (state, payload) => {
      // grab users for group using groupId as the key
      if (state.groupUsers[payload.id]) {
        let users = state.groupUsers[payload.id]
        const existingIds = users.map(v => v.id)
        payload.users.forEach(v => {
          if (!existingIds.includes(v.id)) {
            state.groupUsers[payload.id].push(v)
          }
        })
      } else {
        Vue.set(state.groupUsers, payload.id, payload.users)
      }
    },
    REMOVE_VEHICLES_FOR_GROUP: (state, payload) => {
      let vehicles = get(state.groupVehicles, payload.id, [])
      vehicles = vehicles.filter(v => !payload.vehicleIds.includes(v.id))
      Vue.set(state.groupVehicles, payload.id, vehicles)
    },
    SET_ACTIVE_GROUP: (state, group) => {
      state.activeGroup = group
    },
    TOGGLE_LOADING_GROUP_VEHICLES: (state, flag) => {
      state.loadingVehicles = flag
    },
    SELECT_H_GROUP_USER: (state, user) => {
      state.selectedGroupUser = user
    },
    UPDATE_H_GROUP_USER: (state, payload) => {
      let idx = null

      if (state.groupUsers[payload.groupId]) {
        state.groupUsers[payload.groupId].forEach((user, i) => {
          if (user.id === payload.user.id) {
            idx = i
          }
        })
        if (typeof idx !== 'number') {
          return
        }
        state.groupUsers[payload.groupId].splice(idx, 1, payload.user)
      } else {
        Vue.set(state.groupUsers, payload.groupId, payload.user)
      }
    },
    REMOVE_H_GROUP_USER: (state, payload) => {
      state.groupUsers[payload.orgId] = state.groupUsers[payload.orgId].filter(user => user.id !== payload.userId)
    },
    SET_COUNT_ON_SELECTED_GROUP: (state, count) => {
      Vue.set(state.selectedGroup, 'vehicleCount', count)
    },
  },
  actions: {
    POST_SUB_GROUP_PROMISE ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.selectedGroupId) {
          reject(new Error('Create new subgroup requires a groupId'))
        }
        axios.post(`/api/v2/orgs/${payload.selectedGroupId}/groups`, {
          name: payload.name,
        })
          .then(resp => {
            commit('SET_NEW_SUB_GROUP', resp.data)
            dispatch('SERVE_TOAST', {
              type: 'success',
              message: 'New group created!',
            })
            resolve(resp)
          })
          .catch(err => {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'Error creating new group. Please try again later.',
            })
            reject(err)
          })
      })
    },
    PUT_SUB_GROUP_PROMISE ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.orgId) {
          // orgId should be root org or parent group
          reject(new Error('Modify subgroup requires a orgId'))
        }
        if (!payload.groupId) {
          // groupId is the group being modified
          reject(new Error('Modify subgroup requires a groupId'))
        }
        axios.put(`/api/v2/orgs/${payload.orgId}/groups/${payload.groupId}`, {
          name: payload.groupName,
        })
          .then(resp => {
            commit('SET_MODIFIED_SUB_GROUP', resp.data)
            dispatch('SERVE_TOAST', {
              type: 'success',
              message: 'group_name_change_success',
            })
            resolve(resp)
          })
          .catch(err => {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'Error changing group name. Please try again later.',
            })
            reject(err)
          })
      })
    },
    PATCH_GROUP_USER_PROMISE ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/orgs/${payload.orgId}/users/${payload.userId}`, {
            user: payload.user,
          })
          .then(resp => {
            const userPayload = {
              groupId: payload.orgId,
              user: resp.data.user,
            }
            commit('UPDATE_H_GROUP_USER', userPayload)
            commit('SELECT_H_GROUP_USER', {})

            dispatch('SERVE_TOAST', {
              type: 'success',
              message: 'Successfully updated user!',
            })
            resolve(resp)
          })
          .catch(err => {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: err.message,
            })
            commit('SELECT_H_GROUP_USER', {})
            reject(err)
          })
      })
    },
    DELETE_GROUP_USER_PROMISE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/orgs/${payload.orgId}/users/${payload.userId}`)
          .then(resp => {
            commit('REMOVE_H_GROUP_USER', payload)
            commit('SELECT_H_GROUP_USER', {})
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    DELETE_SUB_GROUP_PROMISE ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.selectedGroupId) {
          reject(new Error('Delete subgroup requires a groupId'))
        }
        axios.delete(`/api/v2/orgs/${payload.rootGroupId}/groups/${payload.selectedGroupId}`)
          .then(resp => {
            commit('SET_DELETED_SUB_GROUP', payload.selectedGroupId)
            commit('SELECT_H_GROUP', {})
            dispatch('SERVE_TOAST', {
              type: 'success',
              message: 'Group successfully deleted!',
            })
            resolve(resp)
          })
          .catch(err => {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'Error deleting group. Please try again later.',
            })
            reject(err)
          })
      })
    },
    POST_GROUP_USER_PROMISE ({ commit, rootState }, payload) {
      const body = {
        user: payload.user,
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/orgs/${payload.orgId}/users`, body, {
            params: {
              theme: rootState.themeModule.theme,
            },
          })
          .then(resp => {
            commit('SET_USERS_FOR_GROUP', {
              id: payload.orgId,
              users: [resp.data.user],
            })
            resolve(resp)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async SELECT_GROUP ({ commit, state, rootState }, group) {
      commit('SELECT_H_GROUP', group)
      // stop if this is the root org
      if (group.id === rootState.authModule.activeOrg.id) {
        return
      }

      // get since and until from state
      // set fallback to last 7 days if since and until are undefined
      const since = rootState?.paramsModule?.since ? rootState?.paramsModule?.since : new Date(new Date().setDate(new Date().getDate()-7))
      const until = rootState?.paramsModule?.until ? rootState?.paramsModule?.until : new Date()

      // kick off loader
      commit('TOGGLE_LOADING_GROUP_VEHICLES', true)

      // grab the count
      let count = 0
      const { data } = await axios.get(`/api/v2/orgs/${rootState.authModule.activeOrg.id}/groups/${group.id}/vehicles?limit=1&includes=nothing`)
      count = get(data, 'meta.pagination.total', 0)
      commit('SET_COUNT_ON_SELECTED_GROUP', count)

      // check for vehicles still in store
      let vehicles = get(state.groupVehicles, group.id, [])

      // reset group vehicles
      if (vehicles.length > 0) {
        Vue.set(state.groupVehicles, group.id, [])
        vehicles = []
      }

      let fromNetwork = false

      const skipFetch = get(group, 'skipVehicleFetch', false)
      if (!skipFetch) {
         // fetch the vehicles
        const fetchGroupVehicles = async (url) => {
          if (vehicles.length == 0 || url) {
            // toggle loading
            url = url || `/api/v2/orgs/${rootState.authModule.activeOrg.id}/groups/${group.id}/vehicles?since=${since.toISOString()}&until=${until.toISOString()}&limit=100&includes=driver,reportCard,tripCount,status`
            try {
              const resp = await axios.get(url)
              vehicles = vehicles.concat(get(resp, 'data.vehicles', []))
              fromNetwork = true
              const next = get(resp, 'data.meta.pagination.links.next')
              if (next) {
                await fetchGroupVehicles(next)
              }
            } catch (err) {
              commit('TOGGLE_LOADING_GROUP_VEHICLES', false)
              throw err
            }
          }
        }
        await fetchGroupVehicles()
        // commit the vehicles if we grabbed them from the network
        if (fromNetwork) {
          commit('SET_VEHICLES_FOR_GROUP', {
            id: group.id,
            vehicles,
          })
        }
      }

      commit('TOGGLE_LOADING_GROUP_VEHICLES', false)
    },
    SET_ACTIVE_GROUP ({ commit }, group) {
      commit('CLEAR_DATA_MODULE')
      commit('SET_ACTIVE_GROUP', group)
    },
  },
}

export default module
