<template>
  <table class="w-full">
    <thead class="border-b-2 border-grey-dk">
      <tr>
        <th
          v-for="header in headers"
          :key="header"
          class="text-lg"
        >
          {{ $t(header) }}
        </th>
        <th
          v-for="col in extraCols"
          :key="col.header"
          class="text-lg"
        >
          {{ $t( col.header) }}
        </th>
        <th class="w-12" />
      </tr>
    </thead>
    <tbody class="gap-y-3">
      <VinCSVTableLine
        v-for="(line, index) in tableData"
        :key="line.id"
        :errors="errorsByLine[line.id]"
        :line-item="line"
        :headers="headers"
        :index="index"
        :extra-cols="extraCols"
        @delete-row="deleteRow($event)"
        @value-changed="lineValueChanged($event)"
      />
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'VinCSVTable',
  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
    errors: {
      type: Array,
      required: true,
      default: () => [],
    },
    extraCols: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data () {
    return {}
  },
  computed: {
    errorsByLine() {
      let lineErrors = {}
      this.errors.forEach(error => {
        if (lineErrors[error.row]) {
          lineErrors[error.row].push(error)
        } else {
          lineErrors[error.row] = [error]
        }
      })
      return lineErrors
    },
  },
  methods: {
    lineValueChanged () {
      const copy = [...this.tableData]
      const index = copy.findIndex(line => line.id === this.lineItem.id)
      copy[index] = this.lineItem
      this.$emit('value-changed', copy)
    },
    deleteRow (id) {
      this.$emit('delete-row', id)
    },
  },
}
</script>
