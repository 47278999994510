import get from 'lodash.get'
import Vue from 'vue'

import axios from '../middleware/axios-store'

const placeholderString = ''

function swap (input, indexA, indexB) {
  var temp = input[indexA]
  input[indexA] = input[indexB]
  input[indexB] = temp
}

const module = {
  state: {
    org: null,
    children: [],
    totalChildren: 0,
    childStatusToggle: {},
    packs: [],
    globalWidgets: [],
    adminOrgPack: null,
    users: [],
    userPagination: {
      total: 0,
      offset: 0,
      limit: 10,
    },
    activeUserPage: 0,
    fetchingUser: false,
    delUser: null,
    workingHours: [],
    map: true,
    sections: [
      ['WidgetBestVehicle'],
      ['WidgetTotalFleetMileage', 'WidgetAvgFuelEcon', 'WidgetFuelCost'],
      [
        'WidgetFleetCostEvents',
        'WidgetFleetFootprintEvents',
        'WidgetFleetRiskEvents',
      ],
      ['WidgetLongestJourney', 'WidgetAvgJourneyLength', 'WidgetTotalIdleTime'],
    ],
    includeInactveOrgs: false,
    supportSettings: {},
  },
  getters: {
    getPackById: state => id => {
      return state.packs.find(pack => pack.id === id)
    },
    getUserById: state => id => {
      return state.users.find(user => user.id === id)
    },
    getWorkingHoursById: state => id => {
      return state.workingHours.find(profile => profile.id === id)
    },
    orgSettings: (state, getters, rootState) => {
      let org = state.org || {}
      // assign defaults to some keys
      let settings = {
        currency: get(
          org,
          'settigs.currency',
          rootState.localizationModule.currency,
        ),
        distanceUnit: get(
          org,
          'settings.distanceUnit',
          rootState.localizationModule.distanceUnit,
        ),
        fuelEcoUnit: get(
          org,
          'settings.fuelEcoUnit',
          rootState.localizationModule.fuelEcoUnit,
        ),
        time24hr: get(
          org,
          'settings.time24hr',
          rootState.localizationModule.time24hr,
        ),
        dayFirst: get(
          org,
          'settings.dayFirst',
          rootState.localizationModule.dayFirst,
        ),
        workingHoursEnabled: get(org, 'settings.workingHoursEnabled', false),
      }
      return settings
    },
  },
  mutations: {
    CLEAR_ADMIN_MODULE: state => {
      state.org = null
      state.children = []
      state.users = []
      state.userPagination = {
        total: 0,
        offset: 0,
        limit: 10,
      }
      state.packs = []
      state.globalWidgets = []
      state.workingHours = []
      state.orgPack = null
      state.totalChildren = 0
    },
    CLEAR_ORG_SUBDATA: state => {
      state.children = []
      state.packs = []
      state.globalWidgets = []
      state.users = []
      state.workingHours = []
    },
    SET_ORG: (state, payload) => {
      Vue.set(state, 'org', payload)
    },
    SET_ORG_SETTING: (state, payload) => {
      Vue.set(state.org.settings, payload.key, payload.val)
    },
    SET_ORG_DRIVER_OVERRIDE: (state, flag) => {
      let settings = get(state, 'org.settings')
      Vue.set(settings, 'orgDriverOverrideEnabled', flag)
      Vue.set(state.org, 'settings', settings)
    },

    SET_ORG_SUPPORT_SETTINGS: (state, payload) => {
      Vue.set(state, 'supportSettings', payload)
    },

    // org dash config
    SET_ORG_SECTIONS (state, payload) {
      if (payload.constructor === Array) {
        state.sections = payload
      }
    },
    TOGGLE_ORG_DASH_MAP_SETTING: (state, payload) => {
      state.map = payload
    },
    ADD_NEW_ORG_SECTION: (state, full) => {
      const widgets = full
        ? [placeholderString]
        : [placeholderString, placeholderString, placeholderString]
      state.sections.push(widgets)
    },
    DELETE_ORG_SECTION: (state, index) => {
      state.sections.splice(index, 1)
    },
    UPDATE_ORG_WIDGET: (state, payload) => {
      let section = state.sections[payload.sectionIndex]
      let newSection = section

      // check if incoming widget is already in section
      if (newSection.indexOf(payload.widget) > -1) {
        // if so, swap places
        swap(
          newSection,
          payload.widgetIndex,
          newSection.indexOf(payload.widget),
        )
      } else {
        // if not, just set the new widget in it's target index
        newSection[payload.widgetIndex] = payload.widget
      }

      state.sections.splice(payload.sectionIndex, 1, newSection)
    },
    REMOVE_ORG_WIDGET: (state, payload) => {
      let section = state.sections[payload.sectionIndex]
      let newSection = section
      if (newSection[payload.widgetIndex] === payload.widget) {
        newSection[payload.widgetIndex] = placeholderString
      }
      state.sections.splice(payload.sectionIndex, 1, newSection)
    },
    // child org mutations
    SET_CHILDREN: (state, payload) => {
      state.children = payload
    },
    SET_TOTAL_CHILDREN: (state, total) => {
      state.totalChildren = total
    },
    PUSH_CHILD: (state, payload) => {
      state.children.push(payload)
    },
    TOGGLE_ORG_STATUS: (state, payload) => {
      state.childStatusToggle = payload
    },
    UPDATE_ORG_STATUS: (state, orgId) => {
      let index = null
      let payload = null
      state.children.forEach((c, idx) => {
        if (c.id === orgId) {
          payload = c
          index = idx
        }
      })
      if (typeof index !== 'number' && !payload) {
        return
      }
      payload.isActive = !payload.isActive
      Vue.set(state.children, index, payload)
    },
    // pack mutations
    SET_PACKS: (state, payload) => {
      state.packs = payload
    },
    SET_GLOBAL_WIDGETS: (state, payload) => {
      state.globalWidgets = payload
    },
    PUSH_PACK: (state, payload) => {
      state.packs.push(payload)
    },
    UPDATE_PACK: (state, payload) => {
      let index = null
      state.packs.forEach((pack, i) => {
        if (pack.id === payload.id) {
          index = i
        }
      })
      if (typeof index !== 'number') {
        return
      }
      state.packs.splice(index, 1, payload)
    },
    UPDATE_PACK_WIDGETS: (state, payload) => {
      let index = null
      state.packs.forEach(pack => {
        if (pack.id === payload.packId) {
          state.packs.widgets.forEach((widget, i) => {
            if (widget.id === payload.widget) {
              index = i
            }
          })
        }
      })
      if (typeof index !== 'number') {
        return
      }
      state.packs.widgets.splice(index, 1, payload)
    },
    UPDATE_PACK_LIST: (state, packId) => {
      let index = null
      state.packs.forEach((pack, idx) => {
        if (pack.id === packId) {
          index = idx
        }
      })
      if (typeof index !== 'number') {
        return
      }
      state.packs.splice(index, 1)
    },

    // user mutations
    SET_USERS: (state, payload) => {
      state.users = payload.users
    },
    PUSH_USER: (state, payload) => {
      state.users.push(payload)
    },
    SET_DELETED_USER: (state, payload) => {
      state.delUser = payload.user
    },
    CLEAR_DELETED_USER: state => {
      state.delUser = null
    },
    UPDATE_USER: (state, payload) => {
      let index = null
      state.users.forEach((user, i) => {
        if (user.id === payload.id) {
          index = i
        }
      })
      if (typeof index !== 'number') {
        return
      }
      state.users.splice(index, 1, payload)
    },
    REMOVE_USER: (state, payload) => {
      state.users = state.users.filter(user => user.id !== payload)
    },
    UPDATE_ORG_USER_PAGINATION: (state, pagination) => {
      state.userPagination.total = get(
        pagination,
        'total',
        state.userPagination.total,
      )
      state.userPagination.offset = get(
        pagination,
        'offset',
        state.userPagination.offset,
      )
      state.userPagination.limit = get(
        pagination,
        'limit',
        state.userPagination.limit,
      )
    },
    SET_ACTIVE_USER_PAGE: state => {
      state.activeUserPage =
        state.userPagination.offset / state.userPagination.limit
    },
    SET_FETCHING_USER_STATUS: (state, flag) => {
      state.fetchingUser = flag
    },

    // working hours mutations
    SET_WORKING_HOURS: (state, payload) => {
      state.workingHours = payload
    },
    PUSH_WHP: (state, payload) => {
      state.workingHours.push(payload)
    },
    UPDATE_WHP: (state, payload) => {
      let index = null
      state.workingHours.forEach((whp, i) => {
        if (whp.id === payload.id) {
          index = i
        }
      })
      if (typeof index !== 'number') {
        return
      }
      state.workingHours.splice(index, 1, payload)
    },
    REMOVE_WHP: (state, payload) => {
      state.workingHours = state.workingHours.filter(
        profile => profile.id !== payload,
      )
    },
    SET_ADMIN_ORG_PACK: (state, pack) => {
      state.adminOrgPack = pack
    },
    TOGGLE_INACTIVE_ORGS: state => {
      state.includeInactveOrgs = !state.includeInactveOrgs
    },
  },
  actions: {
    HYDRATE_ORG_DATA ({ dispatch }, payload) {
      // this lets us pass a string into payload as orgId, OR an object
      const orgId = get(payload, 'orgId', payload)
      dispatch('FETCH_ORG_PROMISE', orgId)
        .then(() => {
          dispatch('FETCH_ORG_CHILDREN_PROMISE', {
            orgId: orgId,
            params: payload.params,
          }).catch(err => {
            throw err
          })
          dispatch('FETCH_ORG_USERS', orgId)
          dispatch('FETCH_ORG_WORKING_HOURS', orgId)
          dispatch('FETCH_ORG_PACKS', orgId)
            .then(() => {
              dispatch('FETCH_ORG_PACK', orgId)
            })
            .catch(err => {
              throw err
            })
        })
        .catch(error => {
          throw error
        })
    },

    // org actions
    FETCH_ORG_PROMISE ({ commit }, orgId) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/orgs/' + orgId)
          .then(resp => {
            commit('SET_ORG', resp.data)
            resolve(resp.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    UPDATE_ORG_SETTINGS_PROMISE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/orgs/' + payload.orgId, {
            settings: payload.settings,
          })
          .then(resp => {
            commit('SET_ORG', resp.data)
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    UPDATE_ORG_SHARING_DRIVER_OVERRIDE_PROMISE ({ state, commit }, flag) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/orgs/${state.org.id}/driver/override`, {
            orgDriverOverrideEnabled: flag,
          })
          .then(resp => {
            commit('SET_ORG_DRIVER_OVERRIDE', flag)
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    UPDATE_ORG_PROMISE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.name || !payload.orgId) {
          reject(new Error('orgId and name required'))
        }
        let body = {}
        if (payload.name) {
          body.name = payload.name
        }
        if (payload.parents) {
          body.parents = payload.parents
        }
        axios
          .put(`/api/v1/orgs/${payload.orgId}`, body)
          .then(resp => {
            commit('SET_ORG', resp.data)
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // org support settings actions
    FETCH_ORG_SUPPORT_SETTINGS_PROMISE ({ commit }, orgId) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/orgs/' + orgId +'/support-settings')
          .then(resp => {
            commit('SET_ORG_SUPPORT_SETTINGS', resp.data.supportSetting)
            resolve(resp.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    UPDATE_ORG_SUPPORT_SETTINGS_PROMISE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/orgs/${payload.supportSetting.orgId}/support-settings`, payload)
          .then(resp => {
            commit('SET_ORG_SUPPORT_SETTINGS', resp.data.supportSetting)
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // child org actions
    // FETCH_ORG_CHILDREN_PROMISE only fetches children whos type === 'regular'
    FETCH_ORG_CHILDREN_PROMISE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let params = {}

        // grab params if function dispatch sent them
        if (payload.params) {
          params = payload.params
        }

        // append org type filter to params
        params.type = 'regular'

        axios
          .get(`/api/v1/orgs/${payload.orgId}/children`, {
            params: params,
          })
          .then(resp => {
            commit('SET_CHILDREN', resp.data.children)
            commit(
              'SET_TOTAL_CHILDREN',
              get(resp, 'data.meta.pagination.total'),
            )
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    ACTIVATE_SUBORG_PROMISE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/orgs/${payload.orgId}/setting/is_active`, payload.body)
          .then(resp => {
            commit('UPDATE_ORG_STATUS', payload.orgId)
            commit('TOGGLE_ORG_STATUS', {})
            resolve(resp)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // pack actions
    FETCH_ORG_PACKS ({ commit }, orgId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/orgs/${orgId}/packs`)
          .then(resp => {
            commit('SET_PACKS', resp.data.packs)
            resolve(resp.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    FETCH_ORG_PACK ({ commit, state }, orgId) {
      const orgPack = get(state.org, 'settings.pack')
      const packs = state.packs
      // check if we have a pack for the org
      if (orgPack) {
        // try to find the pack in the orgPacks
        const foundPack = packs.find(p => p.id === orgPack)
        if (foundPack) {
          // set it if we found it
          commit('SET_ADMIN_ORG_PACK', foundPack)
        } else {
          // fetch it if we can't find it
          axios
            .get(`/api/v1/orgs/${orgId}/packs/${orgPack}`)
            .then(resp => {
              commit('SET_ADMIN_ORG_PACK', resp.data)
            })
            .catch(err => {
              throw err
            })
        }
      }
    },
    CREATE_PACK_PROMISE (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/orgs/${payload.orgId}/packs`, payload.pack)
          .then(resp => {
            context.dispatch('SET_PACK_MOBILE_DEFAULT', resp.data)
            resolve(resp)
          })
          .catch(error => {
            reject(error)
            throw error
          })
      })
    },
    PUT_PACK_PROMISE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/orgs/${payload.orgId}/packs/${payload.id}`, payload)
          .then(resp => {
            commit('UPDATE_PACK', resp.data)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    DELETE_PACK_PROMISE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/orgs/${payload.orgId}/packs/${payload.packId}`)
          .then(resp => {
            commit('UPDATE_PACK_LIST', payload.packId)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    FETCH_ORG_USERS ({ commit, state, rootState }, orgId) {
      return new Promise((resolve, reject) => {
        if (!orgId) {
          reject(new Error('missing org id when fetching users'))
        }
        const page = state.userPagination.offset / state.userPagination.limit
        if (!state.users.length - 1 < page || !get(state, `users[${page}]`)) {
          commit('SET_FETCHING_USER_STATUS', true)

          // setup params
          const params = {
            offset: state.userPagination.offset,
            limit: state.userPagination.limit,
            sortDir: rootState.paramsModule.sorts.users.asc ? 'asc' : 'desc',
          }

          // get sortby
          switch (rootState.paramsModule.sorts.users.sortBy) {
            case 'First Name':
              params.sortBy = 'firstName'
              break
            case 'Email':
              params.sortBy = 'email'
              break

            default:
              params.sortBy = 'lastName'
              break
          }
          axios
            .get(`/api/v1/orgs/${orgId}/users`, {
              params: params,
            })
            .then(resp => {
              commit('UPDATE_ORG_USER_PAGINATION', {
                total: get(resp, 'data.meta.pagination.total'),
              })
              commit('SET_USERS', resp.data)
              commit('SET_FETCHING_USER_STATUS', false)
              resolve()
            })
            .catch(err => {
              commit('SET_FETCHING_USER_STATUS', false)
              reject(err)
            })
        } else {
          commit('SET_FETCHING_USER_STATUS', false)
          resolve()
        }
      })
    },
    CREATE_USER_PROMISE ({ commit, rootState }, payload) {
      const body = {
        user: payload.user,
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/orgs/${payload.orgId}/users`, body, {
            params: {
              theme: rootState.themeModule.theme,
              prefix: rootState.themeModule.prefix,
            },
          })
          .then(resp => {
            commit('PUSH_USER', resp.data.user)
            resolve(resp)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    UPDATE_USER_PROMISE ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/orgs/${payload.orgId}/users/${payload.userId}`, {
            user: payload.user,
          })
          .then(resp => {
            commit('UPDATE_USER', resp.data.user)
            dispatch('SERVE_TOAST', {
              type: 'success',
              message: 'Successfully updated user!',
            })
            resolve(resp)
          })
          .catch(err => {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: err.message,
            })
            reject(err)
          })
      })
    },
    DELETE_USER_PROMISE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/orgs/${payload.orgId}/users/${payload.userId}`)
          .then(resp => {
            commit('REMOVE_USER', payload.userId)
            commit('CLEAR_DELETED_USER')
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // widget actions
    ADD_WIDGET_PROMISE (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/api/v1/orgs/${payload.orgId}/packs/${payload.packId}/widgets`,
            {
              widgets: payload.widgets,
            },
          )
          .then(resp => {
            context.commit('SET_TARGET_CONFIG_PACK', resp.data)

            const packId = resp.data.id
            const orgPackId = context.rootState.adminModule.adminOrgPack.id

            if (packId === orgPackId) {
              const orgDashPayload = {
                orgId: payload.orgId,
                pack: resp.data,
              }
              context.dispatch('SET_ORG_DASH_WIDGETS', orgDashPayload)
            }
            resolve(resp)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    DELETE_WIDGET_PROMISE (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `/api/v1/orgs/${payload.orgId}/packs/${payload.packId}/widgets`,
            {
              data: {
                widgets: payload.widgets,
              },
            },
          )
          .then(resp => {
            context.commit('SET_TARGET_CONFIG_PACK', resp.data)
            const packId = resp.data.id
            const orgPackId = context.rootState.adminModule.adminOrgPack.id

            if (packId === orgPackId) {
              const orgDashPayload = {
                orgId: payload.orgId,
                pack: resp.data,
              }
              context.dispatch('SET_ORG_DASH_WIDGETS', orgDashPayload)
            }
            resolve(resp)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    FETCH_WIDGETS_PROMISE ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/widgets?limit=100`)
          .then(resp => {
            commit('SET_GLOBAL_WIDGETS', resp.data.Widgets)
            resolve(resp)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    SET_ORG_PACK_PROMISE (context, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.orgId || !payload.packId) {
          reject(
            new Error('SET_ORG_PACK_PROMISE payload requires orgId and packId'),
          )
          return
        }
        axios
          .put(`/api/v1/orgs/${payload.orgId}/pack`, {
            settings: {
              pack: payload.packId,
            },
          })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    SET_ORG_DASH_WIDGETS (context, payload) {
      const widgets = context.getters.widgetFilter(payload.pack.widgets, 'dashboard-widget')

      let sections = []
      let i, j, section, chunk = 3
      for (i = 0, j = widgets.length; i < j; i += chunk) {
        section = widgets.slice(i, i + chunk)
        section.forEach((widget, idx) => {
          section[idx] = widget.componentName
        })
        sections.push(section)
      }

      const orgDashPayload = {
        orgId: payload.orgId,
        settings: {
          dashboard: {
            map: context.rootState.adminModule.map,
            sections: sections,
          },
        },
      }

      context.dispatch('UPDATE_ORG_SETTINGS_PROMISE', orgDashPayload)
    },
  },
}

export default module
