<template>
  <VinCard
    class="relative h-full"
    style="minHeight:358px;paddingTop:6px;"
  >
    <VinLoadingBlock
      v-if="showLoadingBlock"
      class="z-20"
    />
    <slot />
  </VinCard>
</template>

<script>
import VinCard from '@/common/components/VinCard/VinCard'
import VinLoadingBlock from '@/common/components/VinLoadingBlock/VinLoadingBlock'
export default {
  name: 'VinSmallWidgetContainer',
  components: {
    VinCard,
    VinLoadingBlock,
  },
  props: {
    showLoadingBlock: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
