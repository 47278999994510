<template>
  <header class="bg-primary-dk mx-auto">
    <SpotlightSearch v-if="isSearching && !$store.state.searchModule.searchMap && $store.state.searchModule.searchTerm.spotlight.length > 0" />
    <div class="container h-20 flex justify-between">
      <router-link
        class="flex items-center p-1 text-white hover:text-grey-dk"
        :to="{
          name: 'Dashboard',
          params: {
            orgId: orgId
          }
        }"
      >
        <img
          :src="require(`@/assets/themes/${logoSrc}`)"
          alt="main logo"
          class="h-8"
        >
      </router-link>
      <div class="header-nav flex justify-end items-center p-0 m-0">
        <div
          v-if="isDemo"
          class="bg-copy border-2 border-white px-3 py-2px rounded mr-4"
        >
          <span class="leading-none text-white uppercase font-medium">
            {{ $t('demo user') }}
          </span>
        </div>
        <router-link :to="{ name: 'Profile', params: { userId: userId } }">
          <VinGravatar
            :circle="true"
            :size="46"
            :email="email"
            :name="name"
            :custom-icon="userIcon"
            vinli="nav-gravatar"
          />
        </router-link>
        <VinDropdown
          vid="app-header-dropdown"
        >
          <span class="text-white tracking-relaxed text-base ml-4 flex items-center">
            {{ `${first} ${last}` }}
            <chevron-down-icon class="icon ml-2 mr-6 pb-1 w-8 h-8" />
          </span>
          <VinDropdownMenu slot="menu">
            <VinDropdownItem
              v-if="isAuthenticated && !isMimic"
              :text="$t('My Profile')"
              @click.native="navToProfile"
            />
            <VinDropdownItem
              v-if="canAccessAdmin"
              :text="$t('Admin Settings')"
              @click.native="navToAdmin"
            />
            <VinDropdownItem
              v-if="canAccessOrgPicker"
              :text="orgName"
              @click.native="navToSwitchOrg"
            />
            <VinDropdownItem
              :text="$t('contact_support')"
              @click.native="navToSupport"
            />
            <VinDropdownItem
              v-if="isAuthenticated"
              :text="$t('Log Out')"
              @click.native="logout"
            />
          </VinDropdownMenu>
        </VinDropdown>
        <div class="divider-right" />
        <router-link
          id="nav-dash-edit"
          to="/dashboard/edit"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="ml-6 mr-2"
            width="28"
            height="28"
            viewBox="0 0 28 28"
          >
            <path
              fill="none"
              fill-rule="evenodd"
              stroke="#FFF"
              stroke-width="2"
              d="M1.75 1a.75.75 0 0 0-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 0 0 .75-.75v-8.5a.75.75 0 0 0-.75-.75h-8.5zm0 16a.75.75 0 0 0-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 0 0 .75-.75v-8.5a.75.75 0 0 0-.75-.75h-8.5zm16-16a.75.75 0 0 0-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 0 0 .75-.75v-8.5a.75.75 0 0 0-.75-.75h-8.5zm0 16a.75.75 0 0 0-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 0 0 .75-.75v-8.5a.75.75 0 0 0-.75-.75h-8.5z"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </header>
</template>
<script>
import get from 'lodash.get'
import { ChevronDownIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'

import SpotlightSearch from '../../SpotlightSearch/SpotlightSearch'
export default {
  name: 'AppHeaderDesktop',
  components: {
    ChevronDownIcon,
    SpotlightSearch,
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isSearching']),
    session () {
      return this.$store.state.authModule.session
    },
    email () {
      const email = this.session ? this.session.email : ''
      return this.isMimic ? this.$store.state.authModule.mimic.user.email : email
    },
    userIcon () {
      return this.isMimic ? '' : get(this.session, 'userIcon', '')
    },
    isDemo () {
      const accessLevel = this.session ? this.session.accessLevel : ''
      return accessLevel === 'demo'
    },
    name () {
      return this.session ? this.session.firstName + ' ' + this.session.lastName : ''
    },
    first () {
      const first = this.session ? this.session.firstName : ''
      return this.isMimic ? this.$store.state.authModule.mimic.user.firstName : first
    },
    last () {
      const last = this.session ? this.session.lastName : ''
      return this.isMimic ? this.$store.state.authModule.mimic.user.lastName : last
    },
    userId () {
      return this.session ? this.session.id : ''
    },
    activeOrg () {
      return this.$store.state.authModule.activeOrg
    },
    orgName () {
      let name = get(this.activeOrg, 'name', '--')
      return name.charAt(0).toUpperCase() + name.slice(1)
    },
    orgId () {
      return get(this.$store.state.authModule, 'activeOrg.id')
    },
    logoSrc () {
      return `${this.$store.state.themeModule.theme}/app-logo.png`
    },
    isMimic () {
      return this.$store.state.authModule.mimic.isActive
    },
    isRegularOrg () {
      return get(this.$store.state.authModule, 'orgType') === 'regular'
    },
    canAccessAdmin () {
      const hasPermission = this.$store.getters.hasPermission('customer_admin')
      return this.isAuthenticated && this.isRegularOrg && hasPermission
    },
    canAccessOrgPicker () {
      return !this.isMimic
    },
  },
  methods: {
    logout () {
      this.$store.dispatch('DELETE_CURRENT_SESSION')
    },
    navToProfile () {
      this.$router.push({ name: 'Profile', params: { userId: this.userId } })
    },
    navToAdmin () {
      this.$router.push({ name: 'Admin', params: { orgId: this.orgId } })
    },
    navToSwitchOrg () {
      this.$router.push({ name: 'OrgSelection', params: { orgId: this.orgId } })
    },
    navToSupport () {
      this.$router.push({ name: 'ContactSupport' })
    },
  },
}
</script>
<style>
.icon {
  margin-bottom: -5px;
}
</style>
