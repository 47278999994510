import { isBefore, parseISO } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import get from 'lodash.get'

import axios from '../middleware/axios-store'
const module = {
  state: {
    date: null,
    queryDate: null,
    isFetching: false,
    historicalMode: false,
  },
  mutations: {
    CLEAR_HISTORY_MODULE: (state) => {
      state.date = null
      state.queryDate = null
      state.isFetching = false
      state.historicalMode = false
    },
    SET_FLEET_HISTORICAL_DATE: (state, date) => {
      state.date = date
    },
    TOGGLE_HISTORY_IS_FETCHING: (state, flag) => {
      state.isFetching = flag
    },
    TOGGLE_HISTORICAL_MODE: (state, flag) => {
      state.historicalMode = flag
    },
  },
  actions: {
    FLEET_HISTORY ({ commit, state, rootState }) {
      commit('TOGGLE_HISTORY_IS_FETCHING', true)
      commit('TOGGLE_HISTORICAL_MODE', true)

      // convert the local date to utc
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      state.queryDate = zonedTimeToUtc(state.date, timezone).toISOString()

      // should this be migrated to v2 groups?
      // let vehicles = rootState.groupsModule.activeGroup ? rootState.groupsModule.groupVehicles : rootState.vehiclesModule.vehicles

      let vehicles = rootState.vehiclesModule.vehicles
      vehicles.forEach((v, i) => {
        axios.get(`/api/v1/orgs/${rootState.authModule.activeOrg.id}/vehicles/${v.id}/trips`, {
          params: {
            until: state.queryDate,
            limit: 1,
          },
        })
          .then(resp => {
            const trip = get(resp, 'data.trips[0]')
            let historicalLocation = null
            if (trip) {
              const tripStopTime = get(trip, 'stop')
              const lat = get(trip, 'stopPoint.coordinates[1]', null)
              const lng = get(trip, 'stopPoint.coordinates[0]', null)
              if (isBefore(parseISO(tripStopTime), parseISO(state.queryDate)) && lat !== null && lng !== null) {
                historicalLocation = {
                  timestamp: tripStopTime,
                  coordinates: {
                    lat: lat,
                    lng: lng,
                  },
                }
              } else {
                axios.get(`/api/v1/orgs/${rootState.authModule.activeOrg.id}/vehicles/${v.id}/trips/${trip.id}/messages`)
                  .then(resp => {
                    const messages = get(resp, 'data.Messages', [])
                    for (let i = 0; i < messages.length && historicalLocation === null; i++) {
                      const message = messages[i]
                      const timestamp = get(message, 'timestamp', null)
                      const location = get(message, 'data.location', null)

                      if (
                        location &&
                        timestamp &&
                        isBefore(parseISO(timestamp), parseISO(state.queryDate))
                      ) {
                        historicalLocation = {
                          timestamp: timestamp,
                          coordinates: {
                            lat: location.coordinates[1],
                            lng: location.coordinates[0],
                          },
                        }
                        // should this be migrated to v2 groups?
                        // const mutation = rootState.groupsModule.activeGroup ? '' : 'SET_HISTORICAL_LOCATION_ON_VEHICLE'
                        // commit(mutation, {
                        //   vehicleId: v.id,
                        //   location: historicalLocation
                        // })
                        commit('SET_HISTORICAL_LOCATION_ON_VEHICLE', {
                          vehicleId: v.id,
                          location: historicalLocation,
                        })
                      }
                    }
                  })
              }

              if (historicalLocation) {
                // should this also be migrated to v2 groups?
                // const mutation = rootState.groupsModule.activeGroup ? 'SET_HISTORICAL_LOCATION_ON_G_VEHICLE' : 'SET_HISTORICAL_LOCATION_ON_VEHICLE'
                // commit(mutation, {
                //   vehicleId: v.id,
                //   location: historicalLocation
                // })
                commit('SET_HISTORICAL_LOCATION_ON_VEHICLE', {
                  vehicleId: v.id,
                  location: historicalLocation,
                })
              }

              // done
              if (i === vehicles.length - 1) {
                commit('TOGGLE_HISTORY_IS_FETCHING', false)
              }
            }
          })
          .catch(err => {
            throw err
          })
      })
    },
  },
}

export default module
