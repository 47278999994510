<template>
  <div>
    <div class="px-8 mt-4 flex items-center justify-between">
      <span class="text-grey-dk">
        {{ 'Phone' | translate }}
      </span>
    </div>
    <div class="w-full px-8 mt-1 mb-5 flex items-center justify-between">
      <VueTelInput
        v-model="locationPhone"
        class="w-full"
        :preferred-countries="preferredCountries"
        :placeholder="$t('Enter a phone number')"
        :input-options="{ showDialCode: true }"
        disabled-fetching-country
      />
    </div>
  </div>
</template>

<script>
import 'vue-tel-input/dist/vue-tel-input.css'

import VueTelInput from 'vue-tel-input'

import preferredCountries from '@/common/utils/phone-preferred-countries'
export default {
  name: 'ServiceLocationsPhone',
  components: {
    VueTelInput,
  },
  props: {
    inputPhone: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      locationPhone: '',
      preferredCountries,
    }
  },
  watch: {
    inputPhone: {
      immediate: true,
      handler (v) {
        this.locationPhone = v
      },
    },
    locationPhone () {
      this.$emit('phone-input', this.locationPhone)
    },
  },
}
</script>
