module.exports = {
  // absolutes
  transparent: 'transparent',

  black: '#000000',
  white: '#ffffff',

  // greys
  'grey-ltr': '#F5F5F5',
  'grey-lt': '#EEEEEE',
  'grey': '#C9C6C6',
  'grey-dk': '#999999',
  'grey-dkr': '#666666',

  // theme colors
  'primary-lt': '#33525d',
  'primary-dk': '#001e69',
  'primary-dkr': '#001750',
  'cta-lt': '#FF7300',
  'cta-dk': '#FF5000',

  // interaction colors
  'success-lt': '#1D7B8A',
  'success-dk': '#rgba(29, 98, 110)',
  'warning-lt': '#FCB900',
  'warning-dk': '#FF7300',
  'danger-lt': '#CF223F',
  'danger-dk': '#CD2F00',
  'pending-dk': '#bf6702',

  // utility colors
  'border': '#aeb5bc',
  'svg-grey': '#8c91a2',
  'sidebar-grey': '#979aa7',
  'copy': '#333',
  'positive': '#22cd91',
  'negative': '#d0021b',
  'score-a': '#00d28b',
  'score-b': '#0b8eff',
  'score-c': '#ffd255',
  'score-d': '#f98658',
  'score-f': '#f41745',
  // non tailwind colors below
  // auth gradient
  'auth-gradient-0': '#0033cc',
  'auth-gradient-1': '#001e69',
  'auth-gradient-2': '#001750',
  // icon gradient
  'icon-gradient-0': '#2ab3c9',
  'icon-gradient-1': '#1D7B8A',
  // map
  'static-polyline': '001e69',
  // chart
  'bar-0': '#2ab3c9',
  'bar-1': '#1D7B8A',
}
