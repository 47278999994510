<template>
  <div class="absolute w-screen h-full">
    <div class="absolute z-30 flex w-full justify-center">
      <div
        v-clickoutside="handleClose"
        class="search-modal container mx-auto flex flex-col relative bg-white w-full"
      >
        <div class="flex items-center inner-addon right-addon ml-auto mr-3 mb-4">
          <button
            class="close-btn absolute top-0 right-0"
            @click="handleClose"
          >
            <XIcon class="x-icon max:mr-2 fill-current hover:text-primary-dk" />
          </button>
          <VinInput
            :value="searchTerm"
            color="primary-dk"
            class="search exact-margin w-88 max:mr-2 text-lg rounded-xl border-primary-dk bg-grey-ltr"
            @input="handleInput($event)"
          />
        </div>
        <div class="flex justify-between">
          <div class="flex flex-col flex-wrap px-1 w-1/3">
            <div class="flex items-center mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 18 20"
                class="mr-4 text-primary-dk stroke-current"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  transform="translate(1 1)"
                >
                  <path d="M16 18v-2a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v2" />
                  <circle
                    cx="8"
                    cy="4"
                    r="4"
                  />
                </g>
              </svg>
              <h2 class="text-grey-dk text-lg font-normal tracking-wide uppercase">
                {{ $t('Drivers') }}
              </h2>
            </div>
            <VinLoading
              v-if="isFetching"
              class="mx-auto mt-12"
              border="3px solid rgba(100, 100, 100, .25)"
            />
            <SpotlightResultDriver
              v-for="(vehicle, idx) in driverResults.slice(0, 4)"
              :key="idx"
              :driver="vehicle.driver || {}"
              class="h-24"
              @click.native="navToVehicle(vehicle.id)"
            />
            <SpotlightPaginate
              item="driver"
              :result-count="driverResultsCount"
              class="w-full mt-auto -mb-2 pt-4"
              @click.native="driverResultsCount > 4 ?
                navToResults(driverResults, 'driver') :
                $emit('spotlightDriverPaginateClick')"
            />
          </div>

          <div class="divider-right-grey modal-divider w-2px mx-20" />

          <div class="flex flex-col flex-wrap px-1 w-1/3">
            <div class="flex items-center mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="18"
                viewBox="0 0 36 18"
                class="mr-4 text-primary-dk stroke-current"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  transform="matrix(-1 0 0 1 35 1)"
                >
                  <path d="M11.2 12.53h11.838M3.667 12.216h-.964a1.362 1.362 0 0 1-1.284-.909L.395 8.404a2 2 0 0 1 .397-2.001L5.942.664A2 2 0 0 1 7.43 0h10.845a2 2 0 0 1 1.51.69l3.02 3.48a2 2 0 0 0 1.186.663l7.616 1.252a2 2 0 0 1 1.66 2.215l-.315 2.594a1.504 1.504 0 0 1-1.493 1.322" />
                  <circle
                    cx="7.6"
                    cy="12.4"
                    r="3.6"
                  />
                  <circle
                    cx="26.8"
                    cy="12.4"
                    r="3.6"
                  />
                  <path d="M18.858 5.189h-4.016L13.333 3.14" />
                </g>
              </svg>
              <h2 class="text-grey-dk text-lg font-normal tracking-wide uppercase">
                {{ $t('Vehicles') }}
              </h2>
            </div>
            <VinLoading
              v-if="isFetching"
              class="mx-auto mt-12"
              border="3px solid rgba(100, 100, 100, .25)"
            />
            <SpotlightResultVehicle
              v-for="(vehicle, idx) in vehicleResults.slice(0, 4)"
              :key="idx"
              :vehicle="vehicle"
              class="h-24"
              @click.native="navToVehicle(vehicle.id)"
            />
            <SpotlightPaginate
              item="vehicle"
              :result-count="vehicleResultsCount"
              class="w-full mt-auto -mb-2 pt-4"
              @click.native="vehicleResultsCount > 4 ?
                navToResults(vehicleResults, 'vehicle') :
                $emit('spotlightVehiclePaginateClick')"
            />
          </div>

          <div class="divider-right-grey modal-divider w-2px mx-20" />

          <div class="flex flex-col flex-wrap px-1 w-1/3">
            <div class="flex items-center mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="14"
                viewBox="0 0 24 14"
                class="mr-4 text-primary-dk stroke-current"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <path d="M23 1l-9.5 9.5-5-5L1 13" />
                  <path d="M17 1h6v6" />
                </g>
              </svg>
              <h2 class="text-grey-dk text-lg font-normal tracking-wide uppercase">
                {{ $t('Reports') }}
              </h2>
            </div>
            <SpotlightResultReport
              v-for="(report, idx) in reportResults.slice(0, 4)"
              :key="idx"
              :title="report.title"
              :icon="report.icon"
              class="h-24"
              @click.native="navToReport(report.slug)"
            />
            <SpotlightPaginate
              item="report"
              :result-count="reportResultsCount"
              class="w-full mt-auto -mb-2 pt-4"
              @click.native="navToReportCenter"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
import { XIcon } from 'vue-feather-icons'

import { vehicleSearchConfig } from '@/common/utils/search-config'

import SpotlightPaginate from './SpotlightPaginate'
import SpotlightResultDriver from './SpotlightResultDriver'
import SpotlightResultReport from './SpotlightResultReport'
import SpotlightResultVehicle from './SpotlightResultVehicle'

export default {
  name: 'SpotlightSearch',
  components: {
    XIcon,
    SpotlightResultDriver,
    SpotlightResultVehicle,
    SpotlightResultReport,
    SpotlightPaginate,
  },
  directives: {
    clickoutside: {
      bind: function (el, binding) {
        el._handler = evt => {
          if (!el.contains(evt.target) && binding.expression) {
            binding.value(evt)
          }
        }

        document.addEventListener('click', el._handler)
      },
      unbind: function (el) {
        document.removeEventListener('click', el._handler)
      },
    },
  },
  data () {
    return {
      driverResults: [],
      driverResultsCount: 0,
      vehicleResults: [],
      vehicleResultsCount: 0,
      reportResults: [],
      reportResultsCount: 0,
    }
  },
  computed: {
    searchTerm: {
      get () {
        return this.$store.state.searchModule.searchTerm.spotlight
      },
      set (value) {
        this.$store.commit('SEARCH_BY_TERM', { term: value, type: 'spotlight' })
      },
    },
    isFetching () {
      return this.$store.state.vehiclesModule.fetchingVehicles
    },
    hasAllVehicles () {
      return this.$store.state.vehiclesModule.hasAllVehicles
    },
    drivers () {
      return this.$store.state.vehiclesModule.vehicles.filter(v => v.driver)
    },
    vehicles () {
      return this.$store.state.vehiclesModule.vehicles
    },
    fullReportList () {
      let reports = this.$store.getters.getReportsForCategory('fullReportList')
      return reports.filter(r => this.$store.getters.hasWidget(r.widget))
    },
    orgId () {
      return get(
        this.$route,
        'params.orgId',
        get(this.$store.state.authModule.activeOrg, 'id', null),
      )
    },
  },
  watch: {
    searchTerm () {
      this.handleSearch()
    },
    vehicles () {
      this.handleSearch()
    },
  },
  mounted () {
    document.addEventListener('keyup', this.handleEscape)
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this.handleEscape)
  },
  created () {
    // kick off vehicle fetch if needed
    this.checkVehicles()
    this.handleSearch()
  },
  methods: {
    handleSearch () {

      const SEARCH_OPTS = {
        threshold: 0.5,
        distance: 15,
        useExtendedSearch: true,
      }

      if (this.searchTerm.length > 0) {
        this.$search(
          this.searchTerm,
          this.drivers, {
            keys: [
              'driver.firstName',
              'driver.lastName',
              'driver.phone',
              'driver.email',
            ],
            ...SEARCH_OPTS,
          }).then(results => {
          this.driverResults = results
          this.driverResultsCount = results.length
        })
        // 'make', 'model', 'year', 'vin', 'trim'
        this.$search(
          this.searchTerm,
          this.vehicles,
          vehicleSearchConfig).then(
            results => {
            this.vehicleResults = results
            this.vehicleResultsCount = results.length
        })
        this.$search(
          this.searchTerm,
          this.fullReportList, { keys: ['title'], ...SEARCH_OPTS }).then(
            results => {
              this.reportResults = results
              this.reportResultsCount = results.length
          })
      } else {
        this.driverResults = this.drivers
        this.driverResultsCount = this.drivers.length
        this.vehicleResults = this.vehicles
        this.vehicleResultsCount = this.vehicles.length
        this.reportResults = this.fullReportList
        this.reportResultsCount = this.fullReportList.length
      }
    },
    handleClose () {
      // sending nothing sets isSearching to false
      // and hides this component
      this.$store.commit('SEARCH_BY_TERM', { term: '', type: 'spotlight' })
      // remove focus
      document.activeElement.blur()
    },
    handleInput (event) {
      this.searchTerm = event
    },
    handleEscape (event) {
      if (event.keyCode === 27) {
        this.handleClose()
      }
    },
    navToVehicle (vehicleId) {
      this.$router.push({
        name: 'VehicleDetails',
        params: {
          vehicleId: vehicleId,
          orgId: this.orgId,
        },
      })

      this.handleClose()
    },
    navToReportCenter () {
      this.$router.push({
        name: 'Reports',
      })
      this.handleClose()
    },
    setResults (payload) {
      this.$store.commit('SET_SEARCH_RESULTS', { payload, type: 'spotlight' })
    },
    navToResults (results, resultId) {
      this.setResults(results)
      this.$router.push({
        name: `SearchResults`,
        params: {
          resultId: resultId,
          orgId: this.orgId,
        },
        query : {
          searchTerm: this.searchTerm,
        },
      })
      this.handleClose()
    },
    navToReport (reportSlug) {
      this.$router.push({
        name: 'ReportDetails',
        params: {
          orgId: this.orgId,
          slug: reportSlug,
        },
      })
      this.handleClose()
    },
    checkVehicles () {
      if (!this.isFetching && !this.hasAllVehicles) {
        this.$store.dispatch('HYDRATE_ORG_VEHICLES')
      }
    },
  },
}
</script>
<style scoped>
.exact-margin {
  margin-top: 19px;
  margin-botton: 21px;
}
.x-icon {
  color: #979aa7;
}
.modal-divider {
  height: 438px;
}
.close-btn {
  padding-top: 28px;
  padding-right: 138px;
}
.search {
  border-width: 1px;
  height: 43px;
  background-color: #f7f8fb;
}
.search-modal {
  overflow: hidden;
  margin-top: 80px;
  border-radius: 6px;
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  padding-left: 115.5px;
  padding-right: 115.5px;
  padding-bottom: 32px;
}
/* style icon */
.inner-addon .searchicon {
  position: absolute;
  pointer-events: none;
}

.searchicon {
  height: 21px;
  stroke: #979aa7;
}

.search {
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: #002c5d !important;
}

/* align icon */
.right-addon .searchicon {
  right: 12px;
  top: 30px;
}
</style>
