<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-idle-comparison-icon"
        x1="91.704%"
        x2="5.368%"
        y1="1.917%"
        y2="70.231%"
      >
        <stop
          offset="0%"
          stop-color="#876ED5"
        />
        <stop
          offset="100%"
          stop-color="#A08BE3"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="nonzero"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-idle-comparison-icon)"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M38.319 39.776H26.52c-.814 0-1.475-.586-1.475-1.31 0-.724.66-1.31 1.475-1.31H38.32c2.22-.032 4.07-1.522 4.324-3.483.254-1.96-1.167-3.785-3.322-4.264-.752-.156-1.241-.802-1.12-1.48.705-4.078-2.254-7.947-6.78-8.863-4.525-.917-9.078 1.43-10.43 5.376-.182.536-.727.91-1.357.93-3.665.062-6.58 2.752-6.51 6.01.069 3.257 3.096 5.848 6.761 5.786.814 0 1.475.587 1.475 1.31 0 .725-.66 1.311-1.475 1.311-5.018-.007-9.18-3.453-9.544-7.901-.363-4.448 3.194-8.392 8.158-9.045 2.08-4.677 7.604-7.383 13.164-6.449 5.56.935 9.59 5.247 9.605 10.276v.55c3.195 1.244 4.973 4.308 4.26 7.342-.713 3.035-3.721 5.21-7.21 5.214z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconIdleComparison',
}
</script>
