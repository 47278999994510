<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="event-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="gradientStart"
        />
        <stop
          offset="100%"
          :stop-color="gradientStop"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="nonzero"
    >
      <rect
        width="48"
        height="48"
        fill="url(#event-icon)"
        rx="10"
      />
      <path
        fill="#FFF"
        stroke="#FFF"
        d="M36.273 13c-1.528 0-2.728 1.21-2.728 2.75a2.62 2.62 0 0 0 1.146 2.2l-.546-.275-6.6 12.375a2.665 2.665 0 0 0-1.636-.55c-.545 0-1.036.165-1.418.44l-5.018-7.095c.272-.495.436-.99.436-1.595 0-1.54-1.2-2.75-2.727-2.75s-2.727 1.21-2.727 2.75c0 .55.163 1.1.49 1.54l-2.127 2.53c-.327-.11-.709-.22-1.09-.22C10.2 25.1 9 26.31 9 27.85s1.2 2.75 2.727 2.75c1.528 0 2.728-1.21 2.728-2.75a2.63 2.63 0 0 0-.764-1.87l2.018-2.42c.436.275.927.44 1.473.44.545 0 1.036-.165 1.418-.44l5.018 7.095c-.273.495-.436.99-.436 1.595 0 1.54 1.2 2.75 2.727 2.75s2.727-1.21 2.727-2.75c0-.495-.109-.935-.327-1.32l6.818-12.705c.328.165.71.275 1.146.275C37.8 18.5 39 17.29 39 15.75S37.8 13 36.273 13zM11.727 29.5c-.927 0-1.636-.715-1.636-1.65 0-.935.709-1.65 1.636-1.65.928 0 1.637.715 1.637 1.65 0 .935-.71 1.65-1.637 1.65zm5.455-6.6c-.927 0-1.637-.715-1.637-1.65 0-.935.71-1.65 1.637-1.65s1.636.715 1.636 1.65c0 .935-.709 1.65-1.636 1.65zm8.727 11c-.927 0-1.636-.715-1.636-1.65 0-.935.709-1.65 1.636-1.65.927 0 1.636.715 1.636 1.65 0 .935-.709 1.65-1.636 1.65zm10.364-16.5c-.928 0-1.637-.715-1.637-1.65 0-.935.71-1.65 1.637-1.65s1.636.715 1.636 1.65c0 .935-.709 1.65-1.636 1.65z"
      />
    </g>
  </svg>
</template>

<script>
import iconMix from '../icon-mixin'
export default {
  name: 'VinEventIcon',
  mixins: [iconMix],
}
</script>
