<template>
  <transition
    appear
    name="fade"
    mode="in-out"
  >
    <div
      class="flex flex-col justify-center bg-white shadow-lg md:my-4 mx-8 w-full md:w-88"
      vinli="toast"
    >
      <div class="font-bold text-lg text-copy flex justify-between items-center px-6 pt-4 pb-2">
        <p>{{ $t(type.title) }}</p>
        <button @click="close">
          <div class="text-grey-dk hover:text-primary-dk">
            <svg
              class="stroke-current"
              width="18px"
              height="18px"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="x"
                stroke-width="1.25"
                fill="none"
                fill-rule="evenodd"
              >
                <g fill="#000000">
                  <polygon
                    id="Path"
                    points="6 5.61436364 11.6143636 0 12 0.385636364 6.38563636 6 12 11.6143636 11.6143636 12 6 6.38563636 0.385636364 12 4.84460956e-16 11.6143636 5.61436364 6 0 0.385636364 0.385636364 4.84460956e-16"
                  />
                </g>
              </g>
            </svg>
          </div>
        </button>
      </div>

      <div class="px-6 pb-4 leading-loose">
        {{ $t(message) }}
      </div>

      <button
        v-if="cta"
        :class="type.text"
        class="font-medium left-0 self-start pl-6 pb-2"
        @click="cta.handler"
      >
        {{ $t(cta.title) }}
      </button>
      <div
        :class="type.bg"
        style="height:5px"
        class="w-full"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'VinToast',
  props: {
    toast: {
      type: Object,
      required: true,
    },
  },
  computed: {
    message () {
      return this.toast.message
    },
    type () {
      switch (this.toast.type) {
        case 'danger':
          return {
            title: this.$t('Oh no!'),
            bg: 'bg-danger-lt',
            text: 'text-danger-lt',
          }
        case 'success':
          return {
            title: this.$t('Success'),
            bg: 'bg-success-lt',
            text: 'text-success-lt',
          }
        case 'progress':
          return {
            title: this.$t('In Progress'),
            bg: 'bg-success-lt',
            text: 'text-success-lt',
          }
        case 'warning':
          return {
            title: this.$t('Warning'),
            bg: 'bg-warning-lt',
            text: 'text-warning-lt',
          }
        default:
          return {
            title: this.$t('Info'),
            bg: 'bg-primary-lt',
            text: 'text-primary-lt',
          }
      }
    },
    cta () {
      return this.toast.cta || null
    },
    duration () {
      return this.toast.timeout || 2500
    },
  },
  methods: {
    close () {
      this.$store.commit('EAT_TOAST', this.toast.id)
    },
  },
}
</script>

<style scoped>
.fade-enter-active {
  animation: slideDown 0.5s;
  overflow: hidden;
}
.fade-leave-active {
  animation: bounce-out 0.5s;
  overflow: hidden;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(8%);
  }
  65% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
    max-height: 10em;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
    max-height: 0;
  }
}
</style>
