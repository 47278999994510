<template>
  <div>
    <div
      v-if="showTitle"
      class="px-8 mt-4 flex items-center justify-between"
    >
      <span class="text-grey-dk">
        {{ 'supported_makes' | translate }}
      </span>
    </div>
    <div class=" mt-1 mb-5 flex items-center justify-between">
      <Multiselect
        ref="vehicleMakes"
        v-model="localVehicleMakes"
        placeholder="Select options"
        :multiple="true"
        :options="vehicleMakeOptions"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'ServiceLocationsVehicleMakes',
  components: { Multiselect },
  props: {
    inputVehicleMakes: {
      type: Array,
      default: ()=> {
        return []
      },
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data () {
    return {
      localVehicleMakes: [],
    }
  },
  computed: {
    vehicleMakeOptions () {
      return this.$store.state.serviceMaintenanceModule.vehicleMakes.map( (m) => m.name)
    },
  },
  watch: {
    inputVehicleMakes: {
      immediate: true,
      handler (v) {
        this.localVehicleMakes = v
      },
    },
    localVehicleMakes () {
      this.$emit('vehicle-makes-input', this.localVehicleMakes)
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.border-override {
  border-width: 2px !important;
}
</style>
