<template>
  <li
    :id="id"
    class="py-2 text-xl"
    @click="close"
  >
    <hr
      v-if="isHr"
      class="w-full"
    >
    <router-link
      v-if="route"
      :to="route"
      class="pl-3"
    >
      {{ $t(text) }}
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'AppNabMobileDrawerItem',
  props: {
    route: {
      type: Object,
      default: () => { },
    },
    text: {
      type: String,
    },
    isHr: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Function,
    },
  },
  computed: {
    id () {
      return this.isHr ? `mobile-drawer-item-hr` : `mobile-drawer-item-${this.text ? this.text.toLowerCase() : 'default'}`
    },
  },
  methods: {
    close () {
      if (this.action) {
        this.action()
      }
      this.$store.commit('TOGGLE_DRAWER', false)
    },
  },
}
</script>
