import get from 'lodash.get'

import store from '@/state/store'

// yeeters
const yeetDistanceUnitErrors = (u) => {
  const vDistance = [
    'kilometers',
    'kilometer',
    'km',
    'meters',
    'meter',
    'm',
  ]

  if (typeof u !== 'string' || !vDistance.includes(u)) {
    throw new Error(
      `formatNumber(): Invalid distance unit:${u}, must be one of:${vDistance}
      Reassigning type param to default distance formatter: float()`,
    )
  }
}

const yeetFuelUnitErrors = (u) => {
  const vFuel = [
    'econ',
    'consumed',
  ]

  if (!u || typeof u !== 'string' || !vFuel.includes(u)) {
    throw new Error(
      `formatNumber(): Invalid fuel unit:${u}, must be one of:${vFuel}
      Reassigning type param to default fuel formatter: float()`,
    )
  }
}

const yeetDurationUnitErrors = (u) => {
  const vDuration = [
    's',
    'min',
    'hr',
    'day',
  ]

  if (!u.every(val => vDuration.includes(val))) {
    throw new Error(
      `formatNumber(): Invalid duration units:${u}, must be a list containing some or all of:${vDuration}
      Reassigning unit param to default duration ['s', 'min', 'hr', 'day]`,
    )
  }
}

const yeetAbbrvAtErrors = (av) => {
  if (av < 4 || av > 9) {
    throw new Error(`
      formatNumber(): abbrvAt must be >= 4 and <= 9
      Reassigning abbrvAt param:${av}, to undefined
    `)
  }
}

// validators
const validateDuration = (unit) => {
  if (unit && typeof unit.isArray) {
    try {
      yeetDurationUnitErrors(unit)
      return unit
    } catch (e) {
      console.error(e)
      return [
        's',
        'min',
        'hr',
        'day',
      ]
    }
  } else {
    // default duration units
    return [
      's',
      'min',
      'hr',
      'day',
    ]
  }
}

const validateAbbrvAt = (abbrvAt) => {
  if (abbrvAt && typeof abbrvAt === 'number') {
    try {
      yeetAbbrvAtErrors(abbrvAt)
      return abbrvAt
    } catch (e) {
      // fallback to formatter defined thresholds
      console.error(e)
      return undefined
    }
  } else {
    // fallback to formatter defined thresholds
    return undefined
  }
}

// map params
export const validateOpts = ({
  type,
  unit,
  abbrvAt,
} = {}) => {

  let convertUnit = undefined

  if (type && typeof type === 'string') {
    switch (type) {
      case 'integer':
      case 'float':
        // return provided unit and continue
        break

      case 'currency':
        // remove unit for currency and provide convertUnit to formatter
        unit = undefined
        convertUnit = store.getters.orgSettings.currency
        break

      case 'duration':
        // validate user provided units, reassign on error
        unit = validateDuration(unit)
        break

      case 'distance':
      case 'odometer':
      case 'speed':
        try {
          // validation passed, provide convertUnit to formatter
          yeetDistanceUnitErrors(unit)
          convertUnit = get(
            store.state,
            'localizationModule.distanceUnit',
            'kilometers',
          )
        } catch (e) {
          // use default distance formatter: float() with provided unit
          console.error(e)
          type = 'float'
        }
        break

      case 'fuel':
        try {
          // validation passed, provide convertUnit to formatter
          yeetFuelUnitErrors(unit)
          if (unit === 'econ') {
            convertUnit = get(
              store.state,
              'localizationModule.fuelEcoUnit',
              'L/100km',
            )
          }

          if (unit === 'consumed') {
            convertUnit = get(
              store.state,
              'localizationModule.fuelConsumedUnit',
              'G (US)',
            )
          }
        } catch (e) {
          // use default fuel formatter: float() with provided unit
          console.error(e)
          type = 'float'
        }
        break

      case 'event':
        if (unit && unit === 'per100') {
          convertUnit = get(
            store.state,
            'localizationModule.distanceUnit',
            'kilometers',
          )
        }
      break

      default:
        // use default formatter integer() with provided unit
        type = 'integer'
        break
    }
  } else {
    // use default formatter integer() with provided unit
    type = 'integer'
  }

  abbrvAt = validateAbbrvAt(abbrvAt)

  return {
    type,
    unit,
    abbrvAt,
    convertUnit,
  }
}
