import IconAccel from './IconAccel.vue'
import IconBrake from './IconBrake.vue'
import IconCo2 from './IconCo2.vue'
import IconCo2Summary from './IconCo2Summary.vue'
import IconDriverReg from './IconDriverReg.vue'
import IconDrivingTime from './IconDrivingTime.vue'
import IconFleet from './IconFleet.vue'
import IconFleetUtil from './IconFleetUtil.vue'
import IconFuelCost from './IconFuelCost.vue'
import IconFuelUsage from './IconFuelUsage.vue'
import IconHighDailyHours from './IconHighDailyHours.vue'
import IconHighMileage from './IconHighMileage.vue'
import IconHighRisk from './IconHighRisk.vue'
import IconHighTrip from './IconHighTrip.vue'
import IconIdle from './IconIdle.vue'
import IconIdleComparison from './IconIdleComparison.vue'
import IconJourneyDetails from './IconJourneyDetails.vue'
import IconJourneySummary from './IconJourneySummary.vue'
import IconLongDrive from './IconLongDrive.vue'
import IconOverrev from './IconOverrev.vue'
import IconOverrevComparison from './IconOverrevComparison.vue'
import IconRisk from './IconRisk.vue'
import IconRiskSummary from './IconRiskSummary.vue'
import IconSavings from './IconSavings.vue'
import IconSavingsSummary from './IconSavingsSummary.vue'
import IconSpeed from './IconSpeed.vue'
import IconSpeedComparison from './IconSpeedComparison.vue'
import IconStandingIdle from './IconStandingIdle.vue'
import IconVehicleServiceStatus from './IconVehicleServiceStatus.vue'
import IconVehicleStatus from './IconVehicleStatus.vue'
import IconWeeklyTimesheet from './IconWeeklyTimesheet.vue'

export default {
  IconAccel,
  IconBrake,
  IconCo2,
  IconCo2Summary,
  IconDrivingTime,
  IconDriverReg,
  IconFleet,
  IconFleetUtil,
  IconFuelCost,
  IconFuelUsage,
  IconHighDailyHours,
  IconHighMileage,
  IconHighRisk,
  IconHighTrip,
  IconIdle,
  IconIdleComparison,
  IconJourneyDetails,
  IconJourneySummary,
  IconLongDrive,
  IconOverrev,
  IconOverrevComparison,
  IconRisk,
  IconRiskSummary,
  IconSavings,
  IconSavingsSummary,
  IconSpeed,
  IconSpeedComparison,
  IconStandingIdle,
  IconVehicleServiceStatus,
  IconVehicleStatus,
  IconWeeklyTimesheet,
}
