<template>
  <div class="flex flex-wrap items-end mt-6 break-all">
    <span
      v-if="formattedCurrency"
      class="text-6xl font-semithin leading-none mb-0 mr-1 text-copy"
    >
      {{ formattedCurrency }}
    </span>
    <span
      v-if="fetching"
      class="text-6xl font-semiheavy tracking-relaxed leading-none mb-0 text-copy"
    >
      --
    </span>
    <span
      v-else
      class="text-6xl font-semiheavy tracking-relaxed leading-none mb-0 text-copy"
    >
      {{ formattedStat }}
    </span>
    <span
      style="marginBottom:3px;"
      class="text-xl text-copy font-normal leading-none mr-2 ml-1"
    >
      {{ $t(formattedUnit) }}
    </span>
  </div>
</template>

<script>
import get from 'lodash.get'
export default {
  name: 'VinWidgetStat',
  props: {
    stat: {
      type: Object,
      default: () => {
        return {
          numeral: '--',
          unit: '',
          currency: '',
        }
      },
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedStat () {
      return get(this.stat, 'numeral', '--')
    },
    formattedCurrency () {
      return get(this.stat, 'currency', '')
    },
    formattedUnit () {
      return get(this.stat, 'unit', '')
    },
  },
}
</script>
