<template>
  <div
    class="add-section h-48 rounded border-2 border-dashed bg-grey-ltr flex items-center"
    @click="handleAddClick()"
  >
    <div class="w-1/2 flex flex-row-reverse">
      <div
        v-for="block in blockCount"
        :key="block"
        class="h-8 border-2 opacity-50 border-cta-dk bg-cta-lt rounded-lg mr-2"
        :class="blockCount === 1 ? 'w-32' : 'w-8'"
      />
    </div>
    <div class="my-auto ml-4 text-cta-lt text-xl self-end">
      {{ $t(ctaText) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashEditSectionAdd',
  props: {
    full: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ctaText () {
      return this.full ? 'dash_add_full_row' : 'dash_add_3by1_row'
    },
    blockCount () {
      return this.full ? 1 : 3
    },
  },
  methods: {
    handleAddClick () {
      this.$store.commit('ADD_NEW_SECTION', this.full)
    },
  },
}
</script>
<style>
.add-section {
  border-color: #d9dee1;
}
.add-section:hover {
  background-color: rgba(232, 234, 234, 0.3);
}
</style>
