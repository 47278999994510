// import Vue from 'vue'
const placeholderString = ''

function swap (input, indexA, indexB) {
  var temp = input[indexA]
  input[indexA] = input[indexB]
  input[indexB] = temp
}

const module = {
  state: {
    map: true,
    sections: [
      ['WidgetBestVehicle'],
      ['WidgetTotalFleetMileage', 'WidgetAvgFuelEcon', 'WidgetFuelCost'],
      ['WidgetDriverCostEvents', 'WidgetDriverFootprintEvents', 'WidgetDriverRiskEvents'],
      ['WidgetLongestJourney', 'WidgetAvgJourneyLength', 'WidgetTotalIdleTime'],
    ],
    orgPack: null,
  },
  mutations: {
    TOGGLE_DASH_MAP_SETTING: (state, payload) => {
      state.map = payload
    },
    UPDATE_WIDGET: (state, payload) => {
      let section = state.sections[payload.sectionIndex]
      let newSection = section

      // check if incoming widget is already in section
      if (newSection.indexOf(payload.widget) > -1) {
        // if so, swap places
        swap(newSection, payload.widgetIndex, newSection.indexOf(payload.widget))
      } else {
        // if not, just set the new widget in it's target index
        newSection[payload.widgetIndex] = payload.widget
      }

      state.sections.splice(payload.sectionIndex, 1, newSection)
    },
    REMOVE_WIDGET: (state, payload) => {
      let section = state.sections[payload.sectionIndex]
      let newSection = section
      if (newSection[payload.widgetIndex] === payload.widget) {
        newSection[payload.widgetIndex] = placeholderString
      }
      state.sections.splice(payload.sectionIndex, 1, newSection)
    },
    ADD_NEW_SECTION: (state, full) => {
      const widgets = full ? [placeholderString] : [placeholderString, placeholderString, placeholderString]
      state.sections.push(widgets)
    },
    DELETE_SECTION: (state, index) => {
      state.sections.splice(index, 1)
    },
    SET_SECTIONS (state, payload) {
      if (payload?.constructor !== Array) {
        return new Error(`Invalid dashboard sections payload: ${payload}`)
      }
      state.sections = []
      payload.forEach(section => {
        state.sections.push(section)
      })
    },
  },
}

export default module
