import checkGroups from './guards/check-groups'
import checkReport from './guards/check-report'

export default [
  {
    path: '/',
    name: 'DashboardDefault',
    meta: {
      title: 'Dashboard',
      view: 'ViewDashboard',
      needsVehicles: true,
    },
    component: () => import('@/components/Dashboard/DashboardDynamic.vue'),
  },
  {
    path: '/:orgId/dashboard',
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
      view: 'ViewDashboard',
      needsVehicles: true,
    },
    component: () => import('@/components/Dashboard/DashboardDynamic.vue'),
  },
  {
    path: '/:orgId/fleet-map',
    name: 'FleetMap',
    meta: {
      title: 'Fleet Map',
      view: 'ViewFullScreen',
    },
    components: {
      default: () => import('@/components/FleetMap/FleetMapDefault.vue'),
    },
  },
  {
    path: '/:orgId/org-selection',
    name: 'OrgSelection',
    meta: {
      title: 'Org Selection',
    },
    components: {
      default: () => import('@/components/OrgSelection/OrgSelectionDefault.vue'),
      defaultHeader: () => import('@/components/OrgSelection/OrgSelectionDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/vehicles',
    name: 'Vehicles',
    meta: {
      title: 'Vehicles',
      needsVehicles: true,
    },
    components: {
      default: () => import('@/components/Vehicles/VehiclesDefault.vue'),
      defaultHeader: () => import('@/components/Vehicles/VehiclesDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/vehicles/groups',
    name: 'VehicleGroups',
    meta: {
      title: 'Vehicle Groups',
      needsVehicles: true,
    },
    components: {
      default: () => import('@/components/Vehicles/Groups/VehicleGroupsDefault.vue'),
      defaultHeader: () => import('@/components/Vehicles/Groups/VehicleGroupsDefaultHeader.vue'),
    },
    beforeEnter: (to, from, next) => {
      checkGroups(to, from, next)
    },
  },
  {
    path: '/:orgId/vehicles/groups/:groupId/users/:userId',
    name: 'GroupUserDetail',
    meta: {
      title: 'Group User Detail',
      accessLevel: 'customer_admin',
      isGroupUser: true,
    },
    component: () => import('@/components/Admin/Users/UserDetail/UserDetail.vue'),
  },
  {
    path: '/:orgId/vehicles/locations',
    name: 'VehicleLocations',
    meta: {
      title: 'Locations',
      view: 'ViewLocations',
      needsVehicles: true,
    },
    components: {
      default: () => import('@/components/Vehicles/Locations/VehicleLocationsDefault.vue'),
    },
  },
  {
    path: '/:orgId/vehicles/locations/:locationId/alerts',
    name: 'LocationAlerts',
    meta: {
      title: 'Alerts',
      needsVehicles: true,
    },
    components: {
      default: () => import('@/components/Vehicles/Locations/Alerts/LocationAlertsDefault.vue'),
      defaultHeader: () => import('@/components/Vehicles/Locations/Alerts/LocationAlertsDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/vehicles/:vehicleId',
    name: 'VehicleDetails',
    meta: {
      title: 'Vehicle Details',
    },
    components: {
      default: () => import('@/components/Vehicles/Vehicle/VehicleDefault.vue'),
      defaultHeader: () => import('@/components/Vehicles/Vehicle/VehicleDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin/install-device',
    name: 'VehicleInstaller',
    meta: {
      title: 'Vehicle Installer',
      accessLevel: 'admin',
    },
    components: {
      default: () => import('@/components/Vehicles/VehicleInstaller.vue'),
    },
  },
  {
    path: '/:orgId/vehicles/:vehicleId/journey/:tripId',
    name: 'JourneyDetails',
    meta: {
      title: 'Journey Detail',
    },
    components: {
      default: () => import('@/components/Vehicles/Vehicle/Journey/JourneyDefault.vue'),
      defaultHeader: () => import('@/components/Vehicles/Vehicle/Journey/JourneyDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/reports',
    name: 'Reports',
    meta: {
      title: 'Report Center',
    },
    components: {
      default: () => import('@/components/Reports/ReportsDefault.vue'),
      defaultHeader: () => import('@/components/Reports/ReportsDefaultHeader/ReportsDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/reports/:slug',
    name: 'ReportDetails',
    components: {
      default: () => import('@/components/Reports/ReportDetails/ReportDetailsDefault.vue'),
      defaultHeader: () => import('@/components/Reports/ReportDetails/ReportDetailsDefaultHeader.vue'),
    },
    beforeEnter: (to, from, next) => {
      checkReport(to, from, next)
    },
  },
  {
    path: '/:orgId/scheduled-reports',
    name: 'ReportsScheduled',
    meta: {
      title: 'Scheduled Reports',
    },
    components: {
      default: () => import('@/components/Reports/ReportsScheduled/ReportsScheduled.vue'),
      defaultHeader: () => import('@/components/Reports/ReportsScheduled/ReportsScheduledHeader.vue'),
    },
  },
  {
    path: '/:orgId/scheduled-reports/add',
    name: 'ReportsScheduledAdd',
    meta: {
      title: 'Add Scheduled Report',
    },
    components: {
      default: () => import('@/components/Reports/ReportsScheduled/ReportsScheduledAddDefault.vue'),
      defaultHeader: () => import('@/components/Reports/ReportsScheduled/ReportsScheduledAddDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin-reports',
    name: 'ReportsAdmin',
    meta: {
      title: 'ald_admin_reports',
      accessLevel: 'admin',
    },
    components: {
      default: () => import('@/components/Reports/ReportsAdmin/ReportsAdminDefault.vue'),
      defaultHeader: () => import('@/components/Reports/ReportsAdmin/ReportsAdminDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin/',
    name: 'Admin',
    meta: {
      title: 'Admin',
      accessLevel: 'customer_admin',
    },
    components: {
      default: () => import('@/components/Admin/AdminDefault.vue'),
      defaultHeader: () => import('@/components/Admin/AdminHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin/users',
    name: 'Users',
    meta: {
      title: 'Admin User List',
      accessLevel: 'customer_admin',
    },
    components: {
      default: () => import('@/components/Admin/Users/UsersDefault.vue'),
      defaultHeader: () => import('@/components/Admin/Users/UsersDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin/packs',
    name: 'Packs',
    meta: {
      title: 'Admin Pack List',
      accessLevel: 'admin',
    },
    components: {
      default: () => import('@/components/Admin/Pack/PackListDefault.vue'),
      defaultHeader: () => import('@/components/Admin/Pack/PackListDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin/suborgs',
    name: 'SubOrgs',
    meta: {
      title: 'Sub Organizations',
      accessLevel: 'customer_admin',
    },
    components: {
      default: () => import('@/components/Admin/Suborgs/SuborgsDefault.vue'),
      defaultHeader: () => import('@/components/Admin/Suborgs/SuborgsDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin/users/:userId',
    name: 'UserDetail',
    meta: {
      title: 'Admin User Detail',
      accessLevel: 'customer_admin',
      isGroupUser: false,
    },
    component: () => import('@/components/Admin/Users/UserDetail/UserDetail.vue'),
  },
  {
    path: '/:orgId/admin/pack/:packId',
    name: 'PackDetail',
    meta: {
      title: 'Admin Pack Detail',
      accessLevel: 'admin',
    },
    components: {
      default: () => import('@/components/Admin/Pack/PackDefault.vue'),
      defaultHeader: () => import('@/components/Admin/Pack/PackDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin/general-settings',
    name: 'AdminGeneralSettings',
    meta: {
      title: 'Admin General Settings',
      accessLevel: 'admin',
      bottomPadding: 'pb-0',
    },
    components: {
      default: () => import('@/components/Admin/AdminGeneralSettings/AdminGeneralSettingsDefault.vue'),
      defaultHeader: () => import('@/components/Admin/AdminGeneralSettings/AdminGeneralSettingsDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin/locale',
    name: 'Locale Settings',
    meta: {
      title: 'Admin Locale Settings',
      accessLevel: 'customer_admin',
    },
    component: () => import('@/components/Admin/AdminLocaleSettings/AdminLocaleDefault.vue'),
  },
  {
    path: '/:orgId/admin/fuel-prices',
    name: 'OrgFuelPrice',
    meta: {
      title: 'fuel_price_settings',
      accessLevel: 'customer_admin',
    },
    components: {
      default: () => import('@/components/Admin/AdminFuelPrices/AdminFuelPricesDefault.vue'),
      defaultHeader: () => import('@/components/Admin/AdminFuelPrices/AdminFuelPricesDefaultHeader.vue'),
    },
  },
  {
    path: '/:orgId/admin/service-maintenance',
    name: 'AdminServiceMaintenanceSettings',
    meta: {
      title: 'vehicle_service_maintenance_title',
      accessLevel: 'admin',
    },
    components: {
      default: () => import('@/components/Admin/ServiceMaintenance/ServiceMaintenanceDefault'),
      defaultHeader: () => import('@/components/Admin/ServiceMaintenance/ServiceMaintenanceDefaultHeader'),
    },
  },
  {
    path: '/:orgId/admin/service-maintenance/service-locations',
    name: 'AdminServiceLocations',
    meta: {
      title: 'Service Locations',
      view: 'ViewServiceLocations',
      needsVehicles: true,
      accessLevel: 'admin',
    },
    components: {
      default: () => import('@/components/Admin/ServiceLocations/ServiceLocationsDefault.vue'),
    },
  },
  {
    path: '/:orgId/admin/support-settings',
    name: 'AdminSupportSettings',
    meta: {
      title: 'Admin Support Settings',
      accessLevel: 'admin',
    },
    components: {
      default: () => import('@/components/Admin/SupportSettings/SupportSettingsContact.vue'),
    },
  },
  {
    path: '/profile/:userId',
    name: 'Profile',
    meta: {
      title: 'User Profile',
    },
    components: {
      default: () => import('@/components/MyProfile/MyProfileDefault.vue'),
      defaultHeader: () => import('@/components/MyProfile/MyProfileDefaultHeader.vue'),
    },
  },
  {
    path: '/dashboard/edit',
    name: 'Editor',
    meta: {
      title: 'dash_config',
      view: 'ViewDashboardEditor',
    },
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login',
      view: 'ViewAuth',
      authOptOut: true,
    },
    component: () => import('@/components/Auth/AuthLogin/AuthLoginDefault.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      title: 'Forgot Password',
      view: 'ViewAuth',
      authOptOut: true,
    },
    component: () => import('@/components/Auth/AuthForgot/AuthForgotDefault.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      title: 'Reset Password',
      view: 'ViewAuth',
      authOptOut: true,
    },
    component: () => import('@/components/Auth/AuthReset/AuthResetDefault.vue'),
  },
  {
    path: '/driver/reset-password',
    name: 'DriverResetPassword',
    meta: {
      title: 'Driver Reset Password',
      view: 'ViewAuth',
      authOptOut: true,
    },
    component: () => import('@/components/Driver/DriverResetPassword/DriverResetPasswordDefault.vue'),
  },
  {
    path: '/driver/confirm',
    name: 'DriverConfirm',
    meta: {
      title: 'Driver Confirm',
      view: 'ViewAuth',
      authOptOut: true,
    },
    component: () => import('@/components/Driver/DriverConfirm/DriverConfirmDefault.vue'),
  },
  {
    path: '/driver/tos',
    name: 'DriverTos',
    meta: {
      title: 'Driver TOS',
      view: 'ViewAuth',
      authOptOut: true,
      termKey: 'tos',
    },
    component: () => import('@/components/Terms/TermsDefault.vue'),
  },
  {
    path: '/driver/privacy',
    name: 'DriverPrivacy',
    meta: {
      title: 'Driver Privacy',
      view: 'ViewAuth',
      authOptOut: true,
      termKey: 'privacy',
      hideTranslationOptions: true,
    },
    component: () => import('@/components/Terms/TermsDefault.vue'),
  },
  {
    path: '/cookies',
    name: 'CookiePolicies',
    meta: {
      title: 'CookiePolicies',
      authOptOut: true,
      termKey: 'cookie',
      hideTranslationOptions: true,
      multiTemplate: {
        getter: 'isAuthenticated',
        primary: 'ViewDefault',
        secondary: 'ViewAuth',
      },
    },
    component: () => import('@/components/Terms/TermsDefault.vue'),
  },
  {
    path: '/terms-of-service',
    name: 'TermsPortal',
    meta: {
      title: 'Terms of Service',
      authOptOut: true,
      hideTranslationOptions: true,
      multiTemplate: {
        getter: 'isAuthenticated',
        primary: 'ViewDefault',
        secondary: 'ViewAuth',
      },
      termKey: 'tos',

    },
    component: () => import('@/components/Terms/TermsPortalDefault.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPortal',
    meta: {
      title: 'Privacy Policy',
      authOptOut: true,
      hideTranslationOptions: true,
      multiTemplate: {
        getter: 'isAuthenticated',
        primary: 'ViewDefault',
        secondary: 'ViewAuth',
      },
      termKey: 'privacy',

    },
    component: () => import('@/components/Terms/TermsPortalDefault.vue'),
  },
  {
    path: '/contact-support',
    name: 'ContactSupport',
    meta: {
      title: 'Support',
      authOptOut: true,
      multiTemplate: {
        getter: 'isAuthenticated',
        primary: 'ViewDefault',
        secondary: 'ViewAuth',
      },
      termKey: 'tos',

    },
    components: {
      default: () => import('@/components/ContactSupport/ContactSupportDefault.vue'),
      defaultHeader: () => import('@/components/ContactSupport/ContactSupportDefaultHeader.vue'),
    },
  },
  {
    path: '/search-results/:resultId',
    name: 'SearchResults',
    meta: {
      title: 'Search Results',
    },
    components: {
      default: () => import('@/components/SpotlightSearch/SpotlightResultsDefault.vue'),
      defaultHeader: () => import('@/components/SpotlightSearch/SpotlightResultsDefaultHeader.vue'),
    },
  },
  {
    path: '/build-details',
    name: 'AppBuildDetails',
    meta: {
      title: 'Build Details',
    },
    component: () => import('@/components/App/AppBuildDetails.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: '404',
    },
    component: () => import('@/components/App/AppNotFound.vue'),
  },
  {
    path: '*',
    redirect: '/404',
    meta: {
      authOptOut: true,
    },
  },
]
