<template>
  <svg
    id="footer-logo"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 139.85324 37.3047"
    style="enable-background:new 0 0 139.85324 37.3047;"
    xml:space="preserve"
  >
    <path
      :fill="textColor"
      d="M54.20708,14.82204l-1.81079,3.82843h-3.15588l7.68526-15.7904h3.07202l7.70617,15.7904h-3.1583
	l-1.80811-3.82843 M55.42892,12.2527h6.08643l-3.03263-6.40314L55.42892,12.2527z"
    />
    <polygon
      :fill="textColor"
      points="83.11592,18.65047 83.11592,16.03879 72.56818,16.03879 72.56818,2.85739 69.64273,2.85739
	69.64273,18.65047 "
    />
    <path
      :fill="textColor"
      d="M93.28693,2.86007c4.96909,0,8.21125,3.1374,8.21125,7.87497c0,4.38013-3.45143,7.91543-7.76887,7.91543
	h-8.71632V2.86007H93.28693z M93.28693,15.99967c3.43321,0,5.18103-2.2529,5.18103-5.26463
	c0-2.94903-1.74782-5.22176-5.18103-5.22176h-5.3485v10.48639H93.28693z"
    />
    <path
      :fill="textColor"
      d="M54.05784,24.74976h1.43647l4.80939,12.19375h-2.06453l-1.18004-3.11382h-4.51384l-1.15914,3.11382h-1.99112
	L54.05784,24.74976z M56.45195,32.24666l-0.97586-2.76334c-0.35074-0.99676-0.51821-1.54632-0.64629-1.95468h-0.03671
	c-0.12808,0.42657-0.31403,0.97881-0.6621,1.95468l-1.01257,2.76334H56.45195z"
    />
    <path
      :fill="textColor"
      d="M61.85028,35.45211c-0.18328-0.4815-0.25911-0.97613-0.25911-1.75077v-5.4739h1.91796v5.21506
	c0,0.49704,0.03403,0.866,0.16506,1.18004c0.21972,0.57046,0.66183,0.84779,1.34483,0.84779
	c0.82689,0,1.58303-0.42389,2.00693-0.9212v-6.32168h1.91824v6.30078c0,1.19853,0.03644,1.95736,0.16479,2.41796h-1.65913
	l-0.31136-0.9421c-0.70122,0.62539-1.62215,1.12511-2.76575,1.12511C63.52496,37.1292,62.34732,36.77604,61.85028,35.45211"
    />
    <path
      :fill="textColor"
      d="M72.03952,34.88165v-5.14191h-1.53078v-1.50988h1.53078v-2.00961l1.91555-0.586v2.59561h2.61651
	l-0.48123,1.50988h-2.13528v4.71802c0,0.79285,0.29555,1.10689,0.866,1.10689c0.53375,0,1.01257-0.20418,1.36332-0.44506
	l0.60958,1.32687c-0.60958,0.42389-1.56722,0.68273-2.4335,0.68273C72.90311,37.1292,72.03952,36.2999,72.03952,34.88165"
    />
    <path
      :fill="textColor"
      d="M77.7125,32.56069c0-2.59855,1.69556-4.55055,4.25741-4.55055c2.54068,0,4.23625,1.952,4.23625,4.55055
	c0,2.59561-1.69557,4.5685-4.23625,4.5685C79.42656,37.1292,77.7125,35.1563,77.7125,32.56069 M84.23595,32.56069
	c0-1.73228-0.81108-2.98574-2.26604-2.98574c-1.45495,0-2.28452,1.25346-2.28452,2.98574c0,1.7505,0.82957,3.00128,2.28452,3.00128
	C83.42487,35.56197,84.23595,34.31119,84.23595,32.56069"
    />
    <path
      :fill="textColor"
      d="M87.97515,36.94351h1.91528v-6.24344c0.68032-0.57287,1.47318-1.01525,2.26603-1.01525
	c1.1436,0,1.36332,0.90271,1.36332,2.0096v5.24909h1.91556v-5.84045c0-0.14657,0-0.29313-0.01849-0.40299
	c0.70149-0.57287,1.49167-1.01525,2.26604-1.01525c1.16182,0,1.3818,0.88449,1.3818,1.99138v5.26731h1.91529v-5.80374
	c0-2.26603-1.2899-3.13231-2.78156-3.13231c-1.01257,0-2.11946,0.49731-3.16875,1.40002
	c-0.42389-0.92093-1.25078-1.40002-2.28425-1.40002c-1.08599,0-2.08302,0.53643-2.96484,1.25614l-0.20659-1.03374h-1.59884V36.94351
	z"
    />
    <path
      :fill="textColor"
      d="M102.71225,32.56069c0-2.59855,1.69557-4.55055,4.25742-4.55055c2.54068,0,4.23625,1.952,4.23625,4.55055
	c0,2.59561-1.69557,4.5685-4.23625,4.5685C104.42604,37.1292,102.71225,35.1563,102.71225,32.56069 M109.23569,32.56069
	c0-1.73228-0.81134-2.98574-2.26603-2.98574c-1.45763,0-2.28452,1.25346-2.28452,2.98574c0,1.7505,0.82688,3.00128,2.28452,3.00128
	C108.42435,35.56197,109.23569,34.31119,109.23569,32.56069"
    />
    <path
      :fill="textColor"
      d="M113.74927,34.88165v-5.14191h-1.53078v-1.50988h1.53078v-2.00961l1.91556-0.586v2.59561h2.61651
	l-0.47882,1.50988h-2.13769v4.71802c0,0.79285,0.29555,1.10689,0.866,1.10689c0.53375,0,1.01284-0.20418,1.36332-0.44506
	l0.60985,1.32687c-0.60985,0.42389-1.56749,0.68273-2.43377,0.68273C114.61287,37.1292,113.74927,36.2999,113.74927,34.88165"
    />
    <path
      :fill="textColor"
      d="M119.71539,25.44857c0-0.66478,0.49973-1.18031,1.19853-1.18031c0.70122,0,1.19826,0.49731,1.19826,1.18031
	c0,0.66183-0.51553,1.18004-1.19826,1.18004C120.21511,26.62861,119.71539,26.1104,119.71539,25.44857 M119.956,28.22986h1.91556
	v8.71365H119.956V28.22986z"
    />
    <polygon
      :fill="textColor"
      points="126.69917,37.00111 128.17261,37.00111 131.6554,28.22986 129.70313,28.22986 127.47112,34.51269
	127.43468,34.51269 125.15284,28.22986 123.19817,28.22986 "
    />
    <path
      :fill="textColor"
      d="M132.299,32.55801c0-2.63499,1.45737-4.55055,3.81503-4.55055c2.37589,0,3.73921,1.77167,3.73921,4.58726
	v0.46033h-5.59956c0.0367,1.49408,1.01257,2.54336,2.5956,2.54336c0.86627,0,1.5675-0.23794,2.1902-0.58868l0.57582,1.30839
	c-0.85046,0.55224-1.93645,0.81108-2.96751,0.81108C133.60739,37.1292,132.299,34.97329,132.299,32.55801 M137.89856,31.69442
	c-0.03644-1.10689-0.57047-2.19261-1.80544-2.19261c-1.15913,0-1.83945,1.01257-1.86035,2.19261H137.89856z"
    />
    <rect
      fill="#EF394F"
      width="37.30336"
      height="18.65047"
    />
    <rect
      y="18.65047"
      fill="#150F16"
      width="37.30336"
      height="18.65423"
    />
    <rect
      x="6.52077"
      y="17.52"
      fill="#FFFFFF"
      width="24.26692"
      height="2.26871"
    />
  </svg>
</template>

<script>
  export default {
    name: 'AppFooterLogo',
    props: {
      light: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      textColor () {
        return this.light ? '#150F16' : '#FFF'
      },
    },
  }
</script>
