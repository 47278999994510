import get from 'lodash.get'
import Vue from 'vue'
import Router from 'vue-router'

import theme from '@/assets/themes/config'

import store from '../state/store.js'
import routes from './routes'

Vue.use(Router)

const copyLocation = loc => {
  const newLoc = {}
  for (const [key, val] of Object.entries(loc)) {
    newLoc[key] = val
  }
  return newLoc
}

// checks if the location params are missing
const needsDuration = loc => {
  return !loc.query || !loc.query.since || !loc.query.until
}

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  // setup the location
  let newLoc = location
  // inject since/until if authenticated
  if (store.getters.isAuthenticated && needsDuration(newLoc)) {
    // make a copy that we can mutate
    newLoc = copyLocation(location)
    // make a copy of query
    const newQuery = Object.assign(newLoc.query || {})
    // set since and until if they aren't already there
    newQuery.since = get(newQuery, 'since', new Date(store.state.paramsModule.since).toISOString())
    newQuery.until = get(newQuery, 'until', new Date(store.state.paramsModule.until).toISOString())
    // remove original query and replce with modified copy

    newLoc.query = newQuery
  }

  if (onResolve || onReject) return originalPush.call(this, newLoc, onResolve, onReject)
  return originalPush.call(this, newLoc).catch(err => {
    if (err && err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

const base = '/'

const checkAccess = (routeTo) => {
  // accessLevel can be defined in route meta
  // we look for all access levels in matched route
  // then select the most restrictive level
  let accessLevel = routeTo.matched.map(route => route.meta.accessLevel).reduce((level, nextLevel) => {
    if (store.state.authModule.levels.indexOf(nextLevel) >= store.state.authModule.levels.indexOf(level)) {
      return nextLevel
    } else {
      return level
    }
  })
  let hasPermission = true
  if (accessLevel) {
    // if accessLevel is defined, we need to check permission
    hasPermission = store.getters.hasPermission(accessLevel)
  }
  return hasPermission
}

const router = new Router({
  mode: 'history',
  base,
  routes,
  scrollBehavior (to, from, savedPosition) { // eslint-disable-line no-unused-vars
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((routeTo, routeFrom, next) => {
  // always save routeFrom if not root
  if (!routeTo.query.climbOut) {
    store.commit('SET_PREVIOUS_ROUTE', routeFrom)
  }

  // capture starting route on first load
  if (store.state.authModule.firstLoad && routeTo.path !== '/login') {
    store.commit('SET_STARTING_ROUTE', routeTo)
    store.commit('END_FIRST_LOAD')
  }

  // sinceUntil(routeTo, store)

  // By default, auth is always required, but routes can opt
  // out using the `authOptOut` flag in meta
  // (including nested routes).
  const authOptOut = routeTo.matched.some((route) => route.meta.authOptOut)
  // If route opts out, just continue.
  if (authOptOut) return next()

  // If auth is required
  if (store.getters.isAuthenticated) {
    // now that we know things are authenticated, we need to check access level
    const permission = checkAccess(routeTo)
    if (permission) {
      if (routeTo.meta.needsVehicles && !store.state.vehiclesModule.hasAllVehicles && !store.state.vehiclesModule.fetchingVehicles) {
        store.dispatch('HYDRATE_ORG_VEHICLES')
      }
      next()
    } else {
      // stop the nav if they don't have permission
      next(false)
    }
  } else {
    const routeOrgId = get(routeTo, 'params.orgId')
    store.dispatch('FETCH_CURRENT_SESSION', routeOrgId)
      .then(() => {
        // inject org Id here since we have session now
        const vuexActiveOrgId = get(store.state.authModule, 'activeOrg.id')
        if (vuexActiveOrgId || routeOrgId) {
          // prefer the route's orgId over the vuex id
          routeTo.params.orgId = routeOrgId || vuexActiveOrgId
        }
        // now that we know things we've gotten the session, check permissions
        const permission = checkAccess(routeTo)
        if (permission) {
          next()
        } else {
          // send to base if no permission
          next(base)
        }
      })
      .catch(err => {
        if (err) {
          next('/login')
        }
      })
  }
})

router.afterEach(to => {
  Vue.nextTick(() => {
    let title = to.meta.title
    if (to.name === 'ReportDetails') {
      const report = store.getters.getReportBySlug(to.params.slug)
      title = report.title
    }
    document.title = `${Vue.i18n.translate(theme.appName)} - ${Vue.i18n.translate(title)}`
  })
})

export default router

export const useRouter = () => router
