<template>
  <VinWidgetEventTemplate
    vid="widget-fleet-footprint-events-btn"
    :title="`${$t('AVG')} ${$t('Footprint Events')}`"
    :timestamp="generatedAt"
    :fetching="isFetching"
    :stat="stat"
    :best="best"
    :worst="worst"
    :pack-has-report="packHasReport"
    @cta-action="navToReport"
    @bust-cache="fetchData(true)"
  />
</template>

<script>
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'

import { composeWidget, shameTheDriver } from '../compose-widget'
export default {
  name: 'WidgetFleetFootprintEvents',
  displayName: 'Fleet Footprint Events',
  icon: 'Co2',
  setup () {
    const widget = {
      slug: 'report-fleet-footprint-league-table',
    }
    const report = {
      widget: 'ReportFleetFootprintLeague',
      slug: 'report-fleet-footprint-league-table',
    }

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    stat () {
      return formatNumber(get(this, 'results.totals.totalEventsPer100'), {
        type: 'event',
        unit: 'per100',
      })
    },
    rows () {
      return get(this.results, 'rows', [])
    },
    worst () {
      return shameTheDriver(this.rows)
    },
    best () {
      return shameTheDriver(this.rows, false)
    },
  },
}
</script>
