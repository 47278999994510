<template>
  <router-link
    :to="to"
    class="flex items-center hover:cursor-pointer text-primary-dk group-hover:text-primary-lt"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      class="stroke-current fill-current mr-2"
    >
      <path d="M16 0h-8v6h8v-6zm-8 18h-8v6h8v-6zm16 0h-8v6h8v-6zm-3.058-2h-1.801c0-3.75-5.166-1.207-7.142-4.753-1.964 3.526-7.133 1.016-7.141 4.753h-1.8c.003-6.521 7.403-1.532 8.019-8h1.846c.616 6.473 8.019 1.469 8.019 8z" />
    </svg>
    {{ $t('switch_org') }}
  </router-link>
</template>

<script>
export default {
  name: 'AccessBannerSwitchLink',
  props: {
    orgId: {
      type: String,
      required: true,
    },
  },
  computed: {
    to () {
      return {
        name: 'OrgSelection',
        params: {
          orgId: this.orgId,
        },
      }
    },
  },
}
</script>
