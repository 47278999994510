const style = {
  styles: [
    {
      'featureType': 'administrative',
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'color': '#fdfcfc',
        },
      ],
    },
    {
      'featureType': 'administrative.locality',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#002d5d',
        },
      ],
    },
    {
      'featureType': 'administrative.neighborhood',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#1b436e',
        },
      ],
    },
    {
      'featureType': 'landscape',
      'elementType': 'all',
      'stylers': [
        {
          'color': '#f6f6f6',
        },
      ],
    },
    {
      'featureType': 'landscape',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#f6f6f6',
        },
      ],
    },
    {
      'featureType': 'landscape',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#afb5bc',
        },
      ],
    },
    {
      'featureType': 'landscape',
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'color': '#ffffff',
        },
      ],
    },
    {
      'featureType': 'poi',
      'elementType': 'all',
      'stylers': [
        {
          'visibility': 'on',
        },
        {
          'color': '#dadee1',
        },
      ],
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#cadbe5',
        },
      ],
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#4298d3',
        },
      ],
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'color': '#fdfdfd',
        },
      ],
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#ffe58c',
        },
      ],
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'color': '#ffd255',
        },
      ],
    },
    {
      'featureType': 'transit',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#4298d3',
        },
      ],
    },
    {
      'featureType': 'transit',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#b6c6d1',
        },
      ],
    },
    {
      'featureType': 'transit',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'color': '#154e93',
        },
      ],
    },
    {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffab13',
        },
      ],
    },
    {
      'featureType': 'transit.line',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'color': '#000000',
        },
      ],
    },
    {
      'featureType': 'water',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'visibility': 'on',
        },
        {
          'color': '#81c8ef',
        },
      ],
    },
  ],
}

export default style
