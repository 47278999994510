import get from 'lodash.get'

import axios from '../middleware/axios-store'
const module = {
  state: {
    placeMapSearchSelection: null,
    orgPlaces: [],
    vehiclePlaces: [],
    vehiclePlaceIds: [],
    targetPlace: null,
    fetchingOrgPlaces: false,
    placePagination: {
      total: 0,
      offset: 0,
      limit: 10,
    },
    uploadErrors: [],
  },
  getters: {
    getPlaceById: state => id => {
      return state.vehiclePlaces.find(place => place.id === id)
    },
    getOrgPlaceById: state => id => {
      return state.orgPlaces.find(place => place.id === id)
    },
  },
  mutations: {
    SET_MAP_PLACE_SELECTION: (state, payload) => {
      state.placeMapSearchSelection = payload
    },
    CLEAR_PLACES_MODULE (state) {
      state.placeMapSearchSelection = null
      state.orgPlaces = []
      state.vehiclePlaces = []
      state.vehiclePlaceIds = []
      state.targetPlace = null
      state.fetchingOrgPlaces = false
    },
    PUSH_PLACES (state, places) {
      if (places) {
        places.forEach(p => {
          // check if place already exists
          let match = state.orgPlaces.find(place => {
            return place.id === p.id
          })
          if (!match) {
            state.orgPlaces.push(p)
          }
        })
      }
    },
    SET_PLACES (state, places) {
      if (places) {
        places.forEach(p => {
          // check if this slice of 10 doesn't match the current slice of 10
          let match = state.orgPlaces.find(place => {
            return place.id === p.id
          })
          if (!match) {
            state.orgPlaces = places
          }
        })
      }
    },
    PUSH_PLACE (state, place) {
      state.orgPlaces.push(place)
      state.placePagination.total++
    },
    UPDATE_ORG_PLACE: (state, payload) => {
      let index = null
      state.orgPlaces.forEach((place, i) => {
        if (place.id === payload.id) {
          index = i
        }
      })
      if (typeof index !== 'number') {
        return
      }
      state.orgPlaces.splice(index, 1, payload)
    },
    REMOVE_ORG_PLACE: (state, payload) => {
      state.orgPlaces = state.orgPlaces.filter(place => place.id !== payload)
      state.placePagination.total--
    },
    SET_TARGET_PLACE (state, place) {
      if (state.targetPlace) {
        state.targetPlace.id === place.id ? state.targetPlace = null : state.targetPlace = place
      } else {
        state.targetPlace = place
      }
    },
    PUSH_VEHICLE_PLACE (state, place) {
      if (place) {
        // check if place already exists
        let match = state.vehiclePlaces.find(p => {
          return place.id === p.id
        })
        if (!match) {
          state.vehiclePlaces.push(place)
        }
      }
    },
    PUSH_VEHICLE_PLACE_ID (state, placeId) {
      if (placeId) {
        // check if place already exists
        let match = state.vehiclePlaceIds.find(p => {
          return placeId === p
        })
        if (!match) {
          state.vehiclePlaceIds.push(placeId)
        }
      }
    },
    CLEAR_VEHICLE_PLACES (state) {
      state.vehiclePlaces = []
      state.vehiclePlaceIds = []
    },
    SET_FETCHING_PLACES_STATUS: (state, flag) => {
      state.fetchingOrgPlaces = flag
    },
    UPDATE_ORG_PLACES_PAGINATION: (state, pagination) => {
      state.placePagination.total = get(pagination, 'total', state.placePagination.total)
      state.placePagination.offset = get(pagination, 'offset', state.placePagination.offset)
      state.placePagination.limit = get(pagination, 'limit', state.placePagination.limit)
    },
    SET_UPLOAD_ERRORS: (state, errors) => {
      state.uploadErrors = errors
    },
  },
  actions: {
    FETCH_PLACES_PROMISE ({ commit, rootState, state }) {
      return new Promise((resolve, reject) => {
        const page = state.placePagination.offset / state.placePagination.limit
        if (
          !state.orgPlaces.length - 1 < page ||
          !get(state, `orgPlaces[${page}]`)
        ) {
          commit('SET_FETCHING_PLACES_STATUS', true)
          axios.get(`/api/v1/orgs/${rootState.authModule.activeOrg.id}/places`, {
            params: {
              offset: state.placePagination.offset,
              limit: state.placePagination.limit,
            },
          })
            .then(resp => {
              commit('UPDATE_ORG_PLACES_PAGINATION', {
                total: get(resp, 'data.meta.pagination.total'),
              })
              commit('PUSH_PLACES', resp.data.places)
              commit('SET_FETCHING_PLACES_STATUS', false)
              resolve(resp)
            })
            .catch(err => {
              commit('SET_FETCHING_PLACES_STATUS', false)
              reject(err)
            })
        } else {
          commit('SET_FETCHING_PLACES_STATUS', false)
          resolve()
        }
      })
    },
    // TODO: this is a copy of the above action to resolve pagination for both VehicleLocationsMap and VehicleLocationsTable. The previous action is utilized in the DashboardMap and should be affixed with a 'load 20 more' option
    FETCH_PLACES ({ commit, rootState, state }) {
      const page = state.placePagination.offset / state.placePagination.limit
      if (!state.orgPlaces.length - 1 < page || !get(state, `orgPlaces[${page}]`)) {
        commit('SET_FETCHING_PLACES_STATUS', true)
        // setup params
        const params = {
          offset: state.placePagination.offset,
          limit: state.placePagination.limit,
          sortDir: rootState.paramsModule.sorts.locations.asc ? 'asc' : 'desc',
        }
        // get sortby
        switch (rootState.paramsModule.sorts.locations.sortBy) {
          case 'Name':
            params.sortBy = 'name'
            break
          case 'Created On':
            params.sortBy = 'createdAt'
            break
          default:
            params.sortBy = 'name'
            break
        }
        axios.get(`/api/v1/orgs/${rootState.authModule.activeOrg.id}/places`, {
          params: params,
        })
          .then(resp => {
            commit('UPDATE_ORG_PLACES_PAGINATION', {
              total: get(resp, 'data.meta.pagination.total'),
            })
            commit('SET_PLACES', resp.data.places)
            commit('SET_FETCHING_PLACES_STATUS', false)
          })
          .catch(err => {
            commit('SET_FETCHING_PLACES_STATUS', false)
            throw err
          })
      }
    },
    CREATE_PLACE_PROMISE ({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(
          `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places`, {
          place: payload,
        })
          .then(resp => {
            const userAcl = rootState.authModule.session.accessLevel
            const place = userAcl === 'demo' ? resp.data : resp.data.place
            // mock place attributes for demo
            if (userAcl === 'demo') {
              place.name = payload.name
              place.shape = payload.shape
              place.radius = payload.radius
              place.createdAt = new Date().toISOString()
              place.isDemo = true
            }
            commit('PUSH_PLACE', place)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    UPDATE_PLACE_PROMISE ({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios.put(
          `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${payload.locationId}`, {
          name: payload.name,
        })
          .then(resp => {
            commit('UPDATE_ORG_PLACE', resp.data.place)
            commit('SET_TARGET_ALERT_PLACE', resp.data.place)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    DELETE_PLACE_PROMISE ({ commit, rootState }, placeId) {
      return new Promise((resolve, reject) => {
        if (!placeId) {
          reject(new Error('placeId required to delete place'))
        }
        axios.delete(
          `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${placeId}`)
          .then(resp => {
            commit('REMOVE_ORG_PLACE', placeId)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    FETCH_VEHICLE_PLACES_PROMISE ({ commit, rootState }, placeIds) {
      placeIds.forEach(placeId => {
        return new Promise((resolve) => {
          axios.get(
            `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${placeId}`,
          )
            .then(resp => {
              commit('PUSH_VEHICLE_PLACE', resp.data.place)
              resolve(resp)
            })
            .catch(() => {
              commit('PUSH_VEHICLE_PLACE', {
                id: placeId,
                unreachable: true,
              })
              resolve()
            })
        })
      })
    },
    UPLOAD_PLACES_CSV_PROMISE (context, payload) {
      return axios(payload)
    },
  },
}

export default module
