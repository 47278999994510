import Vue from 'vue'

export const currencySymbolMap = (convertUnit) => {
  const symbolMap = {
    'EUR': '€',
    'GBP': '£',
    'DKK': 'kr.',
    'PLN': 'zł',
    'SOL': 'S/',
    'HRK': 'kn',
    'UAH': '₴',
    'esMx': '$',
    'es': '€',
    'com': '$',
    'NOK': 'kr',
    'ukUa': '₴',
    'da': 'kr',
    'svSe': 'kr',
    'fi': '€',
    'cl': '$',
    'HUF': 'Ft',
    'sk': '€',
    'BRL': 'R$',
    'COP': '$',
    'MXN': '$',
    'SEK': 'kr',
    'CLP': '$',
    'TRY': '₺',
  }
  const symbol = symbolMap[convertUnit]

  return symbol
    ? symbol
    : ''
}

export const unitSuffixMap = (type, unit, convertUnit) => {
  switch (type) {
    case 'distance':
    case 'odometer':
      return convertUnit === 'kilometers'
        ? Vue.i18n.translate('km')
        : Vue.i18n.translate('mi')

    case 'speed':
      return convertUnit === 'kilometers'
        ? Vue.i18n.translate('km/h')
        : Vue.i18n.translate('mph')

    case 'fuel':
      return Vue.i18n.translate(convertUnit)

    case 'currency':
    case 'duration':
      return undefined

    case 'event':
      if (unit === 'per100') {
        const per100 = Vue.i18n.translate('per 100')
        const suffix = convertUnit === 'kilometers' ? 'km' : 'miles'

        return `${per100} ${Vue.i18n.translate(suffix)}`
      }

      return unit

    default:
      return unit
  }
}
