<template>
  <div class="flex">
    <label class="flex items-center">
      <input
        v-if="!disabled"
        :id="vid"
        v-model="syncValue"
        type="checkbox"
        :checked="syncValue"
        :text="text"
        @change="emitValue"
      >
      <input
        v-else
        :id="vid"
        type="checkbox"
        :checked="syncValue"
        :text="text"
        disabled
      >
      <span
        class="checkbox w-6 h-6 rounded"
        :class="`mr-${spacing}`"
      />
      <span
        :id="`${vid}-text`"
        :class="`text-${textColor}`"
      >
        {{ text }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'VinCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: 'copy',
    },
    spacing: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      required: true,
      default: null,
    },
  },
  data () {
    return {
      syncValue: this.value,
      focus: false,
    }
  },
  watch: {
    value () {
      this.syncValue = this.value
    },
  },
  methods: {
    emitValue () {
      this.$emit('input', this.syncValue)
    },
  },
}
</script>

<style lang="scss" scoped>
$checkboxBgColor: theme("colors.transparent");
$checkboxCheckedBGColor: theme("colors.primary-dk");
$checkboxDisabledBgColor: theme("colors.transparent");
$checkboxCheckedCheckIcon: url(https://storage.googleapis.com/material-icons/external-assets/v4/icons/svg/ic_check_white_24px.svg);

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

label {
  > input {
    display: none;
    + span {
      display: inline-block;
      background: {
        color: $checkboxBgColor;
        size: 0%;
        position: center;
      }
      @apply border-2 border-primary-dk;
      transform: rotate(-90deg);
      transition: all 300ms cubic-bezier(0.07, 0.98, 0.3, 1.88);
      &:hover {
        cursor: pointer;
      }
    }
    &:checked {
      + span {
        background: {
          color: $checkboxCheckedBGColor;
          image: $checkboxCheckedCheckIcon;
          repeat: no-repeat;
          size: 100%;
        }
        transform: rotate(0deg);
      }
    }
    &:disabled {
      + span {
        background: {
          color: $checkboxDisabledBgColor;
        }
        cursor: not-allowed;
        opacity: 0.25;
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
}
</style>
