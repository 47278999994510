<template>
  <div class="card relative grid grid-cols-3 w-full">
    <VinLoadingBlock v-if="fetching" />
    <div class="col-span-1 pl-10 py-8">
      <VinWidgetHeader
        :title="title"
        :timestamp="timestamp"
        :duration="monthText"
        @bust-cache="$emit('bust-cache')"
      />
      <VinWidgetStat
        :fetching="fetching"
        :stat="formatStat"
      />
      <VinWidgetDelta
        :graph-data="formattedGraphData"
        :text-suffix="textSuffix"
        :invert="invert"
        :has-fuel-econ="hasFuelEcon"
      />
      <VinButton
        v-if="packHasReport"
        :vid="vid"
        color="cta-lt"
        class="btn-width ml-0 text-base font-semibold rounded-lg mt-10"
        :text="$t('View Report')"
        @click="$emit('cta-action')"
      />
    </div>
    <div
      class="col-span-2 pr-10 pb-6 pt-8 w-full h-full"
      style="marginBottom:3px;"
    >
      <VinWidgetBarGraph
        :formatter="formatter"
        :suffix="formattedUnit"
        :y-axis-data="graphData"
        :x-axis-data="months"
      />
    </div>
  </div>
</template>

<script>
import addDays from 'date-fns/addDays'
import parseISO from 'date-fns/parseISO'

import { mxMonthFull } from '@/common/mixins/dateFormatMixin'
import { formatNumber } from '@/common/utils/numeral'
export default {
  name: 'VinWidgetBarGraphTemplate',
  mixins: [mxMonthFull],
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    formatter: {
      type: Object,
      default: () => {},
    },
    graphData: {
      type: Array,
      default: () => [],
    },
    vid: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    timestamp: {
      type: [String, Number],
      default: '',
    },
    textSuffix: {
      type: String,
      default: '',
    },
    invert: {
      type: Boolean,
      default: false,
    },
    hasFuelEcon: {
      type: Boolean,
      default: false,
    },
    packHasReport: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    months () {
      // add a day to the raw date to offset any utc/local time confusion
      return this.rows.map(r => this.mxMonthFull(addDays(parseISO(r.month), 1)))
    },
    monthText () {
      // add a day to the raw date to offset any utc/local time confusion
      return this.months.length > 0
        ? this.months[this.months.length - 1]
        : ''
    },
    formatStat () {
      return formatNumber(this.graphData[this.graphData.length - 1], this.formatter)
    },
    formattedUnit () {
      return this.formatStat
        ? this.formatStat.unit
        : ''
    },
    formattedGraphData () {
      return this.graphData.map(v => formatNumber(v, this.formatter).value())
    },
  },
}
</script>
