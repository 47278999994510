<template>
  <div>
    <div
      v-if="resultCount > 4"
      class="flex flex-col text-center"
    >
      <span class="text-grey-dk text-sm tracking-relaxed flex mx-auto">
        <p>
          {{ 'Displaying' | translate }}
        </p>
        <p class="font-bold mx-1">
          {{ '4' }}
        </p>
        <p class="mr-1">
          {{ 'out of' | translate }}
        </p>
        <p class="font-bold mr-1">
          {{ resultCount }}
        </p>
        <p class="mr-1">
          {{ item | translate }}
        </p>
        <p>
          {{ 'results' | translate }}
        </p>
      </span>
      <div class="flex items-center justify-center mx-auto cursor-pointer hover:underline text-cta-lt">
        <span
          class="text-base mx-auto"
        >
          {{ $t('View More') }}
        </span>
        <chevron-right-icon class="mt-2px" />
      </div>
    </div>
    <div
      v-else
      class="flex flex-col text-center w-full mt-auto"
    >
      <span class="text-grey-dk text-sm tracking-relaxed flex mx-auto">
        <p>
          {{ 'Displaying' | translate }}
        </p>
        <p class="font-bold mx-1">
          {{ resultCount }}
        </p>
        <p class="mr-1">
          {{ item | translate }}
        </p>
        <p>
          {{ 'results' | translate }}
        </p>
      </span>
      <div class="flex items-center justify-center mx-auto text-white">
        <span
          class="text-base mx-auto"
        >
          {{ $t('View More') }}
        </span>
        <chevron-right-icon class="mt-2px" />
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons'
export default {
  name: 'SpotlightPaginate',
  components: {
    ChevronRightIcon,
  },
  props: {
    resultCount: {
      type: Number,
    },
    item: {
      type: String,
    },
  },
}
</script>
