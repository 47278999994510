const themes = {
  fleet: 'profleet',
  ald: 'profleet',
  'lvs-fleet': 'lvs',
  lvs: 'lvs',
  generic: 'generic',
  'fleet-demo': 'generic',
  ford: 'ford',
  'ford-fleet': 'ford',
  nf: 'nf',
}

export default () => {
  // short circuit for dev theme
  if (process.env.VUE_APP_DEV_THEME) {
    return process.env.VUE_APP_DEV_THEME
  }
  // check the query param
  const search = new URLSearchParams(window.location.search)
  const searchTheme = search.get('theme')
  if (searchTheme && themes[searchTheme]) {
    return searchTheme
  }

  // set the default theme
  let theme = themes.fleet
  const hostname = window.location.hostname

  const domainParts = hostname.split('.')
  if (domainParts.length > 0) {
    const prefix = domainParts[0]
    // if the domain prefix matches one of our
    // theme keys, use that theme
    if (themes[prefix]) {
      theme = themes[prefix]
    }
  }
  return theme
}
