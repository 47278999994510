import getTheme from '../../assets/themes/get-theme'

const host = window.location.host
const parts = host.split('.')
let prefix = 'fleet'
if (parts.length > 0) {
  const sub = parts[0]
  if (!sub.includes('localhost')) {
    prefix = sub
  }
}

const module = {
  state: {
    theme: getTheme(),
    prefix,
  },
}

export default module
