import get from 'lodash.get'

import axios from '../middleware/axios-store'
const module = {
  state: {
    isFechingScheduled: false,
    scheduledReports: [],
  },
  mutations: {
    TOGGLE_FETCHING_SCHEDULED: (state, flag) => {
      state.isFechingScheduled = flag
    },
    SET_SCHEDULED_REPORTS: (state, payload) => {
      state.scheduledReports = payload
    },
    PUSH_SCHEDULED_REPORT: (state, payload) => {
      state.scheduledReports.push(payload)
    },
    REMOVE_SCHEDULED_REPORT: (state, payload) => {
      state.scheduledReports = state.scheduledReports.filter(report => report.id !== payload)
    },
    CLEAR_SCHEDULED_REPORTS_MODULE: (state) => {
      state.scheduledReports = []
      state.isFechingScheduled = false
    },
  },
  actions: {
    // scheduled report actions
    FETCH_SCHEDULED_REPORTS ({ commit, rootState }) {
      const orgId = get(rootState, 'authModule.activeOrg.id')
      if (!orgId) {
        return
      }
      commit('TOGGLE_FETCHING_SCHEDULED', true)
      axios
        .get(`/api/v1/orgs/${orgId}/report-scheduler`)
        .then(resp => {
          commit('SET_SCHEDULED_REPORTS', resp.data.reportSchedules)
          commit('TOGGLE_FETCHING_SCHEDULED', false)
        })
        .catch(err => {
          commit('TOGGLE_FETCHING_SCHEDULED', false)
          throw err
        })
    },
    async SCHEDULE_REPORT (context, payload) {
      const orgId = get(context.rootState, 'authModule.activeOrg.id')
      if (!orgId) {
        return
      }
      try {
        const resp = await axios.post(`/api/v1/orgs/${orgId}/report-scheduler`, payload)
        context.commit('PUSH_SCHEDULED_REPORT', resp.data.reportSchedule)
        context.dispatch('SERVE_TOAST', {
          type: 'success',
          message: 'Scheduled Report Created',
        })
      } catch (error) {
        context.dispatch('SERVE_TOAST', {
          type: 'danger',
          message: 'Something went wrong, please try again later.',
        })
        throw error
      }
    },
    DELETE_REPORT_PROMISE ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/report-scheduler/${payload.reportId}`)
          .then(resp => {
            commit('REMOVE_SCHEDULED_REPORT', payload.reportId)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}

export default module
