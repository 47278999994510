<template>
  <div class="card relative grid grid-cols-3 gap-x-6 w-full">
    <VinLoadingBlock v-if="fetching" />
    <div class="col-span-1 pl-10 py-8">
      <VinWidgetHeader
        :title="title"
        :timestamp="timestamp"
        @bust-cache="$emit('bust-cache')"
      />
      <VinWidgetStat
        :fetching="fetching"
        :stat="stat"
      />
      <VinButton
        v-if="packHasReport"
        :vid="vid"
        color="cta-lt"
        class="btn-width ml-0 text-base font-semibold rounded-lg mt-10"
        :text="$t('View Report')"
        @click="$emit('cta-action')"
      />
    </div>

    <div class="col-span-1 flex flex-col py-8 pl-10">
      <VinTitle
        el="h5"
        :text="$t('Best Driver')"
        class="font-normal text-grey-dk text-lg tracking-xl leading-tight uppercase"
      />
      <span class="text-copy text-xs tracking-relaxed leading-tight mt-2px">
        {{ stat.unit }}
      </span>
      <VinWidgetDriverEvents
        :driver="best"
        class="pt-2"
      />
    </div>

    <div class="col-span-1 flex flex-col py-8 pl-10">
      <VinTitle
        el="h5"
        :text="$t('Highest Risk')"
        class="font-normal text-grey-dk text-lg tracking-xl leading-tight uppercase"
      />
      <span class="text-copy text-xs tracking-relaxed leading-tight mt-2px">
        {{ stat.unit }}
      </span>
      <VinWidgetDriverEvents
        :driver="worst"
        class="pt-2"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VinWidgetEventTemplate',
  props: {
    title: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: '',
    },
    timestamp: {
      type: [String, Number],
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    stat: {
      type: Object,
      default: () => {
        return {
          numeral: '--',
          unit: '',
        }
      },
    },
    packHasReport: {
      type: Boolean,
      default: false,
    },
    best: {
      type: Object,
      default: () => {},
    },
    worst: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
