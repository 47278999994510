<template>
  <div class="text-grey-dk card flex items-center fixed-card px-4">
    <p>{{ loadingText }}</p>
  </div>
</template>

<script>
export default {
  name: 'DashboardMapLoadingVehicles',
  computed: {
    total () {
      return this.$store.state.vehiclesModule.totalVehicleCount
    },
    has () {
      return this.$store.state.vehiclesModule.vehicles.length
    },
    loadingText () {
      return `${this.$t('Loading')} ${this.has} ${this.$t('of')} ${this.total} ${this.$t('vehicles')}`
    },
  },
}
</script>

<style scoped>
.fixed-card {
  height: 52px;
}
</style>
