<template>
  <div class="relative">
    <div class="mb-4 pr-10 w-full">
      <div
        v-if="full"
        class="drag-container rounded-lg h-64 mx-2"
      >
        <DashEditSectionBlock
          :section-index="index"
          :widget="section[0]"
        />
      </div>
      <div
        v-else
        class="flex flex-row w-full h-64"
      >
        <div
          v-for="(widget, i) in section"
          :key="i"
          class="w-1/3 h-full drag-container rounded-lg mx-2"
        >
          <DashEditSectionBlock
            :section-index="index"
            :widget-index="i"
          />
        </div>
      </div>
    </div>
    <div
      class="absolute mt-12 top-0 right-0 cursor-pointer h-8 w-8 flex items-center justify-center"
      @click="deleteSection()"
    >
      <VinIcon icon="DragDelete" />
    </div>
    <div class="absolute handle mt-2 top-0 right-0 cursor-pointer h-8 w-8 flex items-center justify-center">
      <VinIcon icon="Drag" />
    </div>
  </div>
</template>

<script>
import DashEditSectionBlock from './DashEditSectionBlock'
export default {
  name: 'DashEditSection',
  components: {
    'DashEditSectionBlock': DashEditSectionBlock,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    section: {
      type: Array,
    },
  },
  computed: {
    full () {
      return this.section.length === 1
    },
  },
  methods: {
    deleteSection () {
      this.$store.commit('DELETE_SECTION', this.index)
    },
  },
}
</script>

<style>
.drag-container {
  background-color: #e8eaea;
  border-color: rgba(126, 132, 136, 0.28);
}
</style>
