<template>
  <div>
    <div
      class="card flex items-center justify-between cursor-default"
      :class="isHistorical ? 'pl-4' : ''"
    >
      <div
        v-if="isHistorical"
        class="flex flex-col justify-center pr-2"
      >
        <span class="leading-none text-sm">
          {{ prettyDate }}
        </span>
        <span
          class="transition-2s leading-none text-center text-sm mt-1 w-auto text-cta-lt hover:cursor-pointer hover:text-cta-dk"
          @click="handleReset"
        >
          {{ $t('reset') }}
        </span>
      </div>
      <svg
        class="transition-2s stroke-current fill-current mx-auto fixed-icon pt-2 hover:cursor-pointer"
        :class="isHistorical ? 'text-success-lt hover:text-success-dk' : 'text-grey-dk hover:text-primary-dk'"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 100 125"
        @click="$emit('toggleSettings')"
      >
        <path
          d="M10.79932,57.99707a39.89663,39.89663,0,0,0,1.36181,5.0083l-4.72851,1.624a44.8964,44.8964,0,0,1-1.53369-5.63818ZM27.73291,83.23242,24.9458,87.38379a45.14545,45.14545,0,0,0,5.05176,2.93555l2.22558-4.47754A40.083,40.083,0,0,1,27.73291,83.23242ZM9.688,70.01709a45.10354,45.10354,0,0,0,2.93555,5.04883l4.15039-2.78809a40.04332,40.04332,0,0,1-2.60938-4.4873Zm10.6289,13.8042,3.29981-3.75684a40.30414,40.30414,0,0,1-3.67383-3.67236L16.187,79.69189A45.404,45.404,0,0,0,20.31689,83.82129Zm16.69678,4.02392-1.62207,4.7295A44.75955,44.75955,0,0,0,41.03418,94.106l.99121-4.90039A39.88783,39.88783,0,0,1,37.01367,87.84521ZM5,50c0,1.05469.03711,2.12158.10986,3.17236l4.98828-.34668C10.0332,51.89014,10,50.93945,10,50ZM50,5A45.08279,45.08279,0,0,0,18.18939,18.18945L9.96991,9.97V30.03H30.03009l-8.306-8.30591A39.9951,39.9951,0,1,1,50,90c-.94141,0-1.8916-.0332-2.82471-.09863l-.34863,4.98779C47.875,94.96289,48.94287,95,50,95A45,45,0,0,0,50,5ZM47.5,21.74463V52.5H78.25537v-5H52.5V21.74463Z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mxDateTimeFull } from '@/common/mixins/dateFormatMixin'
export default {
  name: 'DashboardMapHistoryToggle',
  mixins: [mxDateTimeFull],
  computed: {
    isHistorical () {
      return this.$store.state.fleetHistoryModule.historicalMode
    },
    prettyDate () {
      return this.mxDateTimeFull(this.$store.state.fleetHistoryModule.date)
    },
  },
  methods: {
    handleReset () {
      this.$store.commit('TOGGLE_HISTORICAL_MODE', false)
      this.$store.commit('SET_FLEET_HISTORICAL_DATE', null)
    },
  },
}
</script>
<style scoped>
.fixed-icon {
  height: 52px;
  width: 52px;
}
</style>
