<template>
  <GmapInfoWindow
    :opened="true"
    :options="{
      pixelOffset: {
        width: 0,
        height: -55
      }
    }"
    :position="location.coords"
    @closeclick="handleClose"
  >
    <div class="flex flex-col p-1">
      <h3 class="text-grey-dk text-lg font-normal tracking-relaxed uppercase">
        {{ $t('vehicle') }}
      </h3>
      <p class="text-copy text-lg font-bold mt-4">
        {{ mmy }}
      </p>
      <router-link
        :to="vehicleLink"
        class="text-cta-lt text-base font-normal mt-2 hover:underline hover:text-cta-dk hover:cursor-pointer"
        vinli="vehicle-map-info-window-link"
      >
        {{ urlText }}
      </router-link>

      <h3
        v-if="driverFirst !== '' || driverLast !== ''"
        class="text-grey-dk text-lg font-normal tracking-relaxed mt-6 uppercase"
      >
        {{ $t('driver') }}
      </h3>
      <p
        v-if="driverFirst !== '' || driverLast !== ''"
        class="text-copy text-lg font-bold mt-4"
      >
        {{ `${driverFirst} ${driverLast}` }}
      </p>

      <p class="text-grey-dk text-sm mt-6">
        {{ `${$t('last_check_in')} ${formattedDate}` }}
      </p>
    </div>
  </GmapInfoWindow>
</template>

<script>
import get from 'lodash.get'

import { mxTimestamp } from '@/common/mixins/dateFormatMixin'
import vLastCheckin from '@/common/utils/vehicle-last-checkin.js'
export default {
  name: 'DashboardMapInfoWindow',
  mixins: [mxTimestamp],
  props: {
    location: {
      type: Object,
    },
    mapCenter: {
      type: Object,
    },
  },
  computed: {
    vehicle () {
      return get(this.location, 'vehicle', {})
    },
    locations () {
      const vehicleId = this.vehicle?.id ?? 'x'
      return get(this.$store.state.vehiclesModule.vehicleLocations, vehicleId, [])
    },
    vin () {
      return get(this.location, 'vehicle.vin')
    },
    registration () {
      return get(this.location, 'vehicle.registration')
    },
    urlText () {
      return this.registration || this.vin
    },
    orgId () {
      return get(this.$store.state.authModule, 'activeOrg.id')
    },
    vehicleId () {
      return get(this.location, 'vehicle.id')
    },
    mmy () {
      return this.$store.getters.prettyVehicle(get(this.location, 'vehicle', {}))
    },
    driverFirst () {
      return get(this.location, 'vehicle.driver.firstName', '')
    },
    driverLast () {
      return get(this.location, 'vehicle.driver.lastName', '')
    },
    formattedDate () {
      let date
      // use historical date if in historical mode
      if (this.$store.state.fleetHistoryModule.historicalMode && this.vehicle.historicalLocation) {
        date = get(this.vehicle, 'historicalLocation.timestamp')
      } else {
        date = vLastCheckin(this.vehicle, this.locations)
      }
      if (!date) {
        return '--'
      }
      return this.mxTimestamp(date)
    },
    vehicleLink () {
      return {
        name: 'VehicleDetails',
        params: {
          orgId: this.orgId,
          vehicleId: this.vehicleId,
        },
      }
    },
  },
  methods: {
    handleClose () {
      this.$store.commit('SET_INFO_WINDOW_ID', null)
    },
  },
}
</script>
