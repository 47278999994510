<template>
  <VinSmallWidgetTemplate
    :title="`${$t('AVG')} ${$t('Risk Events')}`"
    :timestamp="generatedAt"
    icon="Risk"
    :fetching="isFetching"
    :stat="stat"
    :pack-has-report="packHasReport"
    :cta-title="$t('view_fleet_risk_league_table')"
    :cta-action="navToReport"
    @bust-cache="fetchData(true)"
  />
</template>

<script>
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'

import { composeWidget } from '../compose-widget'
export default {
  name: 'WidgetFleetRiskEventsSm',
  displayName: 'Fleet Risk Events',
  icon: 'Risk',
  setup () {
    const widget = {
      slug: 'report-fleet-risk-league-table',
    }
    const report = {
      widget: 'ReportFleetRiskLeague',
      slug: 'report-fleet-risk-league-table',
    }

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    stat () {
      return formatNumber(get(this, 'results.totals.totalEventsPer100'), {
        type: 'event',
        unit: 'per100',
      })
    },
  },
}
</script>
