<template>
  <component
    :is="navComponent"
    :items="items"
  />
</template>

<script>
import get from 'lodash.get'

import AppNavDesktop from './AppNavDesktop'
import AppNavMobileDrawer from './AppNavMobileDrawer'
// conditional spread operator will remove empty array items in this component
export default {
  name: 'AppNav',
  components: {
    AppNavDesktop,
    AppNavMobileDrawer,
  },
  computed: {
    navComponent () {
      if (this.$store.state.baseModule.breakpoint === 'desktop') {
        return 'AppNavDesktop'
      }
      const drawerOpen = this.$store.state.baseModule.mobileDrawerOpen
      return drawerOpen
        ? 'AppNavMobileDrawer'
        : null
    },
    items () {
      // default to desktop
      const component = get(this, 'navComponent', 'AppNavDesktop')

      return component === 'AppNavDesktop'
        ? this.desktopNavItems
        : this.mobileNavItems
    },
    orgName () {
      let name = get(this.$store.state.authModule, 'activeOrg.name', '--')
      return name.charAt(0).toUpperCase() + name.slice(1)
    },
    orgId () {
      return get(this.$store.state.authModule, 'activeOrg.id')
    },
    isServiceTypeOrg () {
      return get(this.$store.state.authModule, 'activeOrg.settings.defaultTripType', 'personal') === 'business'
    },
    packIsSimple () {
      return this.$store.getters.packIsSimple
    },
    hasGroups () {
      let hasPermission = false
      const orgType = get(this.$store.state.authModule, 'orgType', 'regular')

      if (orgType === 'regular') {
        hasPermission = this.hasPermission('customer_admin')
      } else {
        hasPermission = true
      }

      return hasPermission
    },
    // expose nested items as top level arrays for reactivity tracking
    vehicleNavSubItems () {
      const subItems = [
        {
          text: 'Vehicles',
          route: this.populateRoute('Vehicles'),
        },
        ...(this.hasGroups ? [
          {
            text: 'Groups',
            route: this.populateRoute('VehicleGroups'),
          },
        ] : []),
        ...(this.$store.getters.hasMixin('lt') && this.isServiceTypeOrg ? [
          {
            text: 'POIs',
            route: this.populateRoute('VehicleLocations'),
          },
        ] : []),
      ]
      // dont show dropdown if it only matches parent nav item
      return subItems.length === 1
        ? null
        : subItems
    },
    reportNavSubItems () {
      return [
        {
          text: 'Report Center',
          route: this.populateRoute('Reports'),
        },
        ...(this.hasPermission('admin') ? [
          {
            text: 'ald_admin_reports',
            route: this.populateRoute('ReportsAdmin'),
          },
        ] : []),
        {
          text: 'Scheduled Reports',
          route: this.populateRoute('ReportsScheduled'),
        },
      ]
    },
    desktopNavItems () {
      return [
        {
          text: 'Dashboard',
          subString: 'dashboard',
          route: this.populateRoute('Dashboard'),
        },
        {
          text: 'Vehicles',
          subString: 'vehicles',
          subItems: this.vehicleNavSubItems,
          route: this.populateRoute('Vehicles'),
        },
        {
          text: 'Reports',
          subString: 'reports',
          subItems: this.reportNavSubItems,
          route: this.populateRoute('Reports'),
        },
      ]
    },
    mobileNavItems () {
      return [
        {
          text: 'Dashboard',
          route: this.populateRoute('Dashboard'),
        },
        {
          text: 'Vehicles',
          route: this.populateRoute('Vehicles'),
        },
        ...(!this.packIsSimple ? [
          {
            text: 'Fleet Map',
            route: this.populateRoute('FleetMap'),
          },
        ] : []),
        {
          text: 'Reports',
          route: this.populateRoute('Reports'),
        },
        {
          text: this.orgName,
          route: this.populateRoute('OrgSelection'),
        },
        {
          hr: true,
        },
        ...(this.hasPermission('customer_admin') ? [
          {
            text: 'Admin Settings',
            route: this.populateRoute('Admin'),
          },
        ] : []),
        ...(this.hasPermission('admin') ? [
          {
            text: 'ald_admin_reports',
            route: this.populateRoute('ReportsAdmin'),
          },
        ] : []),
        ...(this.hasGroups ? [
          {
            text: 'Groups',
            route: this.populateRoute('VehicleGroups'),
          },
        ] : []),
        {
          text: 'Logout',
          action: () => {
            this.$store.dispatch('DELETE_CURRENT_SESSION')
          },
          route: this.populateRoute('Login', false),
        },
      ]
    },
  },
  methods: {
    populateRoute (routeName, hasOrgId = true) {
      const route = {
        name: routeName,
      }
      if (hasOrgId) {
        route.params = {
          orgId: this.orgId,
        }
      }
      return route
    },
    hasPermission (accessLevel) {
      return this.$store.getters.hasPermission(accessLevel)
    },
  },
}
</script>
