<template>
  <div>
    <div class="px-8 mt-4 flex items-center justify-between">
      <span class="text-grey-dk">
        {{ 'Name' | translate }}
      </span>
    </div>
    <div class="px-8 mt-1 mb-5 flex items-center justify-between">
      <VinInput
        ref="name"
        v-model="locationName"
        type="text"
        color="primary-dk"
        class="w-full text-lg rounded transition-2s"
        :border-styles="'border-width: 2px !important;'"
        @input="$emit('name-input', locationName)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceLocationsName',
  props: {
    inputName: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      locationName: '',
    }
  },
  watch: {
    inputName: {
      immediate: true,
      handler (v) {
        this.locationName = v
      },
    },
  },
}
</script>
