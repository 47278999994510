import { lastDayOfMonth,subMonths } from 'date-fns'
import get from 'lodash.get'
import { computed, onBeforeUnmount, onMounted,watch } from 'vue'

import { useRouter } from '@/router/router'
import reportConfig from '@/state/reports/config.json'
import { useStore } from '@/state/store'

/** @type {(Map<string, string>)} */
export const slugMixinMap = new Map()

reportConfig.fullReportList.forEach(r => {
  slugMixinMap.set(r.slug, r.requiredMixin)
})

const widgetMixins = [
  ['widget-total-distance', 'basic'],
  ['widget-fuel-price', 'basic'],
  ['widget-average-distance', 'basic'],
  ['widget-idling', 'dm'],
  ['widget-longest-journey', 'basic'],
  // also support component names
  ['WidgetFuelCost', 'basic'],
  ['WidgetFleetUtilization', 'basic'],
  ['WidgetAvgJourneyLength', 'basic'],
  ['WidgetAvgFuelEcon', 'basic'],
  ['WidgetLongestJourney', 'basic'],
  ['WidgetTotalFleetMileage', 'basic'],
  ['WidgetFleetFootprintEvents', 'dm'],
  ['WidgetFleetCostEvents', 'dm'],
  ['WidgetDriverFootprintEvents', 'dm'],
  ['WidgetDriverCostEvents', 'dm'],
  ['WidgetDriverRiskEvents', 'dm'],
  ['WidgetTotalIdleTime', 'dm'],
  ['WidgetFleetRiskEvents', 'dm'],
  ['WidgetBestVehicle', 'dm'],
]

widgetMixins.forEach(([slug, requiredMixin]) => {
  slugMixinMap.set(slug, requiredMixin)
})



// helpers
export const shameTheDriver = (rows, shouldShame = true) => {
  let driver = {
    eventsPer100: shouldShame
      ? Number.NEGATIVE_INFINITY
      : Number.POSITIVE_INFINITY,
  }

  if (rows.length > 0) {
    rows.forEach(row => {
      if (row.distance > 0) {
        if (shouldShame) {
          driver = row.eventsPer100 > driver.eventsPer100 ? row : driver
        } else {
          driver = row.eventsPer100 < driver.eventsPer100 ? row : driver
        }
      }
    })
  }

  return Number.isFinite(driver.eventsPer100)
    ? driver
    : null
}

// composition
export const composeWidget = (widget, report) => {

  const store = useStore()
  const router = useRouter()


  // computed
  const activeOrgId = computed(() => get(store.state.authModule, 'activeOrg.id'))

  const results = computed(() => {
    const response = store.getters.resultsForSlug(widget.slug)
    if (response) {
      return response
    }
    return null
  })

  const generatedAt = computed(() => {
    return get(results.value, 'generatedAt', '--')
  })

  const isFetching = computed(() => get(store.state.dataModule.fetching, widget.slug, false))

  const packHasReport = computed(() => {
    const reportWidget = get(report, 'widget')
    if (reportWidget) {
      const requiredMixin = slugMixinMap.get(widget.slug)
      return store.getters.hasWidget(reportWidget, requiredMixin)
    }
    return false
  })

  // methods
  const fetchData = async (bustCache = false) => {
    // request in flight for slug
    if (isFetching.value) return

    const formatted = widget.slug.replace(/-/g, '')
    // const widgetParams = get(widget, 'params', {})

    if (activeOrgId.value) {
      const slug = widget.slug

      let params = {
        bustCache: bustCache,
      }

      if (get(widget, 'customDuration')) {
        const until = lastDayOfMonth(store.state.paramsModule.until)
        const since = subMonths(until, 3)

        params.duration = 'custom'
        params.since = since
        params.until = until
      }

      // set 'include' param to get telem, non-telem or all vehicles in the report
      if (get(widget, 'include')) {
        params.include = widget.include
      }

      try {
        store.dispatch('GET_DATA_PROMISE', {
          slugs: slug,
          params,
        })
      } catch (e) {
        store.dispatch('SERVE_TOAST', {
          type: 'danger',
          message: `Error fetching data for: ${formatted}`,
        })
      }
    } else {
      store.dispatch('SERVE_TOAST', {
        type: 'danger',
        message: `Could not fetch data for: ${formatted}`,
      })
    }
  }

  const navToReport = () => {
    if (packHasReport.value && activeOrgId.value && get(report, 'slug')) {
        router.push({
          name: 'ReportDetails',
          params: {
            orgId: activeOrgId.value,
            slug: report.slug,
          },
        })
      } else {
      const formatted = report.slug.replace(/-/g, '')
      store.dispatch('SERVE_TOAST', {
        type: 'danger',
        message: `Could not navigate to: ${formatted}`,
      })
    }
  }

  // watchers
  const activeGroup = computed(() => store.state.groupsModule.activeGroup)
  const duration = computed(() => store.state.paramsModule.duration)
  const since = computed(() => store.state.paramsModule.since)
  const until = computed(() => store.state.paramsModule.until)

  const watchers = watch(
    [activeGroup, duration, since, until],
    (val, prevVal) => {
      if (val && prevVal) {
        fetchData()
      }
    },
  )

  // hooks
  onMounted(() => {
    if (!results.value) {
      fetchData()
    }
  })

  onBeforeUnmount(() => {
    // teardown
    watchers()
  })

  // instance
  return {
    results,
    generatedAt,
    isFetching,
    packHasReport,
    fetchData,
    navToReport,
  }
}
