const module = {
  state: {
    parsed: [],
    errors: [],
  },
  mutations: {
    UPDATE_PARSED_CSV: (state, payload) => {
      state.parsed = payload
    },
    CLEAR_PARSED_CSV: (state) => {
      state.parsed = []
      state.errors = []
    },
  },
}

export default module
