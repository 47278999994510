<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <g
      fill="none"
      fill-rule="nonzero"
    >
      <rect
        width="56"
        height="56"
        fill="#06D9B9"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M30.804 47.157a2.525 2.525 0 0 1-1.797-.746l-1.796-1.797a1.696 1.696 0 1 1 2.39-2.39l1.203 1.203.593-.593a.848.848 0 0 0 .237-.475l1-6.985a3.391 3.391 0 0 1 .966-1.916l4.22-4.222a15.16 15.16 0 0 0 4.475-10.783V15.18h-3.271a15.149 15.149 0 0 0-10.78 4.476l-4.22 4.221a3.39 3.39 0 0 1-1.915.967l-7 1a.847.847 0 0 0-.474.237l-.593.594 1.203 1.204a1.696 1.696 0 1 1-2.39 2.39l-1.796-1.797a2.544 2.544 0 0 1 0-3.594l1.204-1.204a4.253 4.253 0 0 1 2.39-1.204l6.999-1 4.22-4.222a18.52 18.52 0 0 1 13.185-5.46h4.932c.936 0 1.695.76 1.695 1.696v4.968c.015 4.95-1.95 9.7-5.457 13.19l-4.22 4.222-1 7.003a4.256 4.256 0 0 1-1.203 2.39L32.6 46.462c-.483.46-1.13.71-1.796.695zm-9.08-4.823l2.34-2.34a1.66 1.66 0 0 0-2.34-2.34l-2.34 2.34a1.66 1.66 0 1 0 2.34 2.34zm-6.901-2.864l4.973-4.974a1.757 1.757 0 0 0-2.478-2.478l-4.973 4.974a1.757 1.757 0 1 0 2.478 2.478zm22.354-10.995c1.911-2.092 1.911-5.48 0-7.573-.399-.51-1.026-.731-1.623-.575-.596.157-1.062.667-1.206 1.32-.143.652.06 1.337.526 1.774.31.335.483.792.483 1.268s-.174.933-.483 1.268a1.537 1.537 0 0 1-2.303 0 1.873 1.873 0 0 1-.484-1.268c0-.476.174-.933.484-1.268.555-.709.517-1.765-.086-2.425-.603-.66-1.57-.7-2.218-.094-1.31 1.336-1.85 3.348-1.407 5.246.443 1.899 1.8 3.381 3.536 3.866 1.736.485 3.576-.105 4.798-1.539h-.017z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconAccel',
}
</script>
