<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="long-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="gradientStart"
        />
        <stop
          offset="100%"
          :stop-color="gradientStop"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="48"
        height="48"
        fill="url(#long-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        transform="translate(10.12 11.12)"
      >
        <circle
          cx="10.3"
          cy="9.363"
          r="1.793"
        />
        <path d="M13.72 9.374h.65M5.794 9.374h.65M10.304 5.959v-.65M12.791 6.887l.46-.46M7.359 12.319l.46-.46M12.791 11.86l.46.46M7.359 6.428l.46.46" />
        <path
          stroke-width="2"
          d="M4.518 4.503C-.4 9.422-.4 17.425 4.518 22.344c4.92 4.92 12.923 4.92 17.842 0 4.92-4.918 4.919-12.922 0-17.841-4.92-4.92-12.923-4.92-17.842 0z"
        />
        <path
          stroke-width="2"
          d="M17.576 17.483l3.425-3.091 4.092 3.862"
        />
        <path d="M10.304 13.883v-.65" />
        <path
          stroke-width="2"
          d="M6.889 24.206l7.645-10.493 7.226 8.955"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import iconMix from '../icon-mixin'
export default {
  name: 'VinLongIcon',
  mixins: [iconMix],
}
</script>
