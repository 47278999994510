const navigatorAliases = {
  en: 'en-gb',
  'en-us': 'en-gb',
  es: 'es-es',
  ru: 'ru',
}

module.exports = (locales) => {
  const validCodes = locales.map(l => l.code)
  const local = localStorage.getItem('profleet-locale')
  if (local && validCodes.includes(local)) {
    return local
  }

  let sysLang = navigator.language
  // make it lowercase
  sysLang = sysLang.toLowerCase()
  // use an alias if need be
  sysLang = navigatorAliases[sysLang] || sysLang

  if (sysLang && validCodes.includes(sysLang)) {
    localStorage.setItem('profleet-locale', sysLang)
    return sysLang
  }
  return 'en-gb'
}
