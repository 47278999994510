<template>
  <div class="flex items-center justify-center cursor-pointer text-copy hover:text-primary-dk -ml-4">
    <div class="w-1/5">
      <slot name="default">
        <component :is="icon" />
      </slot>
    </div>

    <div class="w-4/5 pl-4">
      <div class="text-xl font-normal leading-none tracking-normal mb-1">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
import Icons from '../Reports/ReportIcons/index.js'
export default {
  name: 'SpotlightResultReport',
  components: Icons,
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
}
</script>
