<template>
  <ul
    :class="noShadow ? '' : 'shadow-dropdown'"
    class="dropdown-menu"
  >
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'VinDropdownMenu',
  props: {
    noShadow: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
