<template>
  <div
    class="flex items-center justify-center leading-none rounded-full font-medium text-center uppercase text-2xl text-white fixed-circle"
    :style="divStyle"
  >
    <span class="mb-2px mx-auto">
      {{ computedGrade }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'VinSingleScore',
  props: {
    grade: {
      type: String,
      default: 'I',
    },
  },
  computed: {
    computedGrade () {
      if (!this.grade || this.grade === 'I' || this.grade === '') {
        return '?'
      }
      return this.grade
    },
    divStyle () {
      return {
        background: this.colorForScore(this.grade),
      }
    },
  },
  methods: {
    colorForScore (score) {
      if (!score || score === 'I') {
        score = '?'
      }
      const color = score === '?' ? 'grey' : 'score-' + score.toLowerCase()
      return `var(--color-${color})`
    },
  },
}
</script>
<style>
.fixed-circle {
  width: 56px;
  height: 56px;
  border: solid 1.4px #fff;
}
</style>
