import axios from 'axios'
const module = {
  state: {
    flags: {},
  },
  mutations: {
    SET_FLAGS: (state, flags) => {
      state.flags = flags
    },
  },
  actions: {
    async GET_FLAGS ({ commit }) {
      try {
        const { data } = await axios.get('/api/v1/flags')
        commit('SET_FLAGS', data.flags)
      } catch (error) {
        console.error(error)
      }
    },
  },
  getters: {
    getFlagValue: (state) => (key, fallback = null) => {
      return state.flags[key] ?? fallback
     },
    showSpeedingEvents: (_, getters, rootState) => {
      const respectTheSetting = getters.getFlagValue('FLAG_RESPECT_SPEED_TOGGLE', false)
      if (!respectTheSetting) {
        return true
      }
      return rootState.authModule.speedingEventsEnabled ?? false
    },
    enableEV: (_, getters) => {
      return getters.getFlagValue('FLAG_ENABLE_EV', false)
    },
    hasFuelType: (_, getters) => (vehicle) => {
      if (getters.enableEV) {
        const vinlieFuelType = vehicle?.vinliFuelData?.vinliFuelType ?? ''
        return ['diesel', 'petrol', 'hybrid', 'phev', 'bev'].includes(vinlieFuelType.toLowerCase())
      }
      return false
    },
    isBEV: (_, getters) => (vehicle) => {
      if (getters.enableEV) {
        const vft = vehicle?.vinliFuelData?.vinliFuelType ?? ''
        return vft === 'bev'
      }
      return false
    },
    gdpr5Enabled: (_, getters) => {
      return getters.getFlagValue('FLAG_GDPR_5_ENABLED', false)
    },
  },
}

export default module
