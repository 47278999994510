<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="report-savings-summary-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#F6B518"
        />
        <stop
          offset="100%"
          stop-color="#F6D118"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="48"
        height="48"
        fill="url(#report-savings-summary-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-width="2.2"
        transform="translate(9 10)"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.327 3.599c.401.056.794.129 1.182.218m5.739 2.964a11.347 11.347 0 0 1 2.774 3.796h1.275c3.727 0 3.852 8.967 0 8.967h-1.513a11.324 11.324 0 0 1-1.904 2.683c.31.31.594.679.835 1.096 1.036 1.795.879 3.826-.349 4.537-1.23.709-3.068-.17-4.104-1.965a5.416 5.416 0 0 1-.404-.868 13.53 13.53 0 0 1-5.445 1.128c-1.754 0-3.424-.328-4.938-.919a5.417 5.417 0 0 1-.399.853c-1.037 1.794-2.874 2.674-4.104 1.964-1.229-.71-1.387-2.741-.35-4.536.2-.347.431-.659.68-.933C3.193 20.553 1.9 17.819 1.9 14.811c0-2.677 1.024-5.139 2.736-7.079M16.816 11.756h3.042M2.626 10.754a2.415 2.415 0 0 1-1.151-4.539"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M17.51 6.266c-.471-.582-.57-1.333-.018-2.418.961-1.891 4.873-3.778 5.68-3.297.807.481.9 4.641-.061 6.532"
        />
        <circle
          cx="10"
          cy="4"
          r="3"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSavingsSummary',
}
</script>
