<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="active-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="gradientStart"
        />
        <stop
          offset="100%"
          :stop-color="gradientStop"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="48"
        height="48"
        fill="url(#active-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.6"
      >
        <path d="M30.447 15.698a8.696 8.696 0 0 1 7.965 7.964M30.461 11.633a12.653 12.653 0 0 1 11.97 11.97M30.398 19.74a4.74 4.74 0 0 1 3.932 3.931M30.126 23.938" />
        <g transform="matrix(-1 0 0 1 32.2 22)">
          <path d="M8.96 10.18h9.47M2.934 9.926h-.771c-.462 0-.873-.293-1.025-.729L.307 6.803a1.6 1.6 0 0 1 .312-1.584L4.752.54A1.6 1.6 0 0 1 5.952 0h8.66a1.6 1.6 0 0 1 1.217.561l2.415 2.828a1.6 1.6 0 0 0 .953.539l6.095 1.018a1.6 1.6 0 0 1 1.326 1.768l-.258 2.154a1.2 1.2 0 0 1-1.193 1.058" />
          <ellipse
            cx="6.08"
            cy="10.075"
            rx="2.88"
            ry="2.925"
          />
          <ellipse
            cx="21.44"
            cy="10.075"
            rx="2.88"
            ry="2.925"
          />
          <path d="M15.086 4.216h-3.212l-1.207-1.664" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMix from '../icon-mixin'
export default {
  name: 'VinActiveIcon',
  mixins: [iconMix],
}
</script>
