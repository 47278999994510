<template>
  <VinSmallWidgetTemplate
    :title="`${$t('AVG')} ${$t('Fuel Economy')}`"
    :timestamp="generatedAt"
    icon="AvgMPG"
    :fetching="isFetching"
    :stat="stat"
    :pack-has-report="packHasReport"
    :cta-title="$t('View Fuel Use Report')"
    :cta-action="navToReport"
    @bust-cache="fetchData(true)"
  />
</template>

<script>
import { formatNumber } from '@/common/utils/numeral'

import { composeWidget } from '../compose-widget'
export default {
  name: 'WidgetAvgFuelEconSm',
  displayName: 'AVG Fuel Economy',
  icon: 'AvgMPG',
  setup () {
    const widget = {
      slug:  'report-fleet-use-overview',
    }
    const report = {
      widget: 'ReportFuelUsage',
      slug: 'report-fuel-usage',
    }
    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    stat() {
      // use the last row
      const rows = this.results?.rows ?? []
      const last = rows.at(-1)

      return formatNumber(last?.fuelEconomy, {
        type: 'fuel',
        unit: 'econ',
      })
    },
  },
}
</script>
