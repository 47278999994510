<template>
  <transition name="vin-loading-block">
    <div class="bg-black opacity-25 absolute inset-0 flex items-center rounded-lg">
      <VinLoading
        v-if="hasSpinner"
        class="mx-auto"
      />
    </div>
  </transition>
</template>

<script>
import VinLoading from '../VinLoading/VinLoading'
export default {
  name: 'VinLoadingBlock',
  components: {
    VinLoading,
  },
  props: {
    hasSpinner: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
.vin-loading-block-enter-active,
.vin-loading-block-leave-active {
  transition: all 0.25s ease-out;
}

.vin-loading-block-enter,
.vin-loading-block-leave-to {
  opacity: 0;
  transform: translateY(0px);
}
</style>
