<template>
  <VinWidgetBarGraphTemplate
    vid="widget-avg-journey-length-btn"
    :rows="rows"
    :graph-data="graphData"
    :formatter="{
      type: 'distance',
      unit: 'm',
    }"
    :title="`${$t('AVG')} ${$t('Mileage')}`"
    :timestamp="generatedAt"
    :fetching="isFetching"
    text-suffix="mileage"
    invert
    :pack-has-report="packHasReport"
    @cta-action="navToReport"
    @bust-cache="fetchData(true)"
  />
</template>

<script>
import get from 'lodash.get'

import { composeWidget } from '../compose-widget'
export default {
  name: 'WidgetAvgJourneyLength',
  displayName: 'AVG Journey Length',
  icon: 'Journey',
  setup () {
    const widget = {
      slug: 'report-fleet-use-overview',
      customDuration: true,
    }
    const report = {
      widget: 'ReportJourneysSummary',
      slug: 'report-journey-summary',
    }

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    rows () {
      return get(this.results, 'rows', [])
    },
    graphData () {
      return this.rows.map(row => get(row, 'averageMileage', 0))
    },
  },
}
</script>
