<template>
  <div>
    <div class="flex items-center justify-between">
      <div class="flex flex-col w-1/2 pr-6">
        <div
          class="transition-2s ml-auto"
          :class="active === 'polygon' ? 'text-primary-dk' : 'text-grey-dk hover:cursor-pointer hover:text-primary-dk'"
          @click="setActive('polygon')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="38"
            viewBox="0 0 49 38"
            class="mt-5 mb-1"
          >
            <path
              class="fill-current stroke-current"
              fill-opacity=".18"
              fill-rule="evenodd"
              stroke-opacity=".7"
              stroke-width="2"
              d="M1.422 5.35l9.948 25.111 29.222 6.08 7.162-35.423L1.422 5.35z"
            />
          </svg>
          <span class="text-sm">{{ 'Polygon' | translate }}</span>
        </div>
      </div>
      <div class="flex flex-col w-1/2 pl-6">
        <div
          class="transition-2s mr-auto"
          :class="active === 'circle' ? 'text-primary-dk' : 'text-grey-dk hover:cursor-pointer hover:text-primary-dk'"
          @click="setActive('circle')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="40"
            viewBox="0 0 39 40"
            class="mt-5 mb-1"
          >
            <ellipse
              class="fill-current stroke-current"
              cx="114.5"
              cy="19.855"
              fill-opacity=".18"
              fill-rule="evenodd"
              stroke-opacity=".7"
              stroke-width="2"
              rx="18.5"
              ry="18.855"
              transform="translate(-95)"
            />
          </svg>
          <span class="text-sm">{{ $t('radius') }}</span>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center mt-4 mb-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        class="ml-6 mr-2"
      >
        <g
          fill="#65747B"
          fill-rule="nonzero"
        >
          <path d="M8.439 18.096h1.592v1.469H8.439zM11.624 18.096h1.592v1.469h-1.592zM2.072 11.605h1.469v1.592H2.072zM2.072 8.421h1.469v1.592H2.072zM11.624 2.053h1.592v1.469h-1.592zM8.44 2.053h1.592v1.469H8.44zM18.114 8.421h1.469v1.592h-1.469zM18.114 11.605h1.469v1.592h-1.469zM5.5.829a.735.735 0 0 0-.735-.735H.848A.735.735 0 0 0 .113.83v3.917c0 .406.329.735.735.735h1.224V6.83H3.54V5.48h1.224a.735.735 0 0 0 .735-.735V3.522h1.348V2.053H5.5V.829zM4.03 4.012H1.582V1.564H4.03v2.448zM16.156 4.746c0 .406.329.735.734.735h1.224V6.83h1.47V5.481h1.224a.735.735 0 0 0 .734-.735V.83a.735.735 0 0 0-.734-.735H16.89a.735.735 0 0 0-.734.735v1.224h-1.348v1.47h1.348v1.223zm1.47-3.182h2.447v2.448h-2.448V1.564zM5.5 16.872a.735.735 0 0 0-.735-.735H3.541V14.79h-1.47v1.348H.849a.735.735 0 0 0-.735.735v3.917c0 .406.329.735.735.735h3.917a.735.735 0 0 0 .735-.735v-1.224h1.347v-1.47H5.5v-1.223zm-1.47 3.182H1.582v-2.448H4.03v2.448zM23.533 17.016l-9.686-5.452a.735.735 0 0 0-1.085.76l1.81 10.967a.735.735 0 0 0 1.322.31l2.785-3.87 4.7-1.369a.735.735 0 0 0 .154-1.346zm-5.509 1.376a.734.734 0 0 0-.39.276l-1.9 2.639-1.274-7.711 6.83 3.844-3.266.952z" />
        </g>
      </svg>
      <div
        v-if="active === 'polygon'"
        class="w-64 ml-2 leading-tight"
      >
        <span class="text-sm text-grey-dk">
          {{ $t('explainer_poi_polygon') }}
        </span>
      </div>
      <div
        v-else
        class="w-64 ml-2 leading-tight"
      >
        <span class="text-sm text-grey-dk">
          {{ $t('explainer_poi_radius') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VehicleLocationsDraw',
  data () {
    return {
      active: 'polygon',
      rad: 50,
    }
  },
  methods: {
    setActive (type) {
      this.active = type
      this.$emit('drawingMode', type)
    },
    handleChange (payload) {
      this.rad = payload
    },
  },
}
</script>
