import Vue from 'vue'

const defaultModals = {
  addUser: {
    flag: false,
    component: 'UsersAdd',
  },
  deleteUser: {
    flag: false,
    component: 'UserDelete',
  },
  orgLocationEdit: {
    flag: false,
    component: 'LocationEdit',
  },
  locationAlertAddVehicle: {
    flag: false,
    component: 'LocationAlertAddVehicle',
  },
  locationAlertEdit: {
    flag: false,
    component: 'LocationAlertEdit',
  },
  addPack: {
    flag: false,
    component: 'PackAdd',
  },
  addSuborg: {
    flag: false,
    component: 'SuborgsAdd',
  },
  toggleSuborg: {
    flag: false,
    component: 'ToggleSuborg',
  },
  addWHP: {
    flag: false,
    component: 'WorkingHoursAdd',
  },
  reportParams: {
    flag: false,
    component: 'ReportParamsModal',
  },
  whpAddVehicles: {
    flag: false,
    component: 'WorkingHoursProfileAddVehiclesModal',
  },
  deleteFuelPrice: {
    flag: false,
    component: 'FuelPriceDeleteModal',
  },
  customDurationPicker: {
    flag: false,
    component: 'ModalCustomDurationPicker',
  },
  reportExportModal: {
    flag: false,
    component: 'ReportExportModal',
    formats: ['xlsx', 'pdf', 'csv'],
    slug: null,
    hasMonth: false,
  },
  editOdometer: {
    flag: false,
    component: 'OdometerEditModal',
  },
  analyticsOptIn: {
    flag: false,
    component: 'AnalyticsOptInModal',
  },
  confirmRollup: {
    flag: false,
    component: 'AdminConfirmRollupModal',
  },
  hGroupNew: {
    flag: false,
    component: 'GroupNewModal',
  },
  hGroupDelete: {
    flag: false,
    component: 'GroupDeleteModal',
  },
  hGroupUsersAdd: {
    flag: false,
    component: 'UsersAdd',
    props: ['hasSelectedGroup'],
  },
  hGroupDeleteUser: {
    flag: false,
    component: 'UserDelete',
    props: ['hasSelectedGroup'],
  },
  hGroupSelect: {
    flag: false,
    component: 'GroupSelectModal',
  },
  speedingConfirm: {
    flag: false,
    component: 'SpeedingConfirmModal',
  },
}

const module = {
  state: {
    toasts: {},
    modals: { ...defaultModals },
  },
  getters: {
    getActiveModalKey: (state) => {
      for (const [modal, conf] of Object.entries(state.modals)) {
        if (conf.flag === true) {
          return modal
        }
      }
      return null
    },
    getActiveModalConfig: (state) => {
      for (const [modal, conf] of Object.entries(state.modals)) {
        if (conf.flag === true) {
          return state.modals[modal]
        }
      }
      return null
    },
  },
  mutations: {
    CLEAR_OVERLAY_MODULE: (state) => {
      state.toasts = {}
      state.modals = { ...defaultModals }
    },
    // toasts
    TOAST_BREAD (state, payload) {
      // validate type
      if (payload.type) {
        const types = ['info', 'danger', 'success', 'progress', 'warning']
        if (types.indexOf(payload.type) === -1) {
          throw new Error(`Toast type must be one of ${types}`)
        }
      }
      // check for required
      if (!payload.message) {
        throw new Error(`Toast requires a message`)
      }

      Vue.set(state.toasts, payload.id, payload)
    },
    EAT_TOAST (state, toastId) {
      Vue.delete(state.toasts, toastId)
    },
    // modals
    TOGGLE_MODAL_STATE: (state, payload) => {
      state.modals[payload.modal].flag = payload.flag
    },
    SET_REPORT_EXPORT_MODAL: (state, payload) => {
      payload.component = 'ReportExportModal'
      payload.flag = true
      Vue.set(state.modals, 'reportExportModal', payload)
    },
  },
  actions: {
    SERVE_TOAST ({ commit }, payload) {
      // attach id for burn
      var d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now()
      }
      var newGuid = 'xx-4xxx-yxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })

      payload.id = newGuid

      commit('TOAST_BREAD', payload)
      if (!payload.forceClose) {
        const time = payload.duration || 2500

        setTimeout(() => {
          commit('EAT_TOAST', payload.id)
        }, time)
      }
    },
  },
}

export default module
