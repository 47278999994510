<template>
  <transition name="drawer-slide">
    <div
      id="app-nav-drawer-mobile-container"
      class="fixed h-screen w-3/4 z-20 flex bg-white shadow-lg py-6 pt-18"
    >
      <ul class="w-full">
        <AppNavMobileDrawerItem
          v-for="item in items"
          :key="item.title"
          :text="item.text"
          :route="item.route"
          :is-hr="item.hr"
          :action="item.action"
        />
      </ul>
    </div>
  </transition>
</template>

<script>
import AppNavMobileDrawerItem from './AppNavMobileDrawerItem'
export default {
  name: 'AppNavMobileDrawer',
  components: {
    AppNavMobileDrawerItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClose () {
      this.$store.commit('TOGGLE_DRAWER', false)
    },
  },
}
</script>

<style scoped>
.drawer-slide-enter-active,
.drawer-slide-leave-active {
  transition: 0.25s ease-out;
  left: 0;
}
.drawer-slide-enter,
.drawer-slide-leave-to {
  left: -80%;
}
</style>
