import axios from 'axios'
import Vue from 'vue'

const module = {
  state: {
    batteryLevels: {},
    tripStats: {},
  },
  mutations: {
    SET_VEHICLE_BATTERY_LEVEL: (state, payload) => {
      Vue.set(state.batteryLevels, payload.vehicleId, payload.evBatteryLevel)
    },
    SET_EV_TRIP_STATS: (state, { tripId, stats}) => {
      Vue.set(state.tripStats, tripId, stats)
    },
  },
  actions: {
    async GET_CURRENT_BATTERY_LEVEL ({ commit, state, rootState }, vehicleId) {
      if (state.batteryLevels[vehicleId] !== undefined) {
        return
      }

      const orgId = rootState.authModule?.activeOrg?.id
      if (!orgId) {
        return
      }
      try {
        const { data } = await axios.get(`/api/v1/orgs/${orgId}/vehicles/${vehicleId}/ev/snapshots?limit=30`)
        const first = data.snapshots.find(m => m.data?.evBatteryLevel)
        if (first) {
          const payload = {
            vehicleId,
            evBatteryLevel: first.data.evBatteryLevel,
          }
          commit('SET_VEHICLE_BATTERY_LEVEL', payload)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async GET_EV_TRIP_STATS ({ commit, state, rootState }, tripId) {
      if (state.tripStats[tripId] !== undefined) {
        return
      }

      const orgId = rootState.authModule?.activeOrg?.id
      if (!orgId) {
        return
      }
      try {
        const { data } = await axios.get(`/api/v1/orgs/${orgId}/trips/${tripId}/ev/stats`)
        const payload = {
          tripId,
          stats: data,
        }
        commit('SET_EV_TRIP_STATS', payload)
      } catch (error) {
        console.error(error)
      }
    },
  },
}

export default module
