<template>
  <div
    id="dash-map-settings-icon-btn"
    class="text-grey-dk hover:cursor-pointer hover:text-primary-dk"
    @click="$emit('toggleSettings')"
  >
    <div class="card flex items-center fixed-card">
      <svg
        class="mx-auto stroke-current"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="20"
        viewBox="0 0 24 20"
      >
        <g
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        >
          <path d="M4 19v-7M4 8V1M12 19v-9M12 6V1M20 19v-5M20 10V1M1 12h6M9 6h6M17 14h6" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardMapSettingsToggle',
}
</script>
<style scoped>
.fixed-card {
  height: 52px;
  width: 52px;
}
</style>
