<template>
  <button
    :id="vid"
    class="btn  p-btn text-sm leading-none font-normal rounded-lg flex items-center justify-center transition-2s"
    :class="[
      color && !invert && !secondary && !disabled ? `bg-${color} hover:bg-${hoverColor} text-white` : ``,
      invert && !color && !secondary && !disabled ? `bg-white border-2 text-grey-dkr hover:bg-grey-ltr active:bg-white` : ``,
      invert && color && !secondary && !disabled ? `bg-white border-2 text-${color} hover:bg-white active:bg-white` : ``,
      !invert && !color && !secondary && !disabled ? `text-grey-dkr bg-cloud-dk border hover:bg-grey-lt active:bg-cloud-dk` : ``,
      invert && border && color && !secondary && !disabled ? `border-${border}` : ``,
      invert && !border && color && !secondary && !disabled ? `border-${color}` : ``,
      invert && !border && !color && !secondary && !disabled ? `border border-grey-lt` : ``,
      secondary && !disabled && !border ? 'border border-grey text-cta-lt hover:bg-grey-ltr bg-white' : '',
      secondary && !disabled && border ? `text-cta-lt hover:bg-grey-ltr bg-white border-${border}` : '',
      isLoading ? 'cursor-not-allowed opacity-50' : '',
      disabled ? 'bg-grey-lt border-grey text-border hover:cursor-not-allowed hover:bg-grey-lt' : ''
    ]"
    :name="name"
    :value="value"
    :style="{ height }"
    @click="handleClick"
  >
    <div class="mx-auto flex items-center">
      <div class="mx-auto">
        {{ text }}
      </div>
      <VinLoading
        v-if="isLoading"
        class="ml-2"
        size="6"
      />
      <slot />
    </div>
  </button>
</template>

<script>
import VinLoading from '../../VinLoading/VinLoading'
export default {
  name: 'VinButton',
  components: { VinLoading },
  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: 'Button',
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
    border: {
      type: String,
      default: null,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      required: true,
      default: null,
    },
    height: {
      type: String,
      default: '52px',
    },
  },
  component: {
    VinLoading,
  },
  computed: {
    hoverColor () {
      let baseColor = this.color.substring(0, this.color.length - 2)
      let shadeSuffix = this.color.substring(
        this.color.length - 2,
        this.color.length,
      )
      return shadeSuffix === 'lt' ? baseColor + 'dk' : baseColor + 'lt'
    },
  },
  methods: {
    handleClick () {
      !this.disabled ? this.$emit('click') : this.$emit('disabledClick')
    },
  },
}
</script>
