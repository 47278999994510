<template>
  <header class="dash-nav bg-white p-0 mx-auto relative">
    <div class="container h-20 px-container flex justify-between">
      <nav class="header-nav flex justify-end items-center p-0 m-0 cursor-pointer">
        <ul class="hidden lg:flex justify-end items-stretch h-full p-0 m-0">
          <li
            v-for="item in items"
            :key="item.id"
            class="py-5 pr-18 leading-loose"
          >
            <div
              v-if="item"
              :class="$route.path.includes(item.subString) || (item.subString === 'dashboard' && $route.path === '/') ? 'line' : 'line-placeholder bg-white'"
            />
            <router-link
              v-if="!item.subItems"
              :id="`main-nav-item-${item.subString}`"
              :to="item.route"
              class="text-lg tracking-wide"
              :class="checkItemSelection(item) ? 'text-primary-dk hover:text-primary-dk' : 'text-grey-dk hover:text-primary-dk hover:cursor-pointer'"
            >
              <span>{{ $t(item.text) }}</span>
            </router-link>
            <VinDropdown
              v-else
              :vid="`navbar-dropdown-${item.subString}`"
              placement="bottom-left"
              class="text-lg tracking-wide"
              :class="checkItemSelection(item) ? 'text-primary-dk hover:text-primary-dk' : 'text-grey-dk hover:text-primary-dk'"
            >
              <router-link
                :id="`main-nav-item-${item.subString}`"
                :to="item.route"
                :class="checkItemSelection(item) ? 'text-primary-dk hover:text-primary-dk' : 'text-grey-dk hover:text-primary-dk hover:cursor-pointer'"
              >
                <div class="flex justify-between items-center">
                  {{ $t(item.text) }}
                  <svg
                    class="fill-current ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                  >
                    <path
                      fill-rule="nonzero"
                      d="M4.218 5.542L1.091 1.624A1 1 0 0 1 1.873 0h6.254a1 1 0 0 1 .782 1.624L5.782 5.542a1 1 0 0 1-1.564 0z"
                    />
                  </svg>
                </div>
              </router-link>
              <VinDropdownMenu
                slot="menu"
                class="push-down"
              >
                <VinDropdownItem
                  v-for="(sub, i) in item.subItems"
                  :key="i"
                  :text="$t(sub.text)"
                  @click.native="$router.push(sub.route)"
                />
              </VinDropdownMenu>
            </VinDropdown>
          </li>
        </ul>
      </nav>
      <div v-if="hasMapAvail && mapEnabled">
        <div class="inner-addon right-addon flex items-center">
          <VinDropdown
            v-if="isDash"
            id="nav-searchbar-dropdown"
            vid="nav-searchbar-dropdown"
            class="px-3 absolute"
          >
            <span class="flex items-center justify-between text-copy font-normal text-lg">
              <search-icon class="h-5 w-5 text-svg-grey" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                class="ml-2"
              >
                <path
                  fill="#8C91A2"
                  fill-rule="nonzero"
                  d="M4.218 5.542L1.091 1.624A1 1 0 0 1 1.873 0h6.254a1 1 0 0 1 .782 1.624L5.782 5.542a1 1 0 0 1-1.564 0z"
                />
              </svg>
            </span>
            <VinDropdownMenu slot="menu">
              <VinDropdownItem
                :text="$t('map_search')"
                class="w-48"
                @click.native="searchMap = true"
              />
              <VinDropdownItem
                :text="$t('fleet_search')"
                @click.native="searchMap = false"
              />
            </VinDropdownMenu>
          </VinDropdown>
          <div
            v-else
            class="px-3 absolute"
          >
            <span class="flex items-center justify-between text-copy font-normal text-lg">
              <search-icon class="h-5 w-5 text-svg-grey" />
            </span>
          </div>
          <VinInput
            v-if="!searchMap || !isDash"
            v-model="searchTerm"
            vid="app-search"
            :placeholder="placeholder"
            :class="isDash ? 'search-left-pad' : 'left-pad'"
            color="primary-dk"
            class="search my-5 w-88 rounded-xl"
            onfocus="value = ''"
          />
          <input
            v-if="searchMap && isDash"
            id="app-search-map"
            ref="autocomplete"
            type="text"
            :placeholder="placeholder"
            class="input block p-input search search-left-pad text-lg border-grey border-2 hover:border-primary-dk my-5 w-88 rounded-xl transition-2s"
            onfocus="value = ''"
          >
        </div>
      </div>
      <div v-else>
        <div class="inner-addon right-addon flex items-center">
          <div class="px-3 absolute">
            <span class="flex items-center justify-between text-copy font-normal text-lg">
              <search-icon class="h-5 w-5 text-svg-grey" />
            </span>
          </div>
          <VinInput
            v-model="searchTerm"
            vid="app-search"
            :placeholder="placeholder"
            color="primary-dk"
            class="search my-5 w-88 rounded-xl left-pad"
          />
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { getGoogleMapsAPI } from 'gmap-vue'
import { SearchIcon } from 'vue-feather-icons'
export default {
  name: 'AppNavDesktop',
  components: {
    SearchIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      autocomplete: null,
    }
  },
  computed: {
    mapEnabled () {
      return this.$store.state.dashboardModule.map && !this.$store.getters.packIsSimple
    },
    google: getGoogleMapsAPI,
    searchTerm: {
      get () {
        return this.$store.state.searchModule.searchTerm.spotlight
      },
      set (value) {
        this.$store.commit('SEARCH_BY_TERM', { term: value, type: 'spotlight' })
      },
    },
    searchMap: {
      get () {
        return this.$store.state.searchModule.searchMap
      },
      set (value) {
        this.$store.commit('TOGGLE_SEARCH_MAP', value)
      },
    },
    isDash () {
      return this.$route.name === 'Dashboard' || this.$route.name === 'DashboardDefault'
    },
    hasMapAvail () {
      return !this.$store.getters.packIsSimple && (this.$store.getters.hasMixin('lt') || this.$store.getters.hasMixin('vam'))
    },
    placeholder () {
      const text = this.isDash && this.searchMap ? `Map Search (Press "/" to focus)` : `Search (Press "/" to focus)`
      return this.$t(text)
    },
  },
  watch: {
    searchTerm () {
      this.$store.commit('SEARCH_BY_TERM', { term: this.searchTerm, type: 'spotlight' })
    },
    searchMap () {
      this.setupFocus()
      if (this.searchMap && !this.autocomplete) {
        this.$gmapApiPromiseLazy().then(() => {
          window.GoogleMapsCallback(google)
          this.autocomplete = new google.maps.places.Autocomplete(
            (this.$refs.autocomplete),
          )
          this.autocomplete.addListener('place_changed', () => {
            let place = this.autocomplete.getPlace()
            let lat = place.geometry.location.lat()
            let lng = place.geometry.location.lng()
            const payload = {
              lat: lat,
              lng: lng,
            }
            this.handleSelection(payload)
          })
        })
      }
    },
  },
  created () {
    this.setupFocus()
  },
  methods: {
    checkItemSelection (item) {
      if (item.subString === 'dashboard') {
        return this.$route.name === 'Dashboard' || this.$route.name === 'DashboardDefault' || this.$route.path === '/'
      }
      return this.$route.path.indexOf(item.subString) > -1
    },
    handleSelection (payload) {
      this.$store.commit('SET_MAP_SEARCH_SELECTION', payload)
    },
    setupFocus () {
      document.removeEventListener('keypress', this.handleFocus, true)
      document.addEventListener('keypress', this.handleFocus, true)
    },
    handleFocus (e) {
      const srcElement = e.srcElement
      // only focus if we are typing from body element
      // this prevents other inputs from triggering focus
      if (e.keyCode === 47 && srcElement.nodeName === 'BODY') {
        e.preventDefault()
        const id = this.searchMap && this.isDash ? 'app-search-map' : 'app-search'
        let el = document.getElementById(id)
        if (el) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
          el.focus()
          // clear the '/' if the browser adds it to the input
          setTimeout(() => {
            el.value = ''
          }, 10)
        }
      }
    },
  },
}
</script>

<style scoped>
.dash-nav {
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
}
.nav-alert >>> .badge-corner {
  position: absolute;
  top: -6px;
  right: -18px;
  transform: translateX(50%);
}
.inner-addon {
  position: relative;
}
/* style icon */
.inner-addon .searchicon {
  position: absolute;
  pointer-events: none;
}
.searchicon {
  height: 21px;
  stroke: #979aa7;
}
/* align icon */
.right-addon .searchicon {
  right: 12px;
  top: 30px;
}
.line {
  height: 4px;
  width: 100%;
  background-color: theme("colors.primary-dk");
  transform: translateY(56px);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  margin-left: auto;
  margin-right: auto;
}
.line-placeholder {
  height: 4px;
  width: 100%;
  transform: translateY(56px);
  margin-left: auto;
  margin-right: auto;
}
.search-left-pad {
  padding-left: 62px;
}
.left-pad {
  padding-left: 42px;
}
.search::placeholder {
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  font-weight: 500 !important;
  color: #8c91a2 !important;
  opacity: 1;
}
.search::-moz-placeholder {
  /* Firefox 19+ */
  line-height: 1.5 !important;
}
.search {
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: #002c5d !important;
  border-color: rgba(151, 151, 151, 0.39);
  border-width: 1px;
  height: 43px;
  background-color: #f7f8fb;
  outline: none;
}
.push-down {
  transform: translateY(10px);
}
.push-down:after {
  position: absolute;
  top: -9px;
  left: 25%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  content: "";
  font-size: 0;
  line-height: 0;
  margin-left: -5px;
  width: 0;
}
</style>
