<template>
  <div
    :id="vid"
    ref="trigger"
    v-clickoutside="handleClose"
    class="dropdown"
    :class="width ? `w-${width}` : ''"
  >
    <div class="dropdown-trigger">
      <slot />
    </div>

    <transition
      name="slide-up"
      @after-leave="$_popoverMixin_doDestory"
    >
      <div
        v-show="show"
        ref="popover"
        :class="width ? `w-${width}` : ''"
        class="dropdown-popover"
      >
        <slot name="menu" />
      </div>
    </transition>
  </div>
</template>

<script>
import PopoverMixin from '@/common/mixins/popoverMixin'
export default {
  name: 'VinDropdown',
  directives: {
    clickoutside: {
      bind: function (el, binding) {
        el._handler = evt => {
          if (!el.contains(evt.target) && binding.expression) {
            binding.value(evt)
          }
        }

        document.addEventListener('click', el._handler)
      },
      unbind: function (el) {
        document.removeEventListener('click', el._handler)
      },
    },
  },
  mixins: [PopoverMixin],
  props: {
    trigger: {
      type: String,
      default: 'hover',
      validator: val => ['click', 'hover', 'focus'].indexOf(val) > -1,
    },
    placement: {
      type: String,
      default: 'bottom',
      validator: val =>
        [
          'top',
          'top-left',
          'top-right',
          'left',
          'left-top',
          'left-bottom',
          'right',
          'right-top',
          'right-bottom',
          'bottom',
          'bottom-left',
          'bottom-right',
        ].indexOf(val) > -1,
    },
    width: {
      type: String,
      default: null,
    },
    vid: {
      type: String,
      required: true,
      default: null,
    },
    gDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted () {
    this.$on('menu-item-click', this.handleMenuItemClick)
  },
  methods: {
    handleClose () {
      this.show = false
    },
    handleMenuItemClick (name) {
      this.show = false
      this.$emit('on-dropdown-command', name)
    },
  },
}
</script>
<style>
.slide-up-enter-active {
  transition: all 0.2s ease;
}
.slide-up-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-up-enter, .slide-up-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.dropdown {
  display: inline-block;
  cursor: pointer;
}
.dropdown-popover {
  position: absolute;
  overflow: visible;
  z-index: 1050;
}
.dropdown-menu {
  position: relative;
  padding: 0;
  width: inherit;
  font-size: 0;
  border-radius: 4px;
  background-color: theme("colors.white");
  list-style: none;
  z-index: 1050;
  max-height: 30vh;
  overflow:auto;
}
.dropdown-menu-item {
  display: block;
  @apply tracking-relaxed;
  padding: 10px 26px;
  min-width: 110px;
  font-size: 16px;
  color: theme("colors.grey-dk");
  line-height: 1.5;
  transition: all 0.3s;
  cursor: pointer;
}
.dropdown-menu-item:first-child {
  padding-top: 20px;
}
.dropdown-menu-item:last-child {
  padding-bottom: 20px;
}
.dropdown-menu-item:hover {
  color: theme("colors.primary-lt");
}
.dropdown-menu-item-disabled {
  color: theme("colors.grey-lt");
  cursor: not-allowed;
}
.dropdown-menu-item-disabled:hover {
  color: theme("colors.grey-lt");
}
.dropdown-menu-item-divided {
  position: relative;
  margin-top: 6px;
  border-top: 1px solid theme("colors.grey");
}
.dropdown-menu-item-divided:before {
  content: "";
  display: block;
  height: 6px;
}
</style>
