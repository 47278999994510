import get from 'lodash.get'

const validate = (resp) => {
  // if there is no status, return false
  const status = get(resp, 'status')
  if (!status) {
    return false
  }
  const sessionOrgs = get(resp, 'data.session.orgs', [])
  const isDeleted = get(resp, 'data.session.isDeleted')
  // if we have at least one active org, we're good!
  const active = sessionOrgs.filter(org => org.isActive).length > 0

  // check for no orgs
  if (sessionOrgs.length === 0) {
    return new Error('Your user is not affiliated with an organization - Please contact your fleet manager.')
    // check for deleted user
  } else if (isDeleted === true) {
    return new Error('Your user has been deleted from this organization - Please contact your fleet manager.')
    // check for inactive org
  } else if (!active) {
    return new Error('Your organization is inactive - Please contact your account manager.')
  } else {
    return true
  }
}
export default validate
