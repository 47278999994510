<template>
  <transition name="fade">
    <span
      v-if="shouldShow"
      class="flex items-center pill-tag text-center
      outline-none whitespace-no-wrap py-2 mr-4
      leading-normal border-2 border-primary-dk bg-white relative transition-2s"
      :class="[
        status ? `bg-${status}-dk border-${status}-dk text-white` : '',
        closable ? 'justify-between' : 'justify-center',
        closeHover ? 'text-cta-dk border-cta-dk' : 'text-copy'
      ]"
    >
      <x-icon
        v-if="closable"
        class="pill-tag-close mt-px ml-4 cursor-pointer"
        @click="closeAction"
        @mouseenter="closeHover = true"
        @mouseleave="closeHover = false"
      />
      <span
        class="cursor-default tag-text text-sm md:text-lg leading-none mb-2px"
        :class="[
          status ? 'text-white' : '',
          closable ? 'pl-3 pr-6' :'px-8'
        ]"
      >
        {{ text }}
      </span>
    </span>
  </transition>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
export default {
  name: 'VinPill',
  components: {
    XIcon,
  },
  props: {
    name: {
      type: [String, Number],
      default: null,
    },
    text: {
      type: [String, Number],
      default: 'Pill',
    },
    color: {
      type: String,
      default: 'default',
    },
    closable: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      shouldShow: true,
      closeHover: false,
    }
  },
  methods: {
    closeAction (evt) {
      if (typeof this.name === 'undefined') {
        this.$emit('on-close', evt)
        this.shouldShow = false
      } else {
        this.$emit('on-close', evt, this.name)
        this.shouldShow = false
      }
    },
  },
}
</script>
<style scoped>
.pill-tag {
  font-size: 0;
  border-radius: 24px;
}
.pill-tag-close {
  stroke-width: 3px;
  height: 16px;
  width: 16px;
}
</style>
