<template>
  <div class="card relative grid grid-cols-3 w-full">
    <VinLoadingBlock v-if="isFetching" />
    <div class="col-span-1 pl-10 py-8">
      <VinWidgetHeader
        :title="$t('Total Fuel Cost')"
        :timestamp="generatedAt"
        @bust-cache="fetchData(true)"
      />
      <VinWidgetStat
        :fetching="isFetching"
        :stat="stat"
      />
      <VinButton
        v-if="packHasReport"
        vid="widget-fuel-cost-btn"
        color="cta-lt"
        class="btn-width ml-0 text-base font-semibold rounded-lg mt-10"
        :text="$t('View Report')"
        @click="navToReport"
      />
    </div>
    <div class="col-span-1 py-8 w-2/3">
      <VinTitle
        el="h5"
        :text="$t('Cost Breakdown')"
        class="font-normal text-grey-dk text-lg tracking-xl leading-tight mb-8 uppercase"
      />

      <VinWidgetFuelCost
        v-for="(item, idx) in costList"
        :key="idx"
        :text="item.text"
        :cost="item.cost"
      />
    </div>

    <div class="col-span-1 mt-4">
      <VinWidgetPieChart
        :chart-data="chartData"
        :formatter="{
          type: 'currency',
        }"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'

import { composeWidget } from '../compose-widget'
export default {
  name: 'WidgetFuelCost',
  displayName: 'Fuel Cost',
  icon: 'Calculate',
  setup () {
    const widget = {
      slug: 'widget-fuel-price',
    }
    const report = {
      slug: 'report-fuel-cost',
      widget: 'ReportFuelCost',
    }

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    stat () {
      return formatNumber(get(this, 'results.totalCost'), {
        type: 'currency',
        abbrvAt: 5,
      })
    },
    petrolCost () {
      return formatNumber(get(this, 'results.petrolCost'), {
        type: 'currency',
      })
    },
    dieselCost () {
      return formatNumber(get(this, 'results.dieselCost'), {
        type: 'currency',
      })
    },
    avgCostPerTrip () {
      return formatNumber(get(this, 'results.avgCostPerTrip'), {
        type: 'currency',
      })
    },
    costList () {
      return [
        {
          text: 'Petrol',
          cost: this.petrolCost,
        },
        {
          text: 'Diesel',
          cost: this.dieselCost,
        },
        {
          text: 'Per Trip',
          cost: this.avgCostPerTrip,
        },
      ]
    },
    chartData () {
      return [
        {
          name: this.$t('Petrol'),
          val: get(this, 'results.petrolCost'),
          color: 'success-lt',
        },
        {
          name: this.$t('Diesel'),
          val: get(this, 'results.dieselCost'),
          color: 'primary-dk',
        },
      ]
    },
  },
}
</script>
