<template>
  <tr class="w-full">
    <td
      v-for="header of headers"
      :key="header+serviceLocation.id"
      class="border-b-2 border-grey-dk my-5"
    >
      <div class="h-full relative">
        <div class="flex items-start justify-center">
          <VinInput
            v-model="serviceLocation[header]"
            :class="headersWithErrors.includes(header) ? 'border-danger-lt w-4/5 ' : 'w-4/5 '"
            @input="valueChanged({value:$event, header})"
          />
        </div>
        <div class="absolute top-5 error-message">
          <p
            v-if="headerErrors[header]"
            :key="serviceLocation.id+header+err"
            class="flex justify-center items-center text-danger-lt text-sm"
          >
            <!-- Required -->
            {{ headerErrors[header][0].message }}
          </p>
        </div>
      </div>
    </td>

    <td
      v-for="col of extraCols"
      :key="col.header+serviceLocation.id"
      class="border-b-2 border-grey-dk my-5 extraCol"
    >
      <div class="h-full relative">
        <div class="flex items-start justify-center">
          <VinInput
            v-if="['text', 'number',].includes(col.type)"
            v-model="serviceLocation[col.header]"
            :class="headersWithErrors.includes(col.header) ? 'border-danger-lt w-4/5 ' : 'w-4/5 '"
            @input="valueChanged({value:$event, header: col.header})"
          />
          <component
            :is="col.type"
            v-else
            v-model="serviceLocation[col.header]"
            :show-title="false"
            :class="headersWithErrors.includes(col.header) ? 'border-danger-lt w-full' : 'w-full'"
            @vehicle-makes-input="valueChanged({value:$event, header: col.header})"
          />
        </div>
        <div class="absolute top-5 error-message">
          <p
            v-if="headerErrors[col.header]"
            :key="serviceLocation.id+col.header+err"
            class="flex justify-center items-center text-danger-lt text-sm"
          >
            <!-- Required -->
            {{ headerErrors[col.header][0].message }}
          </p>
        </div>
      </div>
    </td>

    <td
      class="py-5 border-b-2 border-grey-dk cursor-pointer w-1/12"
      @click="deleteRow(lineItem.id)"
    >
      <div class="flex justify-center">
        <VinXIcon />
      </div>
    </td>
  </tr>
</template>

<script>
import VinXIcon from '@/common/components/VinIcon/IconComponents/VinXIcon.vue'
import ServiceLocationsVehicleMakes from '@/components/Admin/ServiceLocations/ServiceLocationsMapPopover/ServiceLocationsVehicleMakes.vue'
export default {
  name: 'VinCSVTablesLine',
  components: {
    VinXIcon,
    ServiceLocationsVehicleMakes,
  },
  props: {
    lineItem: {
      type: Object,
      required: true,
      default: () => { },
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
      default: () => [],
    },
    extraCols: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data () {
    return {
      serviceLocation: this.lineItem,
    }
  },
  computed: {

    headersWithErrors () {
      return this.errors.map(err => err.header)
    },
    headerErrors () {
      let headerErrors = {}
      this.errors.forEach(error => {
        if (headerErrors[error.header]) {
          headerErrors[error.header].push(error)
        } else {
          headerErrors[error.header] = [error]
        }
      })
      return headerErrors
    },

  },


  methods: {
    deleteRow (header) {
      this.$emit('delete-row', header)
    },

    valueChanged (value) {
      const localItem = Object.assign({}, this.lineItem)
      localItem[value.header] = value.value
      this.$emit('value-changed', localItem)
    },
  },
}
</script>

<style>
td {
  padding: 2.5rem 0;
}

.error-message {
  margin-left: 1rem;
}

.extraCol {
  width: 250px;
}
</style>
