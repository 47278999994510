<template>
  <div
    :class="`w-${size} h-${size} rounded-full spinner`"
    :style="spinnerStyle"
  />
</template>

<script>
export default {
  name: 'VinLoading',
  props: {
    size: {
      type: String,
      default: '12',
    },
    border: {
      type: String,
      default: '3px solid rgba(255, 255, 255, .25)',
    },
    borderTopColor: {
      type: String,
      default: 'rgba(0, 0, 0, .5)',
    },
  },
  computed: {
    spinnerStyle () {
      return {
        border: this.border,
        borderTopColor: this.borderTopColor,
      }
    },
  },
}
</script>

<style scoped>
.spinner {
  /* border: 3px solid rgba(255, 255, 255, .25);
  border-top-color: rgba(0, 0, 0, .5); */
  animation: rotation 0.8s ease infinite;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
