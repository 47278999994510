<template>
  <div>
    <div class="container px-container relative">
      <div class="flex absolute z-10 top-0 right-0 pr-container ">
        <VinButton
          vid="add-sl-btn"
          :color="!showCreateLocation ? 'cta-lt' : 'grey'"
          :text="$t('sl_add')"
          :class="!showCreateLocation ? '' : 'hover:cursor-not-allowed'"
          class="mt-4 px-16 text-base lg:font-normal xl:font-bold tracking-normal rounded-lg"
          @click="!showCreateLocation ?
            handleAdd() :
            $emit('disabled')"
        />
      </div>
    </div>
    <div class="map-wrapper relative">
      <div class="relative">
        <div class="flex absolute z-10 top-0 right-0 bottom-0 h-full">
          <ServiceLocationsCreate
            :show="showCreateLocation"
            :show-address-search="showAddressSearch"
            :errors="newSL.errors"
            :input-phone="newSL.phone"
            :input-name="newSL.name"
            :input-vehicle-makes="newSL.vehicleMakes"
            @handle-name="setName"
            @handle-phone="setPhone"
            @handle-address="setLocation"
            @handle-vehicle-makes="setVehicleMakes"
            @handle-cancel="closeCreateServiceLocation"
            @handle-save="saveServiceLocation"
          />
        </div>
      </div>
      <GmapMap
        id="serviceLocationsMap"
        ref="serviceLocationsMap"
        :center="{ lat: 45, lng: -20 }"
        :zoom="3.5"
        :options="mapStyle"
        class="vehicle-locations-map"
        style="width: 100%; height: 60vh;"
      >
        <GmapMarker
          v-if="computedTargetServiceLocation && computedTargetServiceLocation.lat && computedTargetServiceLocation.lon"
          :position="{
            lat: computedTargetServiceLocation.lat,
            lng: computedTargetServiceLocation.lon
          }"
        />
        <div v-else>
          <GmapMarker
            v-for="(sl, idx) in computedOrgServiceLocations"
            :key="idx"
            :position="{
              lat: sl.lat,
              lng: sl.lon
            }"
          />
        </div>
      </GmapMap>
    </div>
  </div>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue'
import get from 'lodash.get'

import colors from '@/assets/themes/colors'
import mapstyle from '@/common/utils/map-style'

import ServiceLocationsCreate from './ServiceLocationsMapPopover/ServiceLocationsCreate'
export default {
  name: 'ServiceLocationsMap',
  components: {
    ServiceLocationsCreate,
  },
  data () {
    return {
      // ServiceLocationsCreate data store
      showCreateLocation: false,
      showAddressSearch: true,
      newSL: {
        id: null,
        name: '',
        phone: '',
        address: '',
        vehicleMakes: [],
        lat: 0,
        lon: 0,
        isValid: false,
        errors: {},
      },
      // gmap
      map: null,
      overlays: [],
      fences: [],
      mapTypeId: 'roadmap',
      fenceStyles: {
        strokeColor: colors['primary-dk'],
        strokeWeight: '6',
        fillColor: colors['primary-dk'],
        fillOpacity: '0.2',
      },
    }
  },
  computed: {
    google: getGoogleMapsAPI,
    computedOrgServiceLocations () {
      const sl = this.$store.state.serviceMaintenanceModule.serviceLocations
      return sl.map(s => s.serviceLocation)
    },
    computedTargetServiceLocation () {
      return get(this.$store.state.serviceMaintenanceModule.targetServiceLocation, 'serviceLocation', null)
    },
    computedTargetServiceLocationMakes () {
      return get(this.$store.state.serviceMaintenanceModule.targetServiceLocation, 'vehicleMakes', null).map(m => m.name)
    },
    mapStyle () {
      // only style the default map
      const styles = this.mapTypeId === 'roadmap' ? mapstyle : { styles: [] }
      if (this.google) {
        return {
          ...styles,
          zoomControlOptions: {
            position: this.showCreateLocation ? google.maps.ControlPosition.LEFT_BOTTOM : google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          streetViewControlOptions: {
            position: this.showCreateLocation ? google.maps.ControlPosition.LEFT_BOTTOM : google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          fullscreenControlOptions: {
            position: this.showCreateLocation ? google.maps.ControlPosition.LEFT_TOP : google.maps.ControlPosition.RIGHT_TOP,
          },
        }
      }
      return styles
    },
  },
  watch: {
    // watch places, destroy dangling fence on delete
    computedOrgServiceLocations () {
      if (this.computedOrgServiceLocations.length > 0) {
        this.destroyFences()
        this.renderFences()
      } else {
        this.fences.forEach(idx => {
          if (idx.iw) idx.iw.close()
          idx.fence.setMap(null)
        })
        this.fences = []
      }
    },
    computedTargetServiceLocation () {
      if (this.computedTargetServiceLocation) {
        this.panToFence()
      } else {
        this.destroyFences()
        this.renderFences()
      }

      if (this.computedTargetServiceLocation) {
        if (this.computedTargetServiceLocation.id) {
          const el = document.getElementById('serviceLocationsMap')
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            })
          }
          this.handleAdd()
          this.showAddressSearch = false
        }

        this.$nextTick().then(() => {
          setTimeout(() => {
            this.newSL.id = this.computedTargetServiceLocation.id ? this.computedTargetServiceLocation.id : null
            this.newSL.lat = this.computedTargetServiceLocation.lat
            this.newSL.lon = this.computedTargetServiceLocation.lon
            this.newSL.name = this.computedTargetServiceLocation.name
            this.newSL.address = this.computedTargetServiceLocation.address
            this.newSL.phone = this.computedTargetServiceLocation.phone
            this.newSL.vehicleMakes = this.computedTargetServiceLocationMakes
          }, 150)
          this.panMap()
        })
      } else {
        this.newSL.id = null
        this.newSL.lat = 0
        this.newSL.lon = 0
        this.newSL.name = ''
        this.newSL.address = ''
        this.newSL.phone = ''
        this.newSL.vehicleMakes = []
        this.newSL.isValid = false
        this.newSL.errors = {}
      }
    },
  },
  mounted () {
    this.$refs.serviceLocationsMap.$mapPromise.then(map => {
      const _self = this
      // set the map type with listener
      map.addListener('maptypeid_changed', function () {
        const newMapType = map.getMapTypeId()
        _self.mapTypeId = newMapType
      })

      this.$nextTick().then(() => {
        if (this.computedOrgServiceLocations.length > 0) {
          this.renderFences()
          this.map = map
        } else {
          this.map = map
        }
      })
    }).catch(err => {
      throw err
    })
  },
  methods: {
    // gmap Autocomplete center map method
    panMap () {
      let bounds = new google.maps.LatLngBounds()
      let loc = new google.maps.LatLng(this.computedTargetServiceLocation.lat, this.computedTargetServiceLocation.lon)
      bounds.extend(loc)
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.025, bounds.getNorthEast().lng() + 0.025)
        var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.025, bounds.getNorthEast().lng() - 0.025)
        bounds.extend(extendPoint1)
        bounds.extend(extendPoint2)
      }
      this.$refs.serviceLocationsMap.fitBounds(bounds)
    },
    // location render & create methods start
    renderFences () {
      let bounds = new google.maps.LatLngBounds()
      this.computedOrgServiceLocations.forEach(p => {
        this.drawMarker(p)
        bounds.extend({
          lat: p.lat,
          lng: p.lon,
        })
        const offset = this.getCircleBounds(p)
        bounds.extend({
          lat: offset.Lat0,
          lng: offset.Lng0,
        })
        bounds.extend({
          lat: offset.Lat1,
          lng: offset.Lng1,
        })
      })
      this.$nextTick().then(() => {
        this.$refs.serviceLocationsMap.fitBounds(bounds)
      })
      this.$refs.serviceLocationsMap.panToBounds(bounds)
    },
    destroyFences () {
      const orgServiceLocations = this.computedOrgServiceLocations.map(sl => sl.serviceLocation)
      let filtered = this.fences.filter(o1 => orgServiceLocations.some(o2 => o1?.id !== o2?.id))
      filtered.forEach(idx => {
        idx.fence.setMap(null)
        if (idx.iw) idx.iw.close()
      })
    },
    getCircleBounds (sl) {
      const dLat = 500 / 6378137
      const dLng = 500 / (6378137 * Math.cos(Math.PI * sl.lat / 180))
      const Lat0 = sl.lat + dLat * 180 / Math.PI
      const Lng0 = sl.lon + dLng * 180 / Math.PI
      const Lat1 = sl.lat - dLat * 180 / Math.PI
      const Lng1 = sl.lon - dLng * 180 / Math.PI
      return { Lat0, Lng0, Lat1, Lng1 }
    },
    drawMarker (sl) {
      const contentString =
        `<div id="content">
            <h1>${sl.name}</h1>
            <p>${sl.address}</p>
            <p>${sl.phone}</p>
        </div>`
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      })
      let marker = new this.google.maps.Marker({
        position: {
          lat: sl.lat,
          lng: sl.lon,
        },
        ...this.fenceStyles,
      })
      this.$refs.serviceLocationsMap.$mapPromise.then((map) => {
        marker.setMap(map)
        marker.addListener('click', () => {
          this.fences.forEach(idx => {
            if (idx.iw) idx.iw.close()
          })
          infowindow.open({
            anchor: marker,
            map,
          })
        })
        this.fences.push({
          fence: marker,
          slId: sl.id,
          iw: infowindow,
        })
      })
    },
    panToFence () {
      let bounds = new google.maps.LatLngBounds()
      const p = this.computedTargetServiceLocation
      bounds.extend({
        lat: p.lat,
        lng: p.lon,
      })
      const offset = this.getCircleBounds(p)
      bounds.extend({
        lat: offset.Lat0,
        lng: offset.Lng0,
      })
      bounds.extend({
        lat: offset.Lat1,
        lng: offset.Lng1,
      })
      this.$nextTick().then(() => {
        this.$refs.serviceLocationsMap.fitBounds(bounds)
      })
      this.$refs.serviceLocationsMap.panToBounds(bounds)
      this.fences.forEach(idx => {
        if (idx.slId === p.id) {
          this.$refs.serviceLocationsMap.$mapPromise.then((map) => {
            idx.iw.open({
              anchor: idx.fence,
              map,
            })
          })
        }
      })
    },
    // ServiceLocationsCreate methods start
    clearError (field) {
      this.$set(this.newSL.errors, field, null)
    },
    setName (name) {
      this.clearError('slName')
      this.newSL.name = name
    },
    setPhone (phone) {
      this.clearError('slPhone')
      this.newSL.phone = phone
    },
    setLocation (payload) {
      this.clearError('slLocation')
      this.newSL.address = payload.address
      this.newSL.lat = payload.lat
      this.newSL.lon = payload.lon
    },
    setVehicleMakes (vehicleMakes) {
      if (vehicleMakes) {
        this.newSL.vehicleMakes = vehicleMakes
      } else {
        this.newSL.vehicleMakes = []
      }
    },
    handleAdd () {
      this.showAddressSearch = true
      this.showCreateLocation = true
      this.$refs.serviceLocationsMap.$mapPromise.then((map) => {
        this.fences.forEach(idx => {
          idx.fence.setMap(null)
        })
        this.map = map
      })
    },
    validate () {
      let valid = true
      if (this.newSL.name === '') {
        valid = false
        this.$set(this.newSL.errors, 'slName', this.$t('name_is_required'))
      }
      if (this.newSL.name.length > 40) {
        valid = false
        this.$set(this.newSL.errors, 'slName', this.$t('name_min_length', { length: 40 }))
      }
      if (this.newSL.phone === '') {
        valid = false
        this.$set(this.newSL.errors, 'slPhone', this.$t('phone_is_required'))
      }
      if (this.newSL.lat === 0 || this.newSL.lon === 0) {
        valid = false
        this.$set(this.newSL.errors, 'latLng', this.$t('place_point_required'))
      }
      if (valid) {
        this.newSL.errors = {}
      }
      this.newSL.isValid = valid
    },
    saveServiceLocation () {
      this.validate()
      if (this.newSL.isValid) {
        const vehicleMakes = Array.isArray(this.newSL.vehicleMakes) ? this.newSL.vehicleMakes : this.newSL.vehicleMakes.split(',')

        let payload = {
          name: this.newSL.name,
          address: this.newSL.address,
          lat: this.newSL.lat,
          lon: this.newSL.lon,
          phone: this.newSL.phone,
          orgId: this.$store.state.authModule.activeOrg.id,
          vehicleMakes: vehicleMakes,
        }
        if (this.newSL.id == null) {
          this.$store.dispatch('CREATE_SERVICE_LOCATION_PROMISE', payload)
        } else {
          payload.id = this.newSL.id
          this.$store.dispatch('UPDATE_SERVICE_LOCATION_PROMISE', payload)
        }
        this.closeCreateServiceLocation()
      }
    },
    closeCreateServiceLocation () {
      this.showCreateLocation = false
      if (this.computedOrgServiceLocations.length > 0) {
        this.renderFences()
      }
      this.setName('')
      this.setPhone('')
      this.setVehicleMakes(null)
      this.newSl = {
        id: null,
        name: '',
        phone: '',
        address: '',
        vehicleMakes: [],
        lat: 0,
        lon: 0,
        isValid: false,
        errors: {},
      }
      this.$store.commit('SET_TARGET_SERVICE_LOCATION', {})
    },
  },
}
</script>
