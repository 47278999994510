<template>
  <CookieLaw
    theme="ald-theme"
    position="top"
    transition-name="slideFromTop"
  >
    <div
      slot-scope="props"
      class="text-white"
    >
      <div
        slot="message"
        class="text-center text-lg flex flex-wrap items-center justify-center mx-auto pt-0"
      >
        <div class="w-1 rounded bg-primary-dk self-stretch" />
        <div
          style="max-width: 896px;"
          class="px-12"
        >
          <p>
            {{ $t('cookie_banner_explainer_pt1') }}
            <a
              href="/privacy-policy"
              class="text-cta-lt hover:underline"
              target="_window"
            >
              {{ $t('cookie_banner_data_policy_cta') }}
            </a>
            {{ $t('cookie_banner_explainer_pt2') }}
            <a
              href="/cookies"
              class="text-cta-lt hover:underline"
              target="_window"
            >
              {{ $t('cookie_banner_cookie_policy_cta') }}
            </a>
          </p>
        </div>
        <VinButton
          vid="cookie-banner-btn"
          :text="$t('i_accept')"
          color="danger-lt"
          class="px-8 mx-auto my-4 md:my-0 text-lg"
          @click="props.accept"
        />
      </div>
    </div>
  </CookieLaw>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
  name: 'CookieBanner',
  components: {
    CookieLaw,
  },
}
</script>

<style>
.Cookie--ald-theme {
  min-height: 216px;
  box-shadow: 0 8px 15px 10px rgba(0, 0, 0, 0.35);
  @apply bg-copy justify-center !important;
}
.cookie-bar {
  width: 242px;
  height: 4px;
  border-radius: 3px;
  @apply bg-primary-lt;
}
.Cookie__content {
  width: 100%;
}
.Cookie--ald-theme .Cookie__button {
}
.Cookie--ald-theme div.Cookie__button:hover {
}
</style>
