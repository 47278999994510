<template>
  <div>
    <div class="grid grid-rows-1 grid-cols-3">
      <div class="col-span-2">
        <VinTooltip
          id="best-vehicle-tip"
          placement="right"
          trigger="hover"
          content-padding="p-0"
          header-padding="p-0"
          class="leading-none text-grey-dk transition-2s"
        >
          <template
            #content
          >
            <div class="p-4 leading-tight text-center cursor-default">
              <span class="text-xs text-grey-dk font-semithin leading-none">
                {{ $t('click_to_view_vehicle') }}
              </span>
            </div>
          </template>
          <div
            :class="vehicleId ? 'hover:cursor-pointer hover:underline' : ''"
            @click="navToDriver"
          >
            <VinTitle
              class="font-bold text-lg text-copy leading-none mr-auto mb-3"
              style="paddingTop:3px;"
              :text="$t(text)"
            />
            <div
              class="font-normal text-grey-dk text-lg leading-none mr-auto"
              style="paddingTop:1px;"
            >
              {{ nameString }}
            </div>
          </div>
        </VinTooltip>
      </div>
      <div class="col-span-1 text-right">
        <p class="event-stat font-semithin text-copy mb-1 leading-none w-full">
          {{ formattedStat.numeral }}
        </p>
        <div class="pt-px">
          <p class="text-copy text-xs tracking-relaxed leading-none">
            {{ formattedStat.unit }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'
export default {
  name: 'VinSmallWidgetDriverEvents',
  props: {
    text: {
      type: String,
      default: 'Best Driver',
    },
    driver: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    nameString () {
      // handle empty string and unknown names
      let name = get(this.driver, 'driver', '')
      if (name === 'Unknown Driver') {
        name = this.$t('unknown_driver')
      }
      return name ? name : '--'
    },
    vehicleString () {
      // handle empty string make & model
      const vehicleString = `${get(this.driver, 'make', '')} ${get(this.driver, 'model', '')}`
      return vehicleString === ' ' ? '--' : vehicleString
    },
    formattedStat () {
      return formatNumber(get(this.driver, 'eventsPer100', 0), {
        type: 'event',
        unit: 'per100',
      })
    },
    vehicleId () {
      return get(this.driver, 'vehicleId')
    },
    orgId () {
      return get(this.$store.state.authModule, 'activeOrg.id')
    },
  },
  methods: {
    navToDriver () {
      if (this.orgId && this.vehicleId) {
        this.$router.push({
          name: 'VehicleDetails',
          params: {
            vehicleId: this.vehicleId,
            orgId: this.orgId,
          },
        })
      }
    },
  },
}
</script>
<style scoped>
.event-stat {
  font-size: 34px;
}
</style>
