<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="a"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#17D4AA"
        />
        <stop
          offset="100%"
          stop-color="#019F7C"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#a)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.9"
      >
        <path d="M15.05 36.499V13.887a2.707 2.707 0 0 1 2.707-2.71H31.29a2.707 2.707 0 0 1 2.707 2.71v29.807M12.344 43.694h32.48" />
        <path d="M19.91 16.597h9.227v6.774H19.91zM42.117 24.726v9.484a4.062 4.062 0 0 1-4.06 4.064 4.062 4.062 0 0 1-4.06-4.064M42.117 20.661v-4.064l-2.707-4.065M39.41 20.661h5.414" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconFuelCost',
}
</script>
