<template>
  <div>
    <VinCSVTable
      :headers="headers"
      :table-data="parsedCSV"
      :errors="errors"
      :extra-cols="extraCols"
      @delete-row="deleteRow($event)"
      @value-changed="tableDataValueChanged($event)"
    />

    <div class="w-full flex justify-end mt-5">
      <VinButton
        vid="cancel-service-locations-upload-btn"
        :text="$t('Cancel')"
        color="cta-lt"
        invert
        class="w-40 font-bold mt-5 mx-3"
        @click="handleCancelTableData()"
      />
      <VinButton
        vid="cancel-service-locations-upload-btn"
        :text="$t('Save')"
        color="cta-lt"
        class="w-40 font-bold mt-5 mx-3"
        @click="handleSaveTableData()"
      />
    </div>
  </div>
</template>

<script>
import csv from 'csvtojson'

import VinCSVTable from './VinCSVTable.vue'

export default {
  name: 'VinCSVParser',
  components: {
    VinCSVTable,
  },
  props: {
    parserOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    csvString: {
      type: String,
      required: true,
      default: '',
    },
    errors: {
      type: Array,
      required: true,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    extraCols: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data () {
    return {
      parsedCSV: [],
    }
  },
  watch: {
    csvString: {
      immediate: true,
      handler (v) {
        if (v) {
          this.parseCSV(v)
        }
      },
    },
    parsedCSV (newVal) {
      this.$store.commit('UPDATE_PARSED_CSV', newVal)
      if (newVal.length === 0) {
        this.$store.commit('CLEAR_PARSED_CSV', newVal)
        this.handleCancelTableData()
      }
    },
  },
  methods: {
    tableDataValueChanged (csv) {
      this.parsedCSV = csv
    },
    handleSaveTableData () {
      this.$emit('save-table-data', this.parsedCSV)
    },
    handleCancelTableData () {
      this.$emit('cancel-table-data')
    },
    deleteRow (locationId) {
      let updatedTableData = this.parsedCSV.filter(line => line.id !== locationId)
      this.parsedCSV = updatedTableData
      if (this.parsedCSV.length === 0) {
        this.handleCancelTableData()
      }
    },
    parseCSV (csvFile) {
      csv(this.parserOptions)
        .fromString(csvFile)
        .then((parsed) => {
          parsed.forEach(el => {
            let d = new Date().getTime()
            if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
              d += performance.now()
            }
            const newGuid = 'xx-4xxx-yxxxx'.replace(/[xy]/g, function (c) {
              const r = (d + Math.random() * 16) % 16 | 0
              d = Math.floor(d / 16)
              return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
            })
            el.id = newGuid
          })
          this.parsedCSV = parsed
        })
    },
  },
}
</script>

<style>
</style>
