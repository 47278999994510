const KML_TO_MPGUS = 2.35215
const L100KM_TO_MPGUS = 235.215
const MPGUK_TO_MPGUS = 0.832674

export const localizeDistance = (val, unit, convertUnit) => {
  if (unit === 'meters' || unit === 'meter' || unit === 'm') {
    const x = convertUnit === 'kilometers'
      ? 0.001
      : 0.000621371192237

    return val * x
  }

  if (unit === 'kilometers' || unit === 'kilometer' || unit === 'km') {
    return convertUnit === 'kilometers'
      ? val
      : val * 0.6213712
  }
}

//TODO Make a function NormalizeFuelEcon to convert from a given unit to MPG (US)
export const normalizeFuelEcon = (val, unit) => {
  let convertedDistance = 0
  switch (unit) {
    case 'km/L':
      //convert
      convertedDistance = val * KML_TO_MPGUS
      return convertedDistance
    case 'L/100km':
      //convert
      convertedDistance = L100KM_TO_MPGUS / val
      return convertedDistance
    case 'MPG (UK)':
      //convert
      convertedDistance = val * MPGUK_TO_MPGUS
      return convertedDistance
    default:
      //assume MPG (US)
      return val
  }
}

export const localizeFuel = (val, unit, convertUnit) => {
  if (unit === 'econ') {
    switch (convertUnit) {
      case 'L/100km':
        return val > 0
          ? L100KM_TO_MPGUS / val
          : 0

      case 'km/L':
        return val / KML_TO_MPGUS

      case 'MPG (UK)':
        return val / MPGUK_TO_MPGUS

      default:
        // assume MPG
        return val
    }
  }

  if (unit === 'consumed') {
    switch (convertUnit) {
      case 'G (US)':
        return val * 0.26417

      case 'G (UK)':
        return val * 0.21997

      default:
        // assume L
        return val
    }
  }
}

export const localizeEvent = (val, unit, convertUnit) => {
  if (unit === 'per100') {
    return convertUnit === 'kilometers'
      ? val * 1000
      : val / 0.000621371192237
  }

  // unit is custom
  return val
}
