<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-high-daily-icon"
        x1="91.704%"
        x2="5.368%"
        y1="1.917%"
        y2="70.231%"
      >
        <stop
          offset="0%"
          :stop-color="colors['icon-gradient-0']"
        />
        <stop
          offset="100%"
          :stop-color="colors['icon-gradient-1']"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-high-daily-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M40.158 42.737H15.842a4.053 4.053 0 0 1-4.053-4.053V18.421a4.053 4.053 0 0 1 4.053-4.053 1.35 1.35 0 0 0 1.351-1.35v-1.351a1.35 1.35 0 1 1 2.702 0v1.35a4.053 4.053 0 0 1-4.053 4.053 1.35 1.35 0 0 0-1.35 1.351v20.263c0 .746.604 1.351 1.35 1.351h24.316a1.35 1.35 0 0 0 1.35-1.35V15.718a1.35 1.35 0 0 1 2.703 0v22.965a4.053 4.053 0 0 1-4.053 4.053zm-1.351-29.72v-1.35a1.35 1.35 0 1 0-2.702 0v1.35a1.35 1.35 0 0 1-1.35 1.351H23.946a1.35 1.35 0 1 0 0 2.702h10.807a4.053 4.053 0 0 0 4.053-4.052zm-18.912 9.457a1.35 1.35 0 1 0 0 2.701 1.35 1.35 0 0 0 0-2.701zm5.403 0a1.35 1.35 0 1 0 0 2.701 1.35 1.35 0 0 0 0-2.701zm5.404 0a1.35 1.35 0 1 0 0 2.701 1.35 1.35 0 0 0 0-2.701zm5.403 0a1.35 1.35 0 1 0 0 2.701 1.35 1.35 0 0 0 0-2.701zm-16.21 5.403a1.35 1.35 0 1 0 0 2.702 1.35 1.35 0 0 0 0-2.702zm10.807 0a1.35 1.35 0 1 0 0 2.702 1.35 1.35 0 0 0 0-2.702zm-5.404 0a1.35 1.35 0 1 0 0 2.702 1.35 1.35 0 0 0 0-2.702zm10.807 0a1.35 1.35 0 1 0 0 2.702 1.35 1.35 0 0 0 0-2.702zm-16.21 5.404a1.35 1.35 0 1 0 0 2.701 1.35 1.35 0 0 0 0-2.701zm5.403 0a1.35 1.35 0 1 0 0 2.701 1.35 1.35 0 0 0 0-2.701zm5.404 0a1.35 1.35 0 1 0 0 2.701 1.35 1.35 0 0 0 0-2.701z"
      />
      <path
        fill="#FFF"
        stroke="#1298D8"
        stroke-width="2"
        d="M27.7 37.518a2.474 2.474 0 0 1-3.104-.631 2.48 2.48 0 0 1 .349-3.449l5.304-4.243a5.42 5.42 0 0 1 7.227.397l.62.619a.474.474 0 0 0 .646.021l8.238-7.06a2.474 2.474 0 0 1 3.217 3.76l-8.24 7.06a5.421 5.421 0 0 1-7.354-.289l-.616-.616a.474.474 0 0 0-.63-.032l-5.58 4.463h-.078z"
      />
    </g>
  </svg>
</template>

<script>
import colors from '@/assets/themes/colors'
export default {
  name: 'IconHighDailyHours',
  computed: {
    colors () {
      return colors
    },
  },
}
</script>
