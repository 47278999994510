<template>
  <div>
    <transition name="slide-fade">
      <div
        v-if="message"
        class="card flex items-center text-xs gap-x-2 px-3"
      >
        <div class="rounded-full h-2 w-2 bg-success-lt" />
        <p>
          {{ $t('new_data_received_for_vehicle') }}
          <router-link
            :to="vehicleLink"
            class="text-cta-lt hover:underline hover:text-cta-dk hover:cursor-pointer"
            vinli="live-stream-vehicle-info-window-link"
          >
            {{ vinOrReg }}
          </router-link>
          {{ $t('at') }} {{ timestamp }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import get from 'lodash.get'

import { fmtTimeToSecond } from '@/common/utils/date-formatters'
export default {
  name: 'DashboardMapLiveMessage',
  computed: {
    message () {
      return this.$store.state.liveTrackingModule?.lastIncomingMessage
    },
    vehicle () {
      return this.$store.getters.getVehicleById(this.message?.vehicleId)
    },
    vinOrReg () {
      return this.vehicle?.registration || (this.vehicle?.vin ?? '')
    },
    orgId () {
      return get(this.$store.state.authModule, 'activeOrg.id')
    },
    timestamp () {
      const ts = this.message.timestamp
      if (ts) {
        return fmtTimeToSecond(this.$store, ts)
      }
      return '--'
    },
    vehicleLink () {
      return {
        name: 'VehicleDetails',
        params: {
          orgId: this.orgId,
          vehicleId: this.message?.vehicleId,
        },
      }
    },
  },
}
</script>


<style scoped>

.slide-fade-enter-active {
  transition: all .2s;
}
.slide-fade-leave-active {
  transition: all .25s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
