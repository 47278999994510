<template>
  <div class="sidebar bg-primary-dkr px-12">
    <div class="px-4 mt-14 mb-4 flex items-center text-white fixed-width">
      <h1 class="text-4xl font-thin tracking-normal leading-normal">
        Widgets
      </h1>
      <h4 class="font-normal ml-4 self-end leading-xl">
        {{ $t('widget_total_count', { count: scopedWidgetsList.length} ) }}
      </h4>
    </div>
    <div class="flex justify-center inner-addon right-addon mb-4 fixed-width">
      <search-icon class="searchicon text-white" />
      <VinInput
        v-model="keyword"
        :placeholder="$t('widget_search_input', { count: scopedWidgetsList.length} )"
        class="search border-white text-white bg-primary-dkr mb-4 mx-4 w-full py-2 px-4 rounded-xl"
      />
    </div>
    <div class="fixed-width">
      <draggable
        v-model="scopedWidgetsList"
        :animation="150"
        :fallback-on-body="true"
        :sort="false"
        :group="{
          name: 'widgets',
          pull: 'clone',
          put: false
        }"
        ghost-class="list-ghost"
      >
        <DashEditWidgetListItem
          v-for="(widget, i) in scopedWidgetsList"
          :key="`${i}-${widget}`"
          :widget="widget"
          class="mb-6 w-full h-full"
        />
      </draggable>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
import { SearchIcon } from 'vue-feather-icons'
import draggable from 'vuedraggable'

import { slugMixinMap } from '@/common/widgets/compose-widget'

import DashEditWidgetListItem from './DashEditWidgetListItem/DashEditWidgetListItem'

export default {
  name: 'DashEditSidebar',
  components: {
    'draggable': draggable,
    'DashEditWidgetListItem': DashEditWidgetListItem,
    'search-icon': SearchIcon,
  },
  data () {
    return {
      scopedWidgetsList: [],
      keyword: '',
    }
  },
  computed: {
    packWidgets () {
      const widgets = get(this.$store.state.authModule.orgPack, 'widgets', [])
      const dashWidgets = this.$store.getters.widgetFilter(widgets, 'dashboard-widget')
      const list = []
      dashWidgets.forEach(w => {
        const requiredMixin = slugMixinMap.get(w.componentName)
        if (this.$store.getters.hasMixin(requiredMixin)) {
          list.push(w)
        }
      })
      return list
    },
    availableWidgets () {
      return this.packWidgets.map(widget => widget.componentName)
    },
  },
  watch: {
    availableWidgets () {
      this.scopedWidgetsList = this.availableWidgets
    },
    keyword () {
      if (this.keyword === '') {
        this.scopedWidgetsList = this.availableWidgets
        return
      }

      let searchArr = []
      this.availableWidgets.forEach(widg => {
        let name = widg
        if (this.packWidgets.length === 0) {
          name = this.$root.$options.components[widg].extendOptions.displayName
        } else {
          let match = this.packWidgets.find(w => w.componentName === widg)
          name = match.name
        }
        searchArr.push({
          name: name,
          widget: widg,
        })
      })

      this.$search(this.keyword, searchArr, { keys: ['name'] }).then(results => {
        this.scopedWidgetsList = []
        results.forEach(r => {
          this.scopedWidgetsList.push(r.widget)
        })
      })
    },
  },
  created () {
    this.scopedWidgetsList = this.availableWidgets
  },
}
</script>
<style scoped>
.list-ghost {
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: dashed;
  height: 100%;
  background-color: rgba(34, 205, 145, 0.3);
  border-color: rgba(34, 205, 145, 0.5);
}
.search {
  color: white !important;
}
.search::placeholder {
  color: white !important;
}
.sidebar {
  box-shadow: inset 0 9px 11px 0 rgba(0, 0, 0, 0.25);
}

.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .searchicon {
  position: absolute;
  pointer-events: none;
}

/* align icon */
.right-addon .searchicon {
  right: 30px;
  top: 7px;
}
.fixed-width {
  width: 351px;
}
</style>
