<template>
  <div class="flex flex-col mt-10">
    <span class="text-6xl font-semiheavy tracking-relaxed leading-none mb-0 text-copy">
      {{ formattedStat.numeral }}
    </span>
    <VinTooltip
      id="best-vehicle-tip"
      placement="right"
      trigger="hover"
      content-padding="p-0"
      header-padding="p-0"
      class="leading-none text-grey-dk transition-2s"
    >
      <template
        #content
      >
        <div class="p-4 leading-tight text-center cursor-default">
          <span class="text-xs text-grey-dk font-semithin leading-none">
            {{ $t('click_to_view_vehicle') }}
          </span>
        </div>
      </template>
      <div
        class="flex flex-col mr-auto"
        :class="vehicleId ? 'hover:cursor-pointer hover:underline' : ''"
        @click="navToVehicle(vehicleId)"
      >
        <span class="font-bold text-copy text-lg leading-none mt-4">
          {{ nameString }}
        </span>
        <span class="font-semithin text-grey-dk text-md leading-none mt-2">
          {{ vehicleString }}
        </span>
      </div>
    </VinTooltip>
  </div>
</template>

<script>
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'
export default {
  name: 'VinWidgetDriverEvents',
  props: {
    driver: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    nameString () {
      // handle empty string names
      const name = get(this.driver, 'driver', '')
      return name ? name : '--'
    },
    vehicleString () {
      // handle empty string make & model
      const vehicleString = `${get(this.driver, 'make', '')} ${get(this.driver, 'model', '')}`
      return vehicleString === ' ' ? '--' : vehicleString
    },
    formattedStat () {
      return formatNumber(get(this.driver, 'eventsPer100', 0), {
        type: 'event',
        unit: 'per100',
      })
    },
    vehicleId () {
      return get(this.driver, 'vehicleId')
    },
    orgId () {
      return get(this.$store.state.authModule, 'activeOrg.id')
    },
  },
  methods: {
    navToVehicle () {
      if (this.orgId && this.vehicleId) {
        this.$router.push({
          name: 'VehicleDetails',
          params: {
            vehicleId: this.vehicleId,
            orgId: this.orgId,
          },
        })
      }
    },
  },
}
</script>
<style scoped>
.event-stat {
  font-size: 34px;
}
</style>
