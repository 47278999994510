<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="a"
        x1="50%"
        x2="50%"
        y1="100%"
        y2="0%"
      >
        <stop
          offset="0%"
          stop-color="#876ED5"
        />
        <stop
          offset="100%"
          stop-color="#A08BE3"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="nonzero"
    >
      <rect
        width="56"
        height="56"
        fill="url(#a)"
        rx="10"
      />
      <g fill="#FFF">
        <path d="M37.627 12.714H34.81v-.563c0-.623-.505-1.127-1.127-1.127h-3.945v-.564a1.127 1.127 0 0 0-2.254 0v.564H23.54c-.623 0-1.127.504-1.127 1.127v.563h-2.818a3.944 3.944 0 0 0-3.944 3.945v1.29a3.945 3.945 0 0 0-2.818 3.781v18.596a3.945 3.945 0 0 0 3.945 3.944h16.341a3.945 3.945 0 0 0 3.781-2.817h.727a3.944 3.944 0 0 0 3.945-3.945v-20.85a3.944 3.944 0 0 0-3.945-3.944zm-12.96.564h7.889v1.127h-7.89v-1.127zM34.81 40.326a1.69 1.69 0 0 1-1.69 1.69H16.777a1.69 1.69 0 0 1-1.69-1.69V21.73c0-.933.756-1.69 1.69-1.69h16.341c.934 0 1.69.757 1.69 1.69v18.596zm4.508-2.818a1.69 1.69 0 0 1-1.69 1.69h-.564V21.73a3.944 3.944 0 0 0-3.945-3.944H17.905v-1.127c0-.934.757-1.69 1.69-1.69h2.818v.563c0 .622.504 1.127 1.127 1.127h10.143c.622 0 1.127-.505 1.127-1.127v-.564h2.817c.934 0 1.69.757 1.69 1.69v20.85z" />
        <path d="M31.429 22.294h-12.96a1.127 1.127 0 0 0 0 2.254h12.96a1.127 1.127 0 0 0 0-2.254zM31.429 26.802h-12.96a1.127 1.127 0 0 0 0 2.254h12.96a1.127 1.127 0 0 0 0-2.254zM25.23 31.31h-6.762a1.127 1.127 0 0 0 0 2.254h6.762a1.127 1.127 0 0 0 0-2.254z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconJourneyDetails',
}
</script>
