export default (vehicle, locations = []) => {
  const locs = [...locations]
  const timestamps = locs.map(l => l.timestamp)
  timestamps.push(vehicle?.status?.location)
  timestamps.push(vehicle?.status?.startup)
  timestamps.push(vehicle?.status?.shutdown)
  timestamps.push(vehicle?.status?.phoneHome)
  const known = timestamps.filter(ts => ts).sort()
  return known.at(-1)
}
