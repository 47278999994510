<template>
  <div class="grav-container rounded-full">
    <div class="top rounded-full border-2 border-white">
      <div
        class="rounded-full"
        :class="computedClass"
        :style="{
          backgroundImage: `url(${computedImage})`,
          backgroundSize: 'cover',
          width: `${size}px`,
          height: `${size}px`
        }"
      />
    </div>
    <div class="bottom border-2 border-white rounded-full">
      <span class="centered text-white font-semibold pb-px tracking-wide">{{ computedInitials }}</span>
      <div
        :class="computedInitials === '?' ? 'bg-grey-dk' : 'bg-copy'"
        class="rounded-full w-full h-full"
      />
    </div>
  </div>
</template>

<script>
import SparkMD5 from 'spark-md5'

export default {
  name: 'VinGravatar',
  props: {
    email: {
      type: String,
      required: () => {
        if (this.name) {
          return false
        } else {
          return true
        }
      },
      default: '',
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    size: {
      type: Number,
      default: null,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    customIcon: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedEmail () {
      let hash = SparkMD5.hash(this.email)
      return hash
    },
    computedImage () {
      if (this.customIcon) {
        return this.customIcon
      }
      let src = `https://www.gravatar.com/avatar/${this.computedEmail}?d=blank`
      src = this.size ? src + `&s=${this.size}` : src
      return src
    },
    computedClass () {
      if (this.circle) {
        return 'rounded-full text-white'
      }
      return null
    },
    anonymize () {
      return (this.name === '*** ***' || this.name === ' ' || this.name === '')
    },
    computedInitials () {
      if (this.anonymize) {
        return '?'
      }

      // trim the string.  never know what people will do.
      const name = this.name.trim()

      let parts = name.split(' ') || []
      let initials

      if (parts.length !== 0) {
        if (parts.length === 1) {
          return parts[0][0]
        }

        let first = parts[0]
        let last = parts.slice(-1)[0]

        initials = first[0] + last[0]
      }

      return initials
    },
  },
}
</script>
<style>
.grav-container {
  position: relative;
  text-align: center;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top {
  z-index: 3;
  position: relative;
}
.bottom {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
