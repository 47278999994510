<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-fleet-icon"
        x1="91.704%"
        x2="11.892%"
        y1="1.917%"
        y2="65.069%"
      >
        <stop
          offset="0%"
          stop-color="#A200FF"
        />
        <stop
          offset="100%"
          stop-color="#C500FF"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-fleet-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <path
        fill="#FFF"
        fill-rule="nonzero"
        stroke="#FFF"
        stroke-width=".5"
        d="M43.339 9.173c-2.919 0-5.293 2.343-5.293 5.223 0 1.77.898 3.333 2.265 4.279l-5.405 13.393a5.332 5.332 0 0 0-3.487.127l-4.976-4.672c.06-.317.096-.643.096-.976 0-2.88-2.374-5.224-5.292-5.224-2.92 0-5.294 2.343-5.294 5.224 0 1.554.695 2.947 1.79 3.905l-3.078 6.008a5.333 5.333 0 0 0-2.002-.39c-2.92 0-5.295 2.343-5.295 5.223s2.375 5.223 5.293 5.223c2.919 0 5.293-2.342 5.293-5.223 0-1.466-.616-2.79-1.605-3.74l3.12-6.091a5.31 5.31 0 0 0 6.15-1.98l4.059 3.81a5.17 5.17 0 0 0-1.633 3.766c0 2.88 2.374 5.223 5.293 5.223 2.918 0 5.293-2.343 5.293-5.223a5.19 5.19 0 0 0-1.95-4.046l5.46-13.532c.385.088.784.14 1.196.14 2.918 0 5.293-2.344 5.293-5.224 0-2.88-2.373-5.223-5.291-5.223zM12.66 44.539c-1.813 0-3.289-1.456-3.289-3.245 0-1.79 1.476-3.246 3.29-3.246 1.813 0 3.29 1.456 3.29 3.246s-1.477 3.245-3.29 3.245zm8.584-14.746c-1.814 0-3.29-1.456-3.29-3.246s1.476-3.246 3.29-3.246 3.289 1.457 3.289 3.246c0 1.79-1.476 3.246-3.289 3.246zM33.34 40.305c-1.814 0-3.29-1.457-3.29-3.246 0-1.79 1.476-3.247 3.29-3.247s3.29 1.456 3.29 3.247c0 1.79-1.477 3.246-3.29 3.246zm10-22.663c-1.814 0-3.29-1.456-3.29-3.246s1.476-3.246 3.29-3.246c1.813 0 3.289 1.457 3.289 3.246 0 1.79-1.476 3.246-3.29 3.246z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconFleet',
}
</script>
