import axios from 'axios'
import get from 'lodash.get'

import store from '../store'

axios.interceptors.response.use(response => {
  return response
}, error => {
  let request = get(error, 'response.request')
  let url = request ? request.responseURL : ''
  // don't use error handler for session checks
  if (url.indexOf('/api/v1/sessions') === -1) {
    store.dispatch('HANDLE_ERROR', error)
  }
  return Promise.reject(error)
})

export default axios
