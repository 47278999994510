import { format, formatDistanceToNow } from 'date-fns'

import { cleanDate, emptyDate, FALLBACK, fmtDateMed, fmtDateShort } from '../utils/date-formatters'

const mxDateTimeFull = {
  methods: {
    mxDateTimeFull (date, fallback = FALLBACK) {
      if (emptyDate(date)) {
        return fallback
      }
      let hrs = this.$store.state.localizationModule.time24hr ? 'H' : 'h'
      let ampm = this.$store.state.localizationModule.time24hr ? '' : 'a'
      let dayMonth = this.$store.state.localizationModule.dayFirst ? 'd MMMM' : 'MMMM do'

      return format(
        cleanDate(date),
        `${dayMonth}, yyyy ${hrs}:mm${ampm}`,
        {
          locale: this.$store.getters.localeSettings.dateLocale,
        },
      )
    },
  },
}

const mxDateTimeAbbrev = {
  methods: {
    mxDateTimeAbbrev (date, fallback = FALLBACK) {
      if (emptyDate(date)) {
        return fallback
      }
      let hrs = this.$store.state.localizationModule.time24hr ? 'H' : 'h'
      let ampm = this.$store.state.localizationModule.time24hr ? '' : 'a'
      let dayMonth = this.$store.state.localizationModule.dayFirst ? 'd MMM' : 'MMM do'

      return format(
        cleanDate(date),
        `${dayMonth}, yyyy ${hrs}:mm${ampm}`,
        {
          locale: this.$store.getters.localeSettings.dateLocale,
        },
      )
    },
  },
}

const mxDateTimeShort = {
  methods: {
    mxDateTimeShort (date, fallback = FALLBACK) {
      if (emptyDate(date)) {
        return fallback
      }
      let hrs = this.$store.state.localizationModule.time24hr ? 'H' : 'h'
      let ampm = this.$store.state.localizationModule.time24hr ? '' : 'a'
      let dayMonth = this.$store.state.localizationModule.dayFirst ? 'd/MM' : 'MM/d'

      return format(
        cleanDate(date),
        `${dayMonth}/yy ${hrs}:mm${ampm}`,
        {
          locale: this.$store.getters.localeSettings.dateLocale,
        },
      )
    },
  },
}

const mxTimestamp = {
  methods: {
    mxTimestamp (date, fallback = FALLBACK) {
      if (emptyDate(date)) {
        return fallback
      }
      let hrs = this.$store.state.localizationModule.time24hr ? 'H' : 'h'
      let ampm = this.$store.state.localizationModule.time24hr ? '' : 'aaaa'
      let dayMonth = this.$store.state.localizationModule.dayFirst ? 'd/MM' : 'MM/d'

      return format(
        cleanDate(date),
        `${dayMonth}/yyyy ${hrs}:mm:ss ${ampm}`,
        {
          locale: this.$store.getters.localeSettings.dateLocale,
        },
      )
    },
  },
}

const mxTime = {
  methods: {
    mxTime (date, fallback = FALLBACK) {
      if (emptyDate(date)) {
        return fallback
      }
      const hrs = this.$store.state.localizationModule.time24hr ? 'H' : 'h'
      const ampm = this.$store.state.localizationModule.time24hr ? '' : 'aaaa'

      return format(
        cleanDate(date),
        `${hrs}:mm${ampm}`,
        {
          locale: this.$store.getters.localeSettings.dateLocale,
        },
      )
    },
  },
}

const mxDate = {
  methods: {
    mxDate (date, fallback = FALLBACK) {
      if (emptyDate(date)) {
        return fallback
      }
      const dayMonth = this.$store.state.localizationModule.dayFirst ? 'd MMMM' : 'MMMM do'

      return format(
        cleanDate(date),
        `${dayMonth}, yyyy`,
        {
          locale: this.$store.getters.localeSettings.dateLocale,
        },
      )
    },
  },
}

const mxDateShort = {
  methods: {
    mxDateShort (date, fallback = FALLBACK) {
      return fmtDateShort(this.$store, date, fallback)
    },
  },
}

const mxDateMed = {
  methods: {
    mxDateMed (date, fallback = FALLBACK) {
      return fmtDateMed(this.$store, date, fallback)
    },
  },
}

const mxMonthFull = {
  methods: {
    mxMonthFull (date, fallback = FALLBACK) {
      if (emptyDate(date)) {
        return fallback
      }
      return format(
        cleanDate(date),
        `MMMM`,
        {
          locale: this.$store.getters.localeSettings.dateLocale,
        },
      )
    },
  },
}

const mxRelative = {
  methods: {
    mxRelative (date, fallback = FALLBACK) {
      if (emptyDate(date)) {
        return fallback
      }
      return formatDistanceToNow(
        cleanDate(date),
        {
          locale: this.$store.getters.localeSettings.dateLocale,
        },
      )
    },
  },
}

export {
  mxDate,
  mxDateMed,
  mxDateShort,
  mxDateTimeAbbrev,
  mxDateTimeFull,
  mxDateTimeShort,
  mxMonthFull,
  mxRelative,
  mxTime,
  mxTimestamp,
}
