<template>
  <div>
    <div class="flex justify-between items-center mr-12 ml-2 mb-2">
      <span class="w-1/2 text-grey-dk leading-normal tracking-wide uppercase">
        {{ $t('Map Settings') }}
      </span>
      <VinSwitch
        :vid="'dash-map-enabled-switch'"
        :value="mapEnabled"
        checked-text="Show"
        un-checked-text="Hide"
        @change="handleChange($event)"
      />
    </div>
    <div v-if="hasMap">
      <div
        class="card mb-4 mr-12 ml-2 h-64 px-0"
        :style="{ backgroundImage: 'url(' + require('@/assets/img/edit-map-on.png') + ')', backgroundSize: 'cover' }"
      />
    </div>
    <div v-if="!hasMap">
      <div
        class="card mb-4 mr-12 ml-2 h-64 px-0"
        :style="{ backgroundImage: 'url(' + require('@/assets/img/edit-map-off.png') + ')', backgroundSize: 'cover' }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashEditMapSettings',
  data () {
    return {
      mapEnabled: this.$store.state.dashboardModule.map,
    }
  },
  computed: {
    hasMap () {
      return this.$store.state.dashboardModule.map
    },
  },
  methods: {
    handleChange (state) {
      this.$store.commit('TOGGLE_DASH_MAP_SETTING', state)
    },
  },
}
</script>
