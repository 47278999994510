<template>
  <VinSmallWidgetTemplate
    :title="$t('Total Fleet Mileage')"
    :timestamp="generatedAt"
    icon="Journey"
    :fetching="isFetching"
    :stat="stat"
    :pack-has-report="packHasReport"
    :cta-title="$t('View Journeys Summary Report')"
    :cta-action="navToReport"
    @bust-cache="fetchData(true)"
  />
</template>

<script>
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'

import { composeWidget } from '../compose-widget'
export default {
  name: 'WidgetTotalFleetMileage',
  displayName: 'Total Fleet Mileage',
  icon: 'Journey',
  setup () {
    const widget = {
      slug: 'widget-total-distance',
    }
    const report = {
      slug: 'report-journey-summary',
      widget: 'ReportJourneysSummary',
    }

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    stat () {
      return formatNumber(get(this, 'results.totalDistance'), {
        type: 'distance',
        unit: 'm',
        abbrvAt: 5,
      })
    },
  },
}
</script>
