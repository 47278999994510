import get from 'lodash.get'
import Vue from 'vue'
const module = {
  state: {
    geofence: false,
    cluster: true,
    traffic: false,
    liveStream: true,
    mapCenter: {
      lat: 32.781921,
      lng: -96.790589,
    },
    selectedCluster: null,
    infoWindowId: null,
  },
  getters: {
    getStatusMapForCluster: (state, getters, rootState) => cluster => {
      const statusSettings = rootState.vehiclesModule.vehicleStatusSettings
      const markers = get(cluster, 'markers', [])
      let statusMap = {}

      markers.forEach(m => {
        const url = get(m, 'icon.url', '')
        const status = url.split('marker-')[1].split('.')[0]
        const color = statusSettings[status].color
        const val = get(statusMap, `${status}.count`, 0) + 1
        statusMap[status] = {
          count: val,
          color: color,
        }
      })


      return statusMap
    },
  },
  mutations: {
    TOGGLE_MAP_SETTING_GEOFENCE: (state, flag) => {
      state.geofence = flag
    },
    TOGGLE_MAP_SETTING_CLUSTER: (state, flag) => {
      state.cluster = flag
    },
    TOGGLE_MAP_SETTING_TRAFFIC: (state, flag) => {
      state.traffic = flag
    },
    TOGGLE_MAP_SETTING_LIVE_STREAM: (state, flag) => {
      state.liveStream = flag
    },
    SET_MAP_CENTER: (state, center) => {
      Vue.set(state, 'mapCenter', center)
    },
    SET_INFO_WINDOW_ID: (state, id) => {
      state.infoWindowId = id
    },
    SET_SELECTED_CLUSTER: (state, cluster) => {
      state.selectedCluster = cluster
    },
  },
}

export default module
