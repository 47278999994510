<template>
  <div class="py-4 px-6 flex flex-col items-center">
    <p class="text-copy text-sm">
      {{ $t('fleet_history_date_explainer') }}
    </p>
    <v-date-picker
      v-model="date"
      :locale="locale"
      :color="calColor"
      mode="dateTime"
      popover-direction="bottom"
      show-caps
      update-on-input
      :min-date="minDate"
      :max-date="new Date()"
      class="my-4 border border-border text-grey-dk rounded hover:cursor-pointer hover:border-primary-dk transition-2s"
    />

    <VinButton
      vid="fleet-history-fetch-btn"
      :text="$t('fetch_fleet_history')"
      :is-loading="isFetching"
      color="cta-lt"
      class="w-full"
      @click="handleFetch"
    />
    <VinButton
      v-if="$store.state.fleetHistoryModule.historicalMode"
      vid="fleet-history-reset-btn"
      :text="$t('Reset')"
      secondary
      class="w-full my-8"
      @click="handleReset"
    />
  </div>
</template>

<script>
import { addDays, isBefore } from 'date-fns'

import theme from '@/assets/themes/config'
export default {
  name: 'DashboardMapHistoryDatePicker',
  computed: {
    date: {
      get () {
        const d = this.$store.state.fleetHistoryModule.date || new Date()
        return isBefore(d, this.minDate) ? this.minDate : d
      },
      set (val) {
        this.$store.commit('SET_FLEET_HISTORICAL_DATE', val)
      },
    },
    calColor () {
      return theme.calendarColor
    },
    minDate () {
      return addDays(new Date(), -89)
    },
    isFetching () {
      return this.$store.state.fleetHistoryModule.isFetching
    },
    locale () {
      const { calCode } = this.$store.getters.localeSettings
      return calCode
    },
  },
  methods: {
    handleFetch () {
      if (!this.isFetching) {
        this.$store.dispatch('FLEET_HISTORY')
      }
    },
    handleReset () {
      this.$store.commit('TOGGLE_HISTORICAL_MODE', false)
      this.$store.commit('SET_FLEET_HISTORICAL_DATE', null)
    },
  },
}
</script>
