import get from 'lodash.get'

import reportsConfig from '../reports/config.json'
const module = {
  state: {
    showRegDrivers: true,
    fullReportList: reportsConfig['fullReportList'],
    // exclude from full report list
    adminReportList: reportsConfig['adminReportList'],
    bustCacheSlugGroups: reportsConfig['bustCacheSlugGroups'],
  },
  getters: {
    reportFilter: () => (arr, match) => {
      if (arr.length === 0) {
        return []
      }
      return arr.filter((r) => {
        const category = get(r, 'category', '')
        let filtered =
          match !== 'fullReportList'
            ? category.substring(0, match.length) === match
            : true
        return filtered
      })
    },
    getReportsForCategory: (state, getters) => (category) => {
      // unique category not included in app search
      // requires router permission check to access
      if (category === 'adminReports') {
        return state.adminReportList
      }

      const reports = state.fullReportList
      if (category === 'fullReportList') {
        return reports
      }

      const filtered = getters.reportFilter(reports, category)
      if (filtered.length === 0) {
        return reports
      }

      return filtered
    },
    getReportBySlug: (state) => (slug) => {
      return state.fullReportList.find((report) => report.slug === slug)
    },
  },
  mutations: {
    DRIVER_REG_TOGGLE: (state, flag) => {
      state.showRegDrivers = flag
    },
  },
  actions: {},
}

export default module
