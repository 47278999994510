<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="risk-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="gradientStart"
        />
        <stop
          offset="100%"
          :stop-color="gradientStop"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="48"
        height="48"
        fill="url(#risk-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        transform="translate(10.105 11.368)"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.7"
          d="M11.735 2.35l-10.7 17.86A2.526 2.526 0 0 0 3.196 24h21.399a2.526 2.526 0 0 0 2.16-3.79l-10.7-17.86a2.526 2.526 0 0 0-4.32 0zM13.895 8.842v5.053M13.895 18.947"
        />
        <circle
          cx="13.895"
          cy="18.947"
          r="1"
          fill="#FFF"
          stroke-width=".7"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import iconMix from '../icon-mixin'
export default {
  name: 'VinRiskIcon',
  mixins: [iconMix],
}
</script>
