<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-brake-icon"
        x1="94.128%"
        x2="5.217%"
        y1="0%"
        y2="70.35%"
      >
        <stop
          offset="0%"
          stop-color="#EA4D4D"
        />
        <stop
          offset="100%"
          stop-color="#E03E47"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="nonzero"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-brake-icon)"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M25.246 42.737c-2.419 0-4.38-2.21-4.38-4.936V26.286c0-.909-.653-1.646-1.46-1.646a1.268 1.268 0 0 0-1.05.412 1.56 1.56 0 0 0-.395 1.053c.005 1.455-.523 2.847-1.46 3.85-.944.995-2.213 1.502-3.503 1.398l-1.314-.1c-.806-.058-1.417-.843-1.365-1.751.052-.909.748-1.597 1.555-1.538l1.314.098c.483.037.959-.153 1.313-.526.357-.374.559-.899.555-1.448-.003-1.29.461-2.526 1.285-3.422.827-.881 1.927-1.365 3.066-1.349 2.418 0 4.38 2.21 4.38 4.936v11.516c0 .908.653 1.645 1.46 1.645.805 0 1.459-.737 1.459-1.645V19.672c0-2.726 1.96-4.935 4.38-4.935 2.418 0 4.38 2.21 4.38 4.935v13.161c0 .909.653 1.645 1.459 1.645s1.46-.736 1.46-1.645c0-2.726 1.96-4.935 4.38-4.935h1.46c.806 0 1.46.736 1.46 1.645s-.654 1.645-1.46 1.645h-1.46c-.807 0-1.46.737-1.46 1.645 0 2.726-1.961 4.936-4.38 4.936-2.419 0-4.38-2.21-4.38-4.936v-13.16c0-.91-.653-1.646-1.46-1.646-.806 0-1.46.737-1.46 1.645V37.77c.008 1.314-.45 2.578-1.272 3.51-.822.933-1.94 1.458-3.107 1.458z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBrake',
}
</script>
