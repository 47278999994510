<template>
  <IEcharts :option="chartOptions" />
</template>
<script>
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'

import IEcharts from 'vue-echarts-v3/src/lite.js'

import colors from '@/assets/themes/colors'
import { formatNumber } from '@/common/utils/numeral'
export default {
  name: 'VinWidgetPieChart',
  components: {
    IEcharts,
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    legendItems () {
      const formatted = []
      this.chartData.forEach(set => {
        formatted.push(set.name)
      })
      return formatted
    },
    seriesData () {
      const formatted = []
      this.chartData.forEach(set => {
        formatted.push({
          name: set.name,
          value: formatNumber(set.val, { type: 'currency' }).value(),
          itemStyle: {
            color: colors[set.color],
          },
        })
      })
      return formatted
    },
    chartOptions () {
      return {
        tooltip: {
          trigger: 'item',
          backgroundColor: '#ffffff',
          padding: [12, 16],
          textStyle: {
            color: '#65747b',
            fontWeight: 'bolder',
            fontSize: 14,
          },
          extraCssText: 'box-shadow: 0px 0px 10px 0px rgba(37, 38, 94, 0.1);',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          top: 16,
          orient: 'vertical',
          x: 'left',
          data: this.legendItems,
          textStyle: {
            color: '#65747b',
            fontWeight: '600',
            fontSize: 12,
          },
        },
        series: [
          {
            name: 'Fuel Cost',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center',
                formatter: '{b} {d}%',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '16',
                  fontWeight: 'normal',
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: this.seriesData,
          },
        ],
      }
    },
  },
}
</script>
