<template>
  <div>
    <div class="flex mr-10 mt-14 mb-8 px-0">
      <VinTitle
        el="h1"
        :text="$t('Edit Dashboard')"
        class="w-1/2 text-4xl text-copy font-thin tracking-normal leading-normal ml-1"
      />
      <div class="flex w-1/2 flex-row-reverse items-center">
        <VinButton
          vid="save-dash-config-btn"
          color="cta-lt"
          class="w-32 text-md rounded-lg mr-1"
          :text="$t('Done')"
          status="danger"
          @click="handleSave"
        />
      </div>
    </div>
    <DashEditMapSettings v-if="!$store.getters.packIsSimple" />
    <draggable
      v-model="localSections"
      group="sections"
      handle=".handle"
      ghost-class="section-ghost"
      :animation="150"
      :fallback-on-body="true"
      @change="handleSectionChange"
    >
      <DashEditSection
        v-for="(s, i) in localSections"
        :key="`section-${i}`"
        :section="s"
        :index="i"
      />
    </draggable>
    <div class="flex pr-10 mb-12">
      <DashEditSectionAdd class="w-1/2 mx-2" />
      <DashEditSectionAdd
        class="w-1/2 mx-2"
        full
      />
    </div>
  </div>
</template>
<script>
import get from 'lodash.get'
import draggable from 'vuedraggable'

import { slugMixinMap } from '@/common/widgets/compose-widget'

import DashEditMapSettings from './DashEditMapSettings/DashEditMapSettings'
import DashEditSection from './DashEditSection'
import DashEditSectionAdd from './DashEditSectionAdd'
export default {
  name: 'DashEditPreview',
  components: {
    'draggable': draggable,
    'DashEditSection': DashEditSection,
    'DashEditSectionAdd': DashEditSectionAdd,
    'DashEditMapSettings': DashEditMapSettings,
  },
  data () {
    return {
      localSections: [],
    }
  },
  computed: {
    orgId () {
      return get(this.$store.state.authModule, 'activeOrg.id')
    },
    sections() {
      // filter out widgets in each section
      const matrix = []
      const { sections } = this.$store.state.dashboardModule
      sections.forEach(s => {
        const allowedWidgets = s.filter(w => {
          if (w === '') {
            return true
          }
          const requiredMixin = slugMixinMap.get(w)
          return this.$store.getters.hasMixin(requiredMixin)
        })
        if (allowedWidgets.length) {
          matrix.push(allowedWidgets)
        }
      })
      return matrix
    },
  },
  watch: {
    sections () {
      this.localSections = this.sections
    },
  },
  mounted () {
    // map to local variable
    this.localSections = this.sections
  },
  methods: {
    handleSave () {
      const payload = {
        dashboard: {
          map: this.$store.state.dashboardModule.map,
          sections: this.sections,
        },
      }
      this.$store.dispatch('UPDATE_USER_SETTINGS_PROMISE', payload)
        .then(() => {
          this.$store.dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'Successfully Saved Dashboard Settings!',
          })
          this.$store.commit('UPDATE_SESSION_DASHBOARD', payload)
          this.$router.push({
            name: 'Dashboard',
            params: {
              orgId: this.orgId,
            },
          })
        })
        .catch(err => {
          this.$store.dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'Problem Saving Dashboard Settings',
            forceClose: true,
          })
          console.error('problem updating dashboard settings', err)
        })
    },
    handleSectionChange (e) {
      if (e.moved && this.localSections) {
        this.$store.commit('SET_SECTIONS', this.localSections)
      }
    },
  },
}
</script>
