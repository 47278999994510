<template>
  <div id="view-default">
    <div v-if="isAuthenticated">
      <AppHeaderDefault />
      <AppNav />

      <div
        :class="currentBreakpoint === 'desktop' ? '' : 'pt-8'"
        class="w-full bg-white"
      >
        <div
          id="fleet-dashboard-container"
          class="container"
        >
          <router-view name="defaultHeader" />
        </div>
      </div>
      <div
        id="fleet-dashboard-container"
        :class="bottomPadding"
        class="container"
      >
        <router-view name="default" />
      </div>
      <AppFooter />
    </div>
  </div>
</template>

<script>
import AppFooter from '@/components/App/AppFooter'
import AppHeaderDefault from '@/components/App/AppHeader/AppHeaderDefault'
import AppNav from '@/components/App/AppNav/AppNav'
export default {
  name: 'ViewDefault',
  components: {
    AppHeaderDefault,
    AppNav,
    AppFooter,
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    currentBreakpoint () {
      return this.$store.state.baseModule.breakpoint
    },
    bottomPadding () {
      return this.$route.meta.bottomPadding || 'pb-24'
    },
  },
}
</script>
<style>
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-to {
  opacity: 0;
}
</style>
