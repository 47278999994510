<template>
  <div
    id="auth-view-containter"
    :style="`background-image: linear-gradient(55deg, ${gradient[0]}, ${gradient[1]} 16%, ${gradient[1]} 55%, ${gradient[2]})`"
  >
    <div
      v-if="showTranslationOptions"
      class="flex items-center justify-center pt-4"
    >
      <VinDropdown
        :vid="'auth-locale-selector'"
        trigger="click"
        class="card rounded bg-white py-2 px-4"
      >
        <div class="flex items-center justify-between xs:leading-none xl:mt-2px xl:leading-loose">
          <span class="text-grey-dk xs:text-base xl:text-lg font-normal pr-4">
            {{ locale.name }} {{ locale.flag }}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="8"
            viewBox="0 0 12 7"
            class="4r-2 xs:w-3 xl:w-4"
          >
            <path
              fill="none"
              fill-rule="nonzero"
              stroke="#002C5D"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1l5 5 5-5"
              opacity=".5"
            />
          </svg>
        </div>
        <VinDropdownMenu slot="menu">
          <VinDropdownItem
            v-for="(l, i) in $store.getters.orderedLocales"
            :key="i"
            :text="`${l.name} ${l.flag}`"
            @click.native="locale = l.code"
          />
        </VinDropdownMenu>
      </VinDropdown>
    </div>

    <div
      id="fleet-auth-container"
      class="flex justify-center items-center mb-12"
    >
      <slot />
    </div>
    <AppFooter
      light
    />
  </div>
</template>

<script>
import AppFooter from '@/components/App/AppFooter'

import colors from '../assets/themes/colors'
export default {
  name: 'ViewAuth',
  components: {
    'AppFooter': AppFooter,
  },
  computed: {
    showTranslationOptions () {
      return this.$route?.meta?.hideTranslationOptions !== true
    },
    gradient () {
      return [colors['auth-gradient-0'], colors['auth-gradient-1'], colors['auth-gradient-2']]
    },
    locale: {
      get () {
        const locales = this.$store.state.localizationModule.locales
        const key = this.$i18n.locale()
        return locales.find(l => l.code === key)
      },
      set (val) {
        localStorage.setItem('profleet-locale', val)
        this.$i18n.set(val)
      },
    },
  },
}
</script>
