import get from 'lodash.get'
import Vue from 'vue'

import colors from '../../assets/themes/colors'
import axios from '../middleware/axios-store'

const module = {
  getters: {
    getStaticMapUrl: (state, getters) => (payload) => { // eslint-disable-line no-unused-vars
      const trip = payload.trip
      const startLat = get(trip, 'startPoint.coordinates[1]', get(trip, 'startPointLat'))
      const startLon = get(trip, 'startPoint.coordinates[0]', get(trip, 'startPointLon'))
      const stopLat = get(trip, 'stopPoint.coordinates[1]', get(trip, 'stopPointLat'))
      const stopLon = get(trip, 'stopPoint.coordinates[0]', get(trip, 'stopPointLon'))
      const anon = get(trip, 'anonamized')
      // handle all the cases where we should render the placeholder
      if (anon || !startLat || !startLon || !stopLat || !stopLon) {
        return require('@/assets/img/trip-map-placeholder.png')
      }

      const key = process.env.VUE_APP_MAPS_KEY
      const size = payload.size || '148x148'
      const startIconUrl = process.env.VUE_APP_BASE_HOST + '/marker-start.png'
      const stopIconUrl = process.env.VUE_APP_BASE_HOST + '/marker-stop.png'

      const startPoint = `${startLat},${startLon}`
      const stopPoint = `${stopLat},${stopLon}`
      let preview = get(trip, 'preview', '')
      preview = preview ? preview : ''
      const path = encodeURI(preview)
      return `https://maps.googleapis.com/maps/api/staticmap?&size=${size}&path=weight:3%7Ccolor:${colors['static-polyline']}%7Cenc:${path}&markers=anchor:center%7Cicon:${startIconUrl}%7C${startPoint}&markers=anchor:center%7Cicon:${stopIconUrl}%7C${stopPoint}&key=${key}`
    },
    getBearing: state => payload => { // eslint-disable-line no-unused-vars
      let lat1 = payload.lat1
      let lon1 = payload.lon1
      let lat2 = payload.lat2
      let lon2 = payload.lon2

      const toRads = Math.PI / 180

      lat1 *= toRads
      lon1 *= toRads
      lat2 *= toRads
      lon2 *= toRads
      var lonDelta = lon2 - lon1
      var y = Math.sin(lonDelta) * Math.cos(lat2)
      var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lonDelta)
      var brng = Math.atan2(y, x)
      brng = brng * (180 / Math.PI)

      if (brng < 0) {
        brng += 360
      }

      return brng
    },
  },
  actions: {
    async GET_TRIP_ADDRESS (context, trip) {
      // fallback value if google cant find an address
      const fallback = Vue.i18n.translate('no_address')
      const noVal = '--'

      // handle anonymous trip
      const isAnon = get(trip, 'anonamized', false)
      const isSharing = trip?.isSharing ?? false
      // handle simple mode biz logic
      const isSimple = context.getters.packIsSimple
      const isBizTag = get(trip, 'tripTag[0].name') === 'business'
      const hiddenInSimple = isSimple && !isBizTag
      if (isAnon || hiddenInSimple || !isSharing) {
        const hiddenVal = Vue.i18n.translate('journey_not_shared')
        return {
          start: hiddenVal,
          stop: hiddenVal,
        }
      }

      // default our result to no val state
      const result = {
        start: noVal,
        stop: noVal,
      }
      // if we don't have a trip, give up
      if (!trip) {
        return result
      }

      // check for pre geocoded strings
      const preGeocodedStart = trip?.startLocation ?? null
      const preGeocodedStop = trip?.stopLocation ?? null

      if (preGeocodedStart !== null && preGeocodedStop !== null) {
        return {
          start: preGeocodedStart,
          stop: preGeocodedStop,
        }
      }


      const invalidCoordString = (lat, lon, start) => {
        if (lat === null || lon === null || (lat === 0 && lon === 0)) {
          const text = start ? 'missing_location_start' : 'missing_location_stop'
          return Vue.i18n.translate(text)
        }
        return null
      }

      // returns a string for a start or stop point in a trip
      const getAddress = async (start = true) => {
        const key = start ? 'startPoint' : 'stopPoint'
        const lat = get(trip, `${key}.coordinates[1]`, null)
        const lon = get(trip, `${key}.coordinates[0]`, null)
        // if we are missing coordinates, return no val
        const invalidStr = invalidCoordString(lat, lon, start)
        if (invalidStr) {
          return invalidStr
        }

        // default to the fallback
        let formatted = fallback
        try {
          const { data } = await axios(`/api/v1/geo?path=${lat},${lon}`)
          formatted = data?.address?.formatted_address ?? fallback
        } catch (err) {
          console.error('error reverse geocoding coordintes', err)
        }
        return formatted
      }
      result.start = await getAddress(true)
      result.stop = await getAddress(false)
      return result
    },
  },
}

export default module
