<template>
  <VinSmallWidgetContainer :show-loading-block="isFetching">
    <VinWidgetHeader
      :title="$t('Total Fuel Cost')"
      :timestamp="generatedAt"
      :tooltip-content="computedPetrolText + ' ' + computedDieselText"
      icon="Calculate"
      @bust-cache="fetchData(true)"
    />
    <VinWidgetStat
      :fetching="isFetching"
      :stat="stat"
    />
    <VinWidgetFuelCost
      v-for="(item, idx) in costList"
      :key="idx"
      :text="item.text"
      :cost="item.cost"
    />
    <VinSmallWidgetFooter
      v-if="packHasReport"
      cta-title="View Fuel Cost Report"
      :cta-action="navToReport"
      class="absolute bottom-0 w-full pr-20"
      style="height:72px"
    />
  </VinSmallWidgetContainer>
</template>

<script>
import get from 'lodash.get'

import { fetchLatestFuelPrice } from '@/common/utils/fetch-latest-fuel-price'
import { formatNumber } from '@/common/utils/numeral'

import { composeWidget } from '../compose-widget'
export default {
  name: 'WidgetAvgFuelEconSm',
  displayName: 'Fuel Cost',
  icon: 'Calculate',

  setup () {
    const widget = {
      slug: 'widget-fuel-price',
    }
    const report = {
      slug: 'report-fuel-cost',
      widget: 'ReportFuelCost',
    }

    const {
      isFetchingFuel,
      latestFuelPrice,
    } = fetchLatestFuelPrice()

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
      isFetchingFuel,
      latestFuelPrice,
    }
  },
  computed: {
    stat () {
      return formatNumber(get(this, 'results.totalCost'), {
        type: 'currency',
        abbrvAt: 5,
      })
    },
    petrolCost () {
      return formatNumber(get(this, 'results.petrolCost'), {
        type: 'currency',
      })
    },
    dieselCost () {
      return formatNumber(get(this, 'results.dieselCost'), {
        type: 'currency',
      })
    },
    avgCostPerTrip () {
      return formatNumber(get(this, 'results.avgCostPerTrip'), {
        type: 'currency',
      })
    },
    costList () {
      return [
        {
          text: 'Petrol',
          cost: this.petrolCost,
        },
        {
          text: 'Diesel',
          cost: this.dieselCost,
        },
        {
          text: 'Per Trip',
          cost: this.avgCostPerTrip,
        },
      ]
    },
    computedPetrolText () {
      if (this.isFetching) {
        return '--'
      }
      return `${this.$t('petrol_cost')}: ${this.$store.getters.currencySymbol}${this.latestFuelPrice.petrolPrice}`
    },
    computedDieselText () {
      if (this.isFetching) {
        return '--'
      }
      return `${this.$t('diesel_cost')}: ${this.$store.getters.currencySymbol}${this.latestFuelPrice.dieselPrice}`
    },
  },
}
</script>
