<template>
  <transition
    appear
    name="slideFromTop"
  >
    <div
      v-if="showForMimic || showForOrgTree"
      class="mimic-banner w-full fixed px-2 bg-copy flex items-center justify-between"
    >
      <div class="flex w-full items-center justify-center">
        <span class="text-white mr-1">
          {{ $t('viewing_as') }}
        </span>
        <div v-if="showForMimic">
          <span class="text-white font-semibold capitalize">
            {{ computedUser }}
          </span>
          <span class="text-white mx-1">
            |
          </span>
        </div>
        <span class="text-white font-semibold capitalize">
          {{ showForMimic ? computedOrgName : activeOrg.name }}
        </span>
        <div v-if="showForMimic">
          <span class="text-white mx-1">
            |
          </span>
          <span class="text-white font-semibold capitalize">
            {{ computedLevel }}
          </span>
        </div>
        <AccessBannerSwitchLink
          v-if="showForOrgTree"
          :org-id="activeOrg.id"
          class="pl-6"
        />
      </div>
      <div>
        <XIcon
          class="text-white w-5 h-5 hover:cursor-pointer"
          @click="handleClick"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import get from 'lodash.get'
import { XIcon } from 'vue-feather-icons'

import AccessBannerSwitchLink from './AccessBannerSwitchLink'
export default {
  name: 'AccessBanner',
  components: {
    AccessBannerSwitchLink,
    XIcon,
  },
  computed: {
    showForMimic () {
      return this.$store.state.authModule.mimic.isActive
    },
    showForOrgTree () {
      if (this.rootOrg && this.activeOrg) {
        return this.rootOrg.id !== this.activeOrg.id
      }
      return false
    },
    rootOrg () {
      return get(this.$store.state.authModule, 'session.orgs[0]', null)
    },
    activeOrg () {
      return get(this.$store.state.authModule, 'activeOrg', null)
    },
    computedUser () {
      const firstName = this.$store.state.authModule.mimic.user.firstName
      const lastName = this.$store.state.authModule.mimic.user.lastName
      return firstName + ' ' + lastName
    },
    routeParamOrgId () {
      return get(this.$route, 'params.orgId')
    },
    computedOrgName () {
      return this.$store.state.authModule.mimic.org.name
    },
    computedLevel () {
      return this.$store.state.authModule.mimic.user.accessLevel
    },
  },
  watch: {
    routeParamOrgId () {
      // only execute of we have a param, as sometimes we don't
      if (this.routeParamOrgId) {
        if (this.routeParamOrgId !== this.activeOrg.id) {
          this.fetchRouteOrg()
        }
      }
    },
  },
  methods: {
    handleClick () {
      if (this.showForOrgTree) {
        const sessionOrg = this.$store.state.authModule.session.orgs[0]
        this.$store.dispatch('SWITCH_ORG_AND_CLEAR_APP', sessionOrg)
        this.$router.push({
          name: 'Dashboard',
          params: {
            orgId: this.rootOrg.id,
          },
        })
      } else {
        this.$router.push({
          name: 'Dashboard',
          params: {
            orgId: this.rootOrg.id,
          },
        })
      }
      // can always run safely
      this.$store.dispatch('CLEAR_MIMIC_SESSION')
    },
    fetchRouteOrg () {
      return axios.get(`/api/v1/orgs/${this.routeParamOrgId}`)
        .then(resp => {
          this.$store.dispatch('SWITCH_ORG_AND_CLEAR_APP', resp.data)
        })
        .catch(err => {
          throw err
        })
    },
  },
}
</script>

<style scoped>
.mimic-banner {
  height: 40px;
  z-index: 9999;
}
.slideFromTop-enter,
.slideFromTop-leave-to {
  transform: translate(0px, -12.5em);
}
.slideFromTop-enter-to,
.slideFromTop-leave {
  transform: translate(0px, 0px);
}
.slideFromTop-enter-active,
.slideFromTop-leave-active {
  transition: transform 0.4s ease-in;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
