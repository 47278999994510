<template>
  <li
    class="dropdown-menu-item"
    :class="{
      'dropdown-menu-item-disabled': disabled,
      'dropdown-menu-item-divided': divided,
    }"
    :text="text"
    :style="style"
    @click.stop="handleClick"
  >
    {{ text }}
  </li>
</template>

<script>
export default {
  name: 'VinDropdownItem',
  props: {
    text: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    divided: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
  },
  computed: {
    style () {
      return this.width ? { 'width': this.width } : {}
    },
  },
  methods: {
    handleClick () {
      if (this.disabled) return
      this.$parent.$parent.$emit('menu-item-click', this.text)
    },
  },
}
</script>
