const makeURL = (offset, opts) => {
  const base = {
    offset,
    limit: opts.limit,
  }
  const custom = opts?.params ?? {}
  const params = Object.assign(base, custom)
  let optsUrl = opts.url
  if (!optsUrl.startsWith('http')) {
    optsUrl = window.location.origin + opts.url
  }
  const url = new URL(optsUrl)
  for (const k in params) {
    url.searchParams.append(k, params[k])
  }
  return url.toString()
}

function parseBody(body, key) {
  const result = {
    list: body?.[key] ?? [],
    total: body?.meta?.pagination?.total ?? 0,
  }
  return result
}

export default async function leadfoot(opts) {
  const result = {
    list: [],
    total: 0,
    errored: false,
  }
  if (!opts.max) {
    // default the max
    opts.max = Number.POSITIVE_INFINITY
  }

  const getData = async (offset = 0) => {
    let list = []

    try {
      const resp = await fetch(makeURL(offset, opts))
      if (resp.ok) {
        const body = await resp.json()
        const parsed = parseBody(body, opts.listKey)
        result.total = parsed.total
        list = parsed.list
      } else {
        result.errored = true
        result.error = `${resp.status}: ${resp.statusText}`
      }
    } catch (err) {
      result.error = `${err}`
      result.errored = true
    }
    return list
  }

  // get the initial list
  result.list = await getData()
  const total = result.total
  // now get the next batches in parallel
  if (result.list.length < total) {
    const pageCount = Math.round(total / opts.limit)

    const proms = []
    for (let i = 0; i < pageCount; i++) {
      const offset = (i + 1) * opts.limit
      if (offset < opts.max) {
        proms.push(getData(offset))
      }
    }

    try {
      const responses = await Promise.all(proms)
      const allPages = responses.reduce((acc, page) => {
        return acc.concat(page)
      }, [])
      result.list = result.list.concat(allPages)
    } catch (error) {
      const msg = `Error fetching ${opts.listKey} pages `
      console.error(msg + error)
      result.errored = true
      result.error = msg
    }
  }

  return result
}
