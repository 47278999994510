<template>
  <GmapMarker
    :key="markerKey"
    ref="dashMarker"
    :position="coords"
    :clickable="visible"
    :visible="visible"
    :icon="{
      url: require(`@/assets/img/marker-${vehicleStatus}.svg`)
    }"
    @click="setCenter"
  />
</template>

<script>
import { components } from 'gmap-vue'

const { Marker } = components
export default {
  name: 'DashboardMapMarker',
  components: {
    GmapMarker: Marker,
  },
  props: {
    vehicle: {
      type: Object,
    },
    coords: {
      type: Object,
    },
    vehicleStatus: {
      type: String,
      default: 'stopped-l-24',
    },
  },
  computed: {
    markerKey() {
      return this.vehicleStatus + '-' + this.vehicle?.id ?? '-'
    },
    visible() {
      return this.$store.state.vehiclesModule.vehicleStatusSettings[this.vehicleStatus].show
    },
  },
  methods: {
    setCenter () {
      this.$store.commit('SET_MAP_CENTER', this.coords)
      this.$store.commit('SET_INFO_WINDOW_ID', this.vehicle.id)
    },
  },
}
</script>
