<template>
  <div>
    <div class="px-8 mt-6 flex items-center justify-between">
      <span class="text-grey-dk">
        {{ $t('address_search') }}
      </span>
    </div>
    <div class="px-8 mt-1 mb-5 flex items-center justify-between">
      <div class="inner-addon right-addon w-full">
        <search-icon class="searchicon" />
        <input
          ref="autocomplete"
          type="text"
          :placeholder="$t('Search')"
          color="primary-dk"
          class="search w-full p-input text-lg border-grey border-2 rounded hover:border-primary-dk transition-2s"
          @focus="mountAutocomplete"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue'
import { SearchIcon } from 'vue-feather-icons'
export default {
  name: 'ServiceLocationsSearch',
  components: {
    SearchIcon,
  },
  data () {
    return {
      autocomplete: null,
    }
  },
  computed: {
    google: getGoogleMapsAPI,
  },
  methods: {
    handleSelection (payload) {
      // this.$store.commit('SET_MAP_SERVICE_LOCATION_SELECTION', payload)
      this.$emit('select', payload)
    },
    mountAutocomplete () {
      window.GoogleMapsCallback(google)
      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
      )
      this.autocomplete.addListener('place_changed', () => {
        let place = this.autocomplete.getPlace()
        let lat = place.geometry.location.lat()
        let lng = place.geometry.location.lng()
        const payload = {
          lat: lat,
          lon: lng,
          address: place.formatted_address,
          phone: place.international_phone_number ? place.international_phone_number : '',
          name: place.name,
        }
        this.handleSelection(payload)
      })
    },
  },
}
</script>
<style scoped>
input {
  outline: none;
}
.inner-addon {
  position: relative;
}
.search {
  border-width: 2px !important;
  color: #65747b !important;
}
.search::placeholder {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #65747b;
  opacity: 1;
}
/* style icons */
.inner-addon .searchicon {
  position: absolute;
  pointer-events: none;
}
.searchicon {
  height: 21px;
  stroke: #979aa7;
}
/* align icons */
.right-addon .searchicon {
  right: 12px;
  top: 16px;
}
</style>
