import colors from '../../../assets/themes/colors'
export default {
  computed: {
    gradientStart () {
      return colors['icon-gradient-0']
    },
    gradientStop () {
      return colors['icon-gradient-1']
    },
  },
}
