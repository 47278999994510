<template>
  <div :class="topStyle">
    <div
      :class="bgClass"
      :style="fillStyle"
    />
  </div>
</template>

<script>
export default {
  name: 'VinGauge',
  props: {
    value: {
      type: Number,
      default: 75,
    },
    color: {
      type: String,
      default: 'positive',
    },
  },
  computed: {
    topStyle () {
      let style = 'flex w-full h-10px bg-grey-lt rounded-lg'
      return style
    },
    fillStyle () {
      return {
        width: `${this.value}%`,
        background: (this.color[0] === '#') ? this.color : null,
      }
    },
    bgClass () {
      return `bg-${this.color} border-r-2 border-white h-full rounded-lg relative`
    },
  },
}
</script>
