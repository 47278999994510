var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.shouldShow)?_c('span',{staticClass:"flex items-center pill-tag text-center outline-none whitespace-no-wrap py-2 mr-4 leading-normal border-2 border-primary-dk bg-white relative transition-2s",class:[
      _vm.status ? `bg-${_vm.status}-dk border-${_vm.status}-dk text-white` : '',
      _vm.closable ? 'justify-between' : 'justify-center',
      _vm.closeHover ? 'text-cta-dk border-cta-dk' : 'text-copy'
    ]},[(_vm.closable)?_c('x-icon',{staticClass:"pill-tag-close mt-px ml-4 cursor-pointer",on:{"click":_vm.closeAction,"mouseenter":function($event){_vm.closeHover = true},"mouseleave":function($event){_vm.closeHover = false}}}):_vm._e(),_c('span',{staticClass:"cursor-default tag-text text-sm md:text-lg leading-none mb-2px",class:[
        _vm.status ? 'text-white' : '',
        _vm.closable ? 'pl-3 pr-6' :'px-8'
      ]},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }