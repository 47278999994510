<template>
  <div class="flex items-center justify-between w-full mt-3">
    <VinTitle
      el="h5"
      :text="$t(text)"
      class="font-normal text-grey-dk text-sm tracking-xl leading-none mb-0 uppercase"
    />
    <div class="flex">
      <span class="text-xl font-semithin leading-none mb-0 mr-1 text-copy">
        {{ formattedCurrency }}
      </span>
      <span class="text-xl font-semiheavy tracking-relaxed leading-none mb-0 text-copy">
        {{ formattedStat }}
      </span>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
export default {
  name: 'VinWidgetFuelCost',
  props: {
    text: {
      type: String,
      default: 'Diesel',
    },
    cost: {
      type: Object,
      default: () => {
        return {
          numeral: '--',
          currency: '',
        }
      },
    },
  },
  computed: {
    formattedStat () {
      return get(this.cost, 'numeral', '--')
    },
    formattedCurrency () {
      return get(this.cost, 'currency', '')
    },
  },
}
</script>
