import { differenceInMonths } from 'date-fns'
import get from 'lodash.get'
import has from 'lodash.has'
import Vue from 'vue'

import axios from '../middleware/axios-store'
import validateSession from '../middleware/validate-session'

const module = {
  state: {
    firstLoad: true,
    startingRoute: null,
    session: null,
    status: '',
    deletingSession: false,
    activeOrg: null,
    orgPack: {},
    orgType: 'regular',
    levels: ['demo', 'user', 'customer_admin', 'admin', 'super'],
    mixins: new Set(),
    speedingEventsEnabled: null,
    mimic: {
      user: {},
      org: {},
      isActive: false,
    },
  },
  getters: {
    isAuthenticated: state => {
      return !!state.session
    },
    needsAnalyticsOptIn: state => {
      // false if we have no session
      if (!state.session) {
        return false
      }
      const optIn = get(state.session, 'settings.analyticsOptIn')
      if (state.session && typeof optIn !== 'boolean') {
        return true
      }

      const optInDate = get(state.session, 'settings.analyticsOptInDate')
      // if they don't have a date, require that they re-up
      if (!optInDate) {
        return true
      }
      // they do have a date, and a valid setting
      const validForMonths = 6
      // check to see if the date is >= valid months ago
      const expired = differenceInMonths(new Date(), new Date(optInDate)) >= validForMonths

      // only need to re-up if they are opted in AND their opt in date
      // is expired
      return expired && optIn
    },
    packIsSimple: (state, getters) => {
      if (getters.gdpr5Enabled) {
        if (getters.onlyBasic) {
          return true
        }
        const hasBasic = state.mixins.has('basic')
        const hasVAM = state.mixins.has('vam')
        return state.mixins.size === 2 && hasBasic && hasVAM
      }
      return get(state.orgPack, 'isSimple', true)
    },
    onlyBasic: (state, getters) => {
      const hasBasic = getters.hasMixin('basic')
      return hasBasic && state.mixins.size === 1
    },
    authErrorMessage: state => {
      let text = null
      switch (state.status) {
        case 401: {
          text = 'Incorrect username or password :('
          break
        }
        case 400: {
          text = 'Please enter a valid email'
          break
        }
        case 404: {
          text = 'Something went wrong :('
          break
        }
        case 'X': {
          text = 'Something went wrong :('
          break
        }
        default:
          break
      }
      return text
    },
    hasMixin: (state, getters) => slug => {
      if (getters.gdpr5Enabled) {
        return state.mixins.has(slug)
      }
      return true
    },
    hasPermission: state => (requiredLevel, mustBeAbove) => {
      // these are the available levels
      // items with the lowest index have the lowest access level
      const levels = state.levels

      // grab session access level if not mimic
      let userLevel = null
      if (!state.mimic.isActive) {
        userLevel = get(state.session, 'accessLevel')
      } else {
        userLevel = get(state.mimic.user, 'accessLevel')
      }
      // alwaus false if we don't have a access level for the session
      if (!userLevel) {
        return false
      }

      if (levels.indexOf(requiredLevel) === -1) {
        /* eslint-disable */
        console.warn(`invalid level, must be one of ${levels}. Got ${requiredLevel}`)
        /* eslint-disable */
        return false
      }
      // returns true if user's level is at or above needed permission
      if (mustBeAbove) {
        return levels.indexOf(userLevel) > levels.indexOf(requiredLevel)
      } else {
        return levels.indexOf(userLevel) >= levels.indexOf(requiredLevel)
      }
    },
    levelsBelowMe: (state, getters) => (excludeEqualLevel = true) => {
      // returns array of levels below the user's access level
      // scrubs out super
      const levels = state.levels
      const scrubbed = levels.slice(0, state.levels.length - 1)
      return scrubbed.filter((level, i) => {
        return getters.hasPermission(levels[i], excludeEqualLevel)
      })
    },
    prettyAccessLevel: state => level => {
      switch (level) {
        case 'demo':
          return 'Demo'
        case 'user':
          return 'User'
        case 'admin':
          return 'ALD Admin'
        case 'customer_admin':
          return 'Customer Admin'
        case 'super':
          return 'Super User'

        default:
          return level
      }
    },
    templateByType: state => (wtype, defaultVal) => {
      const widgets = get(state.orgPack, 'widgets', [])
      const widg = widgets.find(w => w.type === wtype)
      return get(widg, 'componentName', defaultVal)
    },
    hasWidget: (state, getters) => (componentName, requiredMixin = 'basic') => {
      if (getters.gdpr5Enabled) {
        return getters.hasMixin(requiredMixin)
      }
      // if we're signed in and don't have a pack, include everything
      const packId = get(state.session, 'orgs[0].settings.pack', 'none')
      if (packId === 'none' && get(state.session, 'id')) {
        return true
      }

      // maintenance check first - wont show up as pack widget
      if (componentName === 'ReportVehicleServiceStatus' && getters.isMaintenanceEnabled) {
        return true
      }

      // add our speeding report special case
      if (componentName === 'ReportSpeeding' && !getters.showSpeedingEvents) {
        return false
      }

      const widgets = get(state.orgPack, 'widgets', [])
      return widgets.some(w => w.componentName === componentName)
    },
    isVinli: (state) => {
      const email = state.session?.email ?? ''
      const end = email.slice(-7)
      return end === '@vin.li'
    }
  },
  mutations: {
    SET_STARTING_ROUTE: (state, route) => {
      state.startingRoute = route
    },
    END_FIRST_LOAD: (state) => {
      state.firstLoad = false
    },
    SET_STATUS: (state, payload) => {
      state.status = payload
    },
    SET_SESSION: (state, payload) => {
      state.session = payload
    },
    SET_ACTIVE_ORG: (state, org) => {
      state.activeOrg = org
    },
    SET_MIMIC_SESSION: (state, payload) => {
      state.mimic.user = payload.user
      state.mimic.org = payload.org
      state.mimic.isActive = true
    },
    WIPE_MIMIC: (state) => {
      state.mimic.user = {}
      state.mimic.isActive = false
    },
    TOGGLE_DELETING_STATE: (state, toggle) => {
      state.deletingSession = true
    },
    REMOVE_SESSION: (state) => {
      state.session = null
      state.activeOrg = null
      state.startingRoute = null
    },
    UPDATE_USER_PROFILE_DATA: (state, payload) => {
      for (let key in payload) {
        state.session[key] = payload[key]
        Vue.set(state.session, key, payload[key])
      }
    },
    SET_USER_SETTINGS: (state, payload) => {
      Vue.set(state.session, 'settings', payload)
    },
    SET_SPEEDING_ENABLED: (state, val) => {
      state.speedingEventsEnabled = val
    },
    UPDATE_SESSION_DASHBOARD (state, payload) {
      state.session.settings.dashboard.map = payload.dashboard?.map ?? false
      state.session.settings.dashboard.sections = payload.dashboard.sections
    },
    SET_ORG_PACK (state, pack) {
      if (!pack) {
        state.orgPack = {
          id: 'none',
          widgets: []
        }
        return
      }
      state.orgPack = pack
    },
    SET_ORG_PACK_SIMPLE (state, flag) {
      Vue.set(state.orgPack, 'isSimple', flag)
    },
    SET_ORG_PACK_ALLOW_DRIVER_PRIVACY (state, flag) {
      Vue.set(state.orgPack, 'allowDriverPrivacy', flag)
    },
    SET_ORG_TYPE (state, type) {
      if (!type) {
        state.orgType = 'regular'
        return
      }
      state.orgType = type
    },
    SET_MIXINS(state, mixins) {
      // TODO - ngdpr is always on, need to fix
      Vue.set(state, 'mixins', new Set(mixins.filter(m => m !== 'ngdpr')))
    },
    CLEAR_MIXINS(state) {
      state.mixins.clear()
    }
  },
  actions: {
    CREATE_NEW_SESSION ({ commit, dispatch }, credential) {
      // first, clear the status
      commit('SET_STATUS', null)
      axios.post('/api/v1/sessions', {
        session: {
          email: credential.email,
          password: credential.password
        }
      }).then(resp => {
        // create validator function so it's easy to call
        const validate = () => {
          const valid = validateSession(resp)
          if (valid === true) {
            commit('SET_STATUS', get(resp, 'status', ''))
            dispatch('SERVE_TOAST', {
              type: 'success',
              message: 'Welcome back 🎉'
            })
            dispatch('INITIATE_SESSION', resp.data.session)
          } else {
            commit('SET_STATUS', 'invalid session')
            dispatch('DELETE_CURRENT_SESSION')
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: valid.message,
              forceClose: true
            })
          }
        }
        // check for starting org
        if (credential.startingOrg) {
          axios.get(`/api/v1/orgs/${credential.startingOrg}`)
            .then(orgResp => {
              resp.data.session.org = orgResp.data
              validate()
            })
            .catch(err => {
              commit('SET_STATUS', 'invalid starting org for session')
              commit('SET_STARTING_ROUTE', null)
              dispatch('DELETE_CURRENT_SESSION')
              dispatch('SERVE_TOAST', {
                type: 'danger',
                message: 'Invalid starting url, try logging in again.',
                forceClose: true
              })
              throw err
            })
        } else {
          // if no starting org, just handle normally
          validate()
        }
      }).catch(err => {
        if (err.response && err.response.status) {
          commit('SET_STATUS', err.response.status)
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'Something went wrong 😣'
          })
        } else {
          // generic error
          commit('SET_STATUS', 'X')
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'Something went wrong 😣'
          })
        }
      })
    },
    FETCH_CURRENT_SESSION ({ commit, dispatch }, orgId) {
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/sessions/_current').then(resp => {
          const valid = validateSession(resp)
          if (valid === true) {

            const defaultOrg = get(resp, 'data.session.orgs[0]')
            let session = resp.data.session
            // grab the org that was passed in and doesn't match the default
            if (orgId && orgId !== defaultOrg.id) {
              axios.get(`/api/v1/orgs/${orgId}`)
                .then(orgResp => {
                  session.org = orgResp.data
                  dispatch('INITIATE_SESSION', session)
                  resolve(session)
                }).catch(err => {
                  reject(err)
                })
            } else {
              // just use the default otherwise
              session.org = defaultOrg
              dispatch('INITIATE_SESSION', session)
              resolve(session)
            }
            commit('SET_STATUS', get(resp, 'status', ''))
          } else {
            commit('SET_STATUS', 'invalid session')
            dispatch('DELETE_CURRENT_SESSION')
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: valid.message,
              forceClose: true
            })
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    async DELETE_CURRENT_SESSION({ commit, state }, isDead = false) {
      if (state.deletingSession) {
        return
      }
      commit('TOGGLE_DELETING_STATE', true)
      commit('REMOVE_SESSION')
      commit('SET_STATUS', null)
      commit('SET_ACTIVE_ORG', null)
      commit('SET_ORG_PACK')
      commit('SET_ORG_TYPE')
      commit('CLEAR_ADMIN_MODULE')
      commit('CLEAR_BASE_MODULE')
      commit('CLEAR_OVERLAY_MODULE')
      commit('CLEAR_DATA_MODULE')
      commit('CLEAR_VEHICLE_MODULE')
      commit('CLEAR_PLACES_MODULE')
      commit('CLEAR_FUEL_PRICE_MODULE')
      commit('CLEAR_PARAM_MODULE')
      commit('CLEAR_GROUPS_MODULE')
      commit('CLEAR_SERVICE_MAINTENANCE_MODULE')
      commit('CLEAR_ERRORS')
      commit('CLEAR_MIXINS')
      if (!isDead) {
        await axios.delete('/api/v1/sessions/_current')
      }
      commit('TOGGLE_DELETING_STATE', false)
    },
    HYDRATE_MIMIC_SESSION ({ commit, dispatch }, payload) {
      commit('SET_MIMIC_SESSION', payload)
      dispatch('LOCALIZE_FOR_SESSION', payload.user)
    },
    CLEAR_MIMIC_SESSION ({ commit, dispatch, state }) {
      commit('WIPE_MIMIC')
      dispatch('LOCALIZE_FOR_SESSION', state.session)
    },
    UPDATE_PASSWORD_PROMISE (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/users/password', payload)
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    UPDATE_PROFILE_PROMISE ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const valid = ['firstName', 'lastName', 'email', 'language', 'phone']
        for (let key in payload) {
          if (valid.indexOf(key) === -1) {
            reject(new Error(`Update profile only accepts ${valid} parameters.`))
          }
        }
        axios
          .patch('/api/v1/users/profile', payload)
          .then(resp => {
            commit('UPDATE_USER_PROFILE_DATA', payload)
            dispatch('SERVE_TOAST', {
              type: 'success',
              message: 'user_settings_updated'
            })
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    UPDATE_USER_SETTINGS_PROMISE ({ commit, dispatch }, payload) {
      const settings = {
        settings: payload
      }
      axios.put('/api/v1/users/settings', settings)
        .then(resp => {
          commit('SET_USER_SETTINGS', resp.data.settings)
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'user_settings_updated'
          })
        })
        .catch(err => {
          throw err
        })
    },
    async INITIATE_SESSION ({ commit, dispatch, getters }, session) {
      // set active org
      const org = session.org || get(session, 'orgs[0]', null)
      commit('SET_ACTIVE_ORG', org)

      const proms = ['GET_FLAGS', 'FETCH_ORG_MIXINS', 'GET_SPEEDING_ENABLED_SETTING'].map(async (slug) => await dispatch(slug))
      await Promise.allSettled(proms)

      const gdpr5Enabled = getters.gdpr5Enabled
      if (gdpr5Enabled) {
        dispatch('HYDRATE_SERVICE_MAINTENANCE', {
            maintenanceEnabled: true,
            maintenanceCTAMethod: 'manual',
            maintenanceCTAString: '',
          }
        )
      }
      const packId = get(org, 'settings.pack', null)
      try {
        const { data }  = await axios.get(`/api/v1/orgs/${org.id}/packs/${packId}`)
        commit('SET_ORG_PACK', data)
        if (!gdpr5Enabled) {
          dispatch('HYDRATE_SERVICE_MAINTENANCE', data)
        }
      } catch (error) {
        commit('SET_ORG_PACK')
      }

       // set session
       commit('SET_SESSION', session)

      // setup org type info
      commit('SET_ORG_TYPE', get(org, 'type'))

      // setup dash settings
      if (session.settings?.dashboard?.sections) {
        commit('SET_SECTIONS', session.settings.dashboard.sections)
        commit('TOGGLE_DASH_MAP_SETTING', session.settings?.dashboard?.map ?? false)
      }
      // localize for user session settings
      dispatch('LOCALIZE_FOR_SESSION', session)

      // restore stores from localStorage which support it
      commit('INITIALIZE_STORE')

      // report settings for user
      commit('SET_REPORT_SETTINGS', session.settings)
    },
    FORGOT_PASSWORD_PROMISE ({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.email) {
          reject(new Error('Forgot password requries email._'))
        }
        axios.post(
          '/api/v1/users/forgot-password',
          payload,
          {
            params: {
              theme: rootState.themeModule.theme,
              prefix: rootState.themeModule.prefix,
            }
          }
        ).then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    RESET_PASSWORD_PROMISE (context, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.password || !payload.token) {
          reject(new Error('Reset Password requires token and password'))
        }
        axios.post('/api/v1/users/reset-password', payload).then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    SWITCH_ORG_AND_CLEAR_APP ({ commit, dispatch }, org) {
      // clear out app
      commit('CLEAR_ADMIN_MODULE')
      commit('CLEAR_VEHICLE_MODULE')
      commit('CLEAR_GROUPS_MODULE')
      commit('CLEAR_PLACES_MODULE')
      commit('CLEAR_FUEL_PRICE_MODULE')
      commit('CLEAR_SCHEDULED_REPORTS_MODULE')
      commit('CLEAR_MIXINS')
      // set active group to All Vehicles
      commit('SET_ACTIVE_GROUP', {})
      // set the org
      commit('SET_ACTIVE_ORG', org)
      commit('CLEAR_SERVICE_MAINTENANCE_MODULE')
      // if needed - set currency, as this locale setting wins over user
      if (has(org, 'settings.currency')) {
        commit('SET_CURRENCY', org.settings.currency)
      }
      dispatch('FETCH_ORG_MIXINS')

      // set the pack for the active org
      if (has(org, 'settings.pack')) {
        const payload = {
          orgId: org.id,
          packId: org.settings.pack
        }
        dispatch('FETCH_ORG_PACK_PROMISE', payload)
      }

      dispatch('GET_SPEEDING_ENABLED_SETTING')
      dispatch('HYDRATE_ORG_VEHICLES')
    },
    FETCH_ORG_PACK_PROMISE ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/v1/orgs/${payload.orgId}/packs/${payload.packId}`)
          .then(resp => {
            commit('SET_ORG_PACK', resp.data)
            dispatch('HYDRATE_SERVICE_MAINTENANCE', resp.data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async FETCH_ORG_MIXINS ({ state, commit }) {
      const orgId = state?.activeOrg?.id
      if (!orgId) {
        return
      }
      try {
        const { data } = await axios.get('/api/v1/orgs/' + orgId + '/permissions')
        commit('SET_MIXINS', data ?? [])
      } catch (error) {
        console.error('error fetching org permissions', error)
      }
    },
    async GET_SPEEDING_ENABLED_SETTING({ state }) {
      const orgId = state?.activeOrg?.id
      if (!orgId) {
        return
      }
      try {
        const { data } = await axios.get(`/api/v1/orgs/${orgId}/speeding-setting`)
        state.speedingEventsEnabled = data.speedingEventsEnabled
      } catch (error) {
        console.error('error fetching speedingEventsEnabled setting', error)
        state.speedingEventsEnabled = null
      }
    },
    CHANGE_SPEEDING_ENABLED({ commit }, flag) {
      commit('SET_SPEEDING_ENABLED', flag)
      commit('CLEAR_DATA_MODULE')
    }
  }
}

export default module
