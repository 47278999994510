<template>
  <component :is="componentName" />
</template>

<script>
export default {
  name: 'VinIcon',
  props: {
    icon: {
      type: String,
      default: 'Journey',
    },
  },
  computed: {
    componentName () {
      return `Vin${this.icon}Icon`
    },
  },
}
</script>
