<template>
  <div>
    <div v-if="modalComponent">
      <component
        :is="modalComponent"
        :show="shouldShow"
        v-bind="modalProps"
        @cancel="toggleState(activeModalKey)"
        @save="toggleState(activeModalKey)"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
export default {
  name: 'AppModals',
  data () {
    return {
      modalComponent: null,
      modalProps: {},
    }
  },
  computed: {
    loader () {
      const component = get(this.activeModalConfig, 'component')
      if (!component) {
        return null
      }
      return () => import(`./Modals/${component}`)
    },
    activeModalConfig () {
      return this.$store.getters.getActiveModalConfig
    },
    activeModalKey () {
      return this.$store.getters.getActiveModalKey
    },
    shouldShow () {
      if (this.activeModalConfig) {
        return this.activeModalConfig.flag
      }
      return false
    },
  },
  watch: {
    activeModalConfig: {
      immediate: true,
      handler () {
        if (this.activeModalConfig) {
          this.loader()
            .then(() => {
              this.modalComponent = () => this.loader()
              this.modalProps = this.bindProps()
            })
        }
      },
    },
  },
  methods: {
    toggleState (modal) {
      this.$store.commit('TOGGLE_MODAL_STATE', {
        modal: modal,
        flag: !this.activeModalConfig.flag,
      })
    },
    confirmSave (modal) {
      this.$store.commit('TOGGLE_MODAL_STATE', {
        modal: modal,
        flag: !this.activeModalConfig.flag,
      })
    },
    handleUserDelCancel (modal) {
      this.$store.commit('TOGGLE_MODAL_STATE', {
        modal: modal,
        flag: {
          flag: false,
          user: {},
        },
      })
    },
    bindProps () {
      let props = {}

      const modalProps = get(this.activeModalConfig, 'props')
      if (modalProps) {
        modalProps.forEach(p => {
          props[p] = true
        })
      }

      return props
    },
  },
}
</script>
