import colors from './colors'
import config from './config.js'


for (let key in colors) {
  const color = colors[key]
  document.documentElement.style.setProperty(`--color-${key}`, color)
}

if (config.favicon) {
  const faviconLink = document.querySelector('link[rel="icon"][type="image/svg+xml"]')
  faviconLink.setAttribute('href', `/img/icons/${config.favicon}`)
}

if (config.themeColor) {
  const themeColorMetaTag = document.querySelector('meta[name="theme-color"]')
  themeColorMetaTag.setAttribute('content', config.themeColor)
}
