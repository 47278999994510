<template>
  <div>
    <img
      v-if="image"
      :src="image"
      alt="vehicle-image"
      loading="lazy"
      class="w-full"
    >
    <img
      v-else
      :src="require('@/assets/img/placeholder-car.png')"
      alt="vehicle-placeholder-image"
      loading="lazy"
      class="w-full"
    >
  </div>
</template>

<script>
export default {
  name: 'VinCar',
  props: {
    image: {
      type: String,
      default: null,
    },
  },
}
</script>
