import axios from 'axios'
import get from 'lodash.get'

import store from '../../state/store'

export default (to, from, next) => {
  const orgId = get(to, 'params.orgId', '')
  const reportCenter = {
    name: 'Reports',
    params: {
      orgId,
    },
  }

  const slug = get(to, 'params.slug', '')
  const reportConfig = store.getters.getReportBySlug(slug)
  // start with the local check
  if (store.getters.hasWidget(reportConfig.widget)) {
    next()
    return
  }
  // otherwise, ask the API
  if (slug && orgId) {
    axios.get(`/api/v1/orgs/${orgId}/reports/${reportConfig.title}/has`)
      .then(resp => {
        const hasReport = resp?.data?.has
        // continue on to the report if the API said the org has it
        if (hasReport) {
          next()
        } else {
          // otherwise go back to the report center
          next(reportCenter)
        }
      }).catch(() => {
        // and revert back to report center on error
        next(reportCenter)
      })
  }
}
