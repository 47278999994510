import Vue from 'vue'
import Vuex from 'vuex'

// modules
import adminModule from './modules/admin-module'
import alertsModule from './modules/alerts-module'
import authModule from './modules/auth-module'
import baseModule from './modules/base-module'
import csvParserModule from './modules/csv-parser-module'
import dashboardModule from './modules/dashboard-module'
import dataModule from './modules/data-module'
import errorModule from './modules/error-module'
import evModule from './modules/ev-module'
import flagsModule from './modules/flags-module'
import fleetHistoryModule from './modules/fleet-history-module'
import fuelPriceModule from './modules/fuel-price-module'
import geoModule from './modules/geo-module'
import groupsModule from './modules/groups-module'
import liveTrackingModule from './modules/live-tracking-module'
import localizationModule from './modules/localization-module'
import mapSettingsModule from './modules/map-settings-module'
import overlayModule from './modules/overlay-module'
import packConfigModule from './modules/pack-config-module'
import paramsModule from './modules/params-module'
import placesModule from './modules/places-module'
import reportsModule from './modules/reports-module'
import scheduledReportsModule from './modules/scheduled-reports-module'
import searchModule from './modules/search-module'
import serviceMaintenanceModule from './modules/service-maintenance-module'
import themeModule from './modules/theme-module'
import vehiclesModule from './modules/vehicles-module'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    adminModule: adminModule,
    alertsModule: alertsModule,
    authModule: authModule,
    baseModule: baseModule,
    csvParserModule: csvParserModule,
    dashboardModule: dashboardModule,
    dataModule: dataModule,
    errorModule: errorModule,
    evModule: evModule,
    flagsModule: flagsModule,
    fleetHistoryModule: fleetHistoryModule,
    fuelPriceModule: fuelPriceModule,
    geoModule: geoModule,
    groupsModule: groupsModule,
    liveTrackingModule: liveTrackingModule,
    localizationModule: localizationModule,
    mapSettingsModule: mapSettingsModule,
    overlayModule: overlayModule,
    packConfigModule: packConfigModule,
    paramsModule: paramsModule,
    placesModule: placesModule,
    reportsModule: reportsModule,
    scheduledReportsModule: scheduledReportsModule,
    searchModule: searchModule,
    serviceMaintenanceModule: serviceMaintenanceModule,
    themeModule: themeModule,
    vehiclesModule: vehiclesModule,
  },
})

export default store

export const useStore = () => store
