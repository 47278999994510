<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-high-trip-icon"
        x1="91.704%"
        x2="11.892%"
        y1="1.917%"
        y2="65.069%"
      >
        <stop
          offset="0%"
          stop-color="#00B38B"
        />
        <stop
          offset="100%"
          stop-color="#019F7C"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-high-trip-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M45.684 41.263H16.211a4.421 4.421 0 0 1-4.422-4.42V16.21a1.474 1.474 0 0 1 2.948 0v20.631c0 .814.66 1.474 1.474 1.474h29.473a1.474 1.474 0 0 1 0 2.947zm-25.612-6.219l5.305-4.244a1.474 1.474 0 0 1 1.96.103l.619.62a4.421 4.421 0 0 0 5.998.235l8.238-7.06a1.474 1.474 0 1 0-1.916-2.24l-8.238 7.06a1.474 1.474 0 0 1-2.004-.074l-.62-.619a4.421 4.421 0 0 0-5.894-.324l-5.305 4.244a1.474 1.474 0 0 0 1.842 2.3h.015z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconHighTrip',
}
</script>
