<template>
  <div
    class="flex items-start justify-between"
    style="minHeight:65px;"
  >
    <div class="flex flex-col justify-start w-4/5">
      <div class="flex items-start">
        <VinTitle
          el="h5"
          :text="title"
          class="font-normal text-grey-dk text-lg tracking-xl leading-tight mb-0 uppercase"
          :class="icon ? 'pt-1' : ''"
        />
        <VinTooltip
          v-if="tooltipTitle != '' || tooltipContent != ''"
          :title="tooltipTitle"
          :content="tooltipContent"
          placement="top"
          content-padding="px-8 py-1"
          header-padding=""
          class="text-center text-grey-dk transition-2s -mt-2"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              class="text-primary-lt fill-current"
            >
              <path
                fill-rule="evenodd"
                d="M3.527 23.994a1 1 0 0 1-.63-1.78l1.76-1.43a2 2 0 0 1 2.29-.16A9.998 9.998 0 0 0 20.741 7.14a9.998 9.998 0 0 0-18.735 4.595 9.72 9.72 0 0 0 1.54 5.6 1 1 0 0 1-1.69 1.07 11.69 11.69 0 0 1-1.85-6.73 12 12 0 1 1 5.93 10.679l-1.78 1.41a1 1 0 0 1-.63.23zm9.458-8v-5a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0zm-2-8a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"
              />
            </svg>
          </div>
        </VinTooltip>
      </div>
      <VinTooltip
        id="widget-cache-dropdown"
        ref="cacheTip"
        placement="bottom"
        trigger="click"
        content-padding="p-0"
        header-padding="p-0"
        class="leading-none text-grey-dk transition-2s"
      >
        <template
          v-if="!noCache"
          #content
        >
          <div
            style="width:200px;"
            class="p-4 leading-tight text-center cursor-default"
          >
            <div class="w-full mx-auto px-2">
              <span class="text-xs text-grey-dk font-semithin">
                {{ $t('generated_at') }} {{ formattedStamp }}
              </span>
            </div>
          </div>
          <div
            class="transition-2s w-full pt-2 pb-3 flex items-center justify-center
            border-t border-grey text-cta-lt hover:cursor-pointer hover:text-cta-dk hover:bg-grey-ltr"
            @click="handleClick"
          >
            <span class="font-medium">
              {{ $t('Refresh') }}
            </span>
          </div>
        </template>
        <div
          class="flex items-center transition-2s leading-none"
          :class="noCache ? 'text-copy' : 'text-cta-lt hover:cursor-pointer hover:text-cta-dk hover:underline'"
        >
          <span class="text-xs tracking-relaxed">
            {{ $t(durationText) }}
          </span>
          <RefreshCwIcon
            v-if="!noCache"
            class="h-3 w-3 ml-1"
          />
        </div>
      </VinTooltip>
    </div>
    <VinIcon
      v-if="icon"
      :icon="icon"
      style="height:48px;width:48px;"
    />
  </div>
</template>

<script>
import parseJSON from 'date-fns/parseJSON'
import { RefreshCwIcon } from 'vue-feather-icons'

import { mxTimestamp } from '@/common/mixins/dateFormatMixin'
export default {
  name: 'VinWidgetHeader',
  components: {
    RefreshCwIcon,
  },
  mixins: [mxTimestamp],
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    duration: {
      type: String,
      default: '',
    },
    timestamp: {
      type: [String, Number],
      default: '',
    },
    noCache: {
      type: Boolean,
      default: false,
    },
    tooltipTitle: {
      type: String,
      default: '',
    },
    tooltipContent: {
      type: String,
      default: '',
    },
  },
  computed: {
    durationText () {
      return this.duration
        ? this.duration
        : this.$store.getters.textForDuration(this.$store.state.paramsModule.duration)
    },
    formattedStamp () {
      if (this.timestamp) {
        const d = parseJSON(new Date(this.timestamp)).toISOString()
        return this.mxTimestamp(d)
      }
      return '--'
    },
  },
  methods: {
    handleClick () {
      this.$emit('bust-cache')
      this.$refs.cacheTip.handleClickOut()
    },
  },
}
</script>
