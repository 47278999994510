<template>
  <InfoWindow
    :opened="opened"
    :position="position"
    :options="{
      pixelOffset: {
        width: 0,
        height: -25
      }
    }"
    @closeclick="onClose"
  >
    <div class="flex flex-col p-1 text-sm text-copy">
      <div
        v-for="s in statusItems"
        :key="s.status"
        class="flex pb-1"
      >
        <p
          :style="{color: s.color}"
          class="font-bold"
        >
          {{ s.count }}
        </p>
        <img
          class="h-8 w-8"
          :src="require(`@/assets/img/marker-${s.status}.svg`)"
        >
        <p>{{ $t(s.copy) }}</p>
      </div>
    </div>
  </InfoWindow>
</template>

<script>
import { components } from 'gmap-vue'
const { InfoWindow } = components
export default {
  name: 'DashboardMapClusterInfoWindow',
  components: {
    InfoWindow,
  },
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    cluster () {
      return this.$store.state.mapSettingsModule.selectedCluster
    },
    position() {
      return {
        lat: this.cluster.marker.position.lat(),
        lng: this.cluster.marker.position.lng(),
      }
    },
    statusSettings () {
      return this.$store.state.vehiclesModule.vehicleStatusSettings
    },
    statusMap () {
      return this.$store.getters.getStatusMapForCluster(this.cluster)
    },
    statusItems () {
      let arr = []
      for (let [key, value] of Object.entries(this.statusMap)) {
        arr.push({
          status: key,
          copy: this.statusSettings[key].copy,
          count: value.count,
          color: value.color,
        })
      }
      return arr
    },
  },
  watch: {
    cluster: {
      handler(v) {
        if (v) {
          this.opened = false
          this.opened = true
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.opened = false
      this.$store.commit('SET_INFO_WINDOW_ID', null)
      this.$store.commit('SET_SELECTED_CLUSTER', null)
    },
  },
}
</script>
