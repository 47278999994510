<template>
  <div
    :id="vid"
    class="flex items-center"
  >
    <span
      v-if="unCheckedText || checkedText"
      :class="[
        `switch-text text-${textColor} block leading-tight text-lg mr-4`,
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      ]"
    >
      <slot v-if="checkStatus">{{ checkedText | translate }}</slot>
      <slot v-if="!checkStatus">{{ unCheckedText | translate }}</slot>
    </span>
    <span
      class="switch select-none relative inline-block align-middle h-5 border border-grey-dk rounded-xl bg-grey-dk"
      :class="[
        size ? `switch-${size}` : '',
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        {
          'switch-checked border-success-lt bg-success-lt': checkStatus,
        },
      ]"
      @click="toggleSwitch"
    />
  </div>
</template>

<script>
export default {
  name: 'VinSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    checkedText: {
      type: String,
      default: null,
    },
    unCheckedText: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: 'grey-dk',
    },
    vid: {
      type: String,
      required: true,
      default: null,
    },
  },
  data () {
    return {
      checkStatus: this.value,
    }
  },
  watch: {
    value () {
      this.checkStatus = this.value
    },
  },
  methods: {
    toggleSwitch () {
      if (this.disabled) return
      this.checkStatus = !this.checkStatus
      this.$emit('change', this.checkStatus)
    },
  },
}
</script>
<style scoped>
.switch {
  min-width: 40px;
  transition: all 0.3s;
}
.switch::after {
  display: block;
  position: absolute;
  background-color: #fff;
  content: "";
  left: 1px;
  top: 1px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: all 0.3s;
}
.switch-checked::after {
  left: 100%;
  margin-left: -17px;
}
.switch-checked .switch-text {
  padding-left: 6px;
  padding-right: 22px;
}
.switch-sm {
  min-width: 32px;
  height: 16px;
}
.switch-sm::after {
  width: 12px;
  height: 12px;
}
.switch-sm .switch-text {
  font-spacing: 0.5em;
  font-size: 12px;
  padding-left: 16px;
  padding-right: 4px;
}
.switch-sm.switch-checked::after {
  left: 100%;
  margin-left: -13px;
}
.switch-sm.switch-checked .switch-text {
  padding-left: 4px;
  padding-right: 16px;
}
.switch-lg {
  min-width: 48px;
  height: 24px;
}
.switch-lg::after {
  width: 20px;
  height: 20px;
}
.switch-lg .switch-text {
  font-size: 16px;
  padding-left: 26px;
  padding-right: 6px;
}
.switch-lg.switch-checked::after {
  left: 100%;
  margin-left: -21px;
}
.switch-lg.switch-checked .switch-text {
  padding-left: 6px;
  padding-right: 26px;
}
</style>
