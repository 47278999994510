<template>
  <IEcharts :option="chartOptions" />
</template>
<script>
import 'echarts/lib/chart/bar'

import IEcharts from 'vue-echarts-v3/src/lite.js'

import colors from '@/assets/themes/colors'
import { formatNumber } from '@/common/utils/numeral'
export default {
  name: 'VinWidgetBarGraph',
  components: {
    IEcharts,
  },
  props: {
    yAxisData: {
      type: Array,
      default: () => [],
    },
    xAxisData: {
      type: Array,
      default: () => [],
    },
    formatter: {
      type: Object,
      default: () => {},
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  computed: {
    formattedData () {
      return this.yAxisData.map(d => formatNumber(d, this.formatter))
    },
    chartOptions () {
      return {
        tooltip: {
          trigger: 'item',
          backgroundColor: '#ffffff',
          padding: [12, 16, 12, 12],
          textStyle: {
            color: '#65747b',
            fontWeight: 'bolder',
            fontSize: 14,
          },
          extraCssText: 'box-shadow: 0px 0px 10px 0px rgba(37, 38, 94, 0.1);',
          formatter: (params) => {
            const opts = { type: 'float' }
            return `${formatNumber(params.data, opts).numeral} ${this.suffix}`
          },
        },
        xAxis: {
          type: 'category',
          data: this.xAxisData,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#65747b',
            fontWeight: '600',
            fontSize: 14,
            margin: 20,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: colors.grey,
              width: 1.5,
            },
          },
          splitNumber: 3,
          axisLabel: {
            color: '#65747b',
            fontWeight: '600',
            align: 'center',
            fontSize: 14,
            margin: 34,
          },
        },
        grid: {
          left: 0,
          top: 18,
          right: 0,
          bottom: 4,
          containLabel: true,
        },
        series: [
          {
            type: 'bar',
            cursor: 'default',
            data: this.formattedData.map(d => d.value()),
            barWidth: 32,
            itemStyle: {
              normal: {
                barBorderRadius: [100, 100, 0, 0],
                color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  colorStops: [{
                    offset: 0, color: colors['bar-0'],
                  }, {
                    offset: 1, color: colors['bar-1'],
                  }],
                  globalCoord: false,
                },
              },
            },
          },
        ],
      }
    },
  },
}
</script>
