<template>
  <div class="group px-6 py-4 rounded-md bg-white text-grey-dk tracking-wide leading-normal text-base flex items-center hover:bg-success-lt hover:text-white cursor-pointer">
    <div class="flex justify-center items-center w-12 h-12 rounded-lgr bg-primary-dk text-white  group-hover:text-grey-dk">
      <!-- <component :is="icon"></component> -->
      <VinIcon :icon="icon" />
    </div>
    <div class="ml-6 uppercase truncate">
      {{ $t(displayName) }}
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
export default {
  name: 'DashEditWidgetListItem',
  props: {
    widget: {
      type: String,
    },
  },
  data () {
    return {
      isHovered: false,
    }
  },
  computed: {
    packWidgets () {
      return get(this.$store.state.authModule.orgPack, 'widgets', [])
    },
    displayName () {
      if (this.packWidgets.length > 0) {
        const match = this.packWidgets.find(widget => widget.componentName === this.widget)
        return get(match, 'name', this.widget)
      }
      if (this.$root.$options.components[this.widget]) {
        return this.$root.$options.components[this.widget].extendOptions.displayName || this.widget
      }
      return this.widget
    },
    icon () {
      if (this.$root.$options.components[this.widget]) {
        return this.$root.$options.components[this.widget].extendOptions.icon || 'Journey'
      }
      return 'Journey'
    },
  },
}
</script>
