import {
  addDays,
  differenceInDays,
  isAfter,
  isEqual,
  isValid,
  parseISO,
  } from 'date-fns'

const snagQueryParamDate = (key, fallback) => {
  const query = window.location.search.substring(1)
  const pairs = query.split('&')
  let val = fallback
  pairs.forEach(p => {
    const pair = p.split('=')
    if (pair[0] === key) {
      val = decodeURIComponent(pair[1])
    }
  })
  return isValid(parseISO(val)) ? new Date(val) : fallback
}

export const makeSinceUntil = (duration = 7) => {
  const until = new Date()
  const since = addDays(until, (-1 * duration))
  return { since, until }
}

export const makeDefaultDates = () => {
  const defs = makeSinceUntil()
  const fbSince = defs.since
  const fbUntil = defs.until
  let since = snagQueryParamDate('since', fbSince)
  let until = snagQueryParamDate('until', fbUntil)
  let fromUrl = false
  if (isAfter(since, until)) {
    console.warn('invalid since/until')
    since = fbSince
    until = fbUntil
  }
  if (!isEqual(since, fbSince) || !isEqual(until, fbUntil)) {
    fromUrl = true
  }
  const diff = differenceInDays(until, since)

  const opts = [7, 14, 30]
  return {
    since,
    until,
    duration: opts.includes(diff) ? diff : 'custom',
    fromUrl,
  }
}
