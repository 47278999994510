import { saveAs } from 'file-saver'
import get from 'lodash.get'
import Vue from 'vue'

import axios from '../middleware/axios-store'

const module = {
  state: {
    results: {},
    fetching: {},
    fleetOverview: {
      ReportFleetCostOverview: {
        filter: 'hardAccel',
        prettyFilter: 'Acceleration',
        options: [
          {
            event: 'hardAccel',
            prettyEvent: 'Acceleration',
          },
          {
            event: 'hardDecel',
            prettyEvent: 'Braking',
          },
          {
            event: 'highSpeed',
            prettyEvent: 'High Speed',
          },
          {
            event: 'idling',
            prettyEvent: 'Idling',
          },
          {
            event: 'overRevving',
            prettyEvent: 'Over-Revving',
          },
        ],
      },
      ReportFleetFootprintOverview: {
        filter: 'hardAccel',
        prettyFilter: 'Acceleration',
        options: [
          {
            event: 'hardAccel',
            prettyEvent: 'Acceleration',
          },
          {
            event: 'co2Emissions',
            prettyEvent: 'Co2 Emissions',
          },
          {
            event: 'highSpeed',
            prettyEvent: 'High Speed',
          },
          {
            event: 'idling',
            prettyEvent: 'Idling',
          },
          {
            event: 'overRevving',
            prettyEvent: 'Over-Revving',
          },
        ],
      },
      ReportFleetRiskOverview: {
        filter: 'hardAccel',
        prettyFilter: 'Acceleration',
        options: [
          {
            event: 'hardAccel',
            prettyEvent: 'Acceleration',
          },
          {
            event: 'hardDecel',
            prettyEvent: 'Braking',
          },
          {
            event: 'continuousDriving',
            prettyEvent: 'Long Driving',
          },
          {
            event: 'highRiskHours',
            prettyEvent: 'High Risk Hours',
          },
          {
            event: 'highSpeed',
            prettyEvent: 'High Speed',
          },
          {
            event: 'overRevving',
            prettyEvent: 'Over-Revving',
          },
        ],
      },
    },
  },
  getters: {
    resultsForSlug: (state, getters, rootState) => slug => {
      const key = get(rootState, 'authModule.activeOrg.id')

      return get(state.results[key], slug, null)
    },
  },
  mutations: {
    SET_RESULTS (state, { respResults, orgId }) {
      if (!state.results[orgId]) {
        Vue.set(state.results, orgId, {})
      }

      for (let key in respResults) {
        Vue.set(state.results[orgId], key, respResults[key])
      }
    },
    SET_TABLE_SORT (state, { payload }) {
      if (get(state.results[payload.orgId], payload.slug)) {
        Vue.set(
          state.results[payload.orgId][payload.slug],
          'sortKey',
          payload.sortKey,
        )
      }

    },
    CLEAR_RESULTS_FOR_SLUG (state, { orgId, slug }) {
      if (state.results[orgId]) {
        Vue.delete(state.results[orgId], slug)
      }
    },
    CLEAR_DATA_MODULE (state) {
      state.results = {}
      const keys = Object.keys(state.fleetOverview)
      keys.forEach(k => {
        state.fleetOverview[k].filter = 'hardAccel'
        state.fleetOverview[k].prettyFilter = 'Acceleration'
      })
    },
    TOGGLE_FETCHING_STATUS_FOR_SLUGS (state, slugs) {
      let fetching = true
      if (state.fetching[slugs]) {
        fetching = !state.fetching[slugs]
      }
      Vue.set(state.fetching, slugs, fetching)
    },
    SET_OVERVIEW_FILTER (state, payload) {
      state.fleetOverview[payload.reportWidget].filter = payload.event
      state.fleetOverview[payload.reportWidget].prettyFilter = payload.prettyEvent
    },
  },
  actions: {
    GET_DATA_PROMISE ({ commit, state, dispatch, rootState }, payload) {
      if (!get(rootState, 'authModule.activeOrg.id') && !get(payload.orgId)) {
        throw new Error('missing org id when fetching data')
      }
      const orgId = get(payload, 'orgId', get(rootState, 'authModule.activeOrg.id'))
      const slugs = payload.slugs || payload
      if (!slugs) {
        throw new Error('must include slugs (comma seperated string)')
      }
      // check if this data request is currently being made
      if (state.fetching[slugs]) {
        return
      } else {
        // set fetching to true
        commit('TOGGLE_FETCHING_STATUS_FOR_SLUGS', slugs)
      }

      let request = {
        method: 'get',
        url: `/api/v2/orgs/${orgId}/data`,
      }
      let params = {
        types: slugs,
      }

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      params.timezone = timezone || 'UTC'

      // override date range params if any are passed into the payload
      // this is used to support custom durations for overview reports, etc
      params.since = payload.since || rootState.paramsModule.since
      params.until = payload.until || rootState.paramsModule.until

      const timeout = 1000 * 58

      // check for format param
      if (payload.format) {
        params.format = payload.format

        // add theme
        params.theme = rootState.themeModule.theme
        request.responseType = 'arraybuffer'

        if (payload.emailReport) {
          // export timeout on prev request
          params.emailReport = true
        } else {
          // add 58 second timeout to current request
          request.timeout = timeout
        }
      } else {
        // clear previously stored results
        if (Array.isArray(slugs)) {
          slugs.forEach(slug => {
            commit('CLEAR_RESULTS_FOR_SLUG', {
              orgId,
              slug,
            })
          })
        } else {
          commit('CLEAR_RESULTS_FOR_SLUG', {
            orgId,
            slug: slugs,
          })
        }
      }

      // check for single vehicle
      if (payload.vehicleId) {
        commit('SET_TARGET_VEHICLE', payload.vehicleId)
        params.vehicleId = payload.vehicleId
      }

      // set groupId param for non-widgets
      // but only set if group has vehicles
      // const groupVCount = get(rootState.groupsModule, 'activeGroup.vehicleCount', 0)
      if (rootState.groupsModule.activeGroup && slugs.indexOf('widget') === -1) {
        params.group = rootState.groupsModule.activeGroup.id
      }

      // finally, let payload.params win
      const winningParams = get(payload, 'params', {})
      for (let key in winningParams) {
        params[key] = winningParams[key]
      }

      request.params = params

      const getBustCachePairsBySlug = (slug) => {
        let pairs = [slug]
        rootState.reportsModule.bustCacheSlugGroups.forEach(group => {
          if (group.includes(slug)) {
            pairs = group
          }
        })

        return pairs
      }

      // If bustCache is true, we need to check if there are slug pairs that also require a cache bust
      // Skip for exported reports
      if (request.params.bustCache & !payload.format) {
        const slugPairs = getBustCachePairsBySlug(request.params.types)
        request.params.types = slugPairs.join()
      }


      return new Promise((resolve, reject) => {
        axios(request)
          .then(resp => {
            const status = get(resp, 'status')

            if (status === 202) {
              // we would have emailed the report.
              dispatch('SERVE_TOAST', {
                type: 'warning',
                message: 'error_too_many_report_rows_report_emailed',
              })

              const empty = {
                [slugs]: {
                  'generatedAt': Date.now(),
                  // "error": false,
                  'tooManyResults': true,
                },
              }
              commit('SET_RESULTS', {
                respResults: empty,
                orgId: orgId,
              })
              // set fetching to false and end progress
              commit('TOGGLE_FETCHING_STATUS_FOR_SLUGS', slugs)

              return resolve(resp)
            }

            // export the response if needed
            if (payload.format) {
              dispatch('EXPORT_REPORT_RESPONSE', {
                format: payload.format,
                response: resp,
              })
            }
            // set results in store
            commit('SET_RESULTS', {
              respResults: resp.data,
              orgId: orgId,
            })
            // set fetching to false and end progress
            commit('TOGGLE_FETCHING_STATUS_FOR_SLUGS', slugs)
            resolve(resp)
          })
          .catch(err => {
            // set fetching to false
            commit('TOGGLE_FETCHING_STATUS_FOR_SLUGS', slugs)
            // toast error for export
            const status = get(err, 'response.status')
            let errMessage = ''

            // export err or timeout
            if (payload.format && !payload.emailReport) {
              if (err.message === `timeout of ${timeout}ms exceeded`) {
                dispatch('SERVE_TOAST', {
                  type: 'info',
                  message: 'email_export_report',
                })

                payload.emailReport = true
                dispatch('GET_DATA_PROMISE', payload)
              }
              return resolve(err)
            }

            // non export err handling
            if (!payload.format) {
              switch (status) {
                case 416:
                  // in cases of a 416 (report is too large), we'll default to emailing
                  // the report to the user
                  // for now we're hardcoding the format to be xlsx because we know
                  // this format is supported by all current reports
                  payload.emailReport = true
                  payload.format = 'xlsx'
                  errMessage = 'error_too_many_report_rows'
                  break
                case 500:
                  errMessage = 'Uh oh! Something went wrong, please try again later.'
                  break
                case 501:
                  errMessage = 'Export is not yet available for this report.'
                  break
                default:
                  errMessage = 'Uh oh! Something went wrong, please try again later.'
              }

              // mock payload with empty response and timestamp
              if (Array.isArray(slugs)) {
                slugs.forEach(slug => {
                  const empty = {
                    [slug]: {
                      'generatedAt': Date.now(),
                      'error': true,
                    },
                  }
                  commit('SET_RESULTS', {
                    respResults: empty,
                    orgId: orgId,
                  })
                })
              } else {
                const empty = {
                  [slugs]: {
                    'generatedAt': Date.now(),
                    'error': true,
                  },
                }
                commit('SET_RESULTS', {
                  respResults: empty,
                  orgId: orgId,
                })
              }

              dispatch('SERVE_TOAST', {
                type: status === 416 ? 'warning' : 'danger',
                message: errMessage,
                forceClose: status === 416,
              })
              reject(err)
            }

          })
      })
    },
    EXPORT_REPORT_RESPONSE (context, payload) {
      const response = payload.response
      const disposition = response.headers['content-disposition']
      const filename = disposition.split('filename*=UTF-8\'\'')[1]
      const blobtype = response.headers['content-type']
      const blob = new Blob([response.data], {
        type: blobtype,
      })
      saveAs(blob, decodeURI(filename))
    },
  },
}

export default module
