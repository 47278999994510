<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="avg-mpg-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="gradientStart"
        />
        <stop
          offset="100%"
          :stop-color="gradientStop"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="48"
        height="48"
        fill="url(#avg-mpg-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <path
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M34.826 34.087a13.496 13.496 0 0 0 3.117-8.616c0-1.562-.28-3.055-.768-4.45C35.335 15.771 30.35 12 24.472 12c-5.889 0-10.881 3.784-12.714 9.048A13.422 13.422 0 0 0 11 25.47c0 2.076.473 4.04 1.312 5.797a13.501 13.501 0 0 0 1.805 2.819m15.73 1.51c0 .664-.54 1.203-1.204 1.203H20.3a1.204 1.204 0 0 1-1.204-1.204v-2.37c0-.665.54-1.204 1.204-1.204h8.343c.665 0 1.203.54 1.203 1.204v2.37zm8.096-10.215h-3.51m-19.778 0H11m4.963 4.913l-3.158 1.823m18.363-18.333l-1.735 3.003M24.472 12v3.496m-6.696-1.711l1.77 3.066m-6.693 1.824l3.11 1.795m17.126 9.888l3.09 1.772m-11.707-3.983a2.198 2.198 0 1 0 0-4.396 2.198 2.198 0 0 0 0 4.396zm1.846-3.39l7.78-5.024"
      />
    </g>
  </svg>
</template>

<script>
import iconMix from '../icon-mixin'
export default {
  name: 'VinAvgMPGIcon',
  mixins: [iconMix],
}
</script>
