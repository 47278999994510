const numeral = require('numeral')

numeral.zeroFormat('0')
numeral.nullFormat('0')

// config - locale
numeral.register('locale', 'da-dk', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MIO',
    billion: 'MIA',
    trillion: 'B',
  },
  ordinal () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: 'kr.',
  },
})

numeral.register('locale', 'en-gb', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (~~(n % 100 / 10) === 1) ? 'th' :
      (b === 1) ? 'st' :
        (b === 2) ? 'nd' :
          (b === 3) ? 'rd' : 'th'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '£',
  },
})

numeral.register('locale', 'SEK', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MM',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (b === 1 || b === 3) ? 'er' :
      (b === 2) ? 'do' :
        (b === 7 || b === 0) ? 'mo' :
          (b === 8) ? 'vo' :
            (b === 9) ? 'no' : 'to'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: 'kr',
  },
})

numeral.register('locale', 'BRL', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MM',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (b === 1 || b === 3) ? 'er' :
      (b === 2) ? 'do' :
        (b === 7 || b === 0) ? 'mo' :
          (b === 8) ? 'vo' :
            (b === 9) ? 'no' : 'to'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: 'R$',
  },
})


numeral.register('locale', 'MXN', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MM',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (b === 1 || b === 3) ? 'er' :
      (b === 2) ? 'do' :
        (b === 7 || b === 0) ? 'mo' :
          (b === 8) ? 'vo' :
            (b === 9) ? 'no' : 'to'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '$',
  },
})


numeral.register('locale', 'COP', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MM',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (b === 1 || b === 3) ? 'er' :
      (b === 2) ? 'do' :
        (b === 7 || b === 0) ? 'mo' :
          (b === 8) ? 'vo' :
            (b === 9) ? 'no' : 'to'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '$',
  },
})


numeral.register('locale', 'CLP', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MM',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (b === 1 || b === 3) ? 'er' :
      (b === 2) ? 'do' :
        (b === 7 || b === 0) ? 'mo' :
          (b === 8) ? 'vo' :
            (b === 9) ? 'no' : 'to'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '$',
  },
})


numeral.register('locale', 'es-es', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MM',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (b === 1 || b === 3) ? 'er' :
      (b === 2) ? 'do' :
        (b === 7 || b === 0) ? 'mo' :
          (b === 8) ? 'vo' :
            (b === 9) ? 'no' : 'to'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '€',
  },
})

numeral.register('locale', 'es-pe', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MM',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (b === 1 || b === 3) ? 'er' :
      (b === 2) ? 'do' :
        (b === 7 || b === 0) ? 'mo' :
          (b === 8) ? 'vo' :
            (b === 9) ? 'no' : 'to'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: 'S/',
  },
})
numeral.register('locale', 'es-mx', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MM',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (b === 1 || b === 3) ? 'er' :
      (b === 2) ? 'do' :
        (b === 7 || b === 0) ? 'mo' :
          (b === 8) ? 'vo' :
            (b === 9) ? 'no' : 'to'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '$',
  },
})
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal (n) {
    const b = n % 10
    return (b === 1 || b === 3) ? 'er' :
      (b === 2) ? 'do' :
        (b === 7 || b === 0) ? 'mo' :
          (b === 8) ? 'vo' :
            (b === 9) ? 'no' : 'to'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '€',
  },
})
numeral.register('locale', 'no', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal: function () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: 'kr',
  },
})
numeral.register('locale', 'hu', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'tis.',
    million: 'mil.',
    billion: 'mlr.',
    trillion: 'bil.',
  },
  ordinal: function () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: 'Ft',
  },
})
numeral.register('locale', 'hr', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'tis.',
    million: 'mil.',
    billion: 'mlr.',
    trillion: 'bil.',
  },
  ordinal: function () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: 'kn',
  },
})
numeral.register('locale', 'sl', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'MM',
    billion: 'B',
    trillion: 'T',
  },
  ordinal: function () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()

  currency: {
    symbol: '€',
  },
})
numeral.register('locale', 'pl', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'TYS.',
    million: 'MLN',
    billion: 'MLD',
    trillion: 'BLN',
  },
  ordinal () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: 'zł',
  },
})

numeral.register('locale', 'ru-ua', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'тыс.',
    million: 'млн',
    billion: 'B',
    trillion: 'T',
  },
  ordinal () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '₴',
  },
})

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'тыс.',
    million: 'млн.',
    billion: 'млрд.',
    trillion: 'трлн.',
  },
  ordinal: function () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '₽',
  },
})

numeral.register('locale', 'tr', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal: function () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: '₺',
  },
})

numeral.register('locale', 'sv-se', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 't',
    million: 'M',
    billion: 'Md',
    trillion: 'Tn',
  },
  ordinal: function () {
    return '.'
  },
  // ./numeral-utils/fixtures.js currencySymbolMap()
  currency: {
    symbol: 'kr',
  },
})

export default numeral
