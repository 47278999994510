<template>
  <draggable
    v-model="localWidgets"
    class="w-full h-full cursor-pointer"
    :group="{
      name: 'widgets'
    }"
    ghost-class="block-ghost"
    draggable=".widget-handle"
    :animation="150"
    :fallback-on-body="true"
    @change="handleChange($event)"
  >
    <div
      v-if="!placeholder"
      :class="isDisabled ? '' : 'widget-handle'"
    >
      <div class="flex flex-col text-center">
        <div
          :class="isDisabled ? 'bg-grey-lt text-grey hover:cursor-default' : 'card bg-white text-grey-dk hover:cursor-pointer'"
          class="flex flex-col items-center justify-center h-64 rounded-lg px-4"
        >
          <VinIcon
            class="mx-auto mb-4 mt-6"
            :class="isDisabled ? 'opacity-50' : ''"
            :icon="icon"
          />
          <span class="tracking-wide leading-normal text-lg uppercase h-1/4">
            {{ $t(displayName) }}
          </span>
        </div>
      </div>
    </div>
  </draggable>
</template>

<script>
import get from 'lodash.get'
import draggable from 'vuedraggable'
export default {
  name: 'DashEditSectionBlock',
  components: {
    'draggable': draggable,
  },
  props: {
    sectionIndex: {
      type: Number,
      default: 0,
    },
    widgetIndex: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      localWidgets: [],
    }
  },
  computed: {
    widget () {
      return this.$store.state.dashboardModule.sections[this.sectionIndex][this.widgetIndex]
    },
    orgPack () {
      return this.$store.state.authModule.orgPack
    },
    dashWidgets () {
      const dashWidgets = this.$store.getters.widgetFilter(get(this.orgPack, 'widgets', []), 'dashboard-widget')
      return dashWidgets.map(w => w.componentName)
    },
    isDisabled () {
      return !this.dashWidgets.includes(this.widget)
    },
    placeholder () {
      return this.widget === '' || this.localWidgets.length === 0
    },
    displayName () {
      if (this.$root.$options.components[this.widget]) {
        return this.$root.$options.components[this.widget].extendOptions.displayName || this.widget
      }
      return this.widget
    },
    icon () {
      if (this.$root.$options.components[this.widget]) {
        return this.$root.$options.components[this.widget].extendOptions.icon || 'Journey'
      }
      return 'Journey'
    },
  },
  watch: {
    widget () {
      this.localWidgets = [this.widget]
    },
    localWidgets () {
      // pop off the old widget when a new widget is dragged
      // on top of an existing one
      if (this.localWidgets.length > 1) {
        this.localWidgets.pop()
      }
    },
  },
  created () {
    this.localWidgets = [this.widget]
  },
  methods: {
    handleChange (change) {
      if (change.added) {
        this.$store.commit('UPDATE_WIDGET', {
          widget: change.added.element,
          sectionIndex: this.sectionIndex,
          widgetIndex: this.widgetIndex,
        })
      }

      if (change.removed) {
        this.$store.commit('REMOVE_WIDGET', {
          widget: this.widget,
          widgetIndex: this.widgetIndex,
          sectionIndex: this.sectionIndex,
        })
      }
    },
  },
}
</script>
