import debounce from 'lodash.debounce'
import get from 'lodash.get'

const module = {
  state: {
    liveTrackingSocket: null,
    socketStatus: null,
    retryCount: 0,
    lastIncomingMessage: null,
    freshLiveMapVehicles: [],
  },
  mutations: {
    REGISTER_VEHICLE_STREAM: (state, vehicleIds) => {
      if (state.liveTrackingSocket && vehicleIds && vehicleIds.length > 0 && get(state.liveTrackingSocket, 'readyState', 3) === 1) {
        state.liveTrackingSocket.send(JSON.stringify({ vehicleIds: vehicleIds }))
      }
    },
    SET_SOCKET_STATUS: (state, status) => {
      state.socketStatus = status || state.liveTrackingSocket.readyState
    },
    SET_SOCKET_RETRY_COUNT: (state, count) => {
      state.retryCount = count
    },
    SET_INCOMING: debounce((state, msg) => {
      state.lastIncomingMessage = null
      state.lastIncomingMessage = msg
      setTimeout(() => {
        // compare the ts
        if (state.lastIncomingMessage && state.lastIncomingMessage?.timestamp === msg.timestamp) {
          state.lastIncomingMessage = null
        }
      }, 5000)
    }, 500),
    APPEND_FRESH_VEHICLE: (state, vehicleId) => {
      state.freshLiveMapVehicles.push(vehicleId)
    },
    WIPE_FRESH_VEHICLES: (state) => {
      state.freshLiveMapVehicles = []
    },
  },
  actions: {
    OPEN_TRACKING_SOCKET({ commit, dispatch, state, getters }, orgId) {
      const canTrack = getters.hasMixin('lt')
      if (!canTrack) {
        return
      }
      const readyState = state.liveTrackingSocket?.readyState ?? 3
      if (readyState === 1) {
        return
      }
      if (readyState === 0) {
        dispatch('CLOSE_TRACKING_SOCKET')
      }
      if (!orgId) {
        return
      }

      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
      const host = window.location.host


      // create the ws
      state.liveTrackingSocket = new WebSocket(`${protocol}${host}/api/v1/orgs/${orgId}/livetracking`)

      state.liveTrackingSocket.onopen = () => {
        commit('SET_SOCKET_STATUS')
      }

      state.liveTrackingSocket.onclose = () => {
        commit('SET_SOCKET_STATUS')
      }

      state.liveTrackingSocket.onerror = () => {
        state.liveTrackingSocket.close(4001, 'socket errored, most likely because of an expired session')
      }

      state.liveTrackingSocket.onmessage = message => {
        dispatch('HANDLE_TRACKING_MESSAGE', message)
      }
    },
    RECONNECT_TRACKING_SOCKET ({ dispatch }, orgId) {
      setTimeout(() => {
        dispatch('OPEN_TRACKING_SOCKET', orgId)
      }, 3000)
    },
    HANDLE_TRACKING_MESSAGE ({ commit }, message) {
      const data = get(message, 'data', '{}')
      let split = data.split('\n')
      if (split.length > 0) {
        const telem = JSON.parse(split[0])
        if (get(telem, 'code')) {
          return
        }
        const stopped = get(telem, 'data.tripStopped')
        const tripid = get(telem, 'data.tripId')
        const location = get(telem, 'data.location')
        const vehicleId = get(telem, 'vehicleId')
        const timestamp = get(telem, 'timestamp')
        const speed = get(telem, 'data.vehicleSpeed', null)
        commit('APPEND_FRESH_VEHICLE', vehicleId)
        if (location && vehicleId && timestamp) {
          const payload = {
            vehicleId: vehicleId,
            timestamp: timestamp,
            location: {
              lat: location.lat,
              lng: location.lon,
            },
            speed: speed,
          }
          commit('UPDATE_LOCATION_BY_VEHICLE_ID', payload)
          commit('SET_INCOMING', payload)
        }
        if (stopped && vehicleId && timestamp) {
          // from the trip-stopped telem packet on web socket
          const payload = {
            vehicleId: vehicleId,
            timestamp: timestamp,
            stopped: stopped,
            tripid: tripid,
          }
          commit('UPDATE_TRIP_STATE_BY_VEHICLE_ID', payload)
          commit('SET_INCOMING', payload)
        }
      }
    },
    CLOSE_TRACKING_SOCKET ({ state }) {
      if (state.liveTrackingSocket) {
        state.liveTrackingSocket.close(1000)
      }
    },
    TOGGLE_TRACKING_STREAM ({ commit, dispatch, rootState }, flag) {
      // open if flag is true
      if (flag) {
        dispatch('OPEN_TRACKING_SOCKET', rootState.authModule.activeOrg.id)
      } else {
        // close otherwise
        dispatch('CLOSE_TRACKING_SOCKET')
      }
      // appply flag to map setting
      commit('TOGGLE_MAP_SETTING_LIVE_STREAM', flag)
    },
  },
}

export default module
