<template>
  <header class="app-header-mobile bg-primary-dk mx-auto z-50">
    <div
      id="fleet-dashboard-container"
      class="flex justify-between items-center h-full"
    >
      <div class="flex items-center pl-4">
        <img
          v-if="isAuthenticated"
          :src="require(`@/assets/themes/${logoSrc}`)"
          alt="main logo"
          class="w-8 mr-3"
          @click="toggleDrawer"
        >
      </div>
      <div class="flex items-center">
        <div
          v-if="isDemo"
          class="bg-copy border-2 border-white px-3 py-2px rounded mr-3"
        >
          <span class="leading-none text-white uppercase font-medium">
            {{ $t('demo user') }}
          </span>
        </div>
        <button
          class="pr-4"
          @click="navToProfile()"
        >
          <VinGravatar
            v-if="isAuthenticated"
            :circle="true"
            :size="40"
            :email="email"
            :name="name"
            @click.native="navToProfile()"
            @click="navToProfile()"
          />
        </button>
      </div>
    </div>
  </header>
</template>
<script>
import get from 'lodash.get'
import { mapGetters } from 'vuex'
export default {
  name: 'AppHeaderMobile',
  computed: {
    ...mapGetters(['isAuthenticated', 'isSearching']),
    session () {
      return this.$store.state.authModule.session
    },
    email () {
      const email = this.session ? this.session.email : ''
      return this.isMimic ? this.$store.state.authModule.mimic.user.email : email
    },
    userIcon () {
      return this.isMimic ? '' : get(this.session, 'userIcon', '')
    },
    isDemo () {
      const accessLevel = this.session ? this.session.accessLevel : ''
      return accessLevel === 'demo'
    },
    name () {
      return this.session ? this.session.firstName + this.session.lastName : ''
    },
    first () {
      const first = this.session ? this.session.firstName : ''
      return this.isMimic ? this.$store.state.authModule.mimic.user.firstName : first
    },
    last () {
      const last = this.session ? this.session.lastName : ''
      return this.isMimic ? this.$store.state.authModule.mimic.user.lastName : last
    },
    userId () {
      return this.session ? this.session.id : ''
    },
    activeOrg () {
      return this.$store.state.authModule.activeOrg
    },
    logoSrc () {
      return `${this.$store.state.themeModule.theme}/app-logo-mobile.png`
    },
    isMimic () {
      return this.$store.state.authModule.mimic.isActive
    },
  },
  methods: {
    logout () {
      this.$store.dispatch('DELETE_CURRENT_SESSION')
    },
    navToProfile () {
      if (!this.isMimic) {
        this.$router.push({ name: 'Profile', params: { userId: this.userId } })
      }
    },
    toggleDrawer () {
      this.$store.commit('TOGGLE_DRAWER', !this.$store.state.baseModule.mobileDrawerOpen)
    },
  },
}
</script>
<style>
.app-header-mobile {
  height: 58px;
  overflow: hidden;
  position: fixed;
  width: 100%;
}
</style>
