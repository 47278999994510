
<template>
  <div id="view-dashedit">
    <AppHeaderDefault />
    <div class="flex items-start justify-between">
      <div
        id="fleet-dashboardedit-container"
        class="flex justify-end container mr-4 pr-0 bg-grey-ltr"
      >
        <DashEditPreview class="max:w-4/5 max:pr-4 hd" />
      </div>
      <DashEditSidebar class="w-1/3 self-stretch" />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/App/AppFooter'
import AppHeaderDefault from '@/components/App/AppHeader/AppHeaderDefault'

import DashEditPreview from '../components/DashEdit/DashEditPreview'
import DashEditSidebar from '../components/DashEdit/DashEditSidebar'
export default {
  name: 'ViewDashboardEditor',
  components: {
    'AppHeaderDefault': AppHeaderDefault,
    'DashEditSidebar': DashEditSidebar,
    'DashEditPreview': DashEditPreview,
    'AppFooter': AppFooter,
  },
}
</script>
<style scoped>
@media (min-width: 1700px) {
  .hd {
    padding-left: 3vw;
  }
}
@media (min-width: 2560px) {
  .hd {
    padding-left: 4vw;
  }
}
</style>
