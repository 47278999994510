<template>
  <DashboardMapSideMenu
    :map-height="mapHeight"
    :show="show"
  >
    <template #content>
      <div class="w-full text-left h-full overflow-y-scroll">
        <div class="px-12 flex items-center justify-between mt-8 mb-6">
          <span class="text-grey-dk text-lg tracking-wide uppercase">
            {{ $t('Map Settings') }}
          </span>
          <div class="ml-auto text-grey-dk hover:text-cta-lt hover:cursor-pointer">
            <svg
              class="stroke-current"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              @click="$emit('close')"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              >
                <path d="M13 1L1 13M1 1l12 12" />
              </g>
            </svg>
          </div>
        </div>
        <hr class="w-full border-grey">
        <div class="px-12 flex flex-col my-6">
          <span class="text-copy text-lg font-bold">
            {{ $t('Vehicle Status') }}
          </span>
          <DashboardMapSettingsStatus />
        </div>
        <hr class="w-full border-grey">
        <DashboardMapSettingsSwitchPanel />
      </div>
    </template>
  </DashboardMapSideMenu>
</template>

<script>
import DashboardMapSideMenu from '../DashboardMapSideMenu.vue'
import DashboardMapSettingsStatus from './DashboardMapSettingsStatus.vue'
import DashboardMapSettingsSwitchPanel from './DashboardMapSettingsSwitchPanel.vue'
export default {
  name: 'DashboardMapSettings',
  components: {
    DashboardMapSettingsStatus,
    DashboardMapSettingsSwitchPanel,
    DashboardMapSideMenu,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mapHeight: {
      type: String,
      default: '60vh',
    },
  },
  methods: {
    handleAutoComplete (payload) {
      this.$emit('mapSearch', payload)
    },
    handleCancel () {
      this.$emit('cancel')
    },
  },
}
</script>
