<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-long-drive-icon"
        x1="100%"
        x2="15.549%"
        y1="-23.897%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#FCE41A"
        />
        <stop
          offset="100%"
          stop-color="#F8C40B"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-long-drive-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M27.765 41.264a13.72 13.72 0 0 1-9.505-3.772 1.475 1.475 0 1 1 2.034-2.137 10.787 10.787 0 0 0 7.471 2.947 11.082 11.082 0 0 0 3.066-.427 1.474 1.474 0 0 1 .81 2.83 14.03 14.03 0 0 1-3.876.56zm13.47-11.362c.09-.63.135-1.265.132-1.901-.105-7.42-6.197-13.353-13.616-13.263a13.72 13.72 0 0 0-12.29 7.56 1.476 1.476 0 0 0 2.652 1.297A10.743 10.743 0 0 1 27.75 17.7c5.792-.09 10.564 4.524 10.67 10.316.001.493-.034.985-.104 1.473a1.474 1.474 0 0 0 1.238 1.68h.25c.74.008 1.37-.534 1.474-1.267h-.044zm4.303 2.034c.095-.366.125-.745.088-1.12a1.474 1.474 0 0 0-1.606-1.282 1.474 1.474 0 0 0-1.267 1.591c-.295 1.105-6.013 2.417-15.74 0-7.368-1.857-12.408-4.804-13.557-6.764a.958.958 0 0 1-.192-.604 1.474 1.474 0 0 0-2.844-.767c-.239.98-.063 2.014.486 2.86 1.784 3.02 7.81 6.218 15.37 8.12a46.038 46.038 0 0 0 10.803 1.473c4.362.103 7.796-.958 8.474-3.507h-.015z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconLongDrive',
}
</script>
