<template>
  <div
    v-if="showIf"
    class="flex flex-row flex-wrap"
  >
    <div
      v-for="(item, i) in items"
      :key="i"
      :class="makeClass(i)"
      class="mr-1 text-lg"
      @click="item.meta.canSwitchOrg ? handleOrgSwitch(item.org) : null"
    >
      {{ itemText(item, i) }}
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
export default {
  name: 'VinBreadcrumb',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    showIf () {
      return this.items.length > 1
    },
  },
  methods: {
    itemText (item, i) {
      const text = get(item, 'org.name', '')
      return i === this.items.length - 1 ? text : `${text} > `
    },
    makeClass (i) {
      return i === this.items.length - 1 ? 'font-semibold text-primary-dk cursor-default' : 'hover:cursor-pointer hover:text-primary-dk transition-2s'
    },
    handleOrgSwitch (org) {
      if (!org) {
        console.warn('breadcrumb item has no org property')
        return
      }
      const payload = {
        id: org.id,
        name: org.name,
        path: org.path,
        settings: org.settings,
        createdAt: org.createdAt,
      }
      this.$store.dispatch('SWITCH_ORG_AND_CLEAR_APP', payload)

      this.$router.push({
        name: 'Admin',
        params: {
          orgId: payload.id,
        },
      })
    },
  },
}
</script>
