<template>
  <div class="card-item">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VinCardItem',
}
</script>
<style>
.card-item:first-child {
  @apply pt-0;
}
.card-item:last-child {
  @apply rounded-b-md pb-4;
}
</style>
