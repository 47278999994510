<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-driving-time-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#17D4AA"
        />
        <stop
          offset="100%"
          stop-color="#019F7C"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-driving-time-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        transform="matrix(-1 0 0 1 43.4 28)"
      >
        <path d="M10.453 11.878h11.049M3.423 11.58h-.9a1.265 1.265 0 0 1-1.195-.85L.307 7.79a1.6 1.6 0 0 1 .312-1.585L5.624.541A1.6 1.6 0 0 1 6.823 0h10.348a1.6 1.6 0 0 1 1.216.561l2.96 3.466a1.6 1.6 0 0 0 .954.54l7.458 1.245a1.6 1.6 0 0 1 1.325 1.768l-.331 2.766a1.4 1.4 0 0 1-1.39 1.234" />
        <ellipse
          cx="7.093"
          cy="11.754"
          rx="3.36"
          ry="3.413"
        />
        <ellipse
          cx="25.013"
          cy="11.754"
          rx="3.36"
          ry="3.413"
        />
        <path d="M17.6 4.918h-3.747l-1.409-1.94" />
      </g>
      <g
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      >
        <path d="M43.546 29.71a8.167 8.167 0 1 0-11.6-5.451M39.9 17.5v4.9l3.267 1.633" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconDrivingTime',
}
</script>
