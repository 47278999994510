<template>
  <div>
    <hr class="w-full border-grey">
    <div class="flex">
      <span
        class="text-base text-cta-lt mt-4 cursor-pointer hover:underline"
        @click="ctaAction"
      >
        {{ $t(ctaTitle) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VinSmallWidgetFooter',
  props: {
    ctaTitle: {
      type: String,
      default: 'View More',
    },
    ctaAction: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
