// this module is used for the pack detail/editing page in admin settings
import get from 'lodash.get'
import Vue from 'vue'

import axios from '../middleware/axios-store'

const module = {
  state: {
    targetConfigPack: null,
    mobileConfigDefault: {
      accountSections: [{
        items: ['customize'],
        slug: 'dashboard',
      }, {
        items: ['scheduled_reports'],
        slug: 'documents',
      }, {
        items: ['app_settings'],
        slug: 'settings',
      }, {
        items: ['personal_information', 'devices', 'working_hours'],
        slug: 'profile',
      }, {
        items: ['settings', 'requests'],
        slug: 'privacy',
      }, {
        items: ['website', 'customer_support'],
        slug: 'contact_us',
      }],
      dashboardCards: ['driver_score', 'recent_journey', 'driving_performance', 'badges', 'driver_stats', 'engine_errors', 'driver_privacy_scheduling'],
      options: {
        dashboardNumDaysHistory: 7,
        defaultNumberOfWeeksForJourneys: 1,
        dpoAddress: 'Data Protection Officer, ALD Automotive Limited, Oakwood Drive, Emerson\'s Green. Bristol BS16 7LB',
        dpoEmail: 'dpo@aldautomotive.com',
        maximumDayRangeForJourneySelection: 90,
        minTripsForValidDashboardData: 5,
        onboardingVerificationSigninIntervalSeconds: 15,
        privatePolicyUrl: 'https://fleet{host}/driver/privacy?name=ald_{country_code}_privacy',
        showJourneyEventFilterButtons: true,
        showTripDetailsMapDots: true,
        supportEmail: 'support@ald.com',
        supportUrl: 'https://www.aldautomotive.com/about-ald-automotive/contact-us',
        termsAndConditionsUrl: 'https://fleet{host}/driver/tos?name=ald_{country_code}_tos',
        tripDetailAllowed: true,
        tripListItem: 'detailed_map',
      },
      reports: ['report_driver_harsh_decel', 'report_driver_over_revving', 'report_driver_driving_without_break', 'report_driver_high_daily_hours', 'report_driver_high_mileage', 'report_driver_idling', 'report_driver_high_speed', 'report_driver_high_risk_hours', 'report_driver_high_trip_mileage', 'report_driver_journeys', 'report_driver_harsh_accel'],
      scheduledReports: ['report_driver_journeys'],
      tabs: ['dashboard', 'journeys', 'account'],
    },
    mobileConfigBase: {
      dashboardCards: [
        {
          name: 'Driver Score',
          slug: 'driver_score',
          simpleAllowed: false,
        },
        {
          name: 'Recent Journey',
          slug: 'recent_journey',
          simpleAllowed: false,
        },
        {
          name: 'Engine Errors',
          slug: 'engine_errors',
          simpleAllowed: true,
        },
        {
          name: 'Driving Performance',
          slug: 'driving_performance',
          simpleAllowed: false,
        },
        {
          name: 'Driving Stats',
          slug: 'driver_stats',
          simpleAllowed: true,
        },
        {
          name: 'Driving Time & Distance',
          slug: 'driving_time_and_distance',
          simpleAllowed: true,
        },
        {
          name: 'Badges',
          slug: 'badges',
          simpleAllowed: false,
        },
        {
          name: 'Driver Privacy Scheduling',
          slug: 'driver_privacy_scheduling',
          simpleAllowed: false,
        },
      ],
      reports: [
        {
          slug: 'report_driver_harsh_accel',
          name: 'Acceleration',
          simpleAllowed: false,
        },
        {
          slug: 'report_driver_harsh_decel',
          name: 'Braking',
          simpleAllowed: false,
        },
        {
          slug: 'report_driver_idling',
          name: 'Idling',
          simpleAllowed: false,
        },
        {
          slug: 'report_driver_over_revving',
          name: 'Over Revving',
          simpleAllowed: false,
        },
        {
          slug: 'report_driver_high_speed',
          name: 'High Speed',
          simpleAllowed: false,
        },
        {
          slug: 'report_driver_driving_without_break',
          name: 'Long Driving',
          simpleAllowed: true,
        },
        {
          slug: 'report_driver_high_risk_hours',
          name: 'High Risk Hours',
          simpleAllowed: true,
        },
        {
          slug: 'report_driver_high_daily_hours',
          name: 'High Daily Hours',
          simpleAllowed: true,
        },
        {
          slug: 'report_driver_high_trip_mileage',
          name: 'High Trip Mileage',
          simpleAllowed: true,
        },
        {
          slug: 'report_driver_high_mileage',
          name: 'High Mileage Trips',
          simpleAllowed: true,
        },
        {
          slug: 'report_driver_journeys',
          name: 'Journey Summary',
          scheduleable: true,
          simpleAllowed: true,
        },
      ],
      templates: [
        {
          key: 'tripListItem',
          title: 'Trip List Item',
          options: [
            {
              name: 'Full Preview',
              value: 'detailed_map',
              description: 'Inludes route map preview and trip details',
            },
            {
              name: 'Simple Preview',
              value: 'simple',
              description: 'Only includes high level summary trip information and does not include a map.',
            },
          ],
        },
        {
          key: 'tripDetailAllowed',
          title: 'Trip Detail',
          options: [
            {
              name: 'Full Detail',
              value: true,
              description: 'Allows user to drill into a trip and view full trip details.',
            },
            {
              name: 'No Trip Detail',
              value: false,
              description: 'Prevents user from drilling into a trip, and only shows trip preview.',
            },
          ],
        },
      ],
    },
  },
  getters: {
    widgetFilter: () => (arr, match) => {
      if (arr.length === 0) {
        return []
      }
      return arr.filter(w => {
        const type = get(w, 'type', '')
        return type.substring(0, match.length) === match
      })
    },
    globalReportWidgets (state, getters, rootState) {
      return getters.widgetFilter(rootState.adminModule.globalWidgets, 'report')
    },
    getWidgetsForType: (state, getters, rootState) => (type) => {
      return getters.widgetFilter(rootState.adminModule.globalWidgets, type)
    },
    targetDashboardWidgets: (state, getters) => {
      const widgets = get(state.targetConfigPack, 'widgets', [])
      return getters.widgetFilter(widgets, 'dashboard-widget')
    },
  },
  mutations: {
    SET_TARGET_CONFIG_PACK: (state, pack) => {
      state.targetConfigPack = pack
    },
    SET_IS_SIMPLE_ON_TARGET_PACK: (state, flag) => {
      Vue.set(state.targetConfigPack, 'isSimple', flag)
    },
    SET_SERVICE_MAINTENANCE_ON_TARGET_PACK: (state, flag) => {
      Vue.set(state.targetConfigPack, 'maintenanceEnabled', flag)
    },
    SET_ALLOW_DRIVER_PRIVACY_ON_TARGET_PACK: (state, flag) => {
      Vue.set(state.targetConfigPack, 'allowDriverPrivacy', flag)
    },
  },
  actions: {
    SET_PACK_MOBILE_DEFAULT: ({ state, rootState, commit }, payload) => {
      if (!rootState.adminModule.org.id) {
        throw new Error('missing org id when updating pack mobile options')
      }
      let defaultConfig = state.mobileConfigDefault

      axios.put(`/api/v1/orgs/${rootState.adminModule.org.id}/packs/${payload.id}/mobile-config`, {
        pack: {
          mobileConfig: defaultConfig,
        },
      }).then(resp => {
        commit('PUSH_PACK', resp.data)
      })
        .catch(err => {
          throw err
        })
    },
    UPDATE_PACK_MOBILE_DASHBOARD_CARDS: ({ state, rootState }, cards) => {
      if (!rootState.adminModule.org.id) {
        throw new Error('missing org id when updating pack mobile setting')
      }
      let mobileConfig = get(state.targetConfigPack, 'mobileConfig', {})
      mobileConfig['dashboardCards'] = cards
      axios.put(`/api/v1/orgs/${rootState.adminModule.org.id}/packs/${state.targetConfigPack.id}/mobile-config`, {
        pack: {
          mobileConfig: mobileConfig,
        },
      })
        .catch(err => {
          throw err
        })
    },
    UPDATE_PACK_MOBILE_OPTION: ({ state, rootState }, payload) => {
      if (!rootState.adminModule.org.id) {
        throw new Error('missing org id when updating pack mobile options')
      }
      let mobileConfig = get(state.targetConfigPack, 'mobileConfig', {})
      let options = get(mobileConfig, 'options', {})
      Vue.set(options, payload.key, payload.value)
      Vue.set(mobileConfig, 'options', options)

      axios.put(`/api/v1/orgs/${rootState.adminModule.org.id}/packs/${state.targetConfigPack.id}/mobile-config`, {
        pack: {
          mobileConfig: mobileConfig,
        },
      })
        .catch(err => {
          throw err
        })
    },
    UPDATE_PACK_MOBILE_REPORTS: ({ state, rootState }, reports) => {
      if (!rootState.adminModule.org.id) {
        throw new Error('missing org id when updating pack mobile reports')
      }
      let mobileConfig = get(state.targetConfigPack, 'mobileConfig', {})
      Vue.set(mobileConfig, 'reports', reports)

      axios.put(`/api/v1/orgs/${rootState.adminModule.org.id}/packs/${state.targetConfigPack.id}/mobile-config`, {
        pack: {
          mobileConfig: mobileConfig,
        },
      })
        .catch(err => {
          throw err
        })
    },
    UPDATE_PACK_MOBILE_SCHEDULED_REPORTS: ({ state, rootState }, reports) => {
      if (!rootState.adminModule.org.id) {
        throw new Error('missing org id when updating pack mobile scheduledReports')
      }
      let mobileConfig = get(state.targetConfigPack, 'mobileConfig', {})
      Vue.set(mobileConfig, 'scheduledReports', reports)

      axios.put(`/api/v1/orgs/${rootState.adminModule.org.id}/packs/${state.targetConfigPack.id}/mobile-config`, {
        pack: {
          mobileConfig: mobileConfig,
        },
      })
        .catch(err => {
          throw err
        })
    },
    TOGGLE_PACK_MOBILE_CONFIG_SIMPLE: ({ state, rootState }, isSimple) => {
      // grab the current config and options
      let mobileConfig = get(state.targetConfigPack, 'mobileConfig', {})
      let options = get(mobileConfig, 'options', {})

      // create new options based on isSimpleFlag
      const newOptions = [
        {
          key: 'showJourneyEventFilterButtons',
          value: !isSimple,
        },
        {
          key: 'tripDetailAllowed',
          value: !isSimple,
        },
        {
          key: 'tripListItem',
          value: isSimple ? 'simple' : 'detailed_map',
        },
      ]
      // update config with new options
      newOptions.forEach(o => {
        Vue.set(options, o.key, o.value)
        Vue.set(mobileConfig, 'options', options)
      })

      // Scrub cards and reports so only simple can be added
      if (isSimple) {
        let cards = get(mobileConfig, 'dashboardCards', [])
        const filteredCards = cards.filter(card => {
          return state.mobileConfigBase.dashboardCards.some(dashCard => {
            return dashCard.slug === card && dashCard.simpleAllowed
          })
        })
        Vue.set(mobileConfig, 'dashboardCards', filteredCards)

        let reports = get(mobileConfig, 'reports', [])
        const filteredReports = reports.filter(report => {
          return state.mobileConfigBase.reports.some(r => {
            return r.slug === report && r.simpleAllowed
          })
        })
        Vue.set(mobileConfig, 'reports', filteredReports)

        let scheduledReports = get(mobileConfig, 'scheduledReports', [])
        const filteredScheduledReports = scheduledReports.filter(report => {
          return state.mobileConfigBase.reports.some(r => {
            return r.slug === report && r.simpleAllowed && r.scheduleable
          })
        })
        Vue.set(mobileConfig, 'scheduledReports', filteredScheduledReports)
      }

      axios.put(`/api/v1/orgs/${rootState.adminModule.org.id}/packs/${state.targetConfigPack.id}/mobile-config`, {
        pack: {
          mobileConfig: mobileConfig,
        },
      })
        .catch(err => {
          throw err
        })
    },
    SET_IS_SIMPLE_ON_TARGET_PACK: ({ state, rootState, commit, dispatch }, flag) => {
      commit('SET_IS_SIMPLE_ON_TARGET_PACK', flag)

      dispatch('PUT_PACK_PROMISE', state.targetConfigPack)
        .then(() => {
          // update the mobile config
          dispatch('TOGGLE_PACK_MOBILE_CONFIG_SIMPLE', flag)

          let orgPack = rootState.authModule.orgPack
          if (state.targetConfigPack.id === orgPack.id) {
            commit('SET_ORG_PACK_SIMPLE', flag)
          }
        })
        .catch(err => {
          commit('SET_IS_SIMPLE_ON_TARGET_PACK', !flag)
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'Problem updating simple mode for pack. Try again later.',
          })
          throw err
        })
    },

    SET_ALLOW_DRIVER_PRIVACY_ON_TARGET_PACK: ({ state, rootState, commit, dispatch }, flag) => {
      commit('SET_ALLOW_DRIVER_PRIVACY_ON_TARGET_PACK', flag)

      dispatch('PUT_PACK_PROMISE', state.targetConfigPack)
        .then(() => {
          // update the mobile config
          // dispatch('TOGGLE_PACK_ALLOW_DRIVER_PRIVACY', flag)

          let orgPack = rootState.authModule.orgPack
          if (state.targetConfigPack.id === orgPack.id) {
            commit('SET_ORG_PACK_ALLOW_DRIVER_PRIVACY', flag)
          }
        })
        .catch(err => {
          commit('SET_ALLOW_DRIVER_PRIVACY_ON_TARGET_PACK', !flag)
          dispatch('SERVE_TOAST', {
            type: 'danger',
            message: 'Problem updating allow driver privacy setting for pack. Try again later.',
          })
          throw err
        })
    },
    // TODO change this to acutally set the service & Maintenance flag

    SET_SERVICE_MAINTENANCE_ON_TARGET_PACK: ({ commit, dispatch }, payload) => {
      commit('SET_SERVICE_MAINTENANCE_ON_TARGET_PACK', payload.flag)

      return new Promise((resolve) => {
        axios
          .put(`/api/v1/orgs/${payload.orgId}/packs/${payload.packId}/service-maintenance`, {
            pack: {
              maintenanceEnabled: payload.maintenanceEnabled,
              maintenanceCTAMethod: payload.maintenanceCTAMethod,
              maintenanceCTAString: payload.maintenanceCTAString,
            },
          })
          .then(resp => {
            commit('SET_TARGET_CONFIG_PACK', resp.data)
            resolve(resp)
          })
          .catch(e => {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'service_and_maintenance_toggle_error',
            })
            throw e
          })
      })
    },
  },
}

export default module
