var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.syncValue),expression:"syncValue"}],staticClass:"input block outline-none leading-normal p-input md:text-sm bg-white border rounded",class:[
    _vm.color ? `hover:border-${_vm.color} ` : '',
    _vm.color ? `focus:border-${_vm.color}` : '',
    _vm.textColor ? `text-${_vm.textColor}` : 'text-grey-dkr',
    _vm.borderColor ? `border-${_vm.borderColor}` : 'border-grey',
  ],style:(_vm.borderStyles),attrs:{"id":_vm.vid,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxLength,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.syncValue)?_vm._i(_vm.syncValue,null)>-1:(_vm.syncValue)},on:{"input":_vm.emitValue,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')},"change":function($event){var $$a=_vm.syncValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.syncValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.syncValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.syncValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.syncValue),expression:"syncValue"}],staticClass:"input block outline-none leading-normal p-input md:text-sm bg-white border rounded",class:[
    _vm.color ? `hover:border-${_vm.color} ` : '',
    _vm.color ? `focus:border-${_vm.color}` : '',
    _vm.textColor ? `text-${_vm.textColor}` : 'text-grey-dkr',
    _vm.borderColor ? `border-${_vm.borderColor}` : 'border-grey',
  ],style:(_vm.borderStyles),attrs:{"id":_vm.vid,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxLength,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.syncValue,null)},on:{"input":_vm.emitValue,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')},"change":function($event){_vm.syncValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.syncValue),expression:"syncValue"}],staticClass:"input block outline-none leading-normal p-input md:text-sm bg-white border rounded",class:[
    _vm.color ? `hover:border-${_vm.color} ` : '',
    _vm.color ? `focus:border-${_vm.color}` : '',
    _vm.textColor ? `text-${_vm.textColor}` : 'text-grey-dkr',
    _vm.borderColor ? `border-${_vm.borderColor}` : 'border-grey',
  ],style:(_vm.borderStyles),attrs:{"id":_vm.vid,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxLength,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.syncValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.syncValue=$event.target.value},_vm.emitValue],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')}}})
}
var staticRenderFns = []

export { render, staticRenderFns }