<template>
  <transition
    v-if="show"
    name="slide-fade"
    appear
  >
    <div class="modal-container">
      <slot name="content" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ServiceLocationsPopover',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.modal-container {
  margin: 0px auto;
  width: 370px;
  height: 60vh;
  background-color: #fff;
  -webkit-box-shadow: inset 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  -moz-box-shadow: inset 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  box-shadow: inset 0 4px 24px 0 rgba(37, 38, 94, 0.1);
}
.slide-fade-enter-active {
  transition: all .4s;
}
.slide-fade-leave-active {
  transition: all .4s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(370px);
  opacity: 0;
}
</style>
