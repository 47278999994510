<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g
      fill="#000"
      fill-rule="evenodd"
      opacity=".2"
    >
      <circle
        cx="18"
        cy="18"
        r="2"
      />
      <circle
        cx="10"
        cy="18"
        r="2"
      />
      <circle
        cx="2"
        cy="18"
        r="2"
      />
      <circle
        cx="18"
        cy="10"
        r="2"
      />
      <circle
        cx="10"
        cy="10"
        r="2"
      />
      <circle
        cx="2"
        cy="10"
        r="2"
      />
      <circle
        cx="18"
        cy="2"
        r="2"
      />
      <circle
        cx="10"
        cy="2"
        r="2"
      />
      <circle
        cx="2"
        cy="2"
        r="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VinDragIcon',
}
</script>
