<template>
  <VinSmallWidgetTemplate
    :title="$t('total_idling_time')"
    :timestamp="generatedAt"
    icon="Idling"
    :fetching="isFetching"
    :stat="stat"
    :pack-has-report="packHasReport"
    :cta-title="$t('view_idling_report')"
    :cta-action="navToReport"
    @bust-cache="fetchData(true)"
  />
</template>

<script>
import { addMilliseconds, differenceInHours,differenceInMinutes } from 'date-fns'
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'

import { composeWidget } from '../compose-widget'
export default {
  name: 'WidgetTotalIdleTime',
  displayName: 'total_idling_time',
  icon: 'Idling',
  setup () {
    const widget = {
      slug: 'widget-idling',
    }
    const report = {
        slug: 'report-idling',
        widget: 'ReportIdling',
      }

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    time () {
      // is in seconds
      return get(this.results, 'duration')
    },
    timeUnit () {
      const secs = this.time ? this.time / 1000 : 0
      let unit = 'secs'
      if (secs >= 60) {
        unit = 'mins'
      }
      if (secs >= 60 * 60) {
        unit = 'hrs'
      }
      return unit
    },
    formattedTime () {
      if (!this.time) {
        return '--'
      }
      const date1 = new Date()
      const date2 = addMilliseconds(date1, this.time)

      switch (this.timeUnit) {
        case 'mins':
          return differenceInMinutes(date2, date1)
        case 'hrs':
          return differenceInHours(date2, date1)
        default:
          return this.time
      }
    },
    stat () {
      return formatNumber(this.formattedTime, {
        unit: this.$t(this.timeUnit),
      })
    },
  },
}
</script>
