<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="a4"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#53B7FF"
        />
        <stop
          offset="100%"
          stop-color="#007FFF"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#a4)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      >
        <path d="M35.521 18.314a10.146 10.146 0 0 1 9.293 9.292M35.538 13.572a14.761 14.761 0 0 1 13.965 13.965M35.465 23.03a5.53 5.53 0 0 1 4.586 4.587M35.147 27.928" />
        <g transform="matrix(-1 0 0 1 37.567 25.667)">
          <path d="M10.453 11.878h11.049M3.423 11.58h-.9a1.265 1.265 0 0 1-1.195-.85L.307 7.79a1.6 1.6 0 0 1 .312-1.585L5.624.541A1.6 1.6 0 0 1 6.823 0h10.348a1.6 1.6 0 0 1 1.216.561l2.96 3.466a1.6 1.6 0 0 0 .954.54l7.458 1.245a1.6 1.6 0 0 1 1.325 1.768l-.331 2.766a1.4 1.4 0 0 1-1.39 1.234h-1.244" />
          <ellipse
            cx="7.093"
            cy="11.754"
            rx="3.36"
            ry="3.413"
          />
          <ellipse
            cx="25.013"
            cy="11.754"
            rx="3.36"
            ry="3.413"
          />
          <path d="M17.6 4.918h-3.747l-1.409-1.94" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconVehicleStatus',
}
</script>
