import get from 'lodash.get'
import { computed, onMounted, reactive,ref } from 'vue'

import axios from '@/state/middleware/axios-store'
import { useStore } from '@/state/store'

// composition
export const fetchLatestFuelPrice = () => {

  const store = useStore()

  const isFetchingFuel = ref(true)
  const latestFuelPrice = reactive({
    petrolPrice: 0,
    dieselPrice: 0,
    month: 0,
    year: 0,
  })

  // computed
  const activeOrgId = computed(() => get(store.state.authModule, 'activeOrg.id'))

  // methods
  const fetchFuelPrices = async () => {
    // One-off fetch for latest fuel info to not disrupt admin fuel flow
    isFetchingFuel.value = true
    axios.get(`/api/v1/orgs/${activeOrgId.value}/fuel-prices`, {
      params: {
        offset: 0,
        limit: 1,
        sortBy: 'date',
        sortDir: 'desc',
      },
    })
      .then(resp => {
        const p = get(resp, 'data.prices[0]', null)
        if (p != null) {
          latestFuelPrice.petrolPrice = p?.petrolPrice ?? 0
          latestFuelPrice.dieselPrice = p?.dieselPrice ?? 0
          latestFuelPrice.month = p?.month ?? 0
          latestFuelPrice.year = p?.year ?? 0
        }
        isFetchingFuel.value = false
      })
      .catch(err => {
        isFetchingFuel.value = false
        throw err
      })
  }

  // hooks
  onMounted(() => {
    fetchFuelPrices()
  })

  // instance
  return {
    isFetchingFuel,
    latestFuelPrice,
  }
}
