<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="ysyc8lolsa"
        x1="50%"
        y1="100%"
        x2="50%"
        y2="0%"
      >
        <stop
          stop-color="#876ED5"
          offset="0%"
        />
        <stop
          stop-color="#A08BE3"
          offset="100%"
        />
      </linearGradient>
    </defs>
    <g
      fill-rule="nonzero"
      fill="none"
    >
      <rect
        fill="url(#ysyc8lolsa)"
        width="56"
        height="56"
        rx="10"
      />
      <g fill="#FFF">
        <path d="M19.988 38.69a3.629 3.629 0 0 1-2.555-6.23l8.68-8.54a5.6 5.6 0 0 1-.117-1.166 6.417 6.417 0 0 1 7.653-6.3 6.23 6.23 0 0 1 3.302 1.761c.453.455.453 1.19 0 1.645l-3.71 3.745a1.171 1.171 0 0 1-1.657-1.656l2.777-2.765a4.06 4.06 0 0 0-5.11 1.003 4.025 4.025 0 0 0-.922 2.59c.006.397.072.79.199 1.167.123.415.006.864-.304 1.166l-9.158 9.019c-.25.242-.392.574-.397.921.002.35.14.685.385.934a1.318 1.318 0 0 0 1.844 0l9.193-9.03a1.167 1.167 0 0 1 1.167-.292c.376.126.77.193 1.166.198a4.072 4.072 0 0 0 4.072-4.316 1.168 1.168 0 1 1 2.333-.117 6.405 6.405 0 0 1-2.566 5.495 6.09 6.09 0 0 1-4.994 1.167l-8.773 8.563a3.57 3.57 0 0 1-2.508 1.038z" />
        <path d="M24.958 27.63c-.31.002-.608-.12-.829-.338l-7.128-7.128a1.167 1.167 0 1 1 1.657-1.645l7.128 7.175a1.167 1.167 0 0 1-.828 1.983v-.047zM33.253 38.725a3.897 3.897 0 0 1-2.754-1.166l-4.666-4.667a1.167 1.167 0 1 1 1.645-1.657l4.666 4.667a1.555 1.555 0 1 0 2.194-2.205l-1.342-1.272a1.167 1.167 0 1 1 1.657-1.645l1.306 1.307a3.897 3.897 0 0 1-2.753 6.638h.047z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconVehicleServiceStatus',
}
</script>
