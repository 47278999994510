module.exports = {
  // absolutes
  transparent: 'transparent',

  black: '#000000',
  white: '#ffffff',

  // greys
  'grey-ltr': '#F5F5F5',
  'grey-lt': '#c8c6c7',
  'grey': '#8a898e',
  'grey-dk': '#464646',
  'grey-dkr': '#1c1c19',

  // theme colors
  'primary-lt': '#8cd0ff',
  'primary-dk': '#0413a2',
  'primary-dkr': '#000162',
  'cta-dk': '#1c1c19',
  'cta-lt': '#0c26ff',

  // interaction colors
  'success-lt': '#1D7B8A',
  'success-dk': '#rgba(29, 98, 110)',
  'warning-lt': '#ffe076',
  'warning-dk': '#e3812f',
  'danger-lt': '#ff535e',
  'danger-dk': '#cc424b',
  'pending-dk': '#020b61',

  // utility colors
  'border': '##8a898e',
  'svg-grey': '#8a898e',
  'sidebar-grey': '#c8c6c7',
  'copy': '#000162',
  'positive': '#22cd91',
  'negative': '#d0021b',
  'score-a': '#00d28b',
  'score-b': '#0b8eff',
  'score-c': '#ffd255',
  'score-d': '#f98658',
  'score-f': '#f41745',
  // non tailwind colors below
  // auth gradient
  'auth-gradient-0': '#0000a5',
  'auth-gradient-1': '#000162',
  'auth-gradient-2': '#0000a5',
  // icon gradient
  'icon-gradient-0': '#0000a5',
  'icon-gradient-1': '#000162',
  // map
  'static-polyline': '001e69',
  // chart
  'bar-0': '#0000a5',
  'bar-1': '#000162',
}
