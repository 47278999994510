<template>
  <div>
    <div class="px-8 mt-4 flex items-center justify-between">
      <span class="text-grey-dk">
        {{ 'Name' | translate }}
      </span>
    </div>
    <div class="px-8 mt-1 mb-5 flex items-center justify-between">
      <VinInput
        ref="name"
        v-model="locationName"
        type="text"
        color="primary-dk"
        class="w-full border-override text-lg rounded transition-2s"
        @input="$emit('nameInput', locationName)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VehicleLocationsName',
  data () {
    return {
      locationName: '',
      locationDescription: '',
    }
  },
  watch: {
    locationName () {
      if (this.locationName.length > 39) {
        const sub = this.locationName.substring(0, 39)
        this.locationName = ''
        this.$nextTick().then(() => {
          this.locationName = sub
        })
      }
    },
  },
}
</script>
<style>
.border-override {
  border-width: 2px !important;
}
</style>
