const vehicleSearchConfig = {
  opts: {
    threshold: 0.25,
    distance: 10,
    useExtendedSearch: true,
  },
  keys: [
    {
      name: 'vin',
      weight: 0.25,
    },
    {
      name: 'registration',
      weight: 0.15,
    },
    {
      name: 'make',
      weight: 0.033,
    },
    {
      name: 'model',
      weight: 0.033,
    },
    {
      name: 'year',
      weight: 0.033,
    },
  ],
}

export { vehicleSearchConfig }
