import get from 'lodash.get'

import store from '@/state/store'

import numeral from './numeral'
import { localizeDistance, localizeEvent, localizeFuel } from './numeral-utils/converters'
import { currencySymbolMap, unitSuffixMap } from './numeral-utils/fixtures'
import { currency, duration, float, integer } from './numeral-utils/formatters'
import { validateOpts } from './numeral-utils/validations'

const numeralTypeMap = (val, type, unit, numeral, abbrvAt, convertUnit) => {

  val = numeral(val).value()
    ? numeral(val).value()
    : 0

  switch (type) {
    case 'integer':
      return {
        fn: integer(val, numeral, abbrvAt),
        value: numeral(val).value(),
      }

    case 'float':
      return {
        fn: float(val, numeral, abbrvAt),
        value: numeral(val).value(),
      }

    case 'duration':
      const pretty = duration(val, unit)
      return {
        fn: pretty.fn,
        pairs: pretty.pairs,
        value: val,
      }

    case 'currency':
      return {
        fn: currency(val, numeral, abbrvAt),
        value: numeral(val).value(),
        cs: currencySymbolMap(convertUnit),
      }

    case 'distance':
    case 'speed':
      val = localizeDistance(val, unit, convertUnit)
      return {
        fn: float(val, numeral, abbrvAt),
        value: numeral(val).value(),
      }

    case 'odometer':
      val = localizeDistance(val, unit, convertUnit)
      return {
        fn: integer(val, numeral, null),
        value: numeral(val).value(),
      }

    case 'fuel':
      val = localizeFuel(val, unit, convertUnit)
      return {
        fn: float(val, numeral, abbrvAt),
        value: numeral(val).value(),
      }

    case 'event':
      val = localizeEvent(val, unit, convertUnit)
      return {
        fn: integer(val, numeral, abbrvAt),
        value: numeral(val).value(),
      }

    default:

      return {
        fn: integer(val, numeral, abbrvAt),
        value: numeral(val).value(),
      }
  }
}

export const formatNumber = (val, opts = {}) => {

  // setup locale
  const loc = get(store.state, 'i18n.locale', 'en-gb')
  numeral.locale(loc)

  const locData = numeral.localeData()

  if (
    !get(locData, 'delimiters') ||
    !get(locData, 'abbreviations') ||
    !get(locData, 'ordinal') ||
    !get(locData, 'currency')) {
      // invalid or missing config - fallback to en-gb
      numeral.locale('en-gb')
  }


  // validate params and setup defaults
  const {
    type,
    unit,
    abbrvAt,
    convertUnit,
  } = validateOpts(opts)

  // localized numeral output string and bare value()
  const {
    fn,
    value,
    pairs,
    cs,
  } = numeralTypeMap(val, type, unit, numeral, abbrvAt, convertUnit)

  // prepare unit suffix
  const un = unitSuffixMap(type, unit, convertUnit)

  return {
    numeral: fn,
    unit: un,
    currency: cs,
    pairs: pairs,
    value: () => value,
  }
}
