<template>
  <div class="flex items-end mt-1">
    <VinStatChange
      :change-class="computedChangeClass"
      :direction="computedDirection"
      :change="computedDelta"
    />
    <p class="text-copy text-sm tracking-relaxed leading-none">
      {{ computedText }}
    </p>
  </div>
</template>

<script>
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'
export default {
  name: 'VinWidgetDelta',
  props: {
    graphData: {
      type: Array,
      default: () => [],
    },
    textSuffix: {
      type: String,
      default: 'efficient',
    },
    invert: {
      type: Boolean,
      default: false,
    },
    hasFuelEcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    delta () {
      let currentVal = get(this.graphData, '[3]', 0)
      let lastVal = get(this.graphData, '[2]', 0)
      let diff = currentVal - lastVal
      if (lastVal !== 0) {
        let delta = (diff / lastVal) * 100
        return formatNumber(delta, {
          type: 'float',
        }).numeral
      } else {
        return currentVal
      }
    },
    computedChangeClass () {
      let types = ['positive', 'negative']
      const typeIdx = this.delta > 0 ? 0 : 1

      this.invert ? types = types.reverse() : null
      return types[typeIdx]
    },
    computedDirection () {
      return this.delta > 0 ? 'up' : 'down'
    },
    computedDelta () {
      return this.delta < 0 ? this.delta * -1 : this.delta
    },
    computedText () {
      let invertForFuel = false
      let types = ['more', 'less']
      const typeIdx = this.delta > 0 ? 0 : 1

      if (this.hasFuelEcon) {
        // L/100km - more efficient for lesser val with green arrow
        // L/100km - less efficient for greater val with red arrow
        invertForFuel = this.$store.state.localizationModule.fuelEcoUnit === 'L/100km'
      }
      invertForFuel ? types = types.reverse() : null

      return `${this.$t(types[typeIdx])} ${this.$t(this.textSuffix)}`
    },
  },
}
</script>
