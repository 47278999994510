<template>
  <div>
    <StreamVehicles
      v-if="liveStream"
      is-fleet
    />
    <div
      v-if="isToday"
      :class="stackBtns ? 'stack-btns' : 'container px-container'"
      class="relative"
    >
      <div
        :class="stackBtns ? 'pt-12' : 'pr-container'"
        class="flex absolute z-10 top-0 right-0"
      >
        <div
          :class="stackBtns ? 'flex flex-col' : 'flex items-start'"
          class="pt-4 gap-x-4"
        >
          <DashboardMapLoadingVehicles v-if="!$store.state.vehiclesModule.hasAllVehicles" />
          <DashboardMapLiveMessage
            v-if="vehicles.length !== 0"
          />
          <DashboardMapHistoryToggle
            v-if="vehicles.length !== 0"
            @toggleSettings="handleClick(false)"
          />
          <DashboardMapSettingsToggle
            v-if="vehicles.length !== 0"
            @toggleSettings="handleClick(true)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isToday"
      class="map-wrapper relative"
    >
      <div class="relative">
        <div class="flex absolute z-10 top-0 right-0 bottom-0 h-full">
          <DashboardMapSettings
            :map-height="mapHeight"
            :show="showSettings"
            @close="handleClick(true)"
          />
          <DashboardMapHistory
            :map-height="mapHeight"
            :show="showHistory"
            @close="handleClick(false)"
          />
        </div>
      </div>
      <div
        v-if="vehicles.length === 0"
        :style="`width: 100%; height: ${mapHeight};`"
        class="map-wrapper bg-grey-lt flex items-center justify-center"
      >
        <VinLoading v-if="status === 'loading'" />
        <div
          v-if="status === 'error'"
          class="flex flex-col items-center"
        >
          <p>{{ $t('Something went wrong 😣') }}</p>
          <VinButton
            vid="dash-error-reload-btn"
            invert
            :text="$t('Reload')"
            class="mt-4"
            @click="$store.dispatch('HYDRATE_ORG_VEHICLES')"
          />
        </div>
        <div
          v-if="status === 'success' && vehicles.length === 0"
          class="flex flex-col items-center"
        >
          <p>{{ $t('No vehicles found') }}</p>
        </div>
      </div>
      <GmapMap
        v-show="vehicles.length > 0 && isToday"
        id="dashMap"
        ref="dashMap"
        :center="center"
        :zoom="10"
        :options="mapStyle"
        class="dashboard-map"
        :style="`width: 100%; height: ${mapHeight};`"
      >
        <!-- markers and clustering -->
        <DashboardMapClusterer
          v-if="$store.state.mapSettingsModule.cluster"
          :locations="locations"
        />
        <template v-else>
          <DashboardMapMarker
            v-for="(location, i) in locations"
            :key="i + 'marker'"
            :coords="location.coords"
            :vehicle="location.vehicle"
            :vehicle-status="location.status"
          />
        </template>
        <GmapMarker
          v-if="mapSearchSelection"
          :position="{
            lat: mapSearchSelection.lat,
            lng: mapSearchSelection.lng
          }"
        />
        <!-- vehicle info windows -->
        <DashboardMapInfoWindow
          v-if="infoWindowLoc"
          :location="infoWindowLoc"
          :map-center="center"
        />
        <DashboardMapClusterInfoWindow v-if="$store.state.mapSettingsModule.selectedCluster" />
      </GmapMap>
    </div>
    <DashboardMapHistoricPlaceholder v-else />
  </div>
</template>

<script>
import { isToday } from 'date-fns'
import { components,getGoogleMapsAPI } from 'gmap-vue'
const { Marker } = components
import get from 'lodash.get'
import has from 'lodash.has'
import Vue from 'vue'

import colors from '@/assets/themes/colors'
import mapstyle from '@/common/utils/map-style'
import StreamVehicles from '@/components/Stream/StreamVehicles'

import DashboardMapClusterInfoWindow from './DashbaordMapClusterInfoWindow'
import DashboardMapClusterer from './DashboardMapClusterer'
import DashboardMapHistoricPlaceholder from './DashboardMapHistoricPlaceholder'
import DashboardMapHistory from './DashboardMapHistory/DashboardMapHistory'
import DashboardMapHistoryToggle from './DashboardMapHistory/DashboardMapHistoryToggle'
import DashboardMapInfoWindow from './DashboardMapInfoWindow'
import DashboardMapLiveMessage from './DashboardMapLiveMessage.vue'
import DashboardMapLoadingVehicles from './DashboardMapLoadingVehicles'
import DashboardMapMarker from './DashboardMapMarker'
import DashboardMapSettings from './DashboardMapSettings/DashboardMapSettings'
import DashboardMapSettingsToggle from './DashboardMapSettings/DashboardMapSettingsToggle'
export default {
  name: 'DashboardMap',
  components: {
    DashboardMapHistoricPlaceholder,
    DashboardMapHistory,
    DashboardMapHistoryToggle,
    DashboardMapLiveMessage,
    DashboardMapLoadingVehicles,
    DashboardMapSettingsToggle,
    DashboardMapSettings,
    DashboardMapMarker,
    DashboardMapClusterer,
    DashboardMapClusterInfoWindow,
    DashboardMapInfoWindow,
    GmapMarker: Marker,
    StreamVehicles,
  },
  props: {
    mapHeight: {
      type: String,
      default: '60vh',
    },
    stackBtns: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      vehiclesLoaded: false,
      showSettings: false,
      showHistory: false,
      trafficLayer: null,
      map: null,
      recalculating: false,
      clusterer: null,
      locMap: {},
      fences: [],
      fenceStyles: {
        strokeColor: colors['primary-dk'],
        strokeWeight: '6',
        fillColor: colors['primary-dk'],
        fillOpacity: '0.2',
      },
      orgPlaces: [],
      mapTypeId: 'roadmap',
    }
  },
  computed: {
    google: getGoogleMapsAPI,
    center () {
      return this.$store.state.mapSettingsModule.mapCenter
    },
    isToday () {
      return isToday(new Date(this.$store.state.paramsModule.until))
    },
    status () {
      return this.$store.state.vehiclesModule.vehicleFetchStatus
    },
    now () {
      return this.$store.state.vehiclesModule.now
    },
    hasAll () {
      return this.$store.state.vehiclesModule.hasAllVehicles
    },
    mapStyle () {
      // only use pretty custom styles for ROADMAP
      const prettyStyles = this.mapTypeId === 'roadmap' ? mapstyle : { styles: [] }

      if (this.google) {
        return {
          ...prettyStyles,
          zoomControlOptions: {
            position: this.showSettings || this.showHistory ? google.maps.ControlPosition.LEFT_BOTTOM : google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          streetViewControlOptions: {
            position: this.showSettings || this.showHistory ? google.maps.ControlPosition.LEFT_BOTTOM : google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          fullscreenControlOptions: {
            position: this.showSettings || this.showHistory ? google.maps.ControlPosition.LEFT_TOP : google.maps.ControlPosition.RIGHT_TOP,
          },
        }
      }
      return prettyStyles
    },
    mapSearchSelection () {
      return this.$store.state.searchModule.mapSearchSelection
    },
    vehicles () {
      return this.$store.getters.appVehicles
    },
    historicalMode () {
      return this.$store.state.fleetHistoryModule.historicalMode
    },
    historicalIsFetching () {
      return this.$store.state.fleetHistoryModule.isFetching
    },
    infoWindowLoc () {
      const id = this.$store.state.mapSettingsModule.infoWindowId
      if (!id) {
        return null
      }
      return this.locations.find(l => l.vehicle?.id === id)
    },
    locations () {
      let locs = []
      Object.values(this.locMap).forEach(loc => {
        const filter = this.$store.state.vehiclesModule.vehicleStatusSettings[loc.status]
        if (filter.show) {
          locs.push(loc)
        }
      })
      return locs
    },
    showTraffic () {
      return this.$store.state.mapSettingsModule.traffic
    },
    computedOrgPlaces () {
      return this.$store.state.placesModule.orgPlaces
    },
    showFences () {
      return this.$store.state.mapSettingsModule.geofence
    },
    liveStream () {
      return this.$store.state.mapSettingsModule.liveStream
    },
    orgId () {
      return this.$store.state.authModule?.activeOrg?.id
    },
    locationKeys() {
      return Object.keys(this.locMap)
    },
  },
  watch: {
    hasAll() {
      if (this.vehicles.length !== 0 && this.isToday) {
        this.recalcLocations()
        this.setBounds()
      }
    },
    mapSearchSelection () {
      if (this.vehicles.length !== 0) {
        this.findClosestVehicle()
      }
    },
    vehicles () {
      if (!this.vehiclesLoaded && this.isToday) {
        this.vehiclesLoaded = true
        this.recalcLocations()
        this.setBounds()
      }
    },
    showTraffic () {
      this.handleTrafficLayer()
    },
    showFences () {
      if (this.showFences) {
        if (this.orgPlaces.length > 0) {
          this.renderFences()
        }
      } else {
        this.destroyFences()
        this.setBounds()
      }
    },
    isToday () {
      if (this.isToday) {
        this.$nextTick().then(() => {
          this.setupMap()
        })
      }
    },
    computedOrgPlaces () {
      this.orgPlaces = this.computedOrgPlaces
    },
    now () {
      this.recalcLocations()
    },
    orgId () {
      this.clearLocs()
      this.recalcLocations()
      this.setBounds()
    },
    locationKeys (newVal, oldVal) {
      if (oldVal.length === 0 && newVal.length > 0) {
        this.setBounds()
      }
    },
  },
  mounted () {
    if (this.isToday) {
      this.setupMap()
    }
    // check places
    if (this.computedOrgPlaces.length === 0) {
      this.$store.dispatch('FETCH_PLACES_PROMISE')
    }
  },
  destroyed () {
    this.$store.commit('CLEAR_MAP_SEARCH')
    this.$store.commit('SET_MAP_CENTER', {
      lat: 32.781921,
      lng: -96.790589,
    })
    if (this.$store.state.fleetHistoryModule.historicalMode) {
      this.$store.commit('CLEAR_HISTORY_MODULE')
    }
  },
  methods: {
    async setupMap () {
      // setup map
      let _self = this
      await this.$gmapApiPromiseLazy()
      this.$refs?.dashMap?.$mapPromise.then(map => {
        if (!this.map) {
          this.map = map
          map.addListener('maptypeid_changed', function () {
            const newMapType = map.getMapTypeId()
            _self.mapTypeId = newMapType
          })
        }
        this.recalcLocations()
        this.handleTrafficLayer()
        this.setBounds()
      }).catch(err => {
        throw err
      })
    },
    setBounds () {
      if (this.$refs?.dashMap) {
        this.$refs.dashMap.$mapPromise.then(() => {
          let bounds = new google.maps.LatLngBounds()
          this.locations.forEach(loc => {
            bounds.extend(loc.coords)
            this.$refs.dashMap.fitBounds(bounds)
          })
          this.$refs.dashMap.panToBounds(bounds)
        })
      }
    },
    handleClick (settings = true) {
      if (settings) {
        this.showSettings = !this.showSettings
        this.showHistory = false
      } else {
        this.showHistory = !this.showHistory
        this.showSettings = false
      }
    },
    handleTrafficLayer () {
      if (this.showTraffic) {
        this.trafficLayer = new google.maps.TrafficLayer()
        this.trafficLayer.setMap(this.map)
      } else {
        if (this.trafficLayer) {
          this.trafficLayer.setMap(null)
          this.trafficLayer = null
        }
      }
    },
    catchClusterer (e) {
      if (!this.clusterer) {
        this.clusterer = e
      } else {
        this.$set(this.clusterer, 'clusters_', e.clusters_)
      }
    },
    rad (x) {
      return x * Math.PI / 180
    },
    findClosestVehicle () {
      // haversine formula
      var lat = this.mapSearchSelection.lat
      var lng = this.mapSearchSelection.lng
      var R = 6371 // radius of earth in km
      var distances = []
      var closest = -1
      for (let i in this.locations) {
        var mlat = this.locations[i].coords.lat
        var mlng = this.locations[i].coords.lng
        var dLat = this.rad(mlat - lat)
        var dLong = this.rad(mlng - lng)
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.rad(lat)) * Math.cos(this.rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        var d = R * c
        distances[i] = d
        if (closest === -1 || d < distances[closest]) {
          closest = i
        }
      }

      // set new bounds to marker and nearest location
      let bounds = new google.maps.LatLngBounds()
      bounds.extend(this.locations[closest].coords)
      bounds.extend({
        lat: this.mapSearchSelection.lat,
        lng: this.mapSearchSelection.lng,
      })
      this.$refs.dashMap.fitBounds(bounds)
    },
    renderFences () {
      let bounds = new google.maps.LatLngBounds()
      this.orgPlaces.forEach(p => {
        let type = p.shape.type
        if (type === 'Polygon') {
          this.drawPolygon(p)
          let coords = p.shape.coordinates[0]
          coords.forEach(c => {
            bounds.extend({
              lat: c[1],
              lng: c[0],
            })
          })
        }
        if (type === 'Point') {
          this.drawCircle(p)
          bounds.extend({
            lat: p.shape.coordinates[1],
            lng: p.shape.coordinates[0],
          })
          // TODO: extract bounds from gmap.circle()
          const offset = this.getCircleBounds(p)
          bounds.extend({
            lat: offset.Lat0,
            lng: offset.Lng0,
          })
          bounds.extend({
            lat: offset.Lat1,
            lng: offset.Lng1,
          })
        }
      })
      this.locations.forEach(loc => {
        bounds.extend(loc.coords)
      })
      this.$nextTick().then(() => {
        this.$refs.dashMap.fitBounds(bounds)
      })
      this.$refs.dashMap.panToBounds(bounds)
    },
    destroyFences () {
      this.fences.forEach(idx => {
        idx.fence.setMap(null)
      })
      this.fences = []
    },
    drawPolygon (place) {
      let coords = place.shape.coordinates[0]
      let polyCoords = []
      coords.forEach(c => {
        polyCoords.push({ lat: c[1], lng: c[0] })
      })
      let fence = new this.google.maps.Polygon({
        paths: polyCoords,
        ...this.fenceStyles,
      })
      this.$refs.dashMap.$mapPromise.then((map) => {
        fence.setMap(map)
        this.fences.push({
          fence: fence,
          placeId: place.id,
        })
      })
    },
    drawCircle (place) {
      let circle = new this.google.maps.Circle({
        center: {
          lat: place.shape.coordinates[1],
          lng: place.shape.coordinates[0],
        },
        radius: place.radius,
        ...this.fenceStyles,
      })
      this.$refs.dashMap.$mapPromise.then((map) => {
        circle.setMap(map)
        this.fences.push({
          fence: circle,
          placeId: place.id,
        })
      })
    },
    getCircleBounds (p) {
      const dLat = p.radius / 6378137
      const dLng = p.radius / (6378137 * Math.cos(Math.PI * p.shape.coordinates[1] / 180))
      const Lat0 = p.shape.coordinates[1] + dLat * 180 / Math.PI
      const Lng0 = p.shape.coordinates[0] + dLng * 180 / Math.PI
      const Lat1 = p.shape.coordinates[1] - dLat * 180 / Math.PI
      const Lng1 = p.shape.coordinates[0] - dLng * 180 / Math.PI
      return { Lat0, Lng0, Lat1, Lng1 }
    },
    locationChanged (loc) {
      const prev = this.locMap[loc.vehicle.id]
      if (!prev) {
        return true
      }
      if (this.$store.state.liveTrackingModule.freshLiveMapVehicles.includes(loc.vehicle.id)) {
        return true
      }
      const prevLat = prev.coords.lat
      const prevLng = prev.coords.lng
      const prevStatus = loc.status


      const { lat, lng, status } = loc
      return lat !== prevLat || lng !== prevLng || status !== prevStatus
    },
    clearLocs () {
      Vue.set(this, 'locMap', {})
    },
    recalcLocations() {
      if (this.recalculating) {
        return
      }
      this.recalculating = true
      this.vehicles.forEach(vehicle => {
        const status = this.$store.getters.latestVehicleStatus(vehicle)
        const vehicleLocations = get(this.$store.state.vehiclesModule.vehicleLocations, vehicle.id, [])

        let loc = {
          vehicle: vehicle,
          status: status,
        }
        if (this.historicalMode && !this.historicalIsFetching) {
          if (
            vehicle.historicalLocation &&
            vehicle.historicalLocation.coordinates.lat !== null &&
            vehicle.historicalLocation.coordinates.lng !== null
          ) {
            loc.coords = vehicle.historicalLocation.coordinates
          }
        } else if (vehicleLocations.length > 0 ) {
          loc.coords = vehicleLocations[vehicleLocations.length - 1].coordinates
        } else if (
          has(vehicle, 'latestLocation.features[0].geometry.coordinates[1]') &&
          has(vehicle, 'latestLocation.features[0].geometry.coordinates[0]')
        ) {
          const coords = {
            lat: Number(vehicle.latestLocation.features[0].geometry.coordinates[1]),
            lng: Number(vehicle.latestLocation.features[0].geometry.coordinates[0]),
          }
          loc.coords = coords
        }
        if (loc.coords && this.locationChanged(loc)) {
          Vue.set(this.locMap, vehicle.id, loc)
        }
      })
      this.recalculating = false
    },
  },
}
</script>

<style>
.dashboard-map {
  position: relative;
  z-index: 0;
}

.dashboard-map > .map-wrapper {
  position: relative;
  z-index: 5;
}

.dashboard-map:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 10;
  pointer-events: none;

  -webkit-box-shadow: inset 0 4px 24px 0 rgba(37, 38, 94, 0.15);
  -moz-box-shadow: inset 0 4px 24px 0 rgba(37, 38, 94, 0.15);
  box-shadow: inset 0 4px 24px 0 rgba(37, 38, 94, 0.15);
}

.stack-btns {
  right: 10px;
}
</style>
