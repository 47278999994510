import { format, parseISO } from 'date-fns'

export const FALLBACK = '--'

export const cleanDate = raw => {
  return typeof raw === 'string' ? parseISO(raw) : raw
}
export const emptyDate = raw => {
  // look for falsy or zero-value golang dates
  if (!raw || raw === '0001-01-01T00:00:00Z') {
    return true
  }
  return false
}

export const fmtDateShort = ($store, date, fallback = FALLBACK) => {
  if (emptyDate(date)) {
    return fallback
  }
  const dayMonth = $store.state.localizationModule.dayFirst ? 'd/M' : 'M/d'

  return format(
    cleanDate(date),
    `${dayMonth}/yyyy`,
    {
      locale: $store.getters.localeSettings.dateLocale,
    },
  )
}

export const fmtDateMed = ($store, date, fallback = FALLBACK) => {
  if (emptyDate(date)) {
    return fallback
  }
  const dayMonth = $store.state.localizationModule.dayFirst ? 'd MMM' : 'MMM do'

  return format(
    cleanDate(date),
    `${dayMonth}, yyyy`,
    {
      locale: $store.getters.localeSettings.dateLocale,
    },
  )
}

export const fmtTimeToSecond = ($store, date, fallback = FALLBACK) => {
  if (emptyDate(date)) {
    return fallback
  }
  const hrs = $store.state.localizationModule.time24hr ? 'H' : 'h'
  const ampm = $store.state.localizationModule.time24hr ? '' : 'aaaa'

  return format(
    cleanDate(date),
    `${hrs}:mm:ss ${ampm}`,
    {
      locale: $store.getters.localeSettings.dateLocale,
    },
  )
}
