<template>
  <div
    class="vin-btn-group"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VinButtonGroup',
  props: {
    size: String,
    gap: {
      type: Number,
      default: -1,
    },
  },
}
</script>
<style>
.btn-group {
  display: inline-block;
  padding: 6px 20px;
  font-size: 18px;
  outline: 0;
  line-height: 1.5;
  border-radius: 4px;
  transition: background 0.25s;
  @apply cursor-pointer select-none bg-white border-primary-dk border-2 text-center text-copy whitespace-no-wrap;
}
.btn-group:hover {
  @apply text-white bg-primary-dk;
}
.btn-group.active {
  @apply text-white bg-primary-dk;
}
.vin-btn-group {
  display: inline-block;
}
.vin-btn-group .btn-group {
  border-radius: 0;
}
.vin-btn-group .btn-group:not(:last-child) {
  margin-right: -2px;
}
.vin-btn-group .btn-group:first-child {
  border-radius: 24px 0 0 24px;
}
.vin-btn-group .btn-group:last-child {
  border-radius: 0 24px 24px 0;
}

</style>
