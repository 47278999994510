<template>
  <label :for="this.for">{{ text }}</label>
</template>

<script>
export default {
  name: 'VinLabel',
  props: {
    for: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: 'Label',
    },
  },
}
</script>
