import get from 'lodash.get'

import store from '../../state/store'

export default (to, from, next) => {
  // VehicleGroups can be visited conditionally with any access level
  // rootOrg.type === 'regular' requires admin level
  // rootOrg.type === 'group' has no requirements

  const sessionOrgType = get(store.state, 'authModule.orgType', 'regular')
  let hasPermission = false

  if (sessionOrgType === 'regular') {
    hasPermission = store.getters.hasPermission('customer_admin')
  } else {
    hasPermission = true
  }

  hasPermission
    ? next()
    : next('/')
}
