<template>
  <div class="card relative grid grid-cols-3 gap-x-6 w-full py-8">
    <VinLoadingBlock v-if="isFetching" />
    <div class="col-span-1 pl-10">
      <VinWidgetHeader
        :title="$t('Longest Journey')"
        :timestamp="generatedAt"
        @bust-cache="fetchData(true)"
      />
      <VinWidgetStat
        :fetching="isFetching"
        :stat="stat"
      />
      <VinButton
        v-if="packHasReport"
        vid="widget-longest-journey-btn"
        color="cta-lt"
        class="btn-width ml-0 text-base font-semibold rounded-lg mt-10"
        :text="$t('View Report')"
        @click="navToReport"
      />
    </div>


    <div class="col-span-2 grid grid-cols-3 gap-x-6 divide-x-2 divide-grey items-center px-10">
      <div class="col-span-1 flex items-center">
        <VinGravatar
          :email="email"
          :name="name === '--' ? '?' : name"
          :circle="true"
          :size="60"
          class="text-xl mr-4"
          style="min-width: 50px;"
        />

        <div class="flex flex-col justify-between">
          <VinTitle
            el="h3"
            :text="name"
            class="font-bold text-copy text-lg mb-0 leading-none my-3"
          />
          <VinTitle
            el="h3"
            :text="$t('Fleet Driver')"
            class="font-normal text-grey-dk text-lg leading-none my-3"
          />
        </div>
      </div>
      <div class="col-span-2 pl-8 flex justify-between">
        <VinTooltip
          id="best-vehicle-tip"
          placement="top"
          trigger="hover"
          content-padding="p-0"
          header-padding="p-0"
          class="leading-none text-grey-dk transition-2s"
        >
          <template
            #content
          >
            <div
              style="width:200px;"
              class="p-4 leading-tight text-center cursor-default"
            >
              <div class="w-full mx-auto px-2">
                <span class="text-xs text-grey-dk font-semithin">
                  {{ $t('click_to_view_vehicle') }}
                </span>
              </div>
            </div>
          </template>
          <div
            class="hover:cursor-pointer hover:underline py-1"
            @click="navToVehicle"
          >
            <VinTitle
              el="h3"
              :text="vehicleType"
              class="font-bold text-copy text-lg leading-none mb-4"
            />
            <VinTitle
              el="h4"
              :text="vin"
              class="text-grey-dk font-semithin text-sm leading-normal mb-1"
            />
            <VinTitle
              el="h4"
              :text="reg"
              class="text-grey-dk font-semithin text-sm leading-none"
            />
          </div>
        </VinTooltip>
        <VinCar
          :image="icon"
          class="w-1/2 mt-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'

import { formatNumber } from '@/common/utils/numeral'

import { composeWidget } from '../compose-widget'
export default {
  name: 'WidgetLongestJourney',
  displayName: 'Longest Journey',
  icon: 'Long',
  setup () {
    const widget = {
      slug: 'widget-longest-journey',
    }
    const report = {
      slug: 'report-journey-summary',
      widget: 'ReportJourneysSummary',
    }

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    stat () {
      return formatNumber(get(this, 'results.distance'), {
        type: 'distance',
        unit: 'm',
      })
    },
    vehicle () {
      if (this.results) {
        return this.$store.getters.getVehicleById(this.results.vehicleId)
      }
      return {}
    },
    name () {
      const nameString = `${get(this.vehicle, 'driver.firstName', '')} ${get(this.vehicle, 'driver.lastName', '')}`
      return nameString === ' ' ? '--' : nameString
    },
    email () {
      return get(this.vehicle, 'driver.email', '--')
    },
    vehicleType () {
      const make = get(this.vehicle, 'make')
      const model = get(this.vehicle, 'model')
      if (make || model) {
        return `${make} ${model}`
      }
      return '--'
    },
    vin () {
      let v = get(this.vehicle, 'vin')
      if (!v) {
        v = '--'
      }
      return `${this.$t('VIN')}: ${v}`
    },
    reg () {
      let r = get(this.vehicle, 'registration')
      if (!r) {
        r = '--'
      }
      return `${this.$t('Reg')} #: ${r}`
    },
    icon () {
      return get(this.vehicle, 'icon', null)
    },
  },
  methods: {
    navToVehicle () {
      this.$router.push({
        name: `VehicleDetails`,
        params: {
          orgId: get(this.$store.state.authModule, 'activeOrg.id'),
          vehicleId: this.vehicle.id,
        },
      })
    },
  },
}
</script>
