import { da, enGB, es, fr, hr, hu, pl, ru, sl, sv, tr, uk } from 'date-fns/locale'
import get from 'lodash.get'
import has from 'lodash.has'

const module = {
  state: {
    locales: [
      {
        code: 'da-dk',
        name: 'Dansk',
        flag: '🇩🇰',
        dateLocale: da,
      },

      {
        code: 'en-gb',
        name: 'English (UK)',
        flag: '🇬🇧',
        dateLocale: enGB,
      },
      {
        code: 'es-es',
        name: 'Español',
        flag: '🇪🇸',
        dateLocale: es,
      },
      {
        code: 'pl',
        name: 'Polski',
        flag: '🇵🇱',
        dateLocale: pl,
      },
      {
        code: 'ru',
        name: 'Pусский',
        flag: '🇷🇺',
        dateLocale: ru,
      },
      {
        code: 'sl',
        name: 'slovenian',
        flag: '🇸🇮',
        dateLocale: sl,
      },
      {
        code: 'hr',
        name: 'Hrvatski',
        flag: '🇭🇷',
        dateLocale: hr,
      },
      {
        code: 'uk-ua',
        name: 'Yкраїнський',
        flag: '🇺🇦',
        dateLocale: uk,
      },
      {
        code: 'es-pe',
        name: 'Perú',
        flag: '🇵🇪',
        dateLocale: es,
      },
      {
        code: 'fr',
        name: 'Français',
        flag: '🇫🇷',
        dateLocale: fr,
      },
      {
        code: 'es-mx',
        name: 'Espanol (MEX)',
        flag: '🇲🇽',
        dateLocale: es,
      },
      {
        code: 'hu',
        name: 'Magyar',
        flag: '🇭🇺',
        dateLocale: hu,
      },
      {
        code: 'no',
        name: 'norsk',
        flag: '🇳🇴',
        dateLocale: enGB,
      },
      {
        code: 'tr',
        name: 'Türk',
        flag: '🇹🇷',
        dateLocale: tr,
      },
      {
        code: 'sv-se',
        name: 'svenska',
        flag: '🇸🇪',
        dateLocale: sv,
      },
    ],
    distanceUnit: 'kilometers',
    fuelEcoUnit: 'L/100km',
    fuelConsumedUnit: 'L',
    fuelEcoUnitOptions: ['L/100km', 'km/L', 'MPG (UK)', 'MPG (US)'],
    fuelConsumedUnitOptions: ['L', 'G (UK)', 'G (US)'],
    time24hr: true,
    dayFirst: true,
    currency: 'EUR',
    currencyOptions: ['EUR', 'GBP', 'DKK', 'PLN', 'SOL', 'HRK', 'UAH', 'NOK', 'HUF', 'CLP', 'COP', 'MXN', 'BRL', 'SEK', 'TRY', 'svSe'],
  },
  getters: {
    orderedLocales: (state) => {
      const preffered = navigator.language.toLowerCase()
      const collator = new Intl.Collator(preffered)

      return state.locales.toSorted((a, b) => {
        if (preffered === a.code) {
          return -999
        }
        const diff = collator.compare(a.name, b.name)
        return diff
      })
    },
    localeSettings: (state, getters, rootState) => {
      // overwrite old es locale to es-es, we can remove this soon
      const code = rootState.i18n.locale === 'es' ? 'es-es' : rootState.i18n.locale
      const locale = state.locales.filter(loc => loc.code === code)[0]
      const parts = code.split('-')
      locale.calCode = code
      if (parts.length > 1) {
        locale.calCode = `${parts[0]}-${parts[1].toUpperCase()}`
      }
      return locale
    },
    orgLocaleSettings: (state, getters, rootState) => {
      if (has(rootState.adminModule, 'org.settings.defaultLocale')) {
        const locale = rootState.adminModule.org.settings.defaultLocale === 'es' ? 'es-es' : rootState.adminModule.org.settings.defaultLocale
        return state.locales.filter(loc => loc.code === locale)[0]
      } else {
        return state.locales[0]
      }
    },
    unitDistanceShort: (state) => {
      return state.distanceUnit === 'kilometers' ? 'km' : 'mi'
    },
    speedUnit: (state) => {
      return state.distanceUnit === 'kilometers' ? 'km/h' : 'mph'
    },
    localizedDistanceM: (state) => (distance) => {
      if (distance === 0) {
        return 0
      }
      // assumes distance param is in m
      const x = state.distanceUnit === 'kilometers' ? 0.001 : 0.000621371192237
      return distance * x
    },
    localizedDistanceToM: (state) => (distance) => {
      // Convert distance to meters
      if (distance === 0) {
        return 0
      }
      const x = state.distanceUnit === 'kilometers' ? 0.001 : 0.000621371192237
      return distance / x
    },
    currencySymbol: (state, getters) => {
      switch (getters.orgSettings.currency) {
        case 'GBP':
          return '£'
        case 'DKK':
          return 'kr.'
        case 'PLN':
          return 'zł'
        case 'SOL':
          return 'S/'
        case 'HRK':
          return 'kn'
        case 'UAH':
          return '₴'
        case 'svSe':
        case 'NOK':
        case 'SEK':
          return 'kr'
        case 'HUF':
          return 'Ft'
        case 'CLP':
        case 'COP':
        case 'MXN':
          return '$'
        case 'BRL':
          return 'R$'
        case 'TRY':
          return '₺'
        default:
          return '€'
      }
    },
  },
  mutations: {
    SET_DISTANCE_UNIT (state, unit) {
      if (unit !== 'kilometers' && unit !== 'miles') {
        throw new Error('Distance unit must be kilometers or miles.')
      }
      state.distanceUnit = unit
    },
    SET_FUEL_ECO_UNIT (state, unit) {
      if (state.fuelEcoUnitOptions.indexOf(unit) === -1) {
        throw new Error(`Fuel Economy unit must be one of ${state.fuelEcoUnitOptions}`)
      }
      state.fuelEcoUnit = unit
    },
    SET_FUEL_CONSUMED_UNIT (state, unit) {
      if (state.fuelConsumedUnitOptions.indexOf(unit) === -1) {
        throw new Error(`Fuel Consumed unit must be one of ${state.fuelConsumedUnitOptions}`)
      }
      state.fuelConsumedUnit = unit
    },
    SET_TIME_DISPLAY (state, unit) {
      if (unit !== 'kilometers' && unit !== 'miles') {
        return new Error('Distance unit must be kilometers or miles.')
      }
      state.distanceUnit = unit
    },
    SET_24_HR_TIME (state, flag) {
      if (typeof flag !== 'boolean') {
        throw new Error('time24Hr must be a Boolean')
      }
      state.time24hr = flag
    },
    SET_DAY_FIRST (state, flag) {
      if (typeof flag !== 'boolean') {
        throw new Error('dayFirst must be a Boolean')
      }
      state.dayFirst = flag
    },
    SET_CURRENCY (state, cur) {
      if (state.currencyOptions.indexOf(cur) === -1) {
        throw new Error(`invalid currency ${cur}. Must be one of ${state.currencyOptions}`)
      }
      state.currency = cur
    },
  },
  actions: {
    LOCALIZE_FOR_SESSION ({ commit, rootState }, session) {
      for (let key in session.settings) {
        switch (key) {
          case 'time24hr':
            commit('SET_24_HR_TIME', session.settings.time24hr)
            break
          case 'dayFirst':
            commit('SET_DAY_FIRST', session.settings.dayFirst)
            break
          case 'distanceUnit':
            commit('SET_DISTANCE_UNIT', session.settings.distanceUnit)
            break
          case 'fuelEcoUnit':
            commit('SET_FUEL_ECO_UNIT', session.settings.fuelEcoUnit)
            break
          case 'fuelConsumedUnit':
            commit('SET_FUEL_CONSUMED_UNIT', session.settings.fuelConsumedUnit)
            break
          default:
            break
        }
      }
      if (has(rootState.authModule, 'activeOrg.settings.currency')) {
        commit('SET_CURRENCY', rootState.authModule.activeOrg.settings.currency)
      }
    },
    async RECONCILE_LOCALE_SETTING ({ state, dispatch }, session) {
      const sessionLanguage = get(session, 'language')
      const lsLanguage = localStorage.getItem('profleet-locale')
      // check to see if the language in locale storage is mismatched
      // from the user's setting, if so, we want to update the user
      if (lsLanguage && lsLanguage !== sessionLanguage) {
        // check that local storage has a valid setting
        const validCodes = state.locales.map(l => l.code)
        const localIsValid = validCodes.includes(lsLanguage)
        if (localIsValid) {
          await dispatch('UPDATE_PROFILE_PROMISE', {
            language: lsLanguage,
          })
        }
      }
    },
  },
}

export default module
