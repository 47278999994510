<template>
  <div
    class="px-10 py-6 rounded-b-lg bg-grey-lt"
  >
    <div class="container px-container flex items-center justify-center mb-4">
      <MapIcon />
      <p class="ml-2 text-copy text-xs tracking-relaxed leading-none">
        {{ $t('fleet_map_historical_explainer') }}
      </p>
    </div>
    <VinButton
      vid="reset-date-range-map-btn"
      class="mx-auto"
      :text="$t('reset_date_range_from_map')"
      height="35px"
      secondary
      @click="resetDuration"
    />
  </div>
</template>

<script>
import { MapIcon } from 'vue-feather-icons'
export default {
  name: 'DashboardMapHistoricPlaceholder',
  components: {
    MapIcon,
  },
  methods: {
    resetDuration () {
      this.$store.dispatch('SET_DURATION_AND_CLEAR_DATA', 7)
    },
  },
}
</script>
