<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="report-high-risk-icon"
        x1="94.128%"
        x2="5.217%"
        y1="0%"
        y2="70.35%"
      >
        <stop
          offset="0%"
          stop-color="#FF7378"
        />
        <stop
          offset="100%"
          stop-color="#F2545A"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#report-high-risk-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <path
        fill="#FFF"
        d="M33.895 39.79h-8.842a1.474 1.474 0 0 1 0-2.948h8.842c.814 0 1.473-.66 1.473-1.474V20.632c0-.814-.66-1.474-1.473-1.474h-11.79c-.814 0-1.473.66-1.473 1.474v17.684a1.474 1.474 0 1 1-2.948 0V20.632a4.421 4.421 0 0 1 4.421-4.421h11.79a4.421 4.421 0 0 1 4.42 4.42v14.737a4.421 4.421 0 0 1-4.42 4.421zm0 4.42c0-.813-.66-1.473-1.474-1.473H23.58a1.474 1.474 0 0 0 0 2.947h8.842c.814 0 1.474-.66 1.474-1.473zm0-32.42c0-.814-.66-1.474-1.474-1.474H23.58a1.474 1.474 0 0 0 0 2.947h8.842c.814 0 1.474-.66 1.474-1.474zm-2.137 21.854a1.474 1.474 0 0 0 .413-2.048l-2.697-4.038v-3.98a1.474 1.474 0 0 0-2.948 0v3.98c.002.583.176 1.152.501 1.636l2.697 4.052a1.474 1.474 0 0 0 2.049.413l-.015-.015z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconHighRisk',
}
</script>
