<template>
  <section class="card section bg-white rounded-md opacity-100 md:px-2">
    <div
      v-if="title"
      class="card-header py-5 px-8 rounded-t-md bg-cloud-dk leading-none text-lg"
    >
      <VinTitle :text="title" />
    </div>
    <div class="card-body rounded-b-md px-8 py-6">
      <slot />
    </div>
  </section>
</template>

<script>
import VinTitle from '@/common/components/VinTitle/VinTitle'

export default {
  name: 'VinCard',
  components: {
    VinTitle,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
}
</script>
<style>
.card {
  @apply bg-white rounded-md;
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  transition: opacity 0.2s ease, box-shadow 0.2s ease;
}
.card:hover {
  box-shadow: 0px 0px 10px 0px rgba(37, 38, 94, 0.1);
}
</style>
