<template>
  <Cluster
    v-if="!dirtySettings"
    ref="gcluster"
    :max-zoom="14"
    image-extension="png"
    :renderer="renderer"
    :on-cluster-click="handleClick"
    zoom-on-click
  >
    <DashboardMapMarker
      v-for="(location) in locations"
      :key="location.vehicle.id + 'marker'"
      :coords="location.coords"
      :vehicle="location.vehicle"
      :vehicle-status="location.status"
    />
  </Cluster>
</template>

<script>
import { components } from 'gmap-vue'
const { Cluster } = components

import DashboardMapMarker from './DashboardMapMarker'
export default {
  name: 'DashboardMapClusterer',
  components: {
    DashboardMapMarker,
    Cluster,
  },
  props: {
    locations: {
      type: Array,
    },
  },
  data ()  {
    return {
      show: true,
    }
  },
  computed: {
    dirtySettings () {
      return this.$store.state.vehiclesModule.dirtySettings
    },
    renderer () {
      const r = (cluster) => {
        let statusMap = this.$store.getters.getStatusMapForCluster(cluster)
        let valString = ''
        let colorString = ''
        for (let [key, value] of Object.entries(statusMap)) {
          if (!key || !value) {
            return
          }
          valString = valString === '' ? `${value.count}` : `${valString},${value.count}`
          colorString = colorString === '' ? `${value.color}` : `${colorString},${value.color}`
        }
        const encodedColors = encodeURIComponent(colorString)
        const path = `/api/v1/cluster-image?values=${valString}&colors=${encodedColors}&size=53&level=1`

        return new google.maps.Marker({
          position: cluster.position,
          icon: path,
          label: {
            text: String(cluster.markers.length),
            fontSize: '12px',
            className: 'font-medium',
          },
        })
      }

      return {
        render: r,
      }
    },
  },
  methods: {
    handleClick(_, cluster) {
      this.$store.commit('SET_SELECTED_CLUSTER', cluster)
    },
  },
}
</script>
