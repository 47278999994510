<template>
  <component
    :is="el"
    class="wrap-try"
  >
    {{ $t(text) }}
  </component>
</template>

<script>
export default {
  name: 'VinTitle',
  props: {
    el: {
      type: String,
      default: 'h3',
    },
    text: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>
.wrap-try {
  text-overflow: ellipsis;
}
</style>
