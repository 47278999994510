<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="idling-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="gradientStart"
        />
        <stop
          offset="100%"
          :stop-color="gradientStop"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="48"
        height="48"
        fill="url(#idling-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-width="2"
      >
        <path d="M23.133 24.999c-.435-1.4-1.8-2.463-3.727-2.226-2.599-2.167-6.46-.681-7.02 2.688-2.913 1.778-1.396 5.486 1.537 5.486h9.582c1.768 0 3.262-1.36 3.262-2.983 0-1.682-1.625-3.159-3.634-2.965zM34.88 17.225c-.246-.79-1.004-1.363-2.065-1.233-1.439-1.2-3.577-.377-3.888 1.488-1.613.985-.772 3.046.851 3.046h5.307c.98 0 1.807-.756 1.807-1.655 0-.93-.898-1.754-2.013-1.646z" />
        <path d="M34.433 20.526c.36 1.052.554 2.28.554 3.453 0 5.755-4.7 10.42-10.5 10.42-3.099 0-5.885-1.332-7.807-3.452M14.075 22.624c.67-5.115 5.076-9.066 10.412-9.066 2.293 0 4.415.732 6.141 1.97" />
      </g>
    </g>
  </svg>
</template>

<script>
import iconMix from '../icon-mixin'
export default {
  name: 'VinIdlingIcon',
  mixins: [iconMix],
}
</script>
