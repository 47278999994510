
<template>
  <div>
    <AppHeaderDefault />
    <AppNav />
    <div class="w-screen">
      <router-view name="default" />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/App/AppFooter'
import AppHeaderDefault from '@/components/App/AppHeader/AppHeaderDefault'
import AppNav from '@/components/App/AppNav/AppNav'
export default {
  name: 'ViewFullScreen',
  components: {
    AppHeaderDefault,
    AppNav,
    AppFooter,
  },
  computed: {
    currentBreakpoint () {
      return this.$store.state.baseModule.breakpoint
    },
  },
}
</script>
