<template>
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id="linearGradient-1"
        x1="50%"
        y1="100%"
        x2="50%"
        y2="0%"
      >
        <stop
          stop-color="#876ED5"
          offset="0%"
        />
        <stop
          stop-color="#A08BE3"
          offset="100%"
        />
      </linearGradient>
    </defs>
    <g
      id="Icons/Fuel-Usage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Idling-Events"
        fill="url(#linearGradient-1)"
        fill-rule="nonzero"
      >
        <rect
          id="blue-gradient"
          x="0"
          y="0"
          width="48"
          height="48"
          rx="10"
        />
      </g>
      <g
        id="5415145_air_car_emissions_gase_greenshouse_icon"
        transform="translate(5.000000, 10.000000)"
        fill="#FFFFFF"
        fill-rule="nonzero"
      >
        <path
          id="Shape"
          d="M6.33333333,27 C8.92493333,27 11.1549,25.4102143 12.1340333,23.1428571 L19,23.1428571 C19.3496,23.1428571 19.6333333,22.8548571 19.6333333,22.5 L19.6333333,19.2857143 C19.6333333,18.9308571 19.3496,18.6428571 19,18.6428571 L18.3666667,18.6428571 L18.3666667,15.4285714 L18.3666667,11.5714286 L18.3666667,10.9035 C18.3666667,9.14528571 16.9568667,7.71428571 15.2247,7.71428571 L12.3810333,7.71428571 C11.9079333,7.71428571 11.4563667,7.53492857 11.1086667,7.209 L8.36443333,4.63821429 C8.10983333,4.39971429 7.8622,4.16314286 7.61836667,3.92978571 C5.50366667,1.90928571 3.50613333,0 0,0 L0,1.28571429 C3.0039,1.28571429 4.65753333,2.86585714 6.7507,4.86642857 C6.9977,5.10171429 7.2485,5.3415 7.50563333,5.58321429 L10.2498667,8.154 C10.8319,8.69978571 11.5887333,9 12.3810333,9 L15.2247,9 C16.2583,9 17.1,9.85435714 17.1,10.9035 L17.1,10.9285714 L12.6666667,10.9285714 C11.9681,10.9285714 11.4,11.5052143 11.4,12.2142857 L11.4,12.8571429 C11.4,14.6295 12.8205667,16.0714286 14.5666667,16.0714286 L17.1,16.0714286 L17.1,18.6428571 L12.3747,18.6428571 C11.5659333,16.0373571 9.1637,14.1428571 6.33333333,14.1428571 C2.84113333,14.1428571 0,17.0267143 0,20.5714286 C0,24.1161429 2.84113333,27 6.33333333,27 Z M14.5666667,14.7857143 C13.5191333,14.7857143 12.6666667,13.9204286 12.6666667,12.8571429 L12.6666667,12.2142857 L17.1,12.2142857 L17.1,14.7857143 L14.5666667,14.7857143 Z M18.3666667,19.9285714 L18.3666667,21.8571429 L12.5387333,21.8571429 C12.6223333,21.4418571 12.6666667,21.0117857 12.6666667,20.5714286 C12.6666667,20.3541429 12.6552667,20.1400714 12.6343667,19.9285714 L18.3666667,19.9285714 Z M6.33333333,15.4285714 C9.12696667,15.4285714 11.4,17.7357857 11.4,20.5714286 C11.4,23.4070714 9.12696667,25.7142857 6.33333333,25.7142857 C3.5397,25.7142857 1.26666667,23.4070714 1.26666667,20.5714286 C1.26666667,17.7357857 3.5397,15.4285714 6.33333333,15.4285714 Z"
        />
        <path
          id="Shape"
          d="M6.33333333,24.4285714 C8.42903333,24.4285714 10.1333333,22.6986429 10.1333333,20.5714286 C10.1333333,18.4442143 8.42903333,16.7142857 6.33333333,16.7142857 C4.23763333,16.7142857 2.53333333,18.4442143 2.53333333,20.5714286 C2.53333333,22.6986429 4.23763333,24.4285714 6.33333333,24.4285714 Z M3.8,20.5714286 C3.8,20.2249286 3.86966667,19.8951429 3.99253333,19.593 L5.26743333,20.5630714 L3.98176667,21.5125714 C3.86776667,21.2207143 3.8,20.9044286 3.8,20.5714286 Z M4.7329,22.5482143 L6.32763333,21.3705 L7.90526667,22.572 C7.47206667,22.9236429 6.92993333,23.1428571 6.33333333,23.1428571 C5.72343333,23.1428571 5.17053333,22.914 4.7329,22.5482143 Z M8.68553333,19.6302857 C8.7989,19.9221429 8.86666667,20.2384286 8.86666667,20.5714286 C8.86666667,20.9179286 8.797,21.2477143 8.67413333,21.5498571 L7.39923333,20.5797857 L8.68553333,19.6302857 Z M7.93376667,18.5946429 L6.33903333,19.7723571 L4.7614,18.5708571 C5.1946,18.2192143 5.73673333,18 6.33333333,18 C6.94323333,18 7.49613333,18.2288571 7.93376667,18.5946429 Z"
        />
        <path
          id="Shape"
          d="M1.26666667,9.64285714 L6.33333333,9.64285714 C6.8875,9.64285714 7.35996667,9.29764286 7.53476667,8.76342857 C7.7102,8.22921429 7.53666667,7.66607143 7.09333333,7.32857143 L2.02666667,3.47142857 C1.64096667,3.17764286 1.13176667,3.13071429 0.700466667,3.34992857 C0.268533333,3.56914286 0,4.0095 0,4.5 L0,8.35714286 C0,9.06621429 0.5681,9.64285714 1.26666667,9.64285714 Z M1.26666667,4.5 L6.34156667,8.3565 C6.34156667,8.3565 6.33903333,8.35714286 6.33333333,8.35714286 L1.26666667,8.35714286 L1.26666667,4.5 Z"
        />
        <path
          id="Path"
          d="M29.1333333,18 C29.1333333,16.9367143 28.2808667,16.0714286 27.2333333,16.0714286 L25.65,16.0714286 C24.4276667,16.0714286 23.4333333,15.0621429 23.4333333,13.8214286 C23.4333333,12.5807143 24.4276667,11.5714286 25.65,11.5714286 L26.9344,11.5714286 C27.1845667,11.5714286 27.4106667,11.4222857 27.5126333,11.1908571 C27.6146,10.9594286 27.5728,10.6887857 27.4062333,10.4997857 C26.8862667,9.91028571 26.6,9.14914286 26.6,8.35714286 C26.6,6.58478571 28.0205667,5.14285714 29.7666667,5.14285714 C31.5127667,5.14285714 32.9333333,6.58478571 32.9333333,8.35714286 L34.2,8.35714286 C34.2,5.87571429 32.2113333,3.85714286 29.7666667,3.85714286 C27.322,3.85714286 25.3333333,5.87571429 25.3333333,8.35714286 C25.3333333,9.03085714 25.4809,9.6885 25.7602,10.2857143 L25.65,10.2857143 C23.7291,10.2857143 22.1666667,11.8716429 22.1666667,13.8214286 C22.1666667,15.7712143 23.7291,17.3571429 25.65,17.3571429 L27.2333333,17.3571429 C27.5823,17.3571429 27.8666667,17.6457857 27.8666667,18 C27.8666667,18.3542143 27.5823,18.6428571 27.2333333,18.6428571 L20.9,18.6428571 L20.9,19.9285714 L27.2333333,19.9285714 C28.2808667,19.9285714 29.1333333,19.0632857 29.1333333,18 Z"
        />
        <rect
          id="Rectangle"
          x="20.9"
          y="21.2142857"
          width="1.9"
          height="1.28571429"
        />
        <path
          id="Path"
          d="M38,8.35714286 C38,4.70635714 35.3045333,1.68107143 31.7293667,1.32042857 L31.6046,2.59971429 C34.5280667,2.89478571 36.7333333,5.36978571 36.7333333,8.35714286 C36.7333333,11.6685 33.3899667,14.1428571 30.4,14.1428571 C30.0504,14.1428571 29.7666667,14.4308571 29.7666667,14.7857143 C29.7666667,15.1405714 30.0504,15.4285714 30.4,15.4285714 L33.5666667,15.4285714 C35.3127667,15.4285714 36.7333333,16.8705 36.7333333,18.6428571 C36.7333333,20.061 35.5971333,21.2142857 34.2,21.2142857 L24.0666667,21.2142857 L24.0666667,22.5 L34.2,22.5 C36.2957,22.5 38,20.7700714 38,18.6428571 C38,16.4841429 36.4945667,14.6751429 34.4932333,14.2418571 C36.5712,12.9497143 38,10.7466429 38,8.35714286 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconFuelUsage',
}
</script>
