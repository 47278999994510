<template>
  <footer
    class="absolute bottom-0 w-full"
    :class="footerClass"
  >
    <div class="container py-12 pb-container md:pb-12 px-4 md:px-container">
      <p class="text-sm tracking-relaxed">
        {{ `${$t('Copyright ©')} ${year} ${$t('ALD. All Rights Reserved')}` }}
      </p>
      <hr
        class="w-full my-6"
        :class="dividerClass"
      >
      <p class="text-sm tracking-relaxed">
        <span class="text-sm font-semibold tracking-relaxed">
          {{ $t('Please note:') }}
        </span>
        {{ 'Authorised persons with Fleet Manager access will be able to see the number of \'exception events\' for ALL journeys but will only see the detail (location / time / etc) where those events have occurred on journeys where permission has been granted by the driver. Mixed journey duration times will be added to private duration totals.' | translate }}
      </p>
      <br>
      <div class="flex flex-wrap justify-between items-end gap-4">
        <p class="text-sm tracking-relaxed leading-none">
          <router-link :to="{ name: 'TermsPortal' }">
            <span
              :class="linkClass"
              class="hover:underline"
            >
              {{ $t('terms_of_service') }}
            </span>
          </router-link>
          <span> | </span>
          <router-link :to="{ name: 'PrivacyPolicyPortal' }">
            <span
              :class="linkClass"
              class="hover:underline"
            >
              {{ $t('privacy_policy') }}
            </span>
          </router-link>
          <span> | </span>
          <router-link :to="{ name: 'ContactSupport' }">
            <span
              :class="linkClass"
              class="hover:underline"
            >
              {{ $t('contact_support') }}
            </span>
          </router-link>
        </p>
        <AppFooterLogo
          v-if="showLogo"
          class="w-48"
          :light="light"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import theme from '@/assets/themes/config'

import AppFooterLogo from './AppFooterLogo.vue'
export default {
  name: 'AppFooter',
  components: {
    AppFooterLogo,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    footerClass () {
      return this.light ? 'bg-white text-grey-dkr' : 'bg-primary-dk text-white'
    },
    dividerClass () {
      return this.light ? 'border-grey-dkr' : 'border-white'
    },
    linkClass () {
      return this.light ? 'hover:text-cta-lt' : 'text-white hover:text-grey-lt'
    },
    year () {
      return new Date().getFullYear()
    },
    showLogo () {
      return theme.footerLogo ?? false
    },
  },
}
</script>
<style scoped>
footer {
  max-height: 439px;
}
</style>
