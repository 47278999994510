import get from 'lodash.get'

import axios from '../middleware/axios-store'

const module = {
  state: {
    prices: [],
    pricePagination: {
      total: 0,
      offset: 0,
      limit: 10,
    },
    queuedDeletePrice: null,
    fetchingFuel: false,
  },
  mutations: {
    CLEAR_FUEL_PRICE_MODULE: (state) => {
      state.prices = []
      state.pricePagination = {
        total: 0,
        offset: 0,
        limit: 10,
      }
      state.queuedDeletePrice = null
      state.fetchingFuel = false
    },
    SET_FUEL_PRICES: (state, resp) => {
      state.prices = get(resp, 'data.prices', [])
    },
    PUSH_NEW_PRICE: (state, price) => {
      state.prices.push(price)
    },
    UPDATE_FUEL_PRICE_PAGINATION: (state, pagination) => {
      state.pricePagination.total = get(pagination, 'total', state.pricePagination.total)
      state.pricePagination.offset = get(pagination, 'offset', state.pricePagination.offset)
      state.pricePagination.limit = get(pagination, 'limit', state.pricePagination.limit)
    },
    REMOVE_PRICE_FROM_LIST: (state, priceId) => {
      state.prices = state.prices.filter(p => p.id !== priceId)
    },
    QUEUE_PRICE_FOR_DELETION: (state, price) => {
      state.queuedDeletePrice = price
    },
    SET_FETCHING_FUEL_STATUS: (state, flag) => {
      state.fetchingFuel = flag
    },
  },
  actions: {
    POST_FUEL_PRICE ({ commit, dispatch, rootState }, payload) {
      axios.post(`/api/v1/orgs/${rootState.adminModule.org.id}/fuel-prices`, payload)
        .then(resp => {
          const price = get(resp, 'data')
          if (price) {
            commit('PUSH_NEW_PRICE', price)
          }
          dispatch('SERVE_TOAST', {
            type: 'success',
            message: 'Successfully added price!',
          })
        })
        .catch(err => {
          const code = get(err, 'response.data.code')
          const message = get(err, 'response.data.messages[0].error')
          const duplicate = code === 500 && message === 'unable to set fuel prices'

          dispatch('SERVE_TOAST', {
            type: duplicate ? 'warning' : 'danger',
            message: duplicate ? 'A fuel price already exists for this month. Add pricing data for a new month, or delete and re-create the existing month with new pricing.' : 'Something went wrong.',
          })
        })
    },
    DELETE_FUEL_PRICE_PROMISE ({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        if (!rootState.adminModule.org.id) {
          reject(new Error('admin org id missing'))
        }
        const priceId = state.queuedDeletePrice.id
        axios.delete(`/api/v1/orgs/${rootState.adminModule.org.id}/fuel-prices/${priceId}`)
          .then(() => {
            commit('REMOVE_PRICE_FROM_LIST', priceId)
            commit('QUEUE_PRICE_FOR_DELETION', null)
            resolve()
          })
          .catch(err => {
            commit('QUEUE_PRICE_FOR_DELETION', null)
            reject(err)
          })
      })
    },
    FETCH_FUEL_PRICES_PROMISE ({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        if (!rootState.adminModule.org.id) {
          reject(new Error('missing org id when fetching fuel data'))
        }
        // then check if we already have this page
        // only fire the request if the page is missing or empty
        const page = state.pricePagination.offset / state.pricePagination.limit
        if (!state.prices.length - 1 < page || !get(state, `prices[${page}]`)) {
          commit('SET_FETCHING_FUEL_STATUS', true)

          // sorts
          const sortInfo = rootState.paramsModule.sorts.fuelPrices
          let sortBy = 'date'
          if (sortInfo.sortBy === 'price_petrol') {
            sortBy = 'petrol_price'
          } else if (sortInfo.sortBy === 'price_diesel') {
            sortBy = 'diesel_price'
          } else if (sortInfo.sortBy === 'created_date') {
            sortBy = 'created_at'
          }
          const sortDir = sortInfo.asc ? 'asc' : 'desc'
          axios.get(`/api/v1/orgs/${rootState.adminModule.org.id}/fuel-prices`, {
            params: {
              offset: state.pricePagination.offset,
              limit: state.pricePagination.limit,
              sortBy: sortBy,
              sortDir: sortDir,
            },
          })
            .then(resp => {
              commit('UPDATE_FUEL_PRICE_PAGINATION', {
                total: get(resp, 'data.meta.pagination.total'),
              })
              commit('SET_FUEL_PRICES', resp)
              commit('SET_FETCHING_FUEL_STATUS', false)
              resolve()
            })
            .catch(err => {
              commit('SET_FETCHING_FUEL_STATUS', false)
              reject(err)
            })
        } else {
          commit('SET_FETCHING_FUEL_STATUS', false)
          // just resolve since we already have the page
          resolve()
        }
      })
    },
  },
}

export default module
