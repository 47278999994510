<template>
  <div class="flex justify-between flex-wrap items-end">
    <span class="text-grey-dk tracking-relaxed flex items-end leading-none">
      <p>
        {{ $t('Displaying') }}
      </p>
      <p class="font-bold mx-1">
        {{ range }}
      </p>
      <p class="mr-1">
        {{ $t('out of') }}
      </p>
      <p class="font-bold">
        {{ total }}
      </p>
    </span>
    <div class="flex items-center">
      <VinButton
        :id="`${book}-paginate-previous-btn`"
        :vid="`${book}-paginate-previous-btn`"
        :text="$t('Previous')"
        :color="color"
        :is-loading="loading.prev"
        :disabled="offset > 0 ? false : true"
        class="mr-3 w-24"
        @click="handlePrev"
      />
      <VinButton
        :id="`${book}-paginate-next-btn`"
        :vid="`${book}-paginate-next-btn`"
        :text="$t('Next')"
        :color="color"
        :disabled="end < total ? false : true"
        :is-loading="loading.next"
        class="w-24"
        @click="handleNext"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VinSimplePager',
  props: {
    book: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 10,
    },
    color: {
      type: String,
      default: 'primary-dk',
    },
    loading: {
      type: Object,
      default: () => {
        return {
          prev: false,
          next: false,
        }
      },
    },
  },
  computed: {
    end () {
      return this.limit + this.offset > this.total ? this.total : this.limit + this.offset
    },
    range () {
      const start = this.total === 0 ? 0 : this.offset + 1
      return `${start} - ${this.end}`
    },
  },
  methods: {
    handlePrev () {
      if (this.offset > 0) {
        this.$emit('prev')
      }
    },
    handleNext () {
      if (this.end < this.total) {
        this.$emit('next')
      }
    },
  },
}
</script>
