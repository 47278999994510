<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="report-co2-summary-icon"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#F6B518"
        />
        <stop
          offset="100%"
          stop-color="#F6D118"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="48"
        height="48"
        fill="url(#report-co2-summary-icon)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        stroke="#FFF"
        stroke-linejoin="round"
        stroke-width="1.6"
      >
        <path d="M34.415 31.569a6.268 6.268 0 0 0 4.562-6.049v-.24c0-3.46-2.865-6.29-6.368-6.29h-1.62V18.5c0-3.54-2.905-6.41-6.49-6.41-3.583 0-6.49 2.87-6.49 6.41v.706" />
        <path d="M20.81 20.95c-1.35-1.388-2.552-1.955-4.582-1.955l-.284.01c-3.297.228-5.922 2.963-5.922 6.274v.24a6.266 6.266 0 0 0 4.415 6.006" />
        <path
          stroke-linecap="round"
          d="M20.81 20.95c-.901-.926-1.737-1.487-2.797-1.757M29.191 31.508h3.088v2.768h-3.084v2.634h3.084M22.519 27.59h3.969v9.321h-3.969zM19.825 36.91h-3.228v-9.32h3.228"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCo2Summary',
}
</script>
