<template>
  <div
    :id="vid"
    class="flex items-center w-full justify-between pt-8"
  >
    <div class="text-copy flex items-center leading-none w-48 hover:text-primary-dk">
      <button
        v-if="selected !== 0"
        class="flex items-center justify-between mr-auto"
        @click="page(true)"
      >
        <svg
          class="back-arrow stroke-current mt-2px mr-4"
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="12"
          viewBox="0 0 7 12"
        >
          <path
            fill="none"
            fill-rule="nonzero"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 11l5-5-5-5"
            opacity=".5"
          />
        </svg>
        {{ $t('Previous') }}
      </button>
    </div>

    <div class="flex flex-wrap self-center w-full">
      <div class="mx-auto flex justify-between">
        <button
          v-if="!visibleSteps.includes(0)"
          :key="0"
          :class="0 == selected ? 'card rounded-base text-primary-dk cursor-default' : 'cursor-pointer hover:text-primary-dk'"
          class="h-12 w-12 text-lg mx-1 transition"
          @click="0 == selected ? $emit('active') : setSelected(0)"
        >
          1
        </button>
        <div
          v-if="!visibleSteps.includes(0)"
          class="align-middle pt-1 mx-5"
        >
          ...
        </div>
        <button
          v-for="step in visibleSteps"
          :key="step"
          :class="step == selected ? 'card rounded-base text-primary-dk cursor-default' : 'cursor-pointer hover:text-primary-dk'"
          class="h-12 w-12 text-lg mx-1 transition"
          @click="step == selected ? $emit('active') : setSelected(step)"
        >
          {{ step + 1 }}
        </button>
        <div
          v-if="!visibleSteps.includes(steps.length -1)"
          class="align-middle pt-1 mx-5"
        >
          ...
        </div>

        <button
          v-if="!visibleSteps.includes(steps.length -1)"

          :key="totalPages"
          :class="totalPages == selected ? 'card rounded-base text-primary-dk cursor-default' : 'cursor-pointer hover:text-primary-dk'"
          class="h-12 w-12 text-lg mx-1 transition"
          @click="totalPages == selected ? $emit('active') : setSelected(totalPages)"
        >
          {{ totalPages + 1 }}
        </button>
      </div>
    </div>

    <div class="text-copy flex items-center leading-none w-48 hover:text-primary-dk">
      <button
        v-if="selected < steps.length - 1"
        class="flex items-center justify-between ml-auto"
        @click="page(false)"
      >
        {{ $t('Next') }}
        <svg
          class="stroke-current mt-2px ml-4"
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="12"
          viewBox="0 0 7 12"
        >
          <path
            fill="none"
            fill-rule="nonzero"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 11l5-5-5-5"
            opacity=".5"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'

export default {
  name: 'VinPaginate',
  props: {
    pageName: {
      type: String,
      require: true,
    },
    selectedPagePersistenceMinutes: {
      type: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 100,
    },
    setPageTo: {
      type: Number,
    },
    loading: {
      type: Object,
      default: () => {
        return {
          prev: false,
          next: false,
        }
      },
    },
    vid: {
      type: String,
      required: true,
      default: null,
    },
  },
  data () {
    return {
      basseUrl: '',
      start: 0,
      end: 5,
      selected: 0,
    }
  },
  computed: {
    totalPages() {
      return this.steps.length - 1
    },
    localStorageKey () {
      return `${this.pageName}-${this.orgId}-${this.activeGroupId}-page-number`
    },
    orgId () {
      return get(this.$store.state.authModule, 'activeOrg.id', 'dft_org')
    },
    activeGroupId () {
      return get(this.$store.state.groupsModule, 'activeGroup.id', 'all')
    },
    steps () {
      const count = Math.ceil(this.total / this.pageSize)
      let i = 0
      let steps = []
      while (i < count) {
        steps.push(i)
        i++
      }
      return steps
    },
    stepCount () {
      const breakpoint = this.$store.state.baseModule.breakpoint
      if (breakpoint === 'mobile') {
        return this.steps.length > 2 ? 2 : this.steps.length
      } else {
        return this.steps.length > 5 ? 5 : this.steps.length
      }
    },
    visibleSteps () {
      let steps = this.steps
      let start = this.selected - 2
      let end = this.selected +3
      if ((end) >= this.steps.length) {
        end = this.steps.length
        start = this.steps.length - 5
      }
      if ((start) <= 0) {
        start = 0
        end = 5
      }
      return steps.slice(start, end)
    },
    range () {
      const start = this.selected * this.pageSize
      let end = start + this.pageSize - 1
      if (end >= this.total) {
        end = this.total - 1
      }
      return [start, end]
    },
  },
  watch: {
    stepCount () {
      this.end = this.stepCount
    },
    setPageTo () {
      if (this.setPageTo) {
        this.selected = this.setPageTo
      }
    },
  },
  mounted () {
    this.start = 0
    this.end = this.stepCount
    this.selected = this.setPageTo || this.getPage(JSON.parse(localStorage.getItem(this.localStorageKey)))
    this.setSelected(this.selected)
  },
  methods: {
    getPage (pageObj) {
      const today = new Date()
      const diffTime = Math.abs(today - new Date(pageObj?.timestamp))
      const minutes = diffTime / (1000 * 60)
      if (minutes < this.selectedPagePersistenceMinutes) {
        return pageObj.page
      }
      return 0
    },
    page (back) {
      if (this.selected === 0 && back) {
        // do nothing at start of stepper
        return
      }
      if (!back && this.selected === this.steps.length - 1) {
        // do nothing at end of stepper
        return
      }
      const increment = back ? -1 : 1
      this.selected += increment
      if (this.selected >= this.end - 1 && this.end !== this.steps.length) {
        this.end += increment
        this.start += increment
      }
      if (this.selected <= this.start && this.start !== 0) {
        this.start += increment
        this.end += increment
      }
      this.$emit('paged', this.range)

      localStorage.setItem(this.localStorageKey, JSON.stringify({ timestamp: new Date().toString(), page: this.selected }))
    },
    setSelected (i) {
      this.selected = i
      if (this.selected >= this.end - 1 && this.end !== this.steps.length) {
        this.end += 1
        this.start += 1
      }
      if (this.selected <= this.start + 1 && this.start !== 0) {
        this.start -= 1
        this.end -= 1
      }
      this.$emit('paged', this.range)

      localStorage.setItem(this.localStorageKey, JSON.stringify({ timestamp: new Date().toString(), page: this.selected }))
    },
    reset () {
      this.selected = 0
      this.start = 0
      this.end = 5
    },
  },
}
</script>

<style scoped>
.transition {
  transition: all 0.15s ease-in-out;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.back-arrow {
  transform: rotate(180deg);
}
.card {
  border-radius: 16px;
}
</style>
