import axios from 'axios'
import debounce from 'lodash.debounce'
import get from 'lodash.get'

const checker = debounce(async (err, commit, dispatch) => {
  commit('PUSH_ERROR', err)
  // process the error
  if (err.response) {
    // anytime we see a 401
    const status = get(err, 'response.status')
    if (status === 401) {
      // check the current session
      let instance = axios.create()

      try {
        await instance.get('/api/v1/sessions/_current')
      } catch (sessionErr) {
        console.error(sessionErr)
        dispatch('DELETE_CURRENT_SESSION', true)
      }
    }
  }
}, 3000)

const module = {
  state: {
    errors: [],
    handling: false,
  },
  mutations: {
    PUSH_ERROR: (state, err) => {
      state.errors.push(err)
    },
    CLEAR_ERRORS: (state) => {
      state.errors = []
    },
    TOGGLE_HANDLING: (state, toggle) => {
      state.handling = toggle
    },
  },
  actions: {
    async HANDLE_ERROR({ commit, dispatch }, err) {
      checker(err, commit, dispatch)
    },
  },
}

export default module
