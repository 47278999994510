<template id="modal-template">
  <transition
    v-if="show"
    name="modal"
    appear
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container"
          :class="width"
          :style="styleOverride"
        >
          <slot name="content" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'VinModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'modal-container-width',
    },
    styleOverride: {
      type: Object,
      default: () => {
        return {
          padding: '20px 30px',
        }
      },
    },
  },
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(250, 251, 252, 0.85);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 1385px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  transition: all 0.3s ease;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.modal-container-width {
  width: 70%;
}

.report-params-modal-width {
  width: 592px;
}

.scheduled-modal-container-width {
  width: 527px;
  height: 816px;
}

.custom-duration-width {
  max-width: 740px;
  height: 790px;
}

.small-modal {
  max-width: 400px;
}

.modal-container:hover {
  box-shadow: 0px 0px 10px 0px rgba(37, 38, 94, 0.1);
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
}
</style>
