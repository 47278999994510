<template>
  <div id="view-default">
    <AppHeaderDefault />
    <AppNav />

    <div v-if="!$store.getters.packIsSimple">
      <ServiceLocationsMap v-if="currentBreakpoint === 'desktop'" />
      <div
        :class="currentBreakpoint === 'desktop' ? '' : 'pt-8'"
        class="w-full bg-white"
      >
        <div
          id="fleet-dashboard-container"
          class="container"
        >
          <router-view name="defaultHeader" />
        </div>
      </div>
      <div
        id="fleet-dashboard-container"
        :class="bottomPadding"
        class="container"
      >
        <router-view name="default" />
      </div>
    </div>
    <div
      v-else
      id="fleet-dashboard-container"
      :class="bottomPadding"
      class="container pt-12"
    >
      {{ $t('Service locations are not enabled for your organization. Contact your ALD Account Manager for more information.') }}
    </div>

    <AppFooter />
  </div>
</template>

<script>
import ServiceLocationsMap from '@/components/Admin/ServiceLocations/ServiceLocationsMap'
import AppFooter from '@/components/App/AppFooter'
import AppHeaderDefault from '@/components/App/AppHeader/AppHeaderDefault'
import AppNav from '@/components/App/AppNav/AppNav'
export default {
  name: 'ViewServiceLocations',
  components: {
    AppHeaderDefault,
    AppNav,
    ServiceLocationsMap,
    AppFooter,
  },
  computed: {
    hasMap () {
      return this.$store.state.dashboardModule.map && this.$store.getters.isAuthenticated
    },
    currentBreakpoint () {
      return this.$store.state.baseModule.breakpoint
    },
    bottomPadding () {
      return this.$route.meta.bottomPadding || 'pb-24'
    },
  },
}
</script>
