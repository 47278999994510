import { differenceInMilliseconds,differenceInMinutes, parseISO } from 'date-fns'
import get from 'lodash.get'
import has from 'lodash.has'
import Vue from 'vue'

import leadfoot from '@/common/utils/leadfoot'
import vYear from '@/common/utils/v-year'

import colors from '../../assets/themes/colors'
import axios from '../middleware/axios-store'

const pluckLocation = vehicle => {
  const lat = vehicle?.status?.lat ?? null
  const lon = vehicle?.status?.lon ?? null
  const locTimestamp = vehicle?.status?.location ?? null
  if (lat !== null && lat !== null && locTimestamp !== null) {
    const packet = {
      vehicleId: vehicle.id,
      location: {
        lat: lat,
        lng: lon,
      },
      timestamp: locTimestamp,
    }
    return packet
  }
}

const eventTypes = ['high-speed', 'hard-accel', 'hard-decel', 'idling', 'over-revving']
const nonEVEventTypes = ['over-revving', 'idling']

const module = {
  state: {
    // active org vehicles
    hasAllVehicles: false,
    vehicleFetchStatus: 'loading',
    vehicleIdFetchStatus: {},
    fetchingVehicles: false,
    orgVehicleFetching: {},
    include: 'telem',
    vehicles: [],
    vehicleTripState: {},
    vehicleLocations: {}, // an object where vehicleId is key and value is array of locations
    dirtySettings: false,
    vehicleStatusSettings: {
      active: {
        show: true,
        copy: 'Active',
        color: colors['success-lt'],
        colorClass: 'success-lt',
        icon: 'active',
      },
      'stopped-l-24': {
        show: true,
        copy: 'stopped-l-24',
        copyAbrv: 'stopped-l-24',
        color: colors['primary-dk'],
        colorClass: 'primary-dk',
        icon: 'stopped-l-24',
      },
      'stopped-g-24': {
        show: true,
        copy: 'stopped-g-24',
        copyAbrv: 'stopped-g-24',
        color: colors['danger-lt'],
        colorClass: 'danger-lt',
        icon: 'stopped-g-24',
      },
      'not-transmitting': {
        show: true,
        copy: 'not_transmitting',
        copyAbrv: 'not_transmitting',
        color: colors['grey-dk'],
        colorClass: 'grey-dk',
        icon: 'not-transmitting',
      },
      'no-locations': {
        omit: true,
        copy: 'No Location Data Available',
        copyAbrv: 'No Location Data Available',
        color: colors['grey-dk'],
        colorClass: 'grey-dk',
        icon: 'not-transmitting',
      },
      inactive: {
        omit: true,
        copy: 'Deactivated',
        copyAbrv: 'Deactivated',
        color: colors['grey-dk'],
        colorClass: 'grey-dk',
        icon: 'not-transmitting',
      },
    },
    // org vehicle page stuff
    totalVehicleCount: 0,

    // active vehicle details
    selectedVehicle: {},
    vehicleTrips: [],
    vehicleTripsEvents: [],
    selectedTrip: null,
    loadingTrip: false,

    selectedEvent: null,
    tripCounts: {},

    // journey history
    selectedTrips: [],
    fetchingVehicleTrips: false,

    // status tracking
    now: new Date(),
  },
  getters: {
    allowedEventTypes: (_, getters)=> (isBEV = false) => {
      let list = [...eventTypes]
      if (!getters.showSpeedingEvents) {
        list.shift()
      }
      if (isBEV) {
        list = list.filter(t => !nonEVEventTypes.includes(t))
      }
      return list
    },
    getSelectedVehicle: (state, getters) => route => {
      const id = get(route, 'params.vehicleId')
      if (state.selectedVehicle && state.selectedVehicle.id === id) {
        return state.selectedVehicle
      }
      const fromList = getters.getVehicleById(id)
      if (fromList) {
        return fromList
      }
      return {}
    },
    getVehicleById: state => id => {
      return state.vehicles.find(vehicle => vehicle.id === id)
    },
    getVehiclesByOrgId: state => orgId => {
      return state.vehicles.filter(v => v.orgId === orgId)
    },
    getTripById: state => id => {
      return state.vehicleTrips.find(t => t.id === id)
    },
    cleanTripEvents: (state, getters) => (tripId, isBEV = false) => {
      let trip
      if (tripId === get(state.selectedTrip, 'id')) {
        trip = state.selectedTrip
      } else {
        trip = state.vehicleTrips.find(t => t.id === tripId)
      }
      if (!trip) {
        return []
      }
      const events = get(trip, 'events', [])

      let clean = events.filter(event => {
        return getters.allowedEventTypes(isBEV).indexOf(event.eventType) >= 0
      })
      return clean
    },
    parsedTripEvents: (state, getters, rootState) => (tripId, isBEV = false) => {
      let filtered = getters.cleanTripEvents(tripId, isBEV)

      const sortPref = rootState.paramsModule.sorts.journeyEvents

      const up = sortPref.asc ? 1 : -1
      const down = sortPref.asc ? -1 : 1

      return filtered.sort((a, b) => {
        if (sortPref.sortBy === 'Time') {
          return a.timestamp >= b.timestamp ? up : down
        } else {
          return a.eventType >= b.eventType ? up : down
        }
      })
    },
    colorForTrip: state => tripId => {
      let idx = state.selectedTrips.findIndex(t => t.id === tripId)

      if (idx > 9) {
        idx = idx % 10
      }

      switch (idx) {
        case 0:
          return colors['primary-dk']
        case 1:
          return colors['success-dk']
        case 2:
          return colors['score-d']
        case 3:
          return colors['danger-dk']
        case 4:
          return colors['primary-lt']
        case 5:
          return colors['success-lt']
        case 6:
          return colors['pending-dk']
        case 7:
          return '#c500ff'
        case 8:
          return colors['grey-dk']
        case 9:
          return '#002C5D'
        default:
          return null
      }
    },
    prettyEventName: state => event => { // eslint-disable-line no-unused-vars
      switch (event) {
        case 'high-speed':
          return 'Speeding'
        case 'hard-accel':
          return 'Harsh Acceleration'
        case 'hard-decel':
          return 'Hard Brake'
        case 'idling':
          return 'Idling'
        case 'over-revving':
          return 'Over Revving'
        case 'high-risk-hours':
          return 'High Risk Hours'

        default:
          return event
      }
    },
    appVehicles: (state, getters, rootState) => {
      const activeGroupId = get(rootState.groupsModule.activeGroup, 'id')
      if (activeGroupId) {
        return rootState.groupsModule.groupVehicles[activeGroupId] || []
      }
      return state.vehicles
    },
    appVehiclesLabel: (state, getters, rootState) => {
      const activeGroupName = get(rootState.groupsModule.activeGroup, 'name')
      if (activeGroupName) {
        return `${activeGroupName} ${Vue.i18n.translate('Vehicles')}`
      }
      return Vue.i18n.translate('All Vehicles')
    },
    fetchingAppVehicles: (state, getters, rootState) => {
      const activeGroupId = get(rootState.groupsModule.activeGroup, 'id')
      if (activeGroupId) {
        return rootState.groupsModule.fetchingVehicles
      }
      return state.fetchingVehicles
    },
    latestVehicleStatus: (state, getters, rootState) => (vehicle) => {
      let locationTimestamp
      let comparisonDate = get(state, 'now', new Date())

      const vehicleLocations = get(state.vehicleLocations, vehicle.id, [])
      const tripState = get(state.vehicleTripState, vehicle.id, {})

      if (rootState.fleetHistoryModule.historicalMode) {
        // override if we are in historical mode
        locationTimestamp = get(vehicle, 'historicalLocation.timestamp')
        // use historical date to compare
        comparisonDate = parseISO(rootState.fleetHistoryModule.queryDate)
        if (!locationTimestamp) {
          return 'not-transmitting'
        }
      } else if (vehicleLocations.length > 0) {
        // prefer the most recent location,
        // this will get updated from websocket
        locationTimestamp = vehicleLocations[vehicleLocations.length - 1].timestamp
      }
      // return inactive for vehicles that were not in the org during comparison date
      if (vehicle.stopTime && new Date(vehicle.stopTime) < comparisonDate) {
        return 'inactive'
      }

      // check how long it's been since location
      const minsSinceLocation = differenceInMinutes(comparisonDate, parseISO(locationTimestamp))

      // if within active threshold and we have not seen a trip stopped, we're done, return active
      if (minsSinceLocation <= 4 && !tripState?.stopped) {
        return 'active'
      }

      // otherwise compare available times, not includeing phone home
      const times = [vehicle?.status?.startup, vehicle?.status?.shutdown, vehicle?.status?.location]
      const mins = times.filter(t => t) // filter out nulls
        .map(t => differenceInMinutes(comparisonDate, parseISO(t)))
        .sort((a, b) => a - b) // sort so that smallest difference is first

      const weeks2 = 60 * 24 * 14
      let status = 'not-transmitting'
      if (mins.length > 0) {
        const min = mins[0]
        const hrs24 = 60 * 24
        // less than 24 hrs
        if (min < hrs24) {
          status = 'stopped-l-24'
        }
        // more than 2 weeks
        if (min > weeks2) {
          status = 'not-transmitting'
        }
        // more 24 hrs or less than 2 weeks
        else if (min > hrs24) {
          status = 'stopped-g-24'
        }
      }
      // check phone home if we are still not transmitting.
      // if it is < 2 weeks old, mark status as stopped greater than 24 hours and go have a beer.
      const phoneHome = vehicle?.status?.phoneHome
      if (status === 'not-transmitting' && phoneHome) {
        const phMins = differenceInMinutes(comparisonDate, parseISO(phoneHome))
        if (phMins < weeks2) {
          return 'stopped-g-24'
        }
      }
      // anything else
      return status
    },
    prettyVehicle: () => obj => {
      // parses vehicle make model year from an object
      const parts = [vYear(obj), get(obj, 'make'), get(obj, 'model')]
      return parts.reduce((string, part) => {
        if (part && part !== null) {
          if (string.length > 0) {
            string = `${string} ${part}`
          } else {
            string = part
          }
        }
        return string
      }, '')
    },
    isFetchingForVehicleId: state => vehicleId => {
      return get(state.vehicleIdFetchStatus, vehicleId, false)
    },
    now: state => {
      return state.now
    },
  },
  mutations: {
    CLEAR_VEHICLE_MODULE: (state) => {
      state.hasAllVehicles = false
      state.vehicles = []
      state.vehicleLocations = {}
      state.selectedVehicle = {}
      state.vehicleFetchStatus = 'loading'
      state.vehicleIdFetchStatus = {}
      state.totalVehicleCount = 0
      state.selectedEvent = null
      state.vehicleTrips = []
      state.selectedTrips = []
      state.loadingTrip = false
      state.now = new Date()
      state.tripCounts = {}
    },
    SET_NOW: (state) => {
      state.now = new Date()
    },
    // org vehicle mutations
    SET_VEHICLES: (state, vehicles) => {
      state.vehicles = state.vehicles.concat(vehicles)
      state.totalVehicleCount = state.vehicles.length
    },
    WIPE_VEHICLE: (state) => {
      state.selectedVehicle = {}
    },

    // vehicle detail mutations
    SET_VEHICLE: (state, payload) => {
      state.selectedVehicle = payload
      state.vehicleTripCount = payload.tripCount
    },
    UPDATE_TRIP_STATE_BY_VEHICLE_ID: (state, payload) => {
      const tripstate = get(state.vehicleTripState, payload.vehicleId, null)
      // from the stopped packet
      const packet = {
        stopped: payload.stopped,
        timestamp: payload.timestamp,
        tripid: payload.tripid,
      }

      if (tripstate === null) {
        Vue.set(state.vehicleTripState, payload.vehicleId, packet)
      } else {
        state.vehicleTripState[payload.vehicleId] = packet
      }
    },
    UPDATE_LOCATION_BY_VEHICLE_ID: (state, payload) => {
      const workingList = [...get(state.vehicleLocations, payload.vehicleId, [])]

      // from the location packet
      const packet = {
        coordinates: payload.location,
        timestamp: payload.timestamp,
        speed: payload.speed,
      }

      // see if we have any locations for this vehicleId
      if (workingList.length > 0) {
        // if we do, push location packet on existing list
        workingList.push(packet)
        if (workingList.length > 20) {
          // limit to 20 locations per vehicleId
          workingList.shift()
        }

        let tripState = get(state.vehicleTripState, payload.vehicleId, {})
        const len = workingList.length

        if (len > 1) {

          // update trip state with the previous location near the stopped trip
          if (tripState.stopped && !tripState.previousLocation) {
            const ts2 = parseISO(tripState.timestamp)
            const ts1 = parseISO(workingList[len - 2].timestamp)
            const milliSinceLocation = differenceInMilliseconds(ts2, ts1)
            if (milliSinceLocation > 0) {
              state.vehicleTripState[payload.vehicleId].previousLocation = workingList[len - 2].coordinates
              tripState = state.vehicleTripState[payload.vehicleId]
            }
          }

          // unset the stopped state if the vehicle has moved
          const newLocation = (state, locs) => {

            if (!state.vehicleTripState[payload.vehicleId].previousLocation){
              return false
            }
            const l = locs.length
            const c2 = locs[l - 1].coordinates
            const c1 = state.vehicleTripState[payload.vehicleId].previousLocation
            const dLat = Math.abs(c2.lat - c1.lat)
            const dLon = Math.abs(c2.lng - c1.lng)
            const d = Math.sqrt(dLat**2 + dLon**2)
            if ( d > 0.001 ) {
              // moved about 100m
              return true
            }
            return false
          }
          if (tripState.stopped && newLocation(state, workingList)) {
            console.log(`vehicle (${payload.vehicleId}) moved > 100 ft.`)
            delete state.vehicleTripState[payload.vehicleId].previousLocation
            const vs = {
              timestamp: state.timestamp,
              vehicleId: state.vehicleId,
              stopped: false,
            }
            state.vehicleTripState[payload.vehicleId] = vs
          }
        }
      } else {
        // add the first location
        workingList.push(packet)
      }
      Vue.set(state.vehicleLocations, payload.vehicleId, workingList)
    },
    SET_VEHICLE_FETCH_STATUS: (state, payload) => {
      state.vehicleFetchStatus = payload
    },
    SET_VEHICLE_ID_FETCH_STATUS: (state, payload) => {
      Vue.set(state.vehicleIdFetchStatus, payload.vehicleId, payload.status)
    },
    TOGGLE_FETCHING_VEHICLES: (state, payload) => {
      const { flag, orgId } = payload
      state.fetchingVehicles = flag
      Vue.set(state.orgVehicleFetching, orgId, flag)
    },
    SET_SELECTED_EVENT: (state, id) => {
      if (state.selectedEvent !== id) {
        state.selectedEvent = id
      } else {
        state.selectedEvent = null
      }
    },
    SET_SELECTED_TRIP: (state, trip) => {
      state.selectedTrip = trip
    },
    TOGGLE_LOADING_TRIP: (state, flag) => {
      state.loadingTrip = flag
    },
    PUSH_TRIP: (state, trip) => {
      const match = state.vehicleTrips.find(t => t.id === trip.id)
      if (!match) {
        state.vehicleTrips.push(trip)
      }
    },
    CLEAR_TRIPS: (state) => {
      state.vehicleTrips = []
    },
    TOGGLE_VEHICLE_STATUS: (state, status) => {
      if (state.dirtySettings) {
        return
      }
      state.dirtySettings = true
      Vue.set(state.vehicleStatusSettings[status], 'show', !state.vehicleStatusSettings[status].show)
      state.dirtySettings = false
    },
    TOGGLE_TRIP_HISTORY: (state, payload) => {
      let found = state.selectedTrips.find(function (element) {
        return element === payload
      })
      if (!found) {
        state.selectedTrips.push(payload)
      } else {
        var index = state.selectedTrips.indexOf(payload)
        if (index !== -1) {
          state.selectedTrips.splice(index, 1)
        }
      }
    },
    CLEAR_TRIP_HISTORY: (state) => {
      state.selectedTrips = []
    },
    TOGGLE_FETCHING_VEHICLE_TRIPS: (state) => {
      state.fetchingVehicleTrips = !state.fetchingVehicleTrips
    },
    SET_HISTORICAL_LOCATION_ON_VEHICLE: (state, payload) => {
      // find the vehicle index
      let vehicleIndex = state.vehicles.findIndex(v => v.id === payload.vehicleId)
      // if the vehicle is there, set the location
      if (vehicleIndex > -1) {
        let vehicle = state.vehicles[vehicleIndex]
        vehicle.historicalLocation = payload.location
        // vue set vehicle back into array
        Vue.set(state.vehicles, vehicleIndex, vehicle)
      }
    },
    SET_ODO_FOR_VEHICLE: (state, odo) => {
      // set odo for selected vehicle
      if (get(state.selectedVehicle, 'id') === odo.vehicleId) {
        Vue.set(state.selectedVehicle, 'odometer', odo.distance)
      }

      // find the vehicle index
      let vehicleIndex = state.vehicles.findIndex(v => v.id === odo.vehicleId)
      // if the vehicle is there, set the location
      if (vehicleIndex > -1) {
        let vehicle = state.vehicles[vehicleIndex]
        vehicle.odometer = odo.distance
        // vue set vehicle back into array
        Vue.set(state.vehicles, vehicleIndex, vehicle)
      }
    },
    SET_HAS_ALL_VEHICLES: (state, flag) => {
      state.hasAllVehicles = flag
    },
    SET_VEHICLE_INCLUDE_PARAM: (state, param) => {
      state.include = param
    },
    SET_VEHICLE_ATTRIBUTE: (state, payload) => {
      // set odo for selected vehicle
      if (get(state.selectedVehicle, 'id') === payload.id) {
        Vue.set(state.selectedVehicle, payload.key, payload.value)
      }

      // find the vehicle index
      let vehicleIndex = state.vehicles.findIndex(v => v.id === payload.id)
      // if the vehicle is there, set the location
      if (vehicleIndex > -1) {
        let vehicle = state.vehicles[vehicleIndex]
        vehicle[payload.key] = payload.value
        // vue set vehicle back into array
        Vue.set(state.vehicles, vehicleIndex, vehicle)
      }
    },
    SET_TRIP_COUNTS: (state, payload) => {
      for (let [id, count] of Object.entries(payload.counts)) {
        Vue.set(state.tripCounts, id, count)
      }
    },
  },
  actions: {
    // org vehicle actions
    async HYDRATE_ORG_VEHICLES({ commit, dispatch, state, getters, rootState }) {

      const orgId = rootState.authModule.activeOrg?.id
      if (!orgId) {
        return
      }
      // only do this if we aren't already doing it
      if (!state.orgVehicleFetching[orgId]) {
        // first wipe vehicles so we have a blank slate
        commit('CLEAR_VEHICLE_MODULE')
        commit('TOGGLE_FETCHING_VEHICLES', { orgId, flag: true })
        const includeSupplier = getters.hasPermission('admin')
        let includes = 'driver,status'
        if (includeSupplier) {
          includes = `${includes},supplier`
        }
        const { since, until } = getters.sinceUntil
        const result = await leadfoot({
          url: `/api/v1/orgs/${orgId}/vehicles`,
          limit: 1000,
          listKey: 'vehicles',
          max: 10000,
          params: {
            since,
            until,
            includes,
          },
        })
        // dedupe the list, needed if the since/until spans a time
        // when a vehicle was removed and added back to the same org
        const list = result.list.reduce((acc, v) => {
          const ov = `{'id': ${v.id}}, 'orgId': ${v.orgId}}`
          if (!(ov in acc)) {
            acc[ov] = v
          }
          return acc
        }, {})
        const vehiclesList = Object.values(list)

        if (orgId === rootState.authModule.activeOrg?.id) {
          commit('SET_VEHICLES', vehiclesList)
          vehiclesList.forEach(v =>{
            dispatch('INITIALIZE_VEHICLE_LOCATION', v)
          })
          if (!result.errored) {
            commit('SET_VEHICLE_FETCH_STATUS', 'success')
            commit('SET_HAS_ALL_VEHICLES', true)
          } else {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'Error fetching vehicles.',
            })
          }
        }

        commit('TOGGLE_FETCHING_VEHICLES', { orgId, flag: false })
      }
    },
    // vehicle detail actions
    async HYDRATE_VEHICLE (context, payload) {
      await context.dispatch('FETCH_VEHICLE_DETAILS', payload)
    },
    FETCH_VEHICLE_DETAILS ({ commit, getters, dispatch }, payload) {
      const { vehicleId } = payload

      // enabled but not maintenance records for vehicleId
      let shouldFetchMaintenance = getters.isMaintenanceEnabled && !getters.maintenanceStatusForVehicleId(vehicleId)

      // do we already have the vehicle?
      const existingVehicle = getters.getVehicleById(vehicleId)
      if (existingVehicle && !payload.hardFetch) {
        // set the vehicle if we havae it
        commit('SET_VEHICLE', existingVehicle)
        // check if we have odo
        if (!has(existingVehicle, 'odometer')) {
          dispatch('FETCH_ODOMETER_FOR_VEHICLE', payload)
        }
        // fill maintenance details
        if (shouldFetchMaintenance) {
          dispatch('FETCH_SERVICE_STATUS_FOR_VEHICLE', existingVehicle)
        }
      } else {
        if (getters.isFetchingForVehicleId(vehicleId)) {
          return
        }

        // wipe stuff before fetching
        commit('WIPE_VEHICLE')

        commit('SET_VEHICLE_ID_FETCH_STATUS', vehicleId, true)

        if (getters.isMaintenanceEnabled) {
          // if hard fetching and maintenance is enabled, fetch maintenance
          shouldFetchMaintenance = true
        }

        const orgId = payload.orgId
        const includeSupplier = getters.hasPermission('admin')
        let includes = 'driver,status,reportCard,dtcs'
        if (includeSupplier) {
          includes = `${includes},supplier`
        }
        const { since, until } = getters.sinceUntil
        axios.get(`/api/v1/orgs/${orgId}/vehicles/${vehicleId}`, {
          params: {
            since,
            until,
            includes,
          },
        })
          .then(resp => {
            dispatch('FETCH_ODOMETER_FOR_VEHICLE', payload)
            commit('SET_VEHICLE', resp.data)
            dispatch('INITIALIZE_VEHICLE_LOCATION', resp.data)

            if (shouldFetchMaintenance) {
              // hard fetch maintenance
              dispatch('FETCH_SERVICE_STATUS_FOR_VEHICLE', resp.data)
            }
          })
          .catch(err => {
            throw err
          })

        commit('SET_VEHICLE_ID_FETCH_STATUS', vehicleId, false)
      }
    },
    FETCH_ODOMETER_FOR_VEHICLE ({ commit }, payload) {
      const vehicleId = payload.vehicleId
      const orgId = payload.orgId
      axios.get(`/api/v1/orgs/${orgId}/vehicles/${vehicleId}/distance`)
        .then(resp => {
          commit('SET_ODO_FOR_VEHICLE', resp.data)
        })
        .catch(err => {
          throw err
        })
    },
    FETCH_TRIPS_PROMISE ({ commit, dispatch, rootState, getters }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.vehicleId && !payload.url) {
          reject(new Error('vehicleId is required if payload does not contain url param'))
        }
        // option to pass url in with payload
        const url = payload.url || `/api/v1/orgs/${rootState.authModule.activeOrg.id}/vehicles/${payload.vehicleId}/trips?limit=10`

        const wipePreview = !getters.hasMixin('lt')

        commit('TOGGLE_FETCHING_VEHICLE_TRIPS')
        axios
          .get(url)
          .then(resp => {
            resp.data.trips.forEach(t => {
              if (wipePreview) {
                t.preview = ''
              }
              commit('PUSH_TRIP', t)

              if (!get(t, 'anonamized')) {
                // grab places if not anon
                let stopPointPlaces = get(t, 'stopPointPlaces', [])
                if (stopPointPlaces && stopPointPlaces.length > 0) {
                  stopPointPlaces.forEach(p => {
                    commit('PUSH_VEHICLE_PLACE_ID', p)
                  })
                }

              }
            })
            commit('TOGGLE_FETCHING_VEHICLE_TRIPS')
            resolve(resp.data)
          })
          .catch(err => {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'Error loading journeys',
            })
            commit('TOGGLE_FETCHING_VEHICLE_TRIPS')
            reject(err)
          })
      })
    },
    async FETCH_TRIP_DETAILS({ commit, getters, dispatch }, payload) {
      const canGetEvents = getters.hasMixin('dm')
      commit('TOGGLE_LOADING_TRIP', true)
      const tripInLocal = getters.getTripById(payload.tripId)
      if (tripInLocal) {
        if (canGetEvents) {
          const { data } = await dispatch('FETCH_TRIP_EVENTS_PROMISE', { tripId: tripInLocal.id, vehicleId: tripInLocal.vehicleId })
          Vue.set(tripInLocal, 'events', data.events)
          commit('SET_SELECTED_TRIP', tripInLocal)
        }
        commit('TOGGLE_LOADING_TRIP', false)
        return
      }

      if (payload.tripId) {
        const { since } = getters.sinceUntil
        axios.get(`/api/v1/orgs/${payload.orgId}/trips/${payload.tripId}`, {
          params: {
            since,
          },
        })
          .then(resp => {
            // format the trip to match list rep
            let trip = {
              ...resp.data,
              ...resp.data.trip,
            }
            if (!get(resp.data, 'anonamized') && canGetEvents) {
              dispatch('FETCH_TRIP_EVENTS_PROMISE', {
                tripId: payload.tripId,
                vehicleId: trip.trip.vehicleId,
              })
                .then(eventResp => {
                  Vue.set(trip, 'events', eventResp.data.events)
                  commit('SET_SELECTED_TRIP', trip)
                  commit('TOGGLE_LOADING_TRIP', false)
                })
                .catch(err => {
                  // still set trip and events
                  Vue.set(trip, 'events', [])
                  commit('SET_SELECTED_TRIP', trip)
                  commit('TOGGLE_LOADING_TRIP', false)
                  throw err
                })
            } else {
              Vue.set(trip, 'events', [])
              commit('SET_SELECTED_TRIP', trip)
              commit('TOGGLE_LOADING_TRIP', false)
            }
          })
          .catch(err => {
            dispatch('SERVE_TOAST', {
              type: 'danger',
              message: 'Error getting journey details',
            })
            commit('TOGGLE_LOADING_TRIP', false)
            throw err
          })
      }
    },
    FETCH_TRIP_EVENTS_PROMISE({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.vehicleId || !payload.tripId || !get(rootState, 'authModule.activeOrg.id')) {
          reject(new Error('vehicleId, tripId, and activeOrg.id are required'))
        }

        let params = {}
        if (payload.limit) {
          params.limit = payload.limit
        }
        let url = get(payload, 'url', `/api/v1/orgs/${rootState.authModule.activeOrg.id}/vehicles/${payload.vehicleId}/trips/${payload.tripId}/events`)
        axios
          .get(url, { params: params })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    INITIALIZE_VEHICLE_LOCATION ({ commit }, vehicle) {
      const packet = pluckLocation(vehicle)
      if (packet) {
        commit('UPDATE_LOCATION_BY_VEHICLE_ID', packet)
      }
    },
    // eslint-disable-next-line complexity
    async SIDELOAD_VEHICLE_INFO ({ rootState, commit, getters }, payload) {
      const orgId = get(rootState, 'authModule.activeOrg.id')
      const { vehicle, wants } = payload
      if (!orgId || !vehicle || !vehicle.id || !wants) {
        return
      }
      const { since, until } = getters.sinceUntil

      const opts = {
        method: 'get',
        params: {
          since,
          until,
        },
      }
      const needsGrade = !get(vehicle, 'overallGrade') && wants.includes('reportCard')
      if (needsGrade) {
        let overallGrade
        try {
          opts.url = `/api/v1/orgs/${orgId}/vehicles/${vehicle.id}/score/_overall`
          const { data } = await axios(opts)
          overallGrade = get(data, 'reportCard.overallGrade', 'I') || 'I'
        } catch (err) {
          overallGrade = 'I'
        }
        commit('SET_VEHICLE_ATTRIBUTE', {
          key: 'overallGrade',
          value: overallGrade,
          id: vehicle.id,
        })
      }

      let includes = ''
      const needsEventCounts = get(vehicle, 'eventCounts') === null && wants.includes('eventCounts')
      const needsDTCs = get(vehicle, 'dtcs', null) === null && wants.includes('dtcs')
      if (needsEventCounts) { includes += 'eventCounts' }
      if (needsDTCs) { includes += ',dtcs' }
      if (needsEventCounts || needsDTCs) {
        let eventCount, dtcs
        try {
          opts.url = `/api/v1/orgs/${orgId}/vehicles/${vehicle.id}?includes=${includes}`
          const { data } = await axios(opts)
          eventCount = data.eventCounts || {}
          dtcs = data.dtcs || []
        } catch (err) {
          eventCount = {}
          dtcs = []
        }
        if (needsEventCounts) {
          commit('SET_VEHICLE_ATTRIBUTE', {
            key: 'eventCounts',
            value: eventCount,
            id: vehicle.id,
          })
        }
        if (needsDTCs) {
          commit('SET_VEHICLE_ATTRIBUTE', {
            key: 'dtcs',
            value: dtcs,
            id: vehicle.id,
          })
        }
      }
    },
    VALIDATE_HISTORICAL_TRIPS: ({ commit, dispatch }, trips) => {
      let invalidTrips = 0

      trips.forEach(trip => {
        const preview = get(trip, 'preview', null)
        const startLat = get(trip, 'startPoint.coordinates[1]')
        const stopLat = get(trip, 'stopPoint.coordinates[1]')

        let invalid = false

        if (
          !preview ||
          typeof startLat !== 'number' ||
          typeof stopLat !== 'number'
        ) {
          invalid = true
        } else if (
          startLat < -90 || startLat > 90 ||
          stopLat < -90 || stopLat > 90
        ) {
          invalid = true
        }

        invalid ?
          invalidTrips ++ :
          commit('TOGGLE_TRIP_HISTORY', trip)
      })

      if (invalidTrips > 0) {
        let msg = 'historic_trip_insufficient_data_warning'
        if (invalidTrips > 1) {
          msg = 'historic_trip_insufficient_data_warning_plural'
        }

        dispatch('SERVE_TOAST', {
          type: 'warning',
          message: msg,
        })
      }
    },
    TICKLE_NOW: ({ state, commit, dispatch }) => {
      commit('SET_NOW')
      const vehicleCount = state.vehicles.length
      let refreshRate = 3_000
      if (vehicleCount > 50) {
        refreshRate = 10_000
      }
      if (vehicleCount > 300) {
        refreshRate = 30_000
      }
      setTimeout(() => {
        dispatch('TICKLE_NOW')
      }, refreshRate)
    },
    async SINGLE_VEHICLE_TRIP_COUNTS({ state, rootState, commit, getters }, vehicleId) {
      const orgId = get(rootState, 'authModule.activeOrg.id')
      if (!orgId) {
        return
      }
      if (state.tripCounts[vehicleId] !== undefined) {
        return
      }
      let count = null
      const { since, until } = getters.sinceUntil
      try {
        const { data } = await axios.get(`/api/v1/orgs/${orgId}/vehicles/${vehicleId}/tripsCount`, {
          params: {
            since,
            until,
          },
        })
        count = data?.count ?? null
      } catch (err) {
       console.error('error fetching trip count for vehicle', err)
      }
      const counts = {}
      counts[vehicleId] = count
      commit('SET_TRIP_COUNTS', { counts } )
    },
    async BULK_FETCH_TRIP_COUNTS ({ state, rootState, getters, commit, dispatch }, vehicleIds) {
      const orgId = get(rootState, 'authModule.activeOrg.id')
      if (!orgId) {
        return
      }
      const needed = vehicleIds.filter(id => state.tripCounts[id] === undefined)
      if (needed.length === 0) {
        return
      }
      const { since, until } = getters.sinceUntil

      try {
        const { data } = await axios.get(`/api/v1/orgs/${orgId}/bulk/trip-counts`, {
          params: {
            since,
            until,
            vehicleIds: needed.toString(),
          },
        })
        commit('SET_TRIP_COUNTS', data)
        // no access means that the org-vehicle's start/stop time is not fully
        // within the since/until range. So, retrieve counts from the single vehicle
        // api which will handle date sandwiching
        if (data.noAccess.length > 0) {
          data.noAccess.forEach(vid => dispatch('SINGLE_VEHICLE_TRIP_COUNTS', vid))
        }
      } catch (err) {
        console.error('error getting bulk trip counts', err)
      }

    },
  },
}

export default module
