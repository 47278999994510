<template>
  <div class="flex items-center relative text-grey-dk text-base">
    <chevron-down-icon class="h-6 w-6 absolute mr-2 right-0 pointer-events-none" />
    <select
      :id="vid"
      :name="vid"
      class="py-2 pr-1 pl-4 focus:outline-none appearance-none w-full border-grey border rounded"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
      <option
        v-if="placeholder"
        value=""
      >
        {{ $t(placeholder) }}
      </option>
      <option
        v-for="(opt, i) in formattedOptions"
        :key="i"
        :value="opt.value"
      >
        {{ opt.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { ChevronDownIcon } from 'vue-feather-icons'
export default {
  name: 'VinSelect',
  components: {
    ChevronDownIcon,
  },
  props: {
    placeholder: {
      type: [String, Boolean],
      default: 'default_select_placeholder',
    },
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    value: {
      type: [String, Number, null, undefined],
      default: '',
    },
    vid: {
      type: String,
      required: true,
      default: null,
    },
  },
  computed: {
    formattedOptions () {
      return this.options.map(o => this.formatOpt(o))
    },
  },
  methods: {
    formatOpt (val) {
      if (typeof val === 'string') {
        return {
          label: val,
          value: val,
        }
      }
      return val
    },
  },
}
</script>
