<template>
  <main id="app">
    <VinToaster />
    <AppModals />
    <CookieBanner v-if="showCookies" />
    <AccessBanner />
    <component
      :is="view"
      id="fleet-main"
      :style="showForMimic || showForOrgTree ? 'paddingTop:40px' : ''"
    >
      <router-view />
    </component>
  </main>
</template>

<script>
import get from 'lodash.get'

import AppModals from '@/components/App/AppModals/AppModals'
import AccessBanner from '@/components/General/AccessBanner/AccessBanner'
import CookieBanner from '@/components/General/CookieBanner/CookieBanner'

const defaultView = 'ViewDefault'
export default {
  name: 'App',
  components: {
    AppModals,
    AccessBanner,
    CookieBanner,
  },
  data () {
    return {
      routerInitialized: false,
    }
  },
  computed: {
    view () {
      if (this.$route.meta.multiTemplate) {
        const getter = this.$route.meta.multiTemplate.getter
        const routeGetterEval = this.$store.getters[getter]
        return routeGetterEval
          ? this.$route.meta.multiTemplate.primary
          : this.$route.meta.multiTemplate.secondary
      }
      return (this.$route.meta.view || defaultView)
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    session () {
      return this.$store.state.authModule.session
    },
    showForMimic () {
      return this.$store.state.authModule.mimic.isActive
    },
    showForOrgTree () {
      return this.rootOrgId !== this.activeOrgId
    },
    rootOrgId () {
      return get(this.$store.state.authModule, 'session.orgs[0].id', null)
    },
    activeOrgId () {
      return get(this.$store.state.authModule.activeOrg, 'id', null)
    },
    showCookies () {
      return this.$route.path !== '/cookies'
    },
    routeName () {
      return this.$route.name
    },
  },
  watch: {
    isAuthenticated () {
      if (!this.isAuthenticated) {
        this.$router.push({ name: `Login` })
      }
    },
    session () {
      if (this.session) {
        const language = localStorage.getItem('profleet-locale') || get(this.session ,'language', 'en-gb')
        this.$i18n.set(language)
        this.$store.dispatch('RECONCILE_LOCALE_SETTING', this.session)
      }
    },
    routeName () {
      // initiailze the router once we have a named route
      if (!this.routerInitialized && this.routeName) {
        this.$emit('router-initialize')
      }
    },
  },
  created() {

    this.$store.commit('SET_WINDOW_WIDTH', window.innerWidth)
    window.onresize = () => {
      this.$store.commit('SET_WINDOW_WIDTH', window.innerWidth)
    }
    if (this.session) {
      this.$i18n.set(this.language)
    }
    this.$once('router-initialize', () => {
      this.routerInitialized = true
      if (this.$route.meta.needsVehicles && !this.$store.state.vehiclesModule.hasAllVehicles && !this.$store.state.vehiclesModule.fetchingVehicles) {
        this.$store.dispatch('HYDRATE_ORG_VEHICLES')
      }
    })
    this.$store.dispatch('TICKLE_NOW')
  },
}
</script>
