<template>
  <div class="flex flex-col w-full h-full justify-between">
    <div
      v-for="(item, key) in vehicleStati"
      :key="key"
    >
      <div class="flex items-center my-1">
        <VinCheckbox
          :vid="`map-settings-toggle-${key}-checkbox`"
          :value="item.show"
          @input="toggleStatus(key)"
        />
        <div class="mr-1">
          <img
            class="h-8 w-8 -mb-2"
            :src="require(`@/assets/img/marker-${key}.svg`)"
          >
        </div>
        <span class="text-copy text-sm">
          {{ $t(item.copy) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardMapSettingsStatus',
  computed: {
    vehicleStati () {
      const settings = {}
      const statusSettings = this.$store.state.vehiclesModule.vehicleStatusSettings
      // remove omitted status from map setting
      for (const [key, val] of Object.entries(statusSettings)) {
        if (!val.omit) {
          settings[key] = val
        }
      }
      return settings
    },
  },
  methods: {
    toggleStatus (status) {
      this.$store.commit('TOGGLE_VEHICLE_STATUS', status)
    },
  },
}
</script>
