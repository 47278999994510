const module = {
  state: {
    searchTerm: {
      spotlight: '',
      serviceAndMaintenance: '',
    },
    searchResults: {
      spotlight: [],
      serviceAndMaintenance: [],
    },
    mapSearchSelection: null,
    searchMap: false,
  },
  getters: {
    isSearching: state => {

      let searching = false
      for (let key in state.searchTerm) {
        if (state.searchTerm[key].length > 0) {
          searching = true
        }
      }
      return searching
    },
  },
  mutations: {
    SEARCH_BY_TERM: (state, info) => {
      state.searchTerm[info.type] = info.term
    },
    SET_SEARCH_RESULTS: (state, info) => {
      state.searchResults[info.type] = info.payload
    },
    TOGGLE_SEARCH_MAP: (state, flag) => {
      state.searchMap = flag
    },
    SET_MAP_SEARCH_SELECTION: (state, payload) => {
      state.mapSearchSelection = payload
    },
    CLEAR_MAP_SEARCH: state => {
      state.mapSearchSelection = null
      state.searchMap = false
    },
  },
}

export default module
