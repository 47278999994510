<script>
import get from 'lodash.get'
export default {
  name: 'StreamVehicles',
  props: {
    isFleet: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    streamEnabled () {
      return this.$store.state.mapSettingsModule.liveStream
    },
    socket () {
      return this.$store.state.liveTrackingModule.liveTrackingSocket
    },
    socketOpen () {
      return this.$store.state.liveTrackingModule.socketStatus === 1
    },
    vehicleIds () {
      if (this.isFleet) {
        return this.$store.state.vehiclesModule.vehicles.map(v => v.id)
      } else {
        return [get(this.$store.getters.getSelectedVehicle(this.$route), 'id')]
      }
    },
    orgId () {
      // prefer the org from the route
      return this.$route?.params?.orgId ?? this.$store.state.authModule?.activeOrg?.id
    },
    hasAllVehicles () {
      return this.$store.state.vehiclesModule.hasAllVehicles
    },
  },
  watch: {
    vehicleIds () {
      if (!this.isFleet) {
        this.initStream()
      }
    },
    hasAllVehicles: {
      immediate: true,
      handler () {
        if (this.isFleet) {
          this.initStream()
        }
      },
    },
    socketOpen () {
      this.initStream()
    },
    streamEnabled () {
      this.initStream()
    },
    orgId: {
      immediate: true,
      handler: function () {
        this.$store.dispatch('CLOSE_TRACKING_SOCKET')
        this.openSocket()
      },
    },
  },
  methods: {
    async openSocket () {
      if (this.orgId && this.streamEnabled) {
        await this.$store.dispatch('OPEN_TRACKING_SOCKET', this.orgId)
        this.initStream()
      }
    },
    initStream () {
      if (this.streamEnabled && this.socketOpen) {
        this.$store.commit('REGISTER_VEHICLE_STREAM', this.vehicleIds)
      }
    },
  },
  render () {
    return null
  },
}
</script>
