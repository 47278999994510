<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <defs>
      <linearGradient
        id="icon-driver-reg-grad"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          :stop-color="colors['icon-gradient-0']"
        />
        <stop
          offset="100%"
          :stop-color="colors['icon-gradient-1']"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="56"
        height="56"
        fill="url(#icon-driver-reg-grad)"
        fill-rule="nonzero"
        rx="10"
      />
      <g
        fill="#FFF"
        fill-rule="nonzero"
        stroke="#FFF"
      >
        <path d="M38.279 27.88c-4.492 0-8.147 3.654-8.147 8.146 0 4.492 3.655 8.146 8.147 8.146 4.492 0 8.146-3.654 8.146-8.146 0-4.492-3.654-8.147-8.146-8.147zm0 14.935a6.796 6.796 0 0 1-6.79-6.79 6.796 6.796 0 0 1 6.79-6.788 6.796 6.796 0 0 1 6.789 6.789 6.796 6.796 0 0 1-6.79 6.789z" />
        <path d="M27.769 39.284c-.345 0-.688-.016-1.03-.042l-.177-.013c-.344-.03-.687-.072-1.026-.127l-.046-.008a13.797 13.797 0 0 1-2.112-.527l-.064-.022c-.342-.116-.68-.245-1.012-.387l-.007-.004a14.172 14.172 0 0 1-.926-.443l-.119-.061a13.958 13.958 0 0 1-1.053-.628c-.246-.16-.49-.329-.726-.506l-.073-.057.053-.03 4.294-2.341a2.297 2.297 0 0 0 1.197-2.018v-1.956l-.125-.15c-.012-.014-1.186-1.443-1.63-3.377l-.05-.215-.185-.12a.9.9 0 0 1-.417-.756v-1.925c0-.253.107-.488.302-.664l.18-.162V19.72l-.005-.071c-.002-.013-.162-1.32.758-2.368.785-.895 2.13-1.35 3.999-1.35 1.86 0 3.202.45 3.99 1.34.918 1.037.768 2.368.767 2.379l-.005 3.096.18.162a.888.888 0 0 1 .302.664v1.925a.907.907 0 0 1-.642.856.543.543 0 0 0 .32 1.037 1.976 1.976 0 0 0 1.408-1.893v-1.925c0-.47-.173-.928-.482-1.287v-2.534c.028-.283.128-1.873-1.023-3.185-1-1.143-2.62-1.721-4.815-1.721-2.195 0-3.815.578-4.816 1.72-1.15 1.313-1.05 2.904-1.023 3.185v2.535a1.979 1.979 0 0 0-.481 1.286v1.926c0 .598.268 1.155.727 1.532.44 1.723 1.346 3.028 1.68 3.47v1.571c0 .444-.242.85-.63 1.064l-4.295 2.342a3.925 3.925 0 0 0-.409.259 13.816 13.816 0 0 1-4.602-10.3c0-7.636 6.212-13.849 13.849-13.849 7.636 0 13.849 6.213 13.849 13.85a.543.543 0 1 0 1.086 0c0-8.236-6.7-14.936-14.935-14.936s-14.936 6.7-14.936 14.935c0 4.35 1.87 8.27 4.848 11.003l-.014.012.484.408c.032.027.066.049.097.075.258.213.524.416.796.612.088.063.176.128.265.189.29.2.588.39.892.57l.2.114c.333.19.673.368 1.021.532l.077.035a14.8 14.8 0 0 0 4.905 1.32l.148.011c.4.034.806.055 1.217.055a.543.543 0 1 0 0-1.087z" />
      </g>
      <path
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M35 36.167l2.333 2.333L42 33.833"
      />
    </g>
  </svg>
</template>

<script>
import colors from '@/assets/themes/colors'
export default {
  name: 'IconDriverReg',
  computed: {
    colors () {
      return colors
    },
  },
}
</script>
