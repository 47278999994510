<template>
  <VinSmallWidgetTemplate
    :title="`${$t('AVG')} ${$t('Cost Events')}`"
    :timestamp="generatedAt"
    icon="Savings"
    :fetching="isFetching"
    has-driver
    :has-stat="false"
    :best="best"
    :worst="worst"
    :pack-has-report="packHasReport"
    :cta-title="$t('View Fleet Cost League Table')"
    :cta-action="navToReport"
    @bust-cache="fetchData(true)"
  />
</template>

<script>
import get from 'lodash.get'

import { composeWidget, shameTheDriver } from '../compose-widget'
export default {
  name: 'WidgetDriverCostEventsSm',
  displayName: 'Driver Cost Events',
  icon: 'Savings',
  setup () {
    const widget = {
      slug: 'report-fleet-cost-league-table',
    }
    const report = {
      widget: 'ReportFleetCostLeague',
      slug: 'report-fleet-cost-league-table',
    }

    const {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    } = composeWidget(widget, report)

    return {
      results,
      generatedAt,
      isFetching,
      packHasReport,
      fetchData,
      navToReport,
    }
  },
  computed: {
    rows () {
      return get(this.results, 'rows', [])
    },
    worst () {
      return shameTheDriver(this.rows)
    },
    best () {
      return shameTheDriver(this.rows, false)
    },
  },
}
</script>
