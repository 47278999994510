import { render, staticRenderFns } from "./SpotlightSearch.vue?vue&type=template&id=425ca925&scoped=true&"
import script from "./SpotlightSearch.vue?vue&type=script&lang=js&"
export * from "./SpotlightSearch.vue?vue&type=script&lang=js&"
import style0 from "./SpotlightSearch.vue?vue&type=style&index=0&id=425ca925&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "425ca925",
  null
  
)

export default component.exports