<template>
  <div
    v-clickoutside="handleClickOut"
    class="tooltip"
  >
    <span
      ref="trigger"
      class="tooltip-trigger"
    >
      <slot />
    </span>
    <transition
      :name="transition"
      appear
      @after-leave="$_popoverMixin_doDestory"
    >
      <div
        v-if="show"
        ref="popover"
        class="tooltip-popper"
        :class="[
          placement ?
            'tooltip-' + placement :
            'tooltip-top'
        ]"
      >
        <div
          class="tooltip-arrow"
          :class="kill ? 'hidden' : ''"
        />
        <div class="tooltip-shadow">
          <div
            :class="kill ? 'hidden' : headerPadding"
            class="tooltip-header font-semibold leading-loose tracking-relaxed bg-white text-copy relative"
          >
            <slot
              v-if="header"
              name="header"
            >
              <div v-text="title" />
            </slot>
          </div>
          <div
            class="tooltip-content text-sm leading-loose tracking-relaxed bg-white text-grey-dk relative"
            :class="kill ? 'hidden' : contentPadding"
          >
            <slot name="content">
              <div v-text="content" />
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PopoverMixin from '@/common/mixins/popoverMixin'
export default {
  name: 'VinTooltip',
  directives: {
    clickoutside: {
      bind: function (el, binding) {
        el._handler = evt => {
          if (!el.contains(evt.target) && binding.expression) {
            binding.value(evt)
          }
        }
        document.addEventListener('click', el._handler)
      },
      unbind: function (el) {
        document.removeEventListener('click', el._handler)
      },
    },
  },
  mixins: [PopoverMixin],
  props: {
    trigger: {
      type: String,
      default: 'hover',
    },
    transition: {
      type: String,
      default: 'fade',
    },
    kill: {
      type: Boolean,
      default: false,
    },
    contentPadding: {
      type: String,
      default: 'px-8 pb-5',
    },
    headerPadding: {
      type: String,
      default: 'px-8 pt-5',
    },
  },
  methods: {
    handleClickOut () {
      if (this.trigger === 'click' && this.show) {
        this.show = false
      }
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(6px);
}
.tooltip {
  display: inline-block;
}
.tooltip-shadow {
  box-shadow: 0 8px 15px 0 rgba(152, 169, 188, 0.27);
}
.tooltip-trigger {
  display: inline-block;
  position: relative;
}
.tooltip-popper {
  position: absolute;
  z-index: 1020;
}
.tooltip-content {
  max-width: 400px;
  word-wrap: break-word;
}
.tooltip-header {
  max-width: 400px;
  word-wrap: break-word;
}
.tooltip-arrow {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: 4px solid transparent;
}
.tooltip-top,
.tooltip-top-left,
.tooltip-top-right {
  padding: 4px 0px;
  margin-top: -2px;
}
.tooltip-top .tooltip-arrow,
.tooltip-top-left .tooltip-arrow,
.tooltip-top-right .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -4px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.tooltip-top-left .tooltip-arrow {
  left: 12px;
  right: initial;
}
.tooltip-top-right .tooltip-arrow {
  left: initial;
  right: 8px;
}
.tooltip-bottom,
.tooltip-bottom-left,
.tooltip-bottom-right {
  padding: 4px 0px;
  margin-top: 2px;
}
.tooltip-bottom .tooltip-arrow,
.tooltip-bottom-left .tooltip-arrow,
.tooltip-bottom-right .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -4px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.tooltip-bottom-left .tooltip-arrow {
  left: 12px;
  right: initial;
}
.tooltip-bottom-right .tooltip-arrow {
  left: initial;
  right: 8px;
}
.tooltip-left,
.tooltip-left-top,
.tooltip-left-bottom {
  padding: 0px 4px;
  margin-left: -2px;
}
.tooltip-left .tooltip-arrow,
.tooltip-left-top .tooltip-arrow,
.tooltip-left-bottom .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -4px;
  border-right-width: 0;
  border-left-color: #fff;
}
.tooltip-left-top .tooltip-arrow {
  top: 12px;
  bottom: initial;
}
.tooltip-left-bottom .tooltip-arrow {
  top: initial;
  bottom: 8px;
}
.tooltip-right,
.tooltip-right-top,
.tooltip-right-bottom {
  padding: 0px 4px;
  margin-left: 2px;
}
.tooltip-right .tooltip-arrow,
.tooltip-right-top .tooltip-arrow,
.tooltip-right-bottom .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -4px;
  border-left-width: 0;
  border-right-color: #fff;
}
.tooltip-right-top .tooltip-arrow {
  top: 12px;
  bottom: initial;
}
.tooltip-right-bottom .tooltip-arrow {
  top: initial;
  bottom: 8px;
}
</style>
