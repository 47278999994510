var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn p-btn text-sm leading-none font-normal rounded-lg flex items-center justify-center transition-2s",class:[
    _vm.color && !_vm.invert && !_vm.secondary && !_vm.disabled ? `bg-${_vm.color} hover:bg-${_vm.hoverColor} text-white` : ``,
    _vm.invert && !_vm.color && !_vm.secondary && !_vm.disabled ? `bg-white border-2 text-grey-dkr hover:bg-grey-ltr active:bg-white` : ``,
    _vm.invert && _vm.color && !_vm.secondary && !_vm.disabled ? `bg-white border-2 text-${_vm.color} hover:bg-white active:bg-white` : ``,
    !_vm.invert && !_vm.color && !_vm.secondary && !_vm.disabled ? `text-grey-dkr bg-cloud-dk border hover:bg-grey-lt active:bg-cloud-dk` : ``,
    _vm.invert && _vm.border && _vm.color && !_vm.secondary && !_vm.disabled ? `border-${_vm.border}` : ``,
    _vm.invert && !_vm.border && _vm.color && !_vm.secondary && !_vm.disabled ? `border-${_vm.color}` : ``,
    _vm.invert && !_vm.border && !_vm.color && !_vm.secondary && !_vm.disabled ? `border border-grey-lt` : ``,
    _vm.secondary && !_vm.disabled && !_vm.border ? 'border border-grey text-cta-lt hover:bg-grey-ltr bg-white' : '',
    _vm.secondary && !_vm.disabled && _vm.border ? `text-cta-lt hover:bg-grey-ltr bg-white border-${_vm.border}` : '',
    _vm.isLoading ? 'cursor-not-allowed opacity-50' : '',
    _vm.disabled ? 'bg-grey-lt border-grey text-border hover:cursor-not-allowed hover:bg-grey-lt' : ''
  ],style:({ height: _vm.height }),attrs:{"id":_vm.vid,"name":_vm.name,"value":_vm.value},on:{"click":_vm.handleClick}},[_c('div',{staticClass:"mx-auto flex items-center"},[_c('div',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(_vm.text)+" ")]),(_vm.isLoading)?_c('VinLoading',{staticClass:"ml-2",attrs:{"size":"6"}}):_vm._e(),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }