import get from 'lodash.get'
import Vue from 'vue'

import { EventBus } from '@/event-bus'

import axios from '../middleware/axios-store'
const module = {
  state: {
    isViewingVehicles: false,
    targetAlertPlace: null,
    targetAlert: null,
    targetAlertVehicles: [],
    placeAlerts: [],
    fetchingPlaceAlerts: false,
    alertPagination: {
      total: 0,
      offset: 0,
      limit: 10,
    },
  },
  getters: {
    getVehiclesByAlertId: state => id => {
      const alert = state.placeAlerts.find(alert => alert.id === id)
      if (alert.vehicleIds) {
        return alert.vehicleIds
      }
    },
  },
  mutations: {
    WIPE_ALERTS_MODULE (state) {
      state.isViewingVehicles = false
      state.targetAlertPlace = null
      state.targetAlert = null
      state.placeAlerts = []
      state.fetchingPlaceAlerts = false
      state.alertPagination = {
        total: 0,
        offset: 0,
        limit: 10,
      }
    },
    SET_ALERT_VIEW (state, payload) {
      state.isViewingVehicles = payload
    },
    PUSH_PLACE_ALERTS (state, alerts) {
      if (alerts) {
        state.placeAlerts = alerts
      }
    },
    PUSH_ALERT (state, alert) {
      state.placeAlerts.push(alert)
    },
    UPDATE_ALERT: (state, payload) => {
      // find alert match in store to transfer vehicleIds
      let updatedAlert = payload
      const oldAlert = state.placeAlerts.find(alert => {
        return alert.id === payload.id
      })
      // transfer vehicleIds to updated Alert
      updatedAlert.vehicleIds = oldAlert.vehicleIds
      // filter out old alert using payload
      state.placeAlerts = state.placeAlerts.filter(alert => alert.id !== payload.id)
      // push updated alert with paired vehicleIds
      state.placeAlerts.push(updatedAlert)
    },
    REMOVE_PLACE_ALERT: (state, payload) => {
      state.placeAlerts = state.placeAlerts.filter(alert => alert.id !== payload)
    },
    SET_TARGET_ALERT_PLACE (state, place) {
      state.targetAlertPlace = place
    },
    SET_TARGET_ALERT (state, alert) {
      state.targetAlert = alert
    },
    SET_ALERT_VEHICLES (state, payload) {
      // find the alert index
      let alertIndex = state.placeAlerts.findIndex(a => a.id === payload.alertId)
      // if the alert exists continue
      if (alertIndex > -1) {
        let alert = state.placeAlerts[alertIndex]
        // if the alert has vehicles, pair them
        if (payload.vehicleIds !== null) {
          alert.vehicleIds = payload.vehicleIds
          Vue.set(state.placeAlerts, alertIndex, alert)
        } else {
          // if not, give it an empty array
          alert.vehicleIds = []
          Vue.set(state.placeAlerts, alertIndex, alert)
        }
      }
    },
    PUSH_ALERT_VEHICLE: (state, vehicleId) => {
      // check for null even though it should be empty arr
      if (state.targetAlert.vehicleIds !== null) {
        state.targetAlert.vehicleIds.push(vehicleId)
      } else {
        // else set to array with length 1
        state.targetAlert.vehicleIds = [vehicleId]
      }
    },
    PUSH_TARGET_ALERT_VEHICLE: (state, vehicle) => {
      // check if alert already exists
      let match = state.targetAlertVehicles.find(v => {
        return v.id === vehicle.id
      })
      if (!match) {
        state.targetAlertVehicles.push(vehicle)
      }
    },
    REMOVE_ALERT_VEHICLE: (state, vehicleId) => {
      // clear from arr of vehicleIds
      state.targetAlert.vehicleIds = state.targetAlert.vehicleIds.filter(veh => veh !== vehicleId)
      // clear from arr of alert vehicles
      state.targetAlertVehicles = state.targetAlertVehicles.filter(veh => veh.id !== vehicleId)
    },
    WIPE_TARGET_ALERT_VEHICLES: (state) => {
      state.targetAlertVehicles = []
    },
    SET_FETCHING_ALERT_STATUS: (state, flag) => {
      state.fetchingPlaceAlerts = flag
    },
    UPDATE_PLACE_ALERT_PAGINATION: (state, pagination) => {
      state.alertPagination.total = get(pagination, 'total', state.alertPagination.total)
      state.alertPagination.offset = get(pagination, 'offset', state.alertPagination.offset)
      state.alertPagination.limit = get(pagination, 'limit', state.alertPagination.limit)
    },
  },
  actions: {
    HYDRATE_PLACE_ALERTS (context, placeId) {
      context.dispatch('FETCH_PLACE_PROMISE', placeId)
        .catch(err => {
          throw err
        })
      context.dispatch('FETCH_PLACE_ALERTS_PROMISE', placeId)
        .catch(err => {
          throw err
        })
    },
    FETCH_PLACE_PROMISE ({ commit, rootState }, placeId) {
      return new Promise((resolve, reject) => {
        // check for place in list
        const match = rootState.placesModule.orgPlaces.filter(p => p.id === placeId)
        if (match.length > 0) {
          const place = match[0]
          commit('SET_TARGET_ALERT_PLACE', place)
          return resolve(place)
        }
        axios.get(
          `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${placeId}`,
        )
          .then(resp => {
            commit('SET_TARGET_ALERT_PLACE', resp.data.place)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    FETCH_PLACE_ALERTS_PROMISE ({ dispatch, commit, rootState, state }, placeId) {
      return new Promise((resolve, reject) => {
        const page = state.alertPagination.offset / state.alertPagination.limit
        if (
          !state.alertPagination.length - 1 < page ||
          !get(state, `alertPagination[${page}]`)
        ) {
          commit('SET_FETCHING_ALERT_STATUS', true)
          axios.get(`/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${placeId}/alerts`, {
            params: {
              offset: state.alertPagination.offset,
              limit: state.alertPagination.limit,
            },
          })
            .then(resp => {
              commit('UPDATE_PLACE_ALERT_PAGINATION', {
                total: get(resp, 'data.meta.pagination.total'),
              })
              resp.data.alerts.forEach(a => {
                dispatch('FETCH_ALERT_VEHICLES_PROMISE', a.id)
              })
              commit('PUSH_PLACE_ALERTS', resp.data.alerts)
              commit('SET_FETCHING_ALERT_STATUS', false)
              resolve(resp)
            })
            .catch(err => {
              commit('SET_FETCHING_ALERT_STATUS', false)
              reject(err)
            })
        } else {
          commit('SET_FETCHING_ALERT_STATUS', false)
          resolve()
        }
      })
    },
    FETCH_ALERT_VEHICLES_PROMISE ({ commit, state, rootState }, alertId) {
      const placeId = state.targetAlertPlace.id
      return new Promise((resolve, reject) => {
        axios.get(
          `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${placeId}/alerts/${alertId}/vehicles`,
        )
          .then(resp => {
            const resPayload = {
              alertId: alertId,
              vehicleIds: resp.data.vehicleIds,
            }
            commit('SET_ALERT_VEHICLES', resPayload)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    FETCH_TARGET_ALERT_VEHICLES ({ commit, getters, rootState }, vehicleIds) {
      if (vehicleIds.length > 0) {
        vehicleIds.forEach(vehicleId => {
          // do we already have the vehicle?
          const existingVehicle = getters.getVehicleById(vehicleId)
          if (existingVehicle) {
            commit('PUSH_TARGET_ALERT_VEHICLE', existingVehicle)
            return
          }
          const orgId = rootState.authModule.activeOrg.id
          axios
            .get(`/api/v1/orgs/${orgId}/vehicles/${vehicleId}`)
            .then(resp => {
              commit('PUSH_TARGET_ALERT_VEHICLE', resp.data)
            })
            .catch(err => {
              throw err
            })
        })
      }
    },
    ADD_ALERT_VEHICLE_PROMISE ({ commit, state, rootState }, payload) {
      if (payload.vehicleIds.length > 0) {
        const placeId = state.targetAlertPlace.id
        payload.vehicleIds.forEach(vehicleId => {
          return new Promise((resolve, reject) => {
            axios.put(
              `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${placeId}/alerts/${payload.alertId}/vehicles/${vehicleId}`,
            )
              .then(resp => {
                commit('PUSH_ALERT_VEHICLE', vehicleId)
                resolve(resp)
              })
              .catch(err => {
                reject(err)
              })
          })
        })
      }
    },
    DELETE_ALERT_VEHICLE_PROMISE ({ commit, state, rootState }, vehicleId) {
      const placeId = state.targetAlertPlace.id
      const alertId = state.targetAlert.id
      return new Promise((resolve, reject) => {
        axios.delete(
          `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${placeId}/alerts/${alertId}/vehicles/${vehicleId}`,
        )
          .then(resp => {
            commit('REMOVE_ALERT_VEHICLE', vehicleId)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    CREATE_PLACE_ALERT_PROMISE ({ commit, rootState }, payload) {
      // grab the language setting from the logged-in user
      const language = get(rootState.authModule, 'session.language')
      if (language) {
        payload.alert.settings.language = language
      }
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      payload.alert.timezone = timezone || 'UTC'
      return new Promise((resolve, reject) => {
        axios.post(
          `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${payload.placeId}/alerts`, {
          alert: payload.alert,
        })
          .then(resp => {
            commit('PUSH_ALERT', resp.data.alert)
            EventBus.$emit('place-alert-added')
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    UPDATE_PLACE_ALERT_PROMISE ({ commit, rootState }, payload) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      payload.alert.timezone = timezone || 'UTC'
      return new Promise((resolve, reject) => {
        axios.put(
          `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${payload.placeId}/alerts/${payload.alertId}`, {
          name: payload.alert.name,
          settings: payload.alert.settings,
        })
          .then(resp => {
            commit('UPDATE_ALERT', resp.data.alert)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    DELETE_PLACE_ALERT_PROMISE ({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.alertId) {
          reject(new Error('placeId required to delete place'))
        }
        if (!payload.placeId) {
          reject(new Error('placeId required to delete place'))
        }
        axios.delete(
          `/api/v1/orgs/${rootState.authModule.activeOrg.id}/places/${payload.placeId}/alerts/${payload.alertId}`)
          .then(resp => {
            commit('REMOVE_PLACE_ALERT', payload.alertId)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}

export default module
