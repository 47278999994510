<template>
  <div class="flex items-center justify-center">
    <span
      :class="colorForText"
      class="flex leading-none text-lg"
    >
      <div v-if="direction === 'up'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="stroke-current mr-2 h-full"
          width="16"
          height="13"
          viewBox="0 0 16 15"
        >
          <path
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14.303 9.437v-7.9H6.13m-4.4 12.004l12.45-12.034"
          />
        </svg>
      </div>

      <div v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="stroke-current mr-2 h-full"
          width="16"
          height="13"
          viewBox="0 0 16 15"
        >
          <path
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13.571 5.104v7.9H5.4M1 1l12.45 12.034"
          />
        </svg>
      </div>
      {{ prettyChange }}
      <span v-if="isPercent">%</span>
    </span>
    <span class="ml-2 text-grey-dk text-base tracking-relaxed leading-none">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'VinStatChange',
  props: {
    changeClass: {
      type: String,
      default: 'positive',
    },
    isPercent: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      default: 'up',
    },
    change: {
      type: [String, Number],
      default: 3.4,
    },
    label: {
      type: String,
      default: null,
    },
  },
  computed: {
    colorForText () {
      return this.changeClass === 'positive' ? 'text-positive' : 'text-negative'
    },
    prettyChange () {
      return this.change
    },
  },
}
</script>
