// init the theme
import './assets/themes/init-theme'
// load shared styles
import '@/assets/styles/tailwind.css'
// load shared components
import '@/common/components'
// load widgets
import '@/common/widgets'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import GmapVue from 'gmap-vue'
import omit from 'lodash.omit'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueFuse from 'vue-fuse'
import SmartTable from 'vuejs-smart-table'
import vuexI18n from 'vuex-i18n'

import initLocale from '@/common/utils/initial-locale.js'

// load locales
import daDk from '../public/translations/da-dk.json'
import enGb from '../public/translations/en-gb.json'
import esEs from '../public/translations/es-es.json'
import esMx from '../public/translations/es-mx.json'
import esPe from '../public/translations/es-pe.json'
import fr from '../public/translations/fr.json'
import hr from '../public/translations/hr.json'
import hu from '../public/translations/hu.json'
import no from '../public/translations/no.json'
import pl from '../public/translations/pl.json'
import ru from '../public/translations/ru.json'
import ruUa from '../public/translations/ru-ua.json'
import sl from '../public/translations/sl.json'
import svSe from '../public/translations/sv-se.json'
import tr from '../public/translations/tr.json'
import ukUa from '../public/translations/uk-ua.json'
// load app
import App from './App.vue'
import errorHandler from './errorHandler'
import router from './router/router'
import store from './state/store'
import ViewAuth from './views/ViewAuth.vue'
import ViewDashboard from './views/ViewDashboard.vue'
import ViewDashboardEditor from './views/ViewDashboardEditor.vue'
// load views
import ViewDefault from './views/ViewDefault.vue'
import ViewFullScreen from './views/ViewFullScreen.vue'
import ViewLocations from './views/ViewLocations.vue'
import ViewServiceLocations from './views/ViewServiceLocations.vue'

Vue.component('ViewDefault', ViewDefault)
Vue.component('ViewDashboard', ViewDashboard)
Vue.component('ViewAuth', ViewAuth)
Vue.component('ViewDashboardEditor', ViewDashboardEditor)
Vue.component('ViewFullScreen', ViewFullScreen)
Vue.component('ViewLocations', ViewLocations)
Vue.component('ViewServiceLocations', ViewServiceLocations)

Vue.config.productionTip = false

// v-calendar plugin
Vue.use(VCalendar, {
  firstDayOfWeek: 2, // Monday
})

// gmaps
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_MAPS_KEY,
    libraries: 'geometry,drawing,places',
  },
})

// i18n plugin
Vue.use(
  vuexI18n.plugin,
  store, {
  // onTranslationNotFound (locale, key) {
  // console.warn(`i18n :: Key '${key}' not found for locale '${locale}'`)
  // console.log(`"${key}": "${key}"`)
  // }
},
)

Vue.i18n.add('da-dk', daDk)
Vue.i18n.add('en-gb', enGb)
Vue.i18n.add('es-es', esEs)
Vue.i18n.add('es-mx', esMx)
Vue.i18n.add('pl', pl)
Vue.i18n.add('ru', ru)
Vue.i18n.add('hr', hr)
Vue.i18n.add('sl', sl)
Vue.i18n.add('uk-ua', ukUa)
Vue.i18n.add('fr', fr)
Vue.i18n.add('hu', hu)
Vue.i18n.add('es-pe', esPe)
Vue.i18n.add('ru-ua', ruUa)
Vue.i18n.add('no', no)
Vue.i18n.add('tr', tr)
Vue.i18n.add('sv-se', svSe)
// set the start locale to use
Vue.i18n.set(initLocale(store.state.localizationModule.locales))
Vue.i18n.fallback('en-gb')

// plugins
Vue.use(VueFuse)
Vue.use(SmartTable)

// global error handling
Vue.config.errorHandler = (err) => errorHandler(err)
// error tracking
if (process.env.NODE_ENV !== 'development') {
  const location = `${window.location}`
  const isStage = location.includes('fleet.s01')
  const isProd = location.includes('fleet.ald.vinli.eu') || location.includes('myprofleet.net')

  let stack = 'dev'

  if (isStage) {
    stack = 'stage'
  }
  if (isProd) {
    stack = 'production'
  }
  Sentry.init({
    dsn: 'https://c210db9eca734596baa3a4c13979200e@sentry.io/1513658',
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
    ],
    release: process.env.VUE_APP_BUILD_VERSION,
    environment: stack,
    ignoreErrors: [
      'Request aborted',
      'Map: Expected mapDiv of type Element but was passed undefined.',
    ],
  })
}

store.subscribe((mutation, state) => {
  // Store the paramsModule state object as a JSON string in localstorage anytime it's mutated
  const paramsModuleMutations = ['CLEAR_PARAM_MODULE', 'SET_DURATION', 'SET_CUSTOM_DURATION', 'SET_MONTH_DURATION', 'SET_SORT_FOR_SLUG']
  if (paramsModuleMutations.indexOf(mutation.type) !== -1) {
    const pfParams = omit(state.paramsModule, ['targetVehicle', 'dashConfig', 'since', 'until', 'duration', 'durationOptions'])
    localStorage.setItem('pfParamsModule', JSON.stringify(pfParams))
  }
})

// say howdy to savvy folks!
const css = 'background-image: linear-gradient(to bottom, #eb088c, #29abe2); color: white; font-size: 15px; padding: 5px;'
console.log(`%c(•_•) , ( •_•)>⌐■-■ , (⌐■_■)`, css)

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


export default app
